import moment from "moment";
import { ForbiddenError, NotAuthorizedError, WrappedZodParseError } from ".";

export const parseTimestamps = (q: any) => {
  return ["insertedAt", "updatedAt"].reduce((memo, key) => {
    const val = memo[key];
    if (val) {
      return {
        ...q,
        [key]: moment(val),
      };
    }
    return q;
  }, q);
};

/**
 * For the 403 error to reach the error boundary we have to throw it here.
 */
export function throwIfForbiddenError(
  error?: Error | NotAuthorizedError | ForbiddenError
) {
  if (error instanceof ForbiddenError) {
    throw error;
  }
}

export function throwIfZodError(error?: Error | WrappedZodParseError) {
  if (error instanceof WrappedZodParseError) {
    throw error;
  }
}
