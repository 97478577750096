import React, { useState, useRef, useEffect } from "react";
import { ArrowLeftIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { CogIcon, XCircleIcon } from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";

import Modal from "components/core/Modal";
import Steps from "components/core/Steps";
import { Button } from "lib/lucidez";
import { getPlatformForKind } from "lib/platforms";
import { DestinationProps } from "components/platforms/common/forms/DestinationStep";

enum ExistingDatabaseSetupStep {
  Select = "select",
  EditSchemas = "edit",
}

type ExistingDatabaseSetupProps = DestinationProps & {
  setSetupStep: (step: ExistingDatabaseSetupStep) => void;

  setSelectedDbId: (id?: string) => void;
  selectedDbId?: string;

  onBack: () => void;
};

export const ExistingDatabaseSetup = ({
  onClose,
  ...props
}: DestinationProps & { onClose: () => void }) => {
  const [setupStep, setSetupStep] = useState(ExistingDatabaseSetupStep.Select);
  const [selectedDbId, setSelectedDbId] = useState<string | undefined>();

  const stepProps = { ...props, setSetupStep, selectedDbId, setSelectedDbId };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      cardClassName="transform bg-white rounded shadow-xl p-8"
    >
      <Steps activeStep={setupStep} resetPosition>
        <Steps.Step stepKey={ExistingDatabaseSetupStep.Select}>
          <SelectDatabaseStep {...stepProps} onBack={handleClose} />
        </Steps.Step>
        <Steps.Step stepKey={ExistingDatabaseSetupStep.EditSchemas}>
          <EditSchemaStep
            {...stepProps}
            onBack={() => setSetupStep(ExistingDatabaseSetupStep.Select)}
          />
        </Steps.Step>
      </Steps>
    </Modal>
  );
};

const SelectDatabaseStep = ({
  setSetupStep,
  setSelectedDbId,
  databases,
  destination,
  onBack,
}: ExistingDatabaseSetupProps) => {
  return (
    <>
      <button onClick={onBack} aria-label="Back">
        <ArrowLeftIcon className="h-6 w-6" />
      </button>
      <Modal.Title className="text-2xl font-bold mt-8">
        Select a database
      </Modal.Title>
      <div className="grid grid-cols-1 gap-5 mt-8">
        {databases
          .filter(
            (db) =>
              !(
                db.definition.type === "shared_postgres" &&
                (!db.resources || db.resources.length === 0)
              )
          )
          .map((db) => (
            <div key={db.id}>
              <div className="flex flex-row items-center">
                <h5 className="font-bold">{db.name || db.definition.dbname}</h5>
                <div className="flex-1" />
                {destination.type !== "new" &&
                destination.type !== "placeholder" &&
                destination.databaseId === db.id ? (
                  <>
                    <CheckCircleIcon className="h-4 w-4 text-success" />
                    <span className="font-bold ml-1 mr-5">Selected</span>
                    <Button
                      variant="outlined"
                      size="md"
                      iconLeft={<CogIcon className="h-4 w-4" />}
                      onClick={() => {
                        setSelectedDbId(db.id);
                        setSetupStep(ExistingDatabaseSetupStep.EditSchemas);
                      }}
                    >
                      Configure
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    size="md"
                    onClick={() => {
                      setSelectedDbId(db.id);
                      setSetupStep(ExistingDatabaseSetupStep.EditSchemas);
                    }}
                  >
                    Select
                  </Button>
                )}
              </div>
              <table className="w-full border-collapse rounded mt-2">
                <thead>
                  <tr>
                    <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/3">
                      Platform
                    </th>
                    <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/3">
                      Name
                    </th>
                    <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/3">
                      Schema
                    </th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {db.resources.map((res) => {
                    const platform = getPlatformForKind(res.kind);
                    const DisplayIcon = platform.displayIcon;

                    return (
                      <tr key={res.id}>
                        <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                          <DisplayIcon className="inline-block h-4 w-4 mr-1" />
                          {platform.displayName}
                        </td>
                        <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                          {res.name}
                        </td>
                        <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                          <code>{res.schema}</code>
                        </td>
                      </tr>
                    );
                  })}
                  {db.resources.length === 0 && (
                    <tr>
                      <td
                        className="border border-gray-200 text-black py-3 px-4 w-1/3 text-center"
                        colSpan={3}
                      >
                        No resources are syncing to this database
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </>
  );
};

enum SchemaState {
  Valid = "Valid",
  Duplicated = "Duplicated",
  InvalidChars = "InvalidChars",
  UpperCase = "UpperCase",
  Empty = "Empty",
}

const EditSchemaStep = ({
  selectedDbId,
  databases,
  platformName,
  resourceName,
  resource,
  onDestinationChange,
  onFinishFlow,
  onBack,
}: ExistingDatabaseSetupProps) => {
  const isAlreadySyncingToThisDatabase =
    resource && resource.database && resource.database.id === selectedDbId;

  const selectedDb = databases.find((x) => x.id === selectedDbId);
  const filteredResources = resource
    ? selectedDb.resources.filter((x) => x.id !== resource.id)
    : selectedDb.resources;

  const schemaInput = useRef<HTMLInputElement>();
  const [schemaValidationState, setSchemaValidationState] = useState(
    SchemaState.Valid
  );
  const [schema, setSchema] = useState(
    isAlreadySyncingToThisDatabase
      ? resource.database.schema
      : resourceName.toLowerCase().replace(/[^a-zA-Z0-9_\d]/g, "_")
  );

  const handleConfirm = () => {
    onDestinationChange({
      type: "existing",
      validSchema: true, // for legacy reasons
      databaseId: selectedDbId,
      schema,
    });
    onFinishFlow();
  };

  const revalidateSchema = () => {
    setSchemaValidationState(() => {
      if (!schema) {
        return SchemaState.Empty;
      }

      if (filteredResources.find((x) => x.schema === schema)) {
        return SchemaState.Duplicated;
      }

      if (/[A-Z]/.test(schema)) {
        return SchemaState.UpperCase;
      }

      if (!/^[a-z_]\w*$/.test(schema)) {
        return SchemaState.InvalidChars;
      }

      return SchemaState.Valid;
    });
  };

  useEffect(revalidateSchema, [schema]);

  const getValidationIcon = () => {
    switch (schemaValidationState) {
      case SchemaState.Valid:
        return (
          <Tippy content="Valid schema" interactive>
            <span>
              <CheckCircleIcon className="h-6 w-6 focus:ring ring-black text-success" />
            </span>
          </Tippy>
        );

      case SchemaState.UpperCase:
        return (
          <Tippy
            content="Schema must only contain lowercase characters"
            interactive
          >
            <span>
              <XCircleIcon className="h-6 w-6 focus:ring ring-black text-error" />
            </span>
          </Tippy>
        );

      case SchemaState.InvalidChars:
        return (
          <Tippy content="Schema contains invalid characters" interactive>
            <span>
              <XCircleIcon className="h-6 w-6 focus:ring ring-black text-error" />
            </span>
          </Tippy>
        );

      case SchemaState.Duplicated:
        return (
          <Tippy
            content="Another resource already syncs to this schema"
            interactive
          >
            <span>
              <XCircleIcon className="h-6 w-6 focus:ring ring-black text-error" />
            </span>
          </Tippy>
        );

      case SchemaState.Empty:
        return (
          <Tippy content="Schema can't be empty" interactive>
            <span>
              <XCircleIcon className="h-6 w-6 focus:ring ring-black text-error" />
            </span>
          </Tippy>
        );

      default:
        break;
    }
  };

  return (
    <div>
      <button onClick={onBack} aria-label="Back">
        <ArrowLeftIcon className="h-6 w-6" />
      </button>
      <Modal.Title className="text-2xl font-bold mt-8">
        Set the schema
      </Modal.Title>
      <div className="grid grid-cols-1 gap-5 mt-8">
        <h5 className="font-bold">
          {selectedDb.name || selectedDb.definition.dbname}
        </h5>
        <table className="w-full border-collapse rounded">
          <thead>
            <tr>
              <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/3">
                Platform
              </th>
              <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/3">
                Name
              </th>
              <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/3 border-b-0">
                Schema
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="font-bold">
              <td className="border border-gray-200  text-black py-3 px-4 w-1/3">
                {platformName}
              </td>
              <td className="border border-gray-200  text-black py-3 px-4 w-1/3 border-r-0">
                {resourceName}
              </td>
              <td className="border border-gray-400 focus-within:border-black text-black w-1/3 bg-gray-100">
                <div className="w-full h-full flex flex-row focus-within:ring ring-black items-center">
                  <input
                    className="w-full h-full flex-1 py-3 px-4 focus:outline-none  bg-gray-100"
                    placeholder="Enter schema..."
                    ref={schemaInput}
                    value={schema}
                    onChange={(e) => setSchema(e.target.value)}
                  />
                  <div className="h-full flex justify-center items-center px-2">
                    {getValidationIcon()}
                  </div>
                </div>
              </td>
            </tr>
            {filteredResources.map((res) => {
              const platform = getPlatformForKind(res.kind);

              return (
                <tr key={res.id}>
                  <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                    {platform.displayName}
                  </td>
                  <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                    {res.name}
                  </td>
                  <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                    <code>{res.schema}</code>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-row mt-4">
          <div className="flex-1" />
          <Button
            variant="primary"
            size="md"
            disabled={schemaValidationState !== SchemaState.Valid}
            onClick={handleConfirm}
          >
            Confirm changes
          </Button>
        </div>
      </div>
    </div>
  );
};
