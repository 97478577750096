import { ArrowLeftIcon } from "@heroicons/react/outline";
import SvgChooseListOutlined from "components/svg/ChooseList";
import React, { useState } from "react";
import { ExistingDatabaseSetup } from "components/platforms/common/forms/DestinationStep/ExistingDatabaseSetup";
import { ExternalDatabaseSetup } from "./ExternalDatabaseSetup";
import SequinDevDbIcon from "components/svg/SequinDevDb";
import SequinConnectDbIcon from "components/svg/SequinConnectDb";
import { DestinationProps } from "components/platforms/common/forms/DestinationStep";
import classNames from "classnames";
import SvgLaunchDatabaseOutlined from "components/svg/LaunchDatabase";
import SvgConnectDatabaseOutlined from "components/svg/ConnectDatabase";

export const ChangeDestinationStep = (props: DestinationProps) => {
  const { onDestinationChange, onFinishFlow, databases, destination } = props;

  const [isExisting, setIsExisting] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const filteredDatabases = databases.filter(
    (db) =>
      db.definition.type === "dedicated_postgres" || db.resources.length > 0
  );

  const isPlaceholder = destination.type === "placeholder";

  return (
    <div>
      <div className="text-center flex flex-row items-center">
        {!isPlaceholder && (
          <button
            className="btn-outlined w-8 h-8 border-transparent flex justify-center items-center"
            onClick={() => onFinishFlow(true)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </button>
        )}
        <h3 className="font-bold flex-1">
          {isPlaceholder ? "Select a destination" : "Change destination"}
        </h3>
        {!isPlaceholder && <div className="w-8" />}
      </div>
      <div className="flex flex-col mt-5 gap-2">
        {filteredDatabases.length > 0 && (
          <button
            className="flex border flex-row py-5 w-full rounded items-center border-gray-200 hover:border-gray-800"
            onClick={() => {
              setIsExisting(true);

              if (window.analytics) {
                window.analytics.track("Destination Choose Existing Clicked");
              }
            }}
          >
            <div className="ml-10 mr-8 w-8 flex items-center justify-center">
              <SvgChooseListOutlined className="h-7 w-7" />
            </div>
            <div className="flex flex-col text-left pr-5">
              <p className="font-bold ">Select an existing database</p>
              <p className="text-xs" style={{ maxWidth: 210 }}>
                Choose from one of your connected databases (
                {filteredDatabases.length} total)
              </p>
            </div>
          </button>
        )}
        <button
          className="flex border flex-row py-5 w-full rounded items-center border-gray-200 hover:border-gray-800"
          onClick={() => {
            onDestinationChange({ type: "new" });

            if (window.analytics) {
              window.analytics.track("Destination Launch Clicked");
            }

            onFinishFlow(false);
          }}
        >
          <div className="ml-10 mr-8 w-8 flex items-center justify-center">
            <SequinDevDbIcon className="h-8" />
          </div>
          <div className="flex flex-col text-left pr-5">
            <p className="font-bold">New Sequin Database</p>
            <p className="text-xs" style={{ maxWidth: 210 }}>
              Launch a new database on a shared Sequin instance.
            </p>
          </div>
        </button>
        <button
          className="flex border flex-row py-5 w-full rounded items-center border-gray-200 hover:border-gray-800"
          onClick={() => {
            setIsExternal(true);

            if (window.analytics) {
              window.analytics.track("Destination Connect Clicked");
            }
          }}
        >
          <div className="ml-10 mr-8 w-8 flex items-center justify-center">
            <SequinConnectDbIcon className="h-7 w-7" />
          </div>
          <div className="flex flex-col text-left pr-5">
            <p className="font-bold ">New database connection</p>
            <p className="text-xs" style={{ maxWidth: 210 }}>
              Connect Sequin to an existing Postgres database.
            </p>
          </div>
        </button>

        {isExisting && (
          <ExistingDatabaseSetup
            {...props}
            onClose={() => setIsExisting(false)}
          />
        )}

        {isExternal && (
          <ExternalDatabaseSetup
            {...props}
            onClose={() => setIsExternal(false)}
          />
        )}
      </div>
    </div>
  );
};

export const OnboardChangeDestinationStep = (props: DestinationProps) => {
  const { destination, onDestinationChange, onFinishFlow } = props;
  const [isExternal, setIsExternal] = useState(false);

  const isPlaceholder = destination.type === "placeholder";

  return (
    <div>
      <div className="text-center flex flex-row items-center">
        {!isPlaceholder && (
          <button
            className="btn-outlined w-8 h-8 border-transparent flex justify-center items-center"
            onClick={() => {
              onFinishFlow(true);
            }}
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </button>
        )}

        <h3 className="font-bold flex-1">Choose your destination</h3>
        <div className="w-8" />
      </div>
      <div className="grid grid-cols-2 gap-2 mt-5">
        <button
          className={classNames(
            "bg-white shadow rounded p-4 py-6 border flex flex-col text-center items-center justify-center border-gray-200 hover:border-gray-800",
            { "border-2 border-gray-500": destination.type === "new" }
          )}
          onClick={() => {
            onDestinationChange({ type: "new" });

            if (window.analytics) {
              window.analytics.track("Destination Launch Clicked");
            }

            onFinishFlow(false);
          }}
        >
          <SvgLaunchDatabaseOutlined className="w-12 h-12" />
          <p className="font-bold text-base mt-2">Sequin database</p>
          <p className="text-xxs text-gray-600 mt-2">
            Launch a pre-configured database on a shared Sequin instance.
          </p>
          <span className="mt-2 font-bold text-xxs text-gray-600">
            (easiest)
          </span>
        </button>
        <button
          className={classNames(
            "bg-white shadow rounded p-4 py-6 border flex flex-col text-center items-center justify-center border-gray-200 hover:border-gray-800",
            { "border-2 border-gray-500": destination.type === "external" }
          )}
          onClick={() => {
            setIsExternal(true);

            if (window.analytics) {
              window.analytics.track("Destination Connect Clicked");
            }
          }}
        >
          <SvgConnectDatabaseOutlined className="w-12 h-12" />
          <p className="font-bold text-base mt-2">Your database</p>
          <p className="text-xxs text-gray-600 mt-2">
            Connect Sequin to your existing Postgres database.
          </p>
          <span className="mt-2 font-bold text-xxs text-gray-600">
            (still very easy)
          </span>
        </button>
        {isExternal && (
          <ExternalDatabaseSetup
            {...props}
            onClose={() => setIsExternal(false)}
          />
        )}
      </div>
    </div>
  );
};
