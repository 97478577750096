import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 34 34" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#svgChoose__clip0)">
        <path
          d="M12.467 17.785l6.31 15.327a.75.75 0 001.225.244l3.447-3.447 3.712 3.713a.752.752 0 001.06 0l1.06-1.061a.75.75 0 000-1.061l-3.711-3.712 3.447-3.447a.75.75 0 00-.245-1.224l-15.326-6.311a.75.75 0 00-.98.979v0z"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path d="M0 1h27M0 11h27M0 21h8" stroke="#000" />
      <defs>
        <clipPath id="svgChoose__clip0">
          <path fill="#fff" transform="translate(9 13.5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const SvgChooseListOutlined = React.memo(SvgComponent);
export default SvgChooseListOutlined;
