import React from "react";
import merge from "lodash/merge";
import * as z from "zod";

import AirtableForm, {
  FullPageAirtableForm,
  OnboardCredentialAirtableForm,
} from "components/platforms/airtable/AirtableForm";
import SvgAirtableFilled from "components/svg/AirtableFilled";
import {
  baseCredentialSchema,
  baseResourceSchema,
  RateLimit,
  rawResourceSchema,
} from "lib/api/types";
import { Platform } from "lib/platforms";
import {
  baseInitialCreateState,
  baseInitialUpdateState,
  basePrepareFieldsForCreate,
  basePrepareFieldsForUpdate,
  BaseState,
} from "lib/platforms/base";
import {
  rateLimitableInitialCreateState,
  rateLimitableInitialUpdateState,
  rateLimitablePrepareFieldsForCreate,
  rateLimitablePrepareFieldsForUpdate,
  RateLimitableState,
} from "lib/platforms/rateLimitable";
import { AirtableCredentialOAuthForm } from "components/platforms/airtable/AirtableCredentialOAuthForm";

// Metadata definition
export const airtableDefinitionSchema = z.object({
  baseId: z.string(),
  deltaSyncEnabled: z.boolean(),
  debounceMs: z.number(),
  proxyWaitActive: z.boolean(),
});

export type AirtableDefinition = z.infer<typeof airtableDefinitionSchema>;

export const airtableLegacyCredentialSchema = baseCredentialSchema.extend({
  payload: z.object({
    kind: z.literal("airtable"),
    secret: z.string(),
  }),
});

export type AirtableLegacyCredential = z.infer<
  typeof airtableLegacyCredentialSchema
>;

export const airtableOauthCredentialSchema = baseCredentialSchema.extend({
  payload: z.object({
    kind: z.literal("airtable_oauth"),
    refresh_token: z.string(),
    airtable_uid: z.string(),
  }),
});
export type AirtableOauthCredential = z.infer<
  typeof airtableOauthCredentialSchema
>;

// Credential definition
export const airtableCredentialSchema = z.union([
  airtableLegacyCredentialSchema,
  airtableOauthCredentialSchema,
]);

export type AirtableCredential = z.infer<typeof airtableCredentialSchema>;

// Integral resource definition
export const rawAirtableResourceSchema = rawResourceSchema.extend({
  kind: z.literal("airtable"),
  definition: airtableDefinitionSchema,
  credential: airtableCredentialSchema,
});

export const airtableResourceSchema = baseResourceSchema.extend({
  kind: z.literal("airtable"),
  definition: airtableDefinitionSchema,
  credential: airtableCredentialSchema,
});

export type AirtableResource = z.infer<typeof airtableResourceSchema>;

export interface AirtableState
  extends BaseState<AirtableCredential>,
    RateLimitableState {
  baseId: string;
  usePreferSingleRecordLinks: boolean;
  deltaSyncEnabled: boolean;
  debounceMs: number;
}

const DEFAULT_RATE_LIMIT: RateLimit = {
  allowedRequests: 3,
  interval: 1_000,
  maxConcurrentRequests: 3, // not in use right now, binded to allowedRequests
};

// Platform definition
export const AIRTABLE_PLATFORM: Platform<
  AirtableResource,
  AirtableState,
  AirtableCredential
> = {
  displayName: "Airtable",
  displayIcon: ({ ref, ...props }) => {
    return <SvgAirtableFilled {...props} />;
  },
  resourceAppositive: "base",
  kind: "airtable",

  supportsCollectionStatus: true,
  supportsWriteProxy: true,

  helpers: [
    { name: "ALL_WRITABLE" },
    // {
    //   name: "PROXY",
    //   buildUrl: (res) =>
    //     `https://proxy.sequin.io/api.airtable.com/v0/${res.definition.baseId}`,
    // },
    {
      name: "SOURCE",
      buildUrl: (res) => ({
        url: `https://airtable.com/${res.definition.baseId}`,
        displayUrl: `airtable.com/${res.definition.baseId}`,
      }),
    },
    { name: "TURBO" },
  ],

  buildForm: AirtableForm,
  buildCredentialForm: AirtableCredentialOAuthForm,
  buildOnboardCredentialForm: OnboardCredentialAirtableForm,
  extraTableParams: ([baseId, ..._state]) => {
    // Return `null` if no base_id so we know we're missing something
    return baseId ? { base_id: baseId } : null;
  },

  fullPageForm: FullPageAirtableForm,

  getLabelForCredential: (cred: any) =>
    cred.payload.secret ?? cred.payload.airtable_uid,

  getInitialCreateState: () => ({
    ...baseInitialCreateState("My Airtable Base"),
    ...rateLimitableInitialCreateState(DEFAULT_RATE_LIMIT),
    baseId: "",
    singleRecordLinks: false,
    usePreferSingleRecordLinks: true,
    deltaSyncEnabled: false,
    debounceMs: 0,
  }),

  getInitialUpdateState: (resource) => ({
    ...baseInitialUpdateState(resource),
    ...rateLimitableInitialUpdateState(resource, DEFAULT_RATE_LIMIT),
    baseId: resource.definition.baseId,
    deltaSyncEnabled: resource.definition.deltaSyncEnabled,
    debounceMs: resource.definition.debounceMs,
    usePreferSingleRecordLinks:
      resource.schema.airtableUsePreferSingleRecordLinks,
  }),

  prepareFieldsForCreate: (data) =>
    merge(
      basePrepareFieldsForCreate(data, "airtable"),
      merge(rateLimitablePrepareFieldsForCreate(data, true), {
        definition: {
          baseId: data.baseId,
          deltaSyncEnabled: data.deltaSyncEnabled,
          debounceMs: data.debounceMs,
        },
        schema: {
          transforms: {
            airtableUsePreferSingleRecordLinks: data.usePreferSingleRecordLinks,
          },
        },
      })
    ),

  prepareFieldsForUpdate: (data) =>
    merge(
      basePrepareFieldsForUpdate(data, "airtable"),
      merge(rateLimitablePrepareFieldsForUpdate(data, true), {
        definition: {
          baseId: data.baseId,
          deltaSyncEnabled: data.deltaSyncEnabled,
          debounceMs: data.debounceMs,
        },
        schema: {
          transforms: {
            airtableUsePreferSingleRecordLinks: data.usePreferSingleRecordLinks,
          },
        },
      })
    ),

  syncTime: true,
  sourceLink: (r) => `https://airtable.com/${r.definition.baseId}`,
};
