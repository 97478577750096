import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 23 20" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.1795 0.240781L1.67982 3.75778C1.20716 3.95339 1.21205 4.62489 1.68768 4.81341L10.2228 8.19807C10.9727 8.49548 11.8078 8.49548 12.5577 8.19807L21.093 4.81328C21.5685 4.62489 21.5736 3.95352 21.1007 3.75791L12.6013 0.240653C11.8259 -0.0802175 10.9548 -0.0802175 10.1794 0.240653"
        fill="currentColor"
      />
      <path
        d="M12.1484 10.0462V18.5017C12.1484 18.9037 12.554 19.1792 12.9278 19.031L22.4385 15.3394C22.5446 15.2974 22.6356 15.2244 22.6997 15.13C22.7638 15.0356 22.798 14.9242 22.798 14.81V6.35471C22.798 5.95254 22.3925 5.67716 22.0187 5.82535L12.508 9.51696C12.4019 9.55904 12.3109 9.63199 12.2468 9.72638C12.1827 9.82076 12.1484 9.93222 12.1484 10.0463"
        fill="currentColor"
      />
      <path
        d="M9.92718 10.4825L7.10461 11.8453L6.81803 11.9839L0.85976 14.8388C0.482197 15.021 0 14.7458 0 14.3262V6.39015C0 6.23836 0.077832 6.1073 0.182209 6.00873C0.22503 5.96609 0.273609 5.92965 0.326533 5.90048C0.468796 5.81505 0.67188 5.79224 0.844554 5.86054L9.87976 9.44055C10.339 9.62276 10.3751 10.2663 9.92718 10.4826"
        fill="currentColor"
      />
      <path
        d="M9.92857 10.4825L7.106 11.8453L0.183594 6.0086C0.226423 5.966 0.275002 5.92961 0.327918 5.90048C0.47018 5.81505 0.673265 5.79224 0.845939 5.86054L9.88115 9.44055C10.3404 9.62276 10.3765 10.2663 9.92857 10.4826"
        fill="currentColor"
        fillOpacity="0.25"
      />
    </svg>
  );
}

const SvgAirtableFilled = React.memo(SvgComponent);
export default SvgAirtableFilled;

const SvgComponentColored = ({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg viewBox="0 0 23 20" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M10.1795 0.240781L1.67982 3.75778C1.20716 3.95339 1.21205 4.62489 1.68768 4.81341L10.2228 8.19807C10.9727 8.49548 11.8078 8.49548 12.5577 8.19807L21.093 4.81328C21.5685 4.62489 21.5736 3.95352 21.1007 3.75791L12.6013 0.240653C11.8259 -0.0802175 10.9548 -0.0802175 10.1794 0.240653"
        fill="#FCB400"
      />
      <path
        d="M12.1484 10.0462V18.5017C12.1484 18.9037 12.554 19.1792 12.9278 19.031L22.4385 15.3394C22.5446 15.2974 22.6356 15.2244 22.6997 15.13C22.7638 15.0356 22.798 14.9242 22.798 14.81V6.35471C22.798 5.95254 22.3925 5.67716 22.0187 5.82535L12.508 9.51696C12.4019 9.55904 12.3109 9.63199 12.2468 9.72638C12.1827 9.82076 12.1484 9.93222 12.1484 10.0463"
        fill="#18BFFF"
      />
      <path
        d="M9.92718 10.4825L7.10461 11.8453L6.81803 11.9839L0.85976 14.8388C0.482197 15.021 0 14.7458 0 14.3262V6.39015C0 6.23836 0.077832 6.1073 0.182209 6.00873C0.22503 5.96609 0.273609 5.92965 0.326533 5.90048C0.468796 5.81505 0.67188 5.79224 0.844554 5.86054L9.87976 9.44055C10.339 9.62276 10.3751 10.2663 9.92718 10.4826"
        fill="#F82B60"
      />
      <path
        d="M9.92857 10.4825L7.106 11.8453L0.183594 6.0086C0.226423 5.966 0.275002 5.92961 0.327918 5.90048C0.47018 5.81505 0.673265 5.79224 0.845939 5.86054L9.88115 9.44055C10.3404 9.62276 10.3765 10.2663 9.92857 10.4826"
        fill="#BA2048"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export const SvgAirtableColored = React.memo(SvgComponentColored);
