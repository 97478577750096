import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from "react";
import update from "immutability-helper";
import noScroll from "no-scroll";
import Displace from "./Displace";
import _ from "lodash";
import FocusTrap from "focus-trap-react";
import { useId } from "../../lib/utils";
import classNames from "classnames";

interface State {
  nestedOpenDialogs: number;
  dialogId: string;
  dialogLabelId?: string;
}

enum Actions {
  RegisterDialog,
  UnregisterDialog,
  SetDialogLabel,
}

type ActionObject =
  | { type: Actions.RegisterDialog }
  | { type: Actions.UnregisterDialog }
  | { type: Actions.SetDialogLabel; dialogLabelId: string };

const reducer = (state: State, action: ActionObject) => {
  switch (action.type) {
    case Actions.RegisterDialog:
      return update(state, {
        nestedOpenDialogs: { $set: state.nestedOpenDialogs + 1 },
      });

    case Actions.UnregisterDialog:
      return update(state, {
        nestedOpenDialogs: { $set: state.nestedOpenDialogs + 1 },
      });

    case Actions.SetDialogLabel:
      return update(state, {
        dialogLabelId: { $set: action.dialogLabelId },
      });
  }
};

const DialogContext = createContext<[State, Dispatch<ActionObject>] | null>(
  null
);
DialogContext.displayName = "DialogContext";

const useDialogContext = () => {
  const ctx = useContext(DialogContext);

  if (ctx) {
    const [state, dispatch] = ctx;
    return { state, dispatch };
  }

  return null;
};

interface DialogProps {
  onCloseRequest?: () => void;
  noDisplace?: boolean;
  shouldBlurBackground?: boolean;
}

export default function Dialog({
  children,
  onCloseRequest,
  noDisplace,
  shouldBlurBackground,
}: React.PropsWithChildren<DialogProps>) {
  // Interaction with the outer dialogs
  const ctx = useDialogContext();

  // Handle register
  useEffect(() => {
    // If `ctx` exists, this means we're an inner dialog.
    if (ctx) {
      ctx.dispatch({
        type: Actions.RegisterDialog,
      });
    }

    return () => {
      if (ctx) {
        // Executed on unmounting
        ctx.dispatch({
          type: Actions.UnregisterDialog,
        });
      }
    };
  }, []);

  // Handle scroll lock
  useEffect(() => {
    if (!ctx) {
      noScroll.on();
    }

    return () => {
      if (!ctx) {
        noScroll.off();
      }
    };
  }, []);

  const handleEsc = (ev: KeyboardEvent) => {
    if (ev.key == "Escape" && (!ctx || ctx.state.nestedOpenDialogs === 1)) {
      onCloseRequest && onCloseRequest();
    }
  };

  // Subscribe keyboard events
  useEffect(() => {
    window.addEventListener("keyup", handleEsc);

    return () => {
      window.removeEventListener("keyup", handleEsc);
    };
  }, []);

  // Interaction with the inner dialogs
  const reducerBag = useReducer(reducer, {
    nestedOpenDialogs: 0,
    dialogId: _.uniqueId("dialog-"),
  });

  return (
    <DialogContext.Provider value={reducerBag}>
      {noDisplace ? (
        <DialogInner
          onOverlayClick={() => onCloseRequest && onCloseRequest()}
          shouldBlurBackground={shouldBlurBackground}
        >
          {children}
        </DialogInner>
      ) : (
        <Displace selector="#dialogsRoot">
          <DialogInner
            onOverlayClick={() => onCloseRequest && onCloseRequest()}
            shouldBlurBackground={shouldBlurBackground}
          >
            {children}
          </DialogInner>
        </Displace>
      )}
    </DialogContext.Provider>
  );
}

export const DialogsRoot = () => {
  return <div id="dialogsRoot" />;
};

const DialogInner = ({
  children,
  onOverlayClick,
  shouldBlurBackground,
}: React.PropsWithChildren<{
  onOverlayClick: () => void;
  shouldBlurBackground?: boolean;
}>) => {
  const ctx = useDialogContext();

  return (
    <div
      className="fixed inset-0 z-40 overflow-y-auto"
      role="dialog"
      aria-modal="true"
      aria-labelledby={ctx.state.dialogLabelId}
    >
      <div
        className={classNames("absolute inset-0 bg-black bg-opacity-50", {
          "backdrop-filter backdrop-blur-sm": shouldBlurBackground,
        })}
        onClick={onOverlayClick}
      />
      {children}
    </div>
  );
};

// Dialog Content
const DialogContent = ({
  className,
  children,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
      }}
    >
      <div className={className}>{children}</div>
    </FocusTrap>
  );
};
Dialog.Content = DialogContent;

// Dialog Title
type ExcludeProperties<T, U> = T extends U ? never : T;
type TitlePropsWeControl = "id";

const DialogTitle = (
  props: ExcludeProperties<
    React.ComponentPropsWithRef<"h2">,
    TitlePropsWeControl
  >
) => {
  const ctx = useDialogContext();

  const dialogLabelId = useId("dialog-title-");
  useEffect(() => {
    if (dialogLabelId) {
      ctx.dispatch({
        type: Actions.SetDialogLabel,
        dialogLabelId,
      });
    }
  }, [dialogLabelId]);

  return <h2 id={dialogLabelId} {...props} />;
};
Dialog.Title = DialogTitle;
