import { XCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

import { executeGenericAuthentication } from "lib/api";
import { Button } from "lib/lucidez";
import { CredentialFormProps } from "lib/platforms";
import { GithubCredential, GITHUB_PLATFORM } from "lib/platforms/github";
import SvgSpinner from "components/svg/Spinner";

enum InstallationState {
  Initial,
  Error,
  Connecting,
}

export default function GithubCredentialForm(
  props: CredentialFormProps<GithubCredential>
) {
  return (
    <div className="flex flex-col mt-2">
      <CredentialForm {...props} />
    </div>
  );
}

const CredentialForm = ({ onSave }: CredentialFormProps<GithubCredential>) => {
  const GithubIcon = GITHUB_PLATFORM.displayIcon;

  const [installationState, setInstallationState] = useState(
    InstallationState.Initial
  );

  const handleConnect = async () => {
    setInstallationState(InstallationState.Connecting);

    window?.analytics?.track("Github App Installation Started", {
      kind: "github",
    });

    const res = await executeGenericAuthentication("github");

    if (res.isOk() && res.value) {
      setInstallationState(InstallationState.Initial);

      window?.analytics?.track("Github App Installation Finished", {
        kind: "github",
      });

      onSave(res.value.credential);
    } else {
      window?.analytics?.track("Github App Installation Errored", {
        kind: "github",
      });

      setInstallationState(InstallationState.Error);
    }
  };

  if (installationState === InstallationState.Initial) {
    return (
      <div>
        <div className="flex flex-col">
          <Button
            className="w-full text-center justify-center mt-2"
            variant="primary"
            size="lg"
            iconLeft={<GithubIcon className="w-5 h-5" />}
            onClick={handleConnect}
          >
            Install the Sequin {GITHUB_PLATFORM.displayName} App
          </Button>
        </div>
      </div>
    );
  } else if (installationState === InstallationState.Connecting) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <SvgSpinner className="h-9 w-9 text-black block animate-spin" />
        <p className="mt-1">
          Waiting for installation to finish in the other window.
        </p>
      </div>
    );
  } else if (installationState === InstallationState.Error) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <XCircleIcon className="h-9 w-9 text-error block" />
        <p className="mt-1">
          There was an error installing the Sequin App to{" "}
          {GITHUB_PLATFORM.displayName}.
        </p>
        <Button
          className="mt-2"
          onClick={() => setInstallationState(InstallationState.Initial)}
          variant="primary"
          size="md"
        >
          Try again
        </Button>
      </div>
    );
  }
};
