import React from "react";
import { ClockIcon } from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";
import Slider from "rc-slider";
import { useController, useFormState, useWatch } from "react-hook-form";

import Collapse from "components/core/Collapse";
import { StepProps } from "components/platforms/common/forms/util";
import { useResourceFromContext } from "components/platforms/common/ResourceForm";
import { RateLimit } from "lib/api/types";
import { useResources } from "lib/api/hooks";
import { PlatformCredential, Resource } from "lib/platforms";
import { BaseState } from "lib/platforms/base";
import { RateLimitableState } from "lib/platforms/rateLimitable";
import { getFriendlyValue } from "lib/utils/formatting";
import { StatusDot } from "lib/lucidez/components/StatusDot";
import classNames from "classnames";

interface RateLimitProps {
  minRequestsPerInterval: number;
  maxRequestsPerInterval: number;
  interval: number;
  requestSliderStep: number;
  notice?: string;
  title?: string;
}

interface FixedRateLimitProps {
  requestsPerInterval: number;
  interval: number;
  notice?: string;
  title?: string;
}

interface AutoRateLimitProps {
  notice?: string;
  title?: string;
}

export const RateLimitStep = ({
  collapsed,
  onToggleCollapsed,
  configurableLimit,
  operatingLimit,
  automaticallyManagedLimit,
  renderNotice,
}: StepProps & {
  configurableLimit?: RateLimitProps;
  operatingLimit?: FixedRateLimitProps;
  automaticallyManagedLimit?: AutoRateLimitProps;

  renderNotice: (
    maxRequests: string,
    interval: string,
    usedByOtherResources: string
  ) => React.ReactElement;
}) => {
  const credential = useWatch<BaseState<any>>({
    name: "credential",
  }) as PlatformCredential;

  const getDisabledTooltip = () => {
    if (!credential) {
      return "Before continuing, please enter your credentials above";
    }

    return undefined;
  };

  return (
    <Collapse
      title="Rate limit"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={<ClockIcon className="h-6 w-6" />}
      disabledTooltip={getDisabledTooltip()}
    >
      {credential && (
        <Inner
          credential={credential}
          configurableLimit={configurableLimit}
          operatingLimit={operatingLimit}
          automaticallyManagedLimit={automaticallyManagedLimit}
          renderNotice={renderNotice}
        />
      )}
    </Collapse>
  );
};

const Inner = ({
  credential,
  configurableLimit,
  operatingLimit,
  automaticallyManagedLimit,
  renderNotice,
}: {
  credential: PlatformCredential;
  configurableLimit?: RateLimitProps;
  operatingLimit?: FixedRateLimitProps;
  automaticallyManagedLimit?: AutoRateLimitProps;
  resources?: Resource[];
  renderNotice: (
    maxRequests: string,
    interval: string,
    usedByOtherResources: string
  ) => React.ReactElement;
}) => {
  const [resources] = useResources({
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
  });

  const editingResource = useResourceFromContext();

  const rateLimit = useWatch<RateLimitableState>({
    name: "rateLimit",
  }) as RateLimit;

  const { field } = useController({ name: "rateLimit.allowedRequests" });

  const interval = rateLimit.interval; // One day. Not user editable for now

  const minRequests = configurableLimit
    ? (configurableLimit.minRequestsPerInterval * interval) /
      configurableLimit.interval
    : 0;
  const maxRequests = configurableLimit
    ? (configurableLimit.maxRequestsPerInterval * interval) /
      configurableLimit.interval
    : 0;

  const doCalcs = () => {
    const otherResourcesWithThisCredential = (resources || []).filter(
      (resource) =>
        resource.credential.id === credential.id &&
        resource.id !== editingResource?.id
    );

    // sum the rate limits of all other resources with this credential
    // and normalize it to the interval of this resource
    // and subtract that from the max requests
    const usedByOtherResources = otherResourcesWithThisCredential.reduce(
      (sum, resource) => {
        if (resource.rateLimit) {
          return (
            sum +
            (resource.rateLimit.allowedRequests * interval) /
              resource.rateLimit.interval
          );
        }

        return sum;
      },
      0
    );

    // Don't remove other resources requests for now
    // The slider gets really buggy, even showing negative values...
    // const maxRequestsForThisCredential = maxRequests - usedByOtherResources;
    const maxRequestsForThisCredential = maxRequests;

    return {
      otherResourcesWithThisCredential,
      usedByOtherResources,
      maxRequestsForThisCredential,
    };
  };

  const { maxRequestsForThisCredential, usedByOtherResources } = React.useMemo(
    doCalcs,
    [editingResource, resources, credential, interval]
  );

  return (
    <>
      {configurableLimit &&
        rateLimit.allowedRequests > maxRequestsForThisCredential && (
          <div className="my-2 p-2 px-3 rounded bg-yellow-100 text-yellow-600 flex flex-row">
            <div className="pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="flex-1 ml-2">
              This resource is over the rate limit for this account. Please
              select a lower rate limit or adjust the rate limit of other
              resources using this credential.
            </div>
          </div>
        )}
      <div className="mt-4 flex flex-row gap-2">
        {configurableLimit && (
          <div className="flex-1 flex flex-col justify-center items-center lg:col-span-2 p-8 border w-full relative">
            <div className="flex flex-col items-center w-full">
              <span className="text-center text-black text-2xl">
                {getFriendlyValue(field.value, 0)}
              </span>
              <span className="text-center font-medium mb-4 text-gray-500 text-xs">
                requests per {getFriendlyInterval(interval)}
              </span>
              <Slider
                min={minRequests}
                max={maxRequestsForThisCredential}
                step={configurableLimit.requestSliderStep}
                dots={false}
                value={field.value}
                onChange={(v) => field.onChange(v as number)}
                railStyle={{ background: "#E5E5E5", height: 5 }}
                trackStyle={{
                  background: "#000000",
                  height: 5,
                }}
                handleStyle={{
                  background: "#ffffff",
                  opacity: 1,
                  border: "1px solid #000000",
                  height: 18,
                  width: 18,
                  marginTop: -6,
                }}
                dotStyle={{
                  border: "none",
                  background: "transparent",
                }}
              />
            </div>
            {configurableLimit.title && (
              <span className="text-xs text-black left-3 top-2 absolute font-bold">
                {configurableLimit.title}
              </span>
            )}
            {configurableLimit.notice && (
              <Tippy content={configurableLimit.notice} interactive>
                <InformationCircleIcon className="h-4 w-4 text-gray-500 right-2 top-2 absolute" />
              </Tippy>
            )}
          </div>
        )}

        {operatingLimit && (
          <div className="flex-1 flex flex-col justify-center items-center lg:col-span-2 p-8 border w-full relative">
            <div className="flex flex-col items-center w-full">
              <span className="text-center text-black text-2xl">
                {getFriendlyValue(operatingLimit.requestsPerInterval, 0)}
              </span>
              <span className="text-center font-medium text-gray-500 text-xs">
                requests per {getFriendlyInterval(operatingLimit.interval)}
              </span>
            </div>
            {operatingLimit.title && (
              <span className="text-xs text-black left-3 top-2 absolute font-bold">
                {operatingLimit.title}
              </span>
            )}
            {operatingLimit.notice && (
              <Tippy content={operatingLimit.notice} interactive>
                <InformationCircleIcon className="h-4 w-4 text-gray-500 right-2 top-2 absolute" />
              </Tippy>
            )}
          </div>
        )}

        {automaticallyManagedLimit && (
          <div className="flex-1 flex flex-col justify-center items-center lg:col-span-2 p-8 border w-full relative">
            <div className="flex flex-col items-center w-full">
              <span className="text-center font-medium text-gray-500 text-xs mt-2">
                Automatically managed by Sequin.{" "}
                <Disclosure>
                  {() => (
                    <>
                      <Disclosure.Button className="my-2 link">
                        Learn more.
                      </Disclosure.Button>
                      <Disclosure.Panel className="text-xs p-4 bg-gray-100 rounded text-justify">
                        <p>{automaticallyManagedLimit.notice}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </span>
            </div>
            {automaticallyManagedLimit.title && (
              <span className="text-xs text-black left-3 top-2 absolute font-bold">
                {automaticallyManagedLimit.title}
              </span>
            )}
          </div>
        )}
      </div>
      <div className="text-center font-medium mt-6 text-gray-500 text-xs">
        {renderNotice(
          getFriendlyValue(maxRequestsForThisCredential, 0),
          getFriendlyInterval(interval),
          getFriendlyValue(usedByOtherResources, 0)
        )}
      </div>
    </>
  );
};

export const FullPageRateLimitStep = ({
  configurableLimit,
  operatingLimit,
  automaticallyManagedLimit,
  renderNotice,
}: StepProps & {
  configurableLimit?: RateLimitProps;
  operatingLimit?: FixedRateLimitProps;
  automaticallyManagedLimit?: AutoRateLimitProps;

  renderNotice: (
    maxRequests: string,
    interval: string,
    usedByOtherResources: string
  ) => React.ReactElement;
}) => {
  const credential = useWatch<BaseState<any>>({
    name: "credential",
  }) as PlatformCredential;

  const formState = useFormState<RateLimitableState>({
    name: "rateLimit.allowedRequests",
  });

  const isDirty = formState?.dirtyFields?.rateLimit?.allowedRequests === true;

  return (
    <div>
      <h3 className="font-bold text-xs mb-6">
        Rate limit {isDirty && <StatusDot variant="amber" className="ml-1" />}
      </h3>
      {credential && (
        <FullPageInner
          credential={credential}
          configurableLimit={configurableLimit}
          operatingLimit={operatingLimit}
          automaticallyManagedLimit={automaticallyManagedLimit}
          renderNotice={renderNotice}
        />
      )}
    </div>
  );
};

const FullPageInner = ({
  credential,
  configurableLimit,
  operatingLimit,
  automaticallyManagedLimit,
  renderNotice,
}: {
  credential: PlatformCredential;
  configurableLimit?: RateLimitProps;
  operatingLimit?: FixedRateLimitProps;
  automaticallyManagedLimit?: AutoRateLimitProps;
  resources?: Resource[];
  renderNotice: (
    maxRequests: string,
    interval: string,
    usedByOtherResources: string
  ) => React.ReactElement;
}) => {
  const [resources] = useResources({
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
  });

  const editingResource = useResourceFromContext();

  const rateLimit = useWatch<RateLimitableState>({
    name: "rateLimit",
  }) as RateLimit;

  const { field, formState } = useController({
    name: "rateLimit.allowedRequests",
  });

  const isDirty = formState?.dirtyFields?.rateLimit?.allowedRequests === true;

  const interval = rateLimit.interval; // One day. Not user editable for now

  const minRequests = configurableLimit
    ? (configurableLimit.minRequestsPerInterval * interval) /
      configurableLimit.interval
    : 0;
  const maxRequests = configurableLimit
    ? (configurableLimit.maxRequestsPerInterval * interval) /
      configurableLimit.interval
    : 0;

  const doCalcs = () => {
    const otherResourcesWithThisCredential = (resources || []).filter(
      (resource) =>
        resource.credential.id === credential.id &&
        resource.id !== editingResource?.id
    );

    // sum the rate limits of all other resources with this credential
    // and normalize it to the interval of this resource
    // and subtract that from the max requests
    const usedByOtherResources = otherResourcesWithThisCredential.reduce(
      (sum, resource) => {
        if (resource.rateLimit) {
          return (
            sum +
            (resource.rateLimit.allowedRequests * interval) /
              resource.rateLimit.interval
          );
        }

        return sum;
      },
      0
    );

    // Don't remove other resources requests for now
    // The slider gets really buggy, even showing negative values...
    // const maxRequestsForThisCredential = maxRequests - usedByOtherResources;
    const maxRequestsForThisCredential = maxRequests;

    return {
      otherResourcesWithThisCredential,
      usedByOtherResources,
      maxRequestsForThisCredential,
    };
  };

  const { maxRequestsForThisCredential, usedByOtherResources } = React.useMemo(
    doCalcs,
    [editingResource, resources, credential, interval]
  );

  return (
    <>
      {configurableLimit &&
        rateLimit.allowedRequests > maxRequestsForThisCredential && (
          <div className="my-2 p-2 px-3 rounded bg-yellow-100 text-yellow-600 flex flex-row">
            <div className="pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="flex-1 ml-2">
              This resource is over the rate limit for this account. Please
              select a lower rate limit or adjust the rate limit of other
              resources using this credential.
            </div>
          </div>
        )}

      <div className="flex flex-col gap-6">
        {configurableLimit && (
          <FullPageRateLimitSlider
            value={field.value}
            minVal={minRequests}
            maxVal={maxRequestsForThisCredential}
            step={configurableLimit.requestSliderStep}
            interval={interval}
            onChange={(v) => field.onChange(v as number)}
            title={configurableLimit.title}
            notice={configurableLimit.notice}
            isDirty={isDirty}
          />
        )}

        {operatingLimit && (
          <FullPageOperatingRateLimit
            value={operatingLimit.requestsPerInterval}
            interval={operatingLimit.interval}
            title={operatingLimit.title}
            notice={operatingLimit.notice}
          />
        )}
      </div>

      <div className="mt-4 flex flex-row gap-2">
        {automaticallyManagedLimit && (
          <div className="flex-1 flex flex-col justify-center items-center lg:col-span-2 p-8 border w-full relative">
            <div className="flex flex-col items-center w-full">
              <span className="text-center font-medium text-gray-500 text-xs mt-2">
                Automatically managed by Sequin.{" "}
                <Disclosure>
                  {() => (
                    <>
                      <Disclosure.Button className="my-2 link">
                        Learn more.
                      </Disclosure.Button>
                      <Disclosure.Panel className="text-xs p-4 bg-gray-100 rounded text-justify">
                        <p>{automaticallyManagedLimit.notice}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </span>
            </div>
            {automaticallyManagedLimit.title && (
              <span className="text-xs text-black left-3 top-2 absolute font-bold">
                {automaticallyManagedLimit.title}
              </span>
            )}
          </div>
        )}
      </div>
      <div className="font-medium mt-2 text-gray-500 text-xs">
        {renderNotice(
          getFriendlyValue(maxRequestsForThisCredential, 0),
          getFriendlyInterval(interval),
          getFriendlyValue(usedByOtherResources, 0)
        )}
      </div>
    </>
  );
};

interface FullPageRateLimitSliderProps {
  value: number;
  minVal: number;
  maxVal: number;

  interval: number;
  step: number;

  onChange: (newValue: number) => void;
  notice?: string;
  title: string;

  isDirty?: boolean;
}

const FullPageRateLimitSlider = ({
  value,
  minVal,
  maxVal,
  interval,
  step,
  onChange,
  notice,
  title,
  isDirty,
}: FullPageRateLimitSliderProps) => {
  const normalizeValue = (value: number) => {
    value = Math.floor(value);

    if (value > maxVal) {
      return maxVal;
    }

    if (value < minVal) {
      return minVal;
    }

    return value;
  };

  return (
    <div className="flex flex-col gap-2">
      <h4 className="text-xs">{title}</h4>
      {notice && <span className="text-xs text-gray-500">{notice}</span>}
      <div className="flex flex-row items-center h-8">
        <span className="relative h-8">
          <span className="py-0 px-4" aria-hidden="true">
            {value}
          </span>
          <input
            type="number"
            className={classNames(
              "top-0 left-0 absolute w-full h-8 leading-8 pl-3 border rounded-lg disabled-input",
              isDirty && "border-amber-600"
            )}
            value={value}
            max={maxVal}
            min={minVal}
            onChange={(ev) => onChange(normalizeValue(ev.target.valueAsNumber))}
          />
        </span>
        <span className="ml-2 text-xs font-medium text-gray-500">
          requests per {getFriendlyInterval(interval)}.
        </span>
      </div>
      <div className="w-full max-w-xs">
        <Slider
          min={minVal}
          max={maxVal}
          step={step}
          dots={false}
          value={value}
          onChange={onChange}
          railStyle={{ background: "#E5E5E5", height: 5 }}
          trackStyle={{
            background: "#000000",
            height: 5,
          }}
          handleStyle={{
            background: "#ffffff",
            opacity: 1,
            border: "1px solid #000000",
            height: 18,
            width: 18,
            marginTop: -6,
          }}
          dotStyle={{
            border: "none",
            background: "transparent",
          }}
        />
      </div>
    </div>
  );
};

interface FullPageOperatingRateLimitProps {
  value: number;

  interval: number;

  notice?: string;
  title: string;
}

const FullPageOperatingRateLimit = ({
  value,
  interval,
  notice,
  title,
}: FullPageOperatingRateLimitProps) => {
  return (
    <div className="flex flex-col gap-2">
      <h4 className="text-xs">{title}</h4>
      {notice && <span className="text-xs text-gray-500">{notice}</span>}
      <div className="flex flex-row items-center h-8">
        <span className="text-xs font-medium text-gray-500">
          {value} requests per {getFriendlyInterval(interval)}.
        </span>
      </div>
    </div>
  );
};

const MILISECOND = {
  interval: 1,
  label: {
    singular: "ms",
    plural: "ms",
  },
};

const SECOND = {
  interval: 1000,
  label: {
    singular: "second",
    plural: "seconds",
  },
};

const MINUTE = {
  interval: 60 * 1000,
  label: {
    singular: "minute",
    plural: "minutes",
  },
};

const HOUR = {
  interval: 60 * 60 * 1000,
  label: {
    singular: "hour",
    plural: "hours",
  },
};

const DAY = {
  interval: 24 * 60 * 60 * 1000,
  label: {
    singular: "day",
    plural: "days",
  },
};

const WEEK = {
  interval: 7 * 24 * 60 * 60 * 1000,
  label: {
    singular: "week",
    plural: "weeks",
  },
};

const MONTH = {
  interval: 30 * 24 * 60 * 60 * 1000,
  label: {
    singular: "month",
    plural: "months",
  },
};

const testThrough = [MONTH, WEEK, DAY, HOUR, MINUTE, SECOND, MILISECOND];

const getUnit = (value: number) => {
  for (const unit of testThrough) {
    if (value >= unit.interval) {
      return unit;
    }
  }
};

const getFriendlyInterval = (value: number) => {
  const unit = getUnit(value);
  const units = Math.round(value / unit.interval);

  return `${units > 1 ? units + " " : ""}${
    units === 1 ? unit.label.singular : unit.label.plural
  }`;
};
