import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import memoize from "lodash/memoize";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

import NameStep, {
  FullPageNameStep,
} from "components/platforms/common/forms/NameStep";
import {
  DestinationStep,
  FullPageDestinationStep,
  OnboardDestinationStep,
} from "components/platforms/common/forms/DestinationStep";
import CredentialStep, {
  FullPageCredentialStep,
  OnboardCredentialStep,
} from "components/platforms/common/forms/CredentialStep";
import SelectTablesStep from "components/platforms/common/forms/SelectTablesStep";
import {
  FullPageRateLimitStep,
  RateLimitStep,
} from "components/platforms/common/forms/RateLimitStep";
import {
  AIRTABLE_PLATFORM,
  AirtableCredential,
  AirtableState,
} from "lib/platforms/airtable";
import { FormProps, OnboardCredentialFormProps } from "lib/platforms";
import SelectBaseStep, {
  FullPageSelectBaseStep,
} from "components/platforms/airtable/SelectBaseStep";
import AirtableAdvancedStep, {
  FullPageAirtableAdvancedStep,
} from "components/platforms/airtable/AirtableAdvancedStep";
import { SelectEnvironmentStep } from "components/platforms/common/forms/SelectEnvironmentStep";
import { FullPageEventsStep } from "components/platforms/common/forms/EventsStep";

enum Step {
  AddToken = "AddToken",
  SelectBase = "SelectBase",
  SelectEnvironment = "SelectEnvironment",
  RateLimit = "RateLimit",
  Tables = "Tables",
  Destination = "Destination",
  Advanced = "Advanced",
}

export default function AirtableForm({ isCreate, isOnboard }: FormProps) {
  const [collapsed, setCollapsed] = useState<{ [k: string]: boolean }>({
    [Step.AddToken]: !isCreate, // Default open on creates
    [Step.SelectBase]: !isCreate, // Default open on creates
    [Step.SelectEnvironment]: false,
    [Step.RateLimit]: !isCreate,
    [Step.Tables]: !isCreate, // Default open on creates
    [Step.Destination]: !isCreate || isOnboard, // Default open on creates
    [Step.Advanced]: true, // Advanced is always collapsed by default
  });

  const genericToggleCollapse = React.useMemo(
    () =>
      memoize(
        (step: Step) => () =>
          setCollapsed((collapsed) => {
            return {
              ...collapsed,
              [step]: !collapsed[step],
            };
          })
      ),
    []
  );

  const stepProps = {
    isCreate,
    platform: AIRTABLE_PLATFORM,
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      {!isCreate && <NameStep />}
      <CredentialStep
        {...stepProps}
        collapsed={collapsed[Step.AddToken]}
        onToggleCollapsed={genericToggleCollapse(Step.AddToken)}
        onNextStep={genericToggleCollapse(Step.AddToken)}
      />
      <SelectBaseStep
        {...stepProps}
        collapsed={collapsed[Step.SelectBase]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectBase)}
        onNextStep={genericToggleCollapse(Step.SelectBase)}
      />
      <SelectEnvironmentStep
        collapsed={collapsed[Step.SelectEnvironment]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectEnvironment)}
      />
      <RateLimitStep
        {...stepProps}
        collapsed={collapsed[Step.RateLimit]}
        onToggleCollapsed={genericToggleCollapse(Step.RateLimit)}
        configurableLimit={{
          minRequestsPerInterval: 1,
          maxRequestsPerInterval: 5,
          interval: 1_000,
          requestSliderStep: 1,
        }}
        renderNotice={() => (
          <>
            <ExclamationCircleIcon className="h-5 w-5 inline-block text-black mr-1" />{" "}
            We recommend using 5 req/s unless you have a lot of other services
            connected to your Airtable base.
          </>
        )}
      />
      <SelectTablesStep
        {...stepProps}
        collapsed={collapsed[Step.Tables]}
        onToggleCollapsed={genericToggleCollapse(Step.Tables)}
        helperText="Select tables from the base to sync."
        switchLabel="Sync all tables in this base"
      />
      {isOnboard ? (
        <OnboardDestinationStep
          {...stepProps}
          collapsed={collapsed[Step.Destination]}
          onToggleCollapsed={genericToggleCollapse(Step.Destination)}
        />
      ) : (
        <DestinationStep
          {...stepProps}
          collapsed={collapsed[Step.Destination]}
          onToggleCollapsed={genericToggleCollapse(Step.Destination)}
        />
      )}
      <AirtableAdvancedStep
        {...stepProps}
        collapsed={collapsed[Step.Advanced]}
        onToggleCollapsed={genericToggleCollapse(Step.Advanced)}
      />
    </div>
  );
}

export const FullPageAirtableForm = ({ isCreate }: FormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      {!isCreate && <FullPageNameStep />}
      <FullPageCredentialStep platform={AIRTABLE_PLATFORM} />
      <FullPageSelectBaseStep isCreate={false} platform={AIRTABLE_PLATFORM} />
      <hr />
      <FullPageRateLimitStep
        platform={AIRTABLE_PLATFORM}
        configurableLimit={{
          title: "Read",
          minRequestsPerInterval: 1,
          maxRequestsPerInterval: 5,
          interval: 1_000,
          requestSliderStep: 1,
        }}
        renderNotice={() => (
          <>
            <ExclamationCircleIcon className="h-4 w-4 inline-block text-gray-500 mr-0.5" />{" "}
            We recommend using 5 req/s unless you have a lot of other services
            connected to your Airtable base.
          </>
        )}
      />
      <hr />
      <FullPageAirtableAdvancedStep />
      <hr />
      <FullPageDestinationStep isCreate={false} platform={AIRTABLE_PLATFORM} />
      <hr />
      <FullPageEventsStep />
    </div>
  );
};

export const OnboardCredentialAirtableForm = ({
  handleBackClick,
}: OnboardCredentialFormProps) => {
  const currentCredential = useWatch<AirtableState>({ name: "credential" }) as
    | AirtableCredential
    | undefined;

  const [isAddingNewKey, setIsAddingNewKey] = useState(false);

  const handleStartOauth = () => {
    setIsAddingNewKey(true);
  };

  const handleCompleteOuath = () => {
    setIsAddingNewKey(false);
  };

  return (
    <div className="grid grid-cols-1 gap-8">
      <OnboardCredentialStep
        handleBackClick={handleBackClick}
        onCompleteOAuth={handleCompleteOuath}
        onStartOAuth={handleStartOauth}
        platform={AIRTABLE_PLATFORM}
      />
      {currentCredential && !isAddingNewKey && (
        <div className="mt-8">
          <FullPageSelectBaseStep
            isCreate={true}
            platform={AIRTABLE_PLATFORM}
          />
        </div>
      )}
    </div>
  );
};
