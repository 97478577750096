import React from "react";
import * as z from "zod";

import HubspotCredentialForm from "components/platforms/hubspot/HubspotCredentialForm";
import HubspotForm, {
  FullPageHubspotForm,
  OnboardCredentialHubspotForm,
} from "components/platforms/hubspot/HubspotForm";
import SvgHubspotFilled from "components/svg/Hubspot";
import {
  baseCredentialSchema,
  baseResourceSchema,
  RateLimit,
  rawResourceSchema,
} from "lib/api/types";
import { Platform } from "lib/platforms";
import {
  baseInitialCreateState,
  baseInitialUpdateState,
  basePrepareFieldsForCreate,
  basePrepareFieldsForUpdate,
  BaseState,
} from "lib/platforms/base";
import {
  rateLimitableInitialCreateState,
  rateLimitableInitialUpdateState,
  rateLimitablePrepareFieldsForCreate,
  rateLimitablePrepareFieldsForUpdate,
  RateLimitableState,
} from "lib/platforms/rateLimitable";

// Metadata definition
export const hubspotDefinitionSchema = z.object({
  proxyWaitActive: z.boolean(),
});

export type HubspotDefinition = z.infer<typeof hubspotDefinitionSchema>;

// Credential definition
export const hubspotCredentialSchema = baseCredentialSchema.extend({
  payload: z.object({
    kind: z.literal("hubspot"),
    refresh_token: z.string(),
    hub_id: z.number(),
  }),
});

export type HubspotCredential = z.infer<typeof hubspotCredentialSchema>;

// Integral resource definition
export const rawHubspotResourceSchema = rawResourceSchema.extend({
  kind: z.literal("hubspot"),
  definition: hubspotDefinitionSchema,
  credential: hubspotCredentialSchema,
});

export const hubspotResourceSchema = baseResourceSchema.extend({
  kind: z.literal("hubspot"),
  definition: hubspotDefinitionSchema,
  credential: hubspotCredentialSchema,
});

export type HubspotResource = z.infer<typeof hubspotResourceSchema>;

export interface HubspotState
  extends BaseState<HubspotCredential>,
    RateLimitableState {}

const DEFAULT_RATE_LIMIT: RateLimit = {
  allowedRequests: 28_800,
  interval: 86_400_000, // One day. Not user editable for now
  maxConcurrentRequests: 3, // not in use right now
};

// Platform definition
export const HUBSPOT_PLATFORM: Platform<
  HubspotResource,
  HubspotState,
  HubspotCredential
> = {
  displayName: "HubSpot",
  displayIcon: ({ ref, ...props }) => {
    return <SvgHubspotFilled {...props} />;
  },
  resourceAppositive: "account",
  kind: "hubspot",

  supportsCollectionStatus: true,
  supportsWriteProxy: true,

  helpers: [
    {
      name: "SOURCE",
      buildUrl: (res) => ({
        url: `https://app.hubspot.com/home?portalId=${res.credential.payload.hub_id}`,
        displayUrl: `app.hubspot.com`,
      }),
    },
  ],

  buildForm: HubspotForm,
  buildCredentialForm: HubspotCredentialForm,
  buildOnboardCredentialForm: OnboardCredentialHubspotForm,
  extraTableParams: (_state) => {
    return {};
  },
  getLabelForCredential: (cred) => cred.payload.refresh_token,

  getInitialCreateState: () => ({
    ...baseInitialCreateState("HubSpot"),
    ...rateLimitableInitialCreateState(DEFAULT_RATE_LIMIT),
  }),
  getInitialUpdateState: (res) => ({
    ...baseInitialUpdateState(res),
    ...rateLimitableInitialUpdateState(res, DEFAULT_RATE_LIMIT),
  }),

  fullPageForm: FullPageHubspotForm,

  prepareFieldsForCreate: (d) => ({
    ...basePrepareFieldsForCreate(d, "hubspot"),
    ...rateLimitablePrepareFieldsForCreate(d),
  }),
  prepareFieldsForUpdate: (d) => ({
    ...basePrepareFieldsForUpdate(d, "hubspot"),
    ...rateLimitablePrepareFieldsForUpdate(d),
  }),
};
