import React from "react";
import { w, W } from "lib/windstitch";
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "lib/lucidez/types/component";
import { Spinner } from "./Spinner";

const StyledButton = w.button(
  "transition-all flex-shrink-0 rounded inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-md",
  {
    variants: {
      variant: {
        primary: "bg-black text-white hover:bg-gray-800",
        outlined: "text-black hover:bg-gray-100 border border-gray-400",
        tertiary:
          "text-cool-gray-600 bg-cool-gray-50 hover:bg-cool-gray-100 border border-cool-gray-200",
      },
      size: {
        xs: "h-6 text-xxs  px-2",
        sm: "h-8 text-xs px-4",
        md: "h-10 text-sm px-6",
        lg: "h-12 text-sm px-8",
        xl: "h-16 text-sm px-12",
      },
      iconleft: () => "",
      iconright: () => "",
      isloading: () => "",
    },
    defaultVariants: {
      variant: "outlined",
      size: "md",

      iconleft: null,
      iconright: null,
      isloading: false,
    },
    additionalClasses: ({ iconleft, iconright, size }) => {
      const sLeft = iconleft
        ? [
            size === "xs" && "gap-1",
            size === "sm" && "pl-3 gap-1",
            size === "md" && "pl-4 gap-2",
            size === "lg" && "pl-6 gap-2",
            size === "xl" && "pl-10 gap-2",
          ]
        : [];

      const sRight = iconright
        ? [
            size === "xs" && "gap-1",
            size === "sm" && "pr-3 gap-1",
            size === "md" && "pr-4 gap-2",
            size === "lg" && "pr-6 gap-2",
            size === "xl" && "pr-10 gap-2",
          ]
        : [];

      return sLeft.concat(sRight);
    },
  }
);

type StyledButtonProps = W.Infer<
  typeof StyledButton & {
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    isLoading?: boolean;
  }
>;

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  StyledButtonProps
>;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

const Button_ = <C extends React.ElementType = "button">(
  { iconLeft, iconRight, isLoading, children, ...props }: ButtonProps<C>,
  ref: PolymorphicRef<C>
) => {
  const { size } = props;

  const renderedLeftIcon = isLoading ? <Spinner size={size} /> : iconLeft;

  return (
    <StyledButton
      {...props}
      iconleft={renderedLeftIcon}
      disabled={props.disabled || isLoading}
      ref={ref}
    >
      {<span>{renderedLeftIcon}</span>}
      {children}
      {!isLoading && iconRight && <span>{iconRight}</span>}
    </StyledButton>
  );
};
export const Button: ButtonComponent = React.forwardRef(Button_);
