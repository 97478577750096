import React from "react";

interface DatabaseSetupLayoutProps {
  leftSideAction?: React.ReactNode;
  rightSideAction?: React.ReactNode;
}

export function DatabaseSetupLayout({
  children,
  leftSideAction,
  rightSideAction,
}: React.PropsWithChildren<DatabaseSetupLayoutProps>) {
  return (
    <div className="flex flex-col h-full">
      <div className="p-8 flex-1 overflow-auto">{children}</div>
      <div className="py-4 px-8 bg-cool-gray-50 border-t border-cool-gray-200 bottom-0 flex flex-row">
        {leftSideAction}
        <div className="flex-1" />
        {rightSideAction}
      </div>
    </div>
  );
}
