import { RadioGroup } from "@headlessui/react";
import { ExclamationCircleIcon, ViewGridIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import { useAirtableBases } from "lib/api/hooks";
import { AirtableCredential, AirtableState } from "lib/platforms/airtable";
import Collapse from "components/core/Collapse";
import SvgSpinner from "components/svg/Spinner";
import {
  FieldState,
  getValidationState,
  StepProps,
} from "components/platforms/common/forms/util";
import { StatusDot } from "lib/lucidez/components/StatusDot";

export const SelectBaseStep = ({
  collapsed,
  onToggleCollapsed,
  onNextStep,
  isCreate,
}: StepProps) => {
  const { getFieldState, setValue } = useFormContext<AirtableState>();

  const currentCredential = useWatch<AirtableState>({ name: "credential" }) as
    | AirtableCredential
    | undefined;
  const currentBaseId = useWatch<AirtableState>({ name: "baseId" });

  const formState = useFormState<AirtableState>({
    name: ["baseId", "credential"],
  });

  const [basesResponse] = useAirtableBases(currentCredential?.id);
  const bases =
    basesResponse && basesResponse.ok ? basesResponse.bases : undefined;

  const isValid =
    getValidationState(getFieldState("baseId", formState)) === FieldState.Valid;

  const getDisabledTooltip = () => {
    const credentialState = getValidationState(
      getFieldState("credential", formState)
    );

    if (credentialState === FieldState.Invalid) {
      return "Please check your token above, it appears to be invalid";
    }

    if (!currentCredential) {
      return "Before continuing, please enter your credentials above";
    }

    return undefined;
  };

  const getStepIcon = () => {
    if (isValid) {
      return <CheckCircleIcon className="h-6 w-6 text-success" />;
    }

    return <ViewGridIcon className="h-6 w-6" />;
  };

  const getBaseName = (baseId: string) => {
    if (basesResponse && basesResponse.bases) {
      const found = basesResponse.bases.find((x) => x.id === baseId);

      if (found) {
        return found.name;
      }
    }

    return "id: " + baseId;
  };

  useEffect(() => {
    if (currentBaseId && isCreate) {
      setValue("name", getBaseName(currentBaseId as string));
    }
  }, [currentBaseId]);

  useEffect(() => {
    if (isValid) {
      onNextStep && onNextStep();
    }
  }, [isValid]);

  return (
    <Collapse
      title="Select base"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={getStepIcon()}
      disabledTooltip={getDisabledTooltip()}
    >
      <p className="text-xs mb-4">
        Select the Airtable base you want to connect to below.
      </p>

      {bases ? (
        <Controller
          name="baseId"
          rules={{
            validate: async (baseId?: string) => {
              if (!baseId) {
                return false;
              }
              return true;
            },
          }}
          render={({ field }) => (
            <SelectBaseForm
              value={field.value}
              onChange={field.onChange}
              bases={bases}
            />
          )}
        />
      ) : (
        <SvgSpinner className="animate-spin h-5 w-5" />
      )}

      <p className="text-xs text-gray-500 mt-4">
        <ExclamationCircleIcon className="inline-block h-4 w-4" /> Want to add
        more than one base? Create this one first, then add your other base
        next.
      </p>
    </Collapse>
  );
};

export default SelectBaseStep;

export const FullPageSelectBaseStep = ({ isCreate, onNextStep }: StepProps) => {
  const { getFieldState, setValue, trigger } = useFormContext<AirtableState>();

  const currentCredential = useWatch<AirtableState>({ name: "credential" }) as
    | AirtableCredential
    | undefined;
  const currentBaseId = useWatch<AirtableState>({ name: "baseId" });

  const formState = useFormState<AirtableState>({
    name: "baseId",
  });
  const isDirty = !isCreate && formState?.dirtyFields?.baseId === true;

  const [basesResponse] = useAirtableBases(currentCredential?.id);
  const bases =
    basesResponse && basesResponse.ok ? basesResponse.bases : undefined;

  const isValid =
    getValidationState(getFieldState("baseId", formState)) === FieldState.Valid;

  const getBaseName = (baseId: string) => {
    if (basesResponse && basesResponse.bases) {
      const found = basesResponse.bases.find((x) => x.id === baseId);

      if (found) {
        return found.name;
      }
    }

    return "id: " + baseId;
  };

  useEffect(() => {
    if (currentBaseId && isCreate) {
      setValue("name", getBaseName(currentBaseId as string));
    }
  }, [currentBaseId]);

  useEffect(() => {
    trigger("baseId");
  }, [basesResponse]);

  useEffect(() => {
    if (isValid && onNextStep) {
      onNextStep();
    }
  }, [isValid]);

  const headingText = isCreate ? (
    <>
      <h3 className="font-bold text-lg mb-3">Select your Airtable base</h3>
      <p className="text-sm mb-4">
        Please select the Airtable base you want to connect to.
      </p>
    </>
  ) : (
    <>
      <h3 className="font-bold text-xs mb-3">
        Airtable Base{" "}
        {isDirty && <StatusDot variant="amber" className="ml-1" />}
      </h3>
      <p className="text-sm mb-4">
        Select the Airtable base you want to connect to below.
      </p>
    </>
  );

  return (
    <div>
      {headingText}

      {bases ? (
        <Controller
          name="baseId"
          rules={{
            validate: async (baseId?: string) => {
              if (!baseId) {
                return false;
              }
              if (!bases.find((base) => base.id === baseId)) {
                return false;
              }

              return true;
            },
          }}
          render={({ field }) => (
            <SelectBaseForm
              value={field.value}
              onChange={field.onChange}
              bases={bases}
            />
          )}
        />
      ) : (
        <SvgSpinner className="animate-spin h-5 w-5" />
      )}

      <p className="text-sm text-gray-500 mt-4">
        <ExclamationCircleIcon className="inline-block h-4 w-4" /> Want to add
        more than one base? Create this one first, then add your other base
        next.
      </p>
    </div>
  );
};

const SelectBaseForm = ({
  value,
  onChange,
  bases,
}: {
  value: any;
  onChange: (id: string) => void;
  bases: {
    id?: string;
    name?: string;
  }[];
}) => {
  return (
    <RadioGroup value={value} onChange={(id) => onChange(id)}>
      <RadioGroup.Label className="sr-only">
        Airtable Base to Sync
      </RadioGroup.Label>
      <div className="grid grid-cols-1 gap-2">
        {bases.map((v, idx) => (
          <RadioGroup.Option value={v.id} key={idx}>
            {({ checked }) => (
              <div className="text-xs max-w-full flex flex-row truncate cursor-pointer items-center">
                <div
                  className={`inline-flex h-4 w-4 border rounded-xl flex-row items-center justify-center ${
                    checked ? "border-black " : "border-gray-200"
                  }`}
                >
                  {checked && <div className="h-2 w-2 bg-black rounded-full" />}
                </div>
                <div className="flex-1 max-w-full truncate ml-3 font-semibold text-gray-600 ">
                  {v.name}
                </div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
