import React from "react";
import classNames from "classnames";

import { ResourceDestination } from "lib/api/types";
import { DatabaseWithResources } from "lib/platforms";

export const DestinationHelper = ({
  children,
  destination,
  databases,
  isDirty,
}: React.PropsWithChildren<{
  destination: ResourceDestination;
  databases: DatabaseWithResources[];
  isDirty?: boolean;
}>) => {
  if (destination.type === "new" || destination.type === "placeholder") {
    return <NewDbCard isDirty={isDirty}>{children}</NewDbCard>;
  } else {
    const currentDatabase = databases.find(
      (x) => x.id === destination.databaseId
    );

    if (!currentDatabase) {
      return <>Loading database {destination.databaseId}...</>;
    }

    const name = currentDatabase.name || currentDatabase.definition.dbname;

    if (destination.type === "external") {
      return (
        <ExternalDbCard name={name} isDirty={isDirty}>
          {children}
        </ExternalDbCard>
      );
    } else {
      if (currentDatabase.definition.type === "dedicated_postgres") {
        return (
          <ExternalDbCard name={name} isDirty={isDirty}>
            {children}
          </ExternalDbCard>
        );
      } else {
        return (
          <ExistingDbCard name={name} isDirty={isDirty}>
            {children}
          </ExistingDbCard>
        );
      }
    }
  }
};

const NewDbCard = ({
  children,
  isDirty,
}: React.PropsWithChildren<{ isDirty?: boolean }>) => (
  <div
    className={classNames(
      "p-4 border border-gray-200 rounded flex-col",
      isDirty && "border-amber-600"
    )}
  >
    <h4 className="font-bold">
      New Sequin database <span className="text-gray-600">(Postgres)</span>
    </h4>
    <p className="text-xs text-gray-500">
      We'll provision and host a new Postgres instance for you.
    </p>
    {children && <div className="mt-4">{children}</div>}
  </div>
);

const ExternalDbCard = ({
  children,
  name,
  isDirty,
}: React.PropsWithChildren<{ name: string; isDirty?: boolean }>) => (
  <div
    className={classNames(
      "p-4 border border-gray-200 rounded flex-col",
      isDirty && "border-amber-600"
    )}
  >
    <h4 className="font-bold font-mono">
      {name}{" "}
      <span className="text-gray-400 font-sans font-normal text-xs">
        (self-hosted)
      </span>
    </h4>
    <p className="text-xs text-gray-500">
      Syncing to a schema in{" "}
      <span className="font-mono text-black font-bold">{name}</span> database.
    </p>
    {children && <div className="mt-4">{children}</div>}
  </div>
);

const ExistingDbCard = ({
  children,
  name,
  isDirty,
}: React.PropsWithChildren<{ name: string; isDirty?: boolean }>) => (
  <div
    className={classNames(
      "p-4 border border-gray-200 rounded flex-col",
      isDirty && "border-amber-600"
    )}
  >
    <h4 className="font-bold font-mono">
      {name}{" "}
      <span className="text-gray-400 font-sans font-normal text-xs">
        (Sequin-hosted)
      </span>
    </h4>
    <p className="text-xs text-gray-500">
      Syncing to a schema in{" "}
      <span className="font-mono text-black font-bold">{name}</span> database.
    </p>
    {children && <div className="mt-4">{children}</div>}
  </div>
);
