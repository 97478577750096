export const DOCS_URL = "https://docs.sequin.io";
export const SIGNUP_URL = "https://app.sequin.io/signup";
export const LOGIN_URL = "https://app.sequin.io/login";
export const PRICING_URL = "/pricing";
export const SITE_TITLE =
  "Sequin: A two-way, real-time sync between APIs and your database.";
export const SITE_DESC =
  "Sequin helps you integrate with third-party platforms like Salesforce or Stripe using your Postgres database.";
export const BLOG_URL = "https://blog.sequin.io";

export const MAILTO_LINK = "mailto:support@sequin.io";

export const ONBOARDING_CALENDLY_LINK =
  "https://calendly.com/d/3wm-gt4-sks/new-source-onboarding";

export const SEQUIN_STATUS_PAGE =
  "https://1btv5z1y42jp.statuspage.io/api/v2/components.json";
