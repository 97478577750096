import { XCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

import { executeGenericAuthentication } from "lib/api";
import { Button } from "lib/lucidez";
import { CredentialFormProps } from "lib/platforms";
import { AirtableCredential, AIRTABLE_PLATFORM } from "lib/platforms/airtable";
import SvgSpinner from "components/svg/Spinner";

enum OAuthState {
  Initial,
  Error,
  Connecting,
}

export function AirtableCredentialOAuthForm(
  props: CredentialFormProps<AirtableCredential>
) {
  return (
    <div className="flex flex-col mt-2">
      <OAuthForm {...props} />
    </div>
  );
}

const OAuthForm = ({ onSave }: CredentialFormProps<AirtableCredential>) => {
  const StripeIcon = AIRTABLE_PLATFORM.displayIcon;

  const [oauthState, setOauthState] = useState(OAuthState.Initial);

  const handleConnect = async () => {
    setOauthState(OAuthState.Connecting);

    window.analytics.track("OAuth Connect Started", {
      kind: "airtable",
    });

    const res = await executeGenericAuthentication("airtable");

    if (res.isOk() && res.value) {
      window.analytics.track("OAuth Connect Finished", {
        kind: "airtable",
      });

      onSave(res.value.credential);
    } else {
      window.analytics.track("OAuth Connect Errored", {
        kind: "airtable",
      });

      setOauthState(OAuthState.Error);
    }
  };

  if (oauthState === OAuthState.Initial) {
    return (
      <div>
        <div className="flex flex-col">
          <Button
            className="w-full text-center justify-center mt-2"
            variant="primary"
            size="lg"
            iconLeft={<StripeIcon className="w-5 h-5" />}
            onClick={handleConnect}
          >
            Connect to {AIRTABLE_PLATFORM.displayName}
          </Button>
        </div>
      </div>
    );
  } else if (oauthState === OAuthState.Connecting) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <SvgSpinner className="h-9 w-9 text-black block animate-spin" />
        <p className="mt-1">
          Waiting for connection to finish in the other window.
        </p>
      </div>
    );
  } else if (oauthState === OAuthState.Error) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <XCircleIcon className="h-9 w-9 text-error block" />
        <p className="mt-1">
          There was an error connecting to {AIRTABLE_PLATFORM.displayName}.
        </p>
        <Button
          className="mt-2"
          onClick={() => setOauthState(OAuthState.Initial)}
          variant="primary"
          size="md"
        >
          Try again
        </Button>
      </div>
    );
  }
};
