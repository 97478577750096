import React, { useEffect, useState } from "react";
import { ServerIcon } from "@heroicons/react/outline";
import { useController, useFormContext, useWatch } from "react-hook-form";

import { useDatabases } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import { BaseState } from "lib/platforms/base";
import Collapse from "components/core/Collapse";
import SvgSpinner from "components/svg/Spinner";
import { useResourceFromContext } from "components/platforms/common/ResourceForm";
import { DestinationHelper } from "components/platforms/common/forms/DestinationStep/DestinationHelper";
import { StepProps } from "components/platforms/common/forms/util";
import { ResourceDestination } from "lib/api/types";
import { ResourceKind, Resource, DatabaseWithResources } from "lib/platforms";
import { StatusDot } from "lib/lucidez/components/StatusDot";
import {
  ChangeDestinationStep,
  OnboardChangeDestinationStep,
} from "components/platforms/common/forms/DestinationStep/ChangeDestinationStep";

export interface DestinationProps {
  resourceName: string;
  platformName: string;
  resourceKind: ResourceKind;
  resource?: Resource;
  destination: ResourceDestination;
  onDestinationChange(dest: ResourceDestination);
  onFinishFlow?: (wasCancelled?: boolean) => void;
  databases: DatabaseWithResources[];
}

export const FullPageDestinationStep = ({ isCreate, platform }: StepProps) => {
  const resource = useResourceFromContext();
  const [allOrgDatabases] = useDatabases();

  const { resetField } = useFormContext<BaseState<any>>();
  const { field, fieldState } = useController<BaseState<any>>({
    name: "destination",
    rules: {
      validate: (v) =>
        v.type !== "placeholder" && (v.type === "new" || v.validSchema),
    },
  });
  const destination = field.value;
  const isDirty = !isCreate && fieldState.isDirty;

  const [changingDestination, setChangingDestination] = useState(false);

  const name = useWatch<BaseState<any>>({ name: "name" });

  useEffect(() => {
    if (destination.type === "placeholder") {
      setChangingDestination(true);
    }
  }, [destination]);

  return (
    <div>
      <div className="flex flex-row items-center">
        <h3 className="text-2xl font-bold">Destination</h3>{" "}
        {isDirty && (
          <>
            <StatusDot variant="amber" className="ml-4" />
            <span className="ml-1.5 text-xs text-amber-600">
              Destination added / edited
            </span>
          </>
        )}
      </div>
      <p className="text-xs my-6">
        You'll need to <b>Save Changes</b> to save any edits made to the
        destination
      </p>
      <div className="mb-6">
        {allOrgDatabases ? (
          changingDestination ? (
            <div>
              <ChangeDestinationStep
                resourceName={name}
                resource={resource}
                platformName={platform.displayName}
                resourceKind={platform.kind}
                destination={destination}
                onDestinationChange={(dest) => field.onChange(dest)}
                onFinishFlow={(wasCancelled) => {
                  setChangingDestination(false);

                  if (!wasCancelled && window.analytics) {
                    window.analytics.track("Destination Flow Completed", {
                      destinationType: destination.type,
                    });
                  }
                }}
                databases={allOrgDatabases}
              />
            </div>
          ) : (
            <div>
              <DestinationHelper
                destination={destination}
                databases={allOrgDatabases}
                isDirty={isDirty}
              >
                {isCreate || !isDirty ? (
                  <Button
                    className="w-full text-center justify-center"
                    variant="tertiary"
                    size="lg"
                    onClick={() => {
                      setChangingDestination(true);

                      if (window.analytics) {
                        window.analytics.track("Destination Edit Clicked");
                      }
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  <button
                    className="btn-outlined btn-lg w-full text-center justify-center border-error text-error"
                    onClick={() => {
                      resetField("destination");

                      if (window.analytics) {
                        window.analytics.track("Destination Discard Clicked");
                      }
                    }}
                  >
                    Discard changes
                  </button>
                )}
              </DestinationHelper>
            </div>
          )
        ) : (
          <div>
            <SvgSpinner className="h-6 w-6 animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

export const DestinationStep = ({
  isCreate,
  platform,
  collapsed,
  onToggleCollapsed,
  disabledTooltip,
}: StepProps) => {
  const resource = useResourceFromContext();
  const [allOrgDatabases] = useDatabases();

  const { resetField } = useFormContext<BaseState<any>>();
  const { field, fieldState } = useController<BaseState<any>>({
    name: "destination",
    rules: {
      validate: (v) =>
        v.type !== "placeholder" && (v.type === "new" || v.validSchema),
    },
  });
  const destination = field.value;
  const isDirty = !isCreate && fieldState.isDirty;

  const [changingDestination, setChangingDestination] = useState(false);

  const name = useWatch<BaseState<any>>({ name: "name" });

  useEffect(() => {
    if (destination.type === "placeholder") {
      setChangingDestination(true);
    }
  }, [destination]);

  const selectedDb =
    destination.type !== "placeholder" &&
    destination.type !== "new" &&
    allOrgDatabases
      ? allOrgDatabases.find((x) => x.id === destination.databaseId)
      : null;

  const dbDesc = (
    <p>
      {destination.type === "placeholder" || destination.type === "new" ? (
        "Provision a new Sequin database"
      ) : selectedDb ? (
        selectedDb.definition.type === "dedicated_postgres" ? (
          <span>
            <span className="font-mono font-bold text-black">
              {selectedDb.name || selectedDb.definition.dbname}
            </span>{" "}
            <span className="text-gray-400">(self-hosted)</span>
          </span>
        ) : (
          <span>
            <span className="font-mono font-bold text-black">
              {selectedDb.name || selectedDb.definition.dbname}
            </span>{" "}
            <span className="text-gray-400">(Sequin-hosted)</span>
          </span>
        )
      ) : (
        <span>
          Existing database{" "}
          <span className="font-mono">({destination.databaseId})</span>
        </span>
      )}
    </p>
  );

  return (
    <Collapse
      title="Destination"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={<ServerIcon className="h-6 w-6" />}
      collapsedDesc={dbDesc}
      disabledTooltip={disabledTooltip}
    >
      <>{disabledTooltip}</>
      {allOrgDatabases ? (
        changingDestination ? (
          <div>
            <ChangeDestinationStep
              resourceName={name}
              resource={resource}
              platformName={platform.displayName}
              resourceKind={platform.kind}
              destination={destination}
              onDestinationChange={(dest) => field.onChange(dest)}
              onFinishFlow={(wasCancelled) => {
                setChangingDestination(false);

                if (!wasCancelled && window.analytics) {
                  window.analytics.track("Destination Flow Completed", {
                    destinationType: destination.type,
                  });
                }
              }}
              databases={allOrgDatabases}
            />
          </div>
        ) : (
          <div>
            <DestinationHelper
              destination={destination}
              databases={allOrgDatabases}
            />
            {isCreate ? (
              <Button
                className="mt-2 w-full text-center justify-center"
                variant="primary"
                size="lg"
                onClick={() => {
                  setChangingDestination(true);

                  if (window.analytics) {
                    window.analytics.track("Destination Edit Clicked");
                  }
                }}
              >
                Edit
              </Button>
            ) : isDirty ? (
              <button
                className="btn-outlined btn-lg mt-2 w-full text-center justify-center border-error text-error"
                onClick={() => {
                  resetField("destination");

                  if (window.analytics) {
                    window.analytics.track("Destination Discard Clicked");
                  }
                }}
              >
                Discard changes
              </button>
            ) : (
              <Button
                className="mt-2 w-full text-center justify-center"
                variant="primary"
                size="lg"
                onClick={() => {
                  setChangingDestination(true);

                  if (window.analytics) {
                    window.analytics.track("Destination Edit Clicked");
                  }
                }}
              >
                Change
              </Button>
            )}
          </div>
        )
      ) : (
        <div>
          <SvgSpinner className="h-6 w-6 animate-spin" />
        </div>
      )}
    </Collapse>
  );
};

export const OnboardDestinationStep = ({
  collapsed,
  disabledTooltip,
  isCreate = true,
  onToggleCollapsed,
  platform,
}: StepProps) => {
  const resource = useResourceFromContext();
  const [allOrgDatabases] = useDatabases();

  const { resetField } = useFormContext<BaseState<any>>();
  const { field, fieldState } = useController<BaseState<any>>({
    name: "destination",
    rules: {
      validate: (v) =>
        v.type !== "placeholder" && (v.type === "new" || v.validSchema),
    },
  });
  const destination = field.value;
  const isDirty = !isCreate && fieldState.isDirty;

  const [changingDestination, setChangingDestination] = useState(false);

  const name = useWatch<BaseState<any>>({ name: "name" });

  useEffect(() => {
    if (destination.type === "placeholder") {
      setChangingDestination(true);
    }
  }, [destination]);

  const selectedDb =
    destination.type !== "placeholder" &&
    destination.type !== "new" &&
    allOrgDatabases
      ? allOrgDatabases.find((x) => x.id === destination.databaseId)
      : null;

  const dbDesc = (
    <p>
      {destination.type === "placeholder" || destination.type === "new" ? (
        "Provision a new Sequin database"
      ) : selectedDb ? (
        selectedDb.definition.type === "dedicated_postgres" ? (
          <span>
            <span className="font-mono font-bold text-black">
              {selectedDb.name || selectedDb.definition.dbname}
            </span>{" "}
            <span className="text-gray-400">(self-hosted)</span>
          </span>
        ) : (
          <span>
            <span className="font-mono font-bold text-black">
              {selectedDb.name || selectedDb.definition.dbname}
            </span>{" "}
            <span className="text-gray-400">(Sequin-hosted)</span>
          </span>
        )
      ) : (
        <span>
          Existing database{" "}
          <span className="font-mono">({destination.databaseId})</span>
        </span>
      )}
    </p>
  );

  return (
    <Collapse
      title="Destination"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={<ServerIcon className="h-6 w-6" />}
      collapsedDesc={dbDesc}
      disabledTooltip={disabledTooltip}
    >
      <>{disabledTooltip}</>
      {allOrgDatabases ? (
        changingDestination ? (
          <div>
            <OnboardChangeDestinationStep
              resourceName={name}
              resource={resource}
              platformName={platform.displayName}
              resourceKind={platform.kind}
              destination={destination}
              onDestinationChange={(dest) => field.onChange(dest)}
              onFinishFlow={(wasCancelled) => {
                setChangingDestination(false);

                if (!wasCancelled && window.analytics) {
                  window.analytics.track("Destination Flow Completed", {
                    destinationType: destination.type,
                  });
                }
              }}
              databases={allOrgDatabases}
            />
          </div>
        ) : (
          <div>
            <DestinationHelper
              destination={destination}
              databases={allOrgDatabases}
            />
            {isCreate ? (
              <Button
                className="mt-2 w-full text-center justify-center"
                variant="primary"
                size="lg"
                onClick={() => {
                  setChangingDestination(true);

                  if (window.analytics) {
                    window.analytics.track("Destination Edit Clicked");
                  }
                }}
              >
                Edit
              </Button>
            ) : isDirty ? (
              <button
                className="btn-outlined btn-lg mt-2 w-full text-center justify-center border-error text-error"
                onClick={() => {
                  resetField("destination");

                  if (window.analytics) {
                    window.analytics.track("Destination Discard Clicked");
                  }
                }}
              >
                Discard changes
              </button>
            ) : (
              <Button
                className="mt-2 w-full text-center justify-center"
                variant="primary"
                size="lg"
                onClick={() => {
                  setChangingDestination(true);

                  if (window.analytics) {
                    window.analytics.track("Destination Edit Clicked");
                  }
                }}
              >
                Change
              </Button>
            )}
          </div>
        )
      ) : (
        <div>
          <SvgSpinner className="h-6 w-6 animate-spin" />
        </div>
      )}
    </Collapse>
  );
};

export default DestinationStep;
