import React, { useCallback, useEffect, useRef, useState } from "react";
import copy from "clipboard-copy";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { CheckIcon, ClipboardCopyIcon } from "@heroicons/react/outline";

interface CopyButtonProps {
  className?: string;
  textToCopy: string;
}

export const CopyTextToClipboardButton = (props: CopyButtonProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  const copiedResetTimeout = useRef<number>();

  const resetHasCopied = () => {
    setHasCopied(false);
  };

  const clearResetHasCopiedTimeout = () => {
    if (copiedResetTimeout.current) {
      clearInterval(copiedResetTimeout.current);
    }
  };

  useEffect(() => {
    clearResetHasCopiedTimeout();

    if (hasCopied) {
      copiedResetTimeout.current = setTimeout(
        resetHasCopied,
        3_000
      ) as unknown as number;

      return () => {
        if (copiedResetTimeout.current) {
          clearInterval(copiedResetTimeout.current);
        }
      };
    }
  }, [hasCopied]);

  const copyTextToClipboard = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      ev.preventDefault();
      copy(props.textToCopy);
      setHasCopied(true);
    },
    [props.textToCopy]
  );

  return (
    <Tippy content={hasCopied ? "Copied" : "Copy"} interactive>
      <button
        className={classNames("text-gray-500 hover:text-black", {
          [props.className]: props.className !== undefined,
        })}
        onClick={copyTextToClipboard}
      >
        {hasCopied ? (
          <CheckIcon className="w-5 h-5 text-success" />
        ) : (
          <ClipboardCopyIcon className="w-5 h-5" />
        )}
      </button>
    </Tippy>
  );
};
