import { w } from "lib/windstitch";

export const StatusDot = w.span("inline-block h-1.5 w-1.5 rounded-full", {
  variants: {
    variant: {
      amber: "bg-amber-600",
    },
  },
  defaultVariants: {
    variant: "amber",
  },
});
