import { useMemo } from "react";
import useFullObjectSearch from "./useFullObjectSearch";

export function useFilter<T>(
  filterOn?: T[],
  filter?: string,
  defaultValue: any = []
): T[] {
  const filterResults = useFullObjectSearch(filter, filterOn ?? []);

  const filteredObjects = useMemo(() => {
    if (!filterOn) {
      return defaultValue;
    }

    if (!filter) {
      return filterOn;
    }

    return filterResults.indexes.map((idx) => (filterOn as T[])[idx]);
  }, [filter, filterOn, filterResults]);

  return filteredObjects;
}
