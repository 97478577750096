/**
 * In theory there could be multiple redirectUrl query params.
 * This function normalizes it and avoids the Typescript warnings.
 */
export const normalizeRedirect = (
  redirectUrl: string | string[] | undefined
): string | undefined => {
  if (typeof redirectUrl === "string") {
    return redirectUrl.startsWith("/") ? redirectUrl : `/${redirectUrl}`;
  } else if (Array.isArray(redirectUrl)) {
    // in case there's multiple redirect URLs in the URL we'll use the first one
    return redirectUrl[0].startsWith("/")
      ? redirectUrl[0]
      : `/${redirectUrl[0]}`;
  }

  return undefined;
};
