import * as React from "react";
import { SVGProps, memo } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 29 30"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M89.806 9.75V6.223a2.72 2.72 0 0 0 1.566-2.448v-.081c0-1.5-1.216-2.716-2.716-2.716h-.081a2.729 2.729 0 0 0-2.716 2.716v.081a2.72 2.72 0 0 0 1.566 2.448V9.75a7.697 7.697 0 0 0-3.657 1.61l-9.673-7.534c.069-.249.105-.505.109-.762v-.006a3.074 3.074 0 0 0-3.06-3.06 3.074 3.074 0 0 0-3.06 3.06 3.075 3.075 0 0 0 3.056 3.06 3.023 3.023 0 0 0 1.507-.41l9.525 7.412a7.73 7.73 0 0 0 .118 8.69l-2.897 2.898a2.471 2.471 0 0 0-.724-.118 2.525 2.525 0 0 0-2.511 2.513 2.524 2.524 0 0 0 2.513 2.513 2.525 2.525 0 0 0 2.513-2.511 2.471 2.471 0 0 0-.118-.724l2.866-2.867a7.73 7.73 0 0 0 4.714 1.604c4.24 0 7.728-3.488 7.728-7.728 0-3.803-2.808-7.069-6.568-7.64m-1.188 11.6a3.963 3.963 0 0 1-.109.002c-2.175 0-3.965-1.79-3.965-3.965 0-2.176 1.79-3.965 3.965-3.965l.109.001a3.983 3.983 0 0 1 3.826 3.963 3.982 3.982 0 0 1-3.822 3.962"
      style={{
        fillRule: "nonzero",
      }}
      transform="translate(-68.084 .002)"
      fill="currentColor"
    />
  </svg>
);

const SvgHubspotFilled = memo(SvgComponent);
export default SvgHubspotFilled;
