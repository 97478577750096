import React from "react";
import * as z from "zod";

import GithubCredentialForm from "components/platforms/github/GithubCredentialForm";
import GithubForm, {
  FullPageGithubForm,
  OnboardCredentialGithubForm,
} from "components/platforms/github/GithubForm";
import SvgGithubFilled from "components/svg/GitHubFilled";
import {
  baseCredentialSchema,
  baseResourceSchema,
  RateLimit,
  rawResourceSchema,
} from "lib/api/types";
import { Platform } from "lib/platforms";
import {
  baseInitialCreateState,
  baseInitialUpdateState,
  basePrepareFieldsForCreate,
  basePrepareFieldsForUpdate,
  BaseState,
} from "lib/platforms/base";
import {
  rateLimitableInitialCreateState,
  rateLimitableInitialUpdateState,
  rateLimitablePrepareFieldsForCreate,
  rateLimitablePrepareFieldsForUpdate,
  RateLimitableState,
} from "lib/platforms/rateLimitable";

// Metadata definition
export const githubDefinitionSchema = z.object({
  proxyWaitActive: z.boolean(),
});

export type GithubDefinition = z.infer<typeof githubDefinitionSchema>;

// Credential definition
export const githubCredentialSchema = baseCredentialSchema.extend({
  payload: z.object({
    account_login: z.string(),
    account_type: z.string(),
    installation_id: z.string(),
    kind: z.literal("github"),
  }),
});

export type GithubCredential = z.infer<typeof githubCredentialSchema>;

// Integral resource definition
export const rawGithubResourceSchema = rawResourceSchema.extend({
  kind: z.literal("github"),
  definition: githubDefinitionSchema,
  credential: githubCredentialSchema,
});

export const githubResourceSchema = baseResourceSchema.extend({
  kind: z.literal("github"),
  definition: githubDefinitionSchema,
  credential: githubCredentialSchema,
});

export type GithubResource = z.infer<typeof githubResourceSchema>;

export interface GithubState
  extends BaseState<GithubCredential>,
    RateLimitableState {}

const DEFAULT_RATE_LIMIT: RateLimit = {
  allowedRequests: 5_000,
  interval: 3_600_000, // One hour. Not user editable for now
  maxConcurrentRequests: 3, // not in use right now
};

// Platform definition
export const GITHUB_PLATFORM: Platform<
  GithubResource,
  GithubState,
  GithubCredential
> = {
  displayName: "GitHub",
  displayIcon: ({ ref, ...props }) => {
    return <SvgGithubFilled {...props} />;
  },
  resourceAppositive: "account or organization",
  kind: "github",

  supportsCollectionStatus: false,
  supportsWriteProxy: true,

  helpers: [
    {
      name: "SOURCE",
      buildUrl: (res) => ({
        url: `https://github.com/${res.credential.payload.account_login}`,
        displayUrl: `github.com`,
      }),
    },
  ],

  buildForm: GithubForm,
  buildCredentialForm: GithubCredentialForm,
  buildOnboardCredentialForm: OnboardCredentialGithubForm,
  extraTableParams: (_state) => {
    return {};
  },

  getLabelForCredential: (cred) =>
    `${cred.payload.account_login} (${cred.payload.account_type})`,

  getInitialCreateState: () => ({
    ...baseInitialCreateState("GitHub"),
    ...rateLimitableInitialCreateState(DEFAULT_RATE_LIMIT),
  }),
  getInitialUpdateState: (res) => ({
    ...baseInitialUpdateState(res),
    ...rateLimitableInitialUpdateState(res, DEFAULT_RATE_LIMIT),
  }),

  fullPageForm: FullPageGithubForm,

  prepareFieldsForCreate: (d) => ({
    ...basePrepareFieldsForCreate(d, "github"),
    ...rateLimitablePrepareFieldsForCreate(d),
  }),
  prepareFieldsForUpdate: (d) => ({
    ...basePrepareFieldsForUpdate(d, "github"),
    ...rateLimitablePrepareFieldsForUpdate(d),
  }),
};
