// TODO: remove this in favor of Intl.NumberFormat
export const getFriendlyValue = (value: number, decimalPlaces: number = 1) => {
  const suffix = value < 1_000 ? "" : value < 1_000_000 ? "k" : "m";
  const divider = value < 1_000 ? 1 : value < 1_000_000 ? 1_000 : 1_000_000;

  return prettyInt(value / divider, decimalPlaces) + suffix;
};

const prettyInt = (x: number, decimalPlaces: number) =>
  Math.round(x * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

export const friendlyMilliseconds = (
  milliseconds: number,
  detail: "coarse" | "detailed" = "coarse"
): string => {
  if (milliseconds < 1000) {
    return "1s";
  }

  const seconds = milliseconds / 1000;

  if (seconds < 60) {
    return `${Math.round(seconds)}s`;
  }

  const minutes = seconds / 60;
  const roundedMinuteModulus = Math.round(seconds % 60);

  // To prevent the display of 12m60s. Instead will display as 13m in both
  // coarse and detailed views.
  if (roundedMinuteModulus === 60 || roundedMinuteModulus === 0) {
    return `${Math.ceil(minutes)}m`;
  }

  if (detail === "detailed") {
    return `${Math.trunc(minutes)}m${roundedMinuteModulus}s`;
  }
  return `${Math.trunc(minutes)}m`;
};

export const capitalize = (word) => {
  return word && `${word[0].toUpperCase()}${word.slice(1)}`;
};
