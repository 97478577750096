import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { BaseState } from "lib/platforms/base";
import {
  getValidationStateIcon,
  getValidationState,
} from "components/platforms/common/forms/util";
import classNames from "classnames";
import { StatusDot } from "lib/lucidez/components/StatusDot";

export default function NameStep() {
  const { register, getFieldState } = useFormContext<BaseState<any>>();
  const formState = useFormState<BaseState<any>>({
    name: "name",
  });

  return (
    <div className="pb-8">
      <label>
        <span className="font-bold">Resource name</span>
        <div className="flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black flex-1 mt-2">
          <input
            className="focus:outline-none rounded w-72 flex-1 px-4"
            type="text"
            placeholder="My Resource"
            {...register("name", { required: true })}
          />
          <div className="w-10 h-10 flex items-center justify-center">
            {getValidationStateIcon(
              getValidationState(getFieldState("name", formState))
            )}
          </div>
        </div>
      </label>
    </div>
  );
}

export const FullPageNameStep = () => {
  const { register, getFieldState } = useFormContext<BaseState<any>>();
  const formState = useFormState<BaseState<any>>({
    name: "name",
  });

  const isDirty = formState?.dirtyFields?.name === true;

  return (
    <div>
      <label>
        <span className="font-bold text-xs">
          Resource name{" "}
          {isDirty && <StatusDot variant="amber" className="ml-1" />}
        </span>
        <div
          className={classNames(
            "flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black flex-1 mt-2",
            isDirty && "border-amber-600"
          )}
        >
          <input
            className="focus:outline-none rounded w-72 flex-1 px-4"
            type="text"
            placeholder="My Resource"
            {...register("name", { required: true })}
          />
          <div className="w-10 h-10 flex items-center justify-center">
            {getValidationStateIcon(
              getValidationState(getFieldState("name", formState))
            )}
          </div>
        </div>
      </label>
    </div>
  );
};
