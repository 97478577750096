import React, { useState } from "react";
import memoize from "lodash/memoize";

import { FormProps, OnboardCredentialFormProps } from "lib/platforms";
import { HUBSPOT_PLATFORM } from "lib/platforms/hubspot";
import CredentialStep, {
  FullPageCredentialStep,
  OnboardCredentialStep,
} from "components/platforms/common/forms/CredentialStep";
import {
  DestinationStep,
  FullPageDestinationStep,
  OnboardDestinationStep,
} from "components/platforms/common/forms/DestinationStep";
import NameStep, {
  FullPageNameStep,
} from "components/platforms/common/forms/NameStep";
import SelectTablesStep, {
  LinkWay,
} from "components/platforms/common/forms/SelectTablesStep";
import {
  FullPageRateLimitStep,
  RateLimitStep,
} from "components/platforms/common/forms/RateLimitStep";
import { StepProps } from "components/platforms/common/forms/util";
import SelectAssociationsTables from "components/platforms/hubspot/SelectAssociationsTables";
import { SelectEnvironmentStep } from "components/platforms/common/forms/SelectEnvironmentStep";
import { FullPageEventsStep } from "components/platforms/common/forms/EventsStep";

enum Step {
  AddToken = "AddToken",
  SelectEnvironment = "SelectEnvironment",
  SelectTables = "SelectTables",
  RateLimit = "RateLimit",
  Destination = "Destination",
  Advanced = "Advanced",
}

export default function HubspotForm({ isCreate, isOnboard }: FormProps) {
  const [collapsed, setCollapsed] = useState<{ [k: string]: boolean }>({
    [Step.AddToken]: !isCreate, // Default open on creates
    [Step.SelectEnvironment]: false,
    [Step.RateLimit]: true,
    [Step.SelectTables]: false,
    [Step.Destination]: !isCreate || isOnboard, // Destination is always collapsed by default
  });

  const genericToggleCollapse = React.useMemo(
    () =>
      memoize(
        (step: Step) => () =>
          setCollapsed((collapsed) => ({
            ...collapsed,
            [step]: !collapsed[step],
          }))
      ),
    []
  );

  const stepProps = {
    isCreate,
    platform: HUBSPOT_PLATFORM,
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      {!isCreate && <NameStep />}
      <CredentialStep
        {...stepProps}
        collapsed={collapsed[Step.AddToken]}
        onToggleCollapsed={genericToggleCollapse(Step.AddToken)}
        onNextStep={genericToggleCollapse(Step.AddToken)}
      />
      <SelectEnvironmentStep
        collapsed={collapsed[Step.SelectEnvironment]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectEnvironment)}
      />
      <CustomRateLimitStep
        {...stepProps}
        collapsed={collapsed[Step.RateLimit]}
        onToggleCollapsed={genericToggleCollapse(Step.RateLimit)}
      />
      <SelectTablesStep
        {...stepProps}
        collapsed={collapsed[Step.SelectTables]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectTables)}
        tableLinks={[
          {
            tableId: "PIPELINE",
            linkedTableId: "STAGE",
            way: LinkWay.TwoWay,
          },
          {
            tableId: "STAGE",
            linkedTableId: "PIPELINE",
            way: LinkWay.TwoWay,
          },
        ]}
      >
        {(tables) => (
          <SelectAssociationsTables tables={tables} isCreate={isCreate} />
        )}
      </SelectTablesStep>
      {isOnboard ? (
        <OnboardDestinationStep
          {...stepProps}
          collapsed={collapsed[Step.Destination]}
          onToggleCollapsed={genericToggleCollapse(Step.Destination)}
        />
      ) : (
        <DestinationStep
          {...stepProps}
          collapsed={collapsed[Step.Destination]}
          onToggleCollapsed={genericToggleCollapse(Step.Destination)}
        />
      )}
    </div>
  );
}

export const FullPageHubspotForm = ({ isCreate }: FormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      {!isCreate && <FullPageNameStep />}
      <FullPageCredentialStep platform={HUBSPOT_PLATFORM} />
      <hr />
      <FullPageRateLimitStep
        platform={HUBSPOT_PLATFORM}
        automaticallyManagedLimit={{
          notice:
            "Sequin's HubSpot app is allocated its own rate limit quota. This means Sequin's sync won't impact the rate limit of other third-party apps or your private apps. We'll configure your rate limit to maximize performance of your sync.",
          title: "",
        }}
        renderNotice={() => (
          <>
            <a
              className="link"
              target="_blank"
              href="https://docs.sequin.io/rate-limits"
              rel="noreferrer"
            >
              Read more about rate limits
            </a>
            .
          </>
        )}
      />
      <hr />
      <FullPageDestinationStep isCreate={false} platform={HUBSPOT_PLATFORM} />
      <hr />
      <FullPageEventsStep />
    </div>
  );
};

const CustomRateLimitStep = (stepProps: StepProps) => {
  return (
    <RateLimitStep
      {...stepProps}
      automaticallyManagedLimit={{
        notice:
          "Sequin's HubSpot app is allocated its own rate limit quota. This means Sequin's sync won't impact the rate limit of other third-party apps or your private apps. We'll configure your rate limit to maximize performance of your sync.",
        title: "",
      }}
      renderNotice={() => (
        <>
          <a
            className="link"
            target="_blank"
            href="https://docs.sequin.io/rate-limits"
            rel="noreferrer"
          >
            Read more about rate limits
          </a>
          .
        </>
      )}
    />
  );
};

export const OnboardCredentialHubspotForm = ({
  handleBackClick,
}: OnboardCredentialFormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      <OnboardCredentialStep
        handleBackClick={handleBackClick}
        platform={HUBSPOT_PLATFORM}
      />
    </div>
  );
};
