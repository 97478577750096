import React from "react";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import { CopyableCodeLine } from "./CopyableCodeLine";

export default function MarkdownRender({
  children,
  useCopyableCodeLines,
}: {
  children: string;
  useCopyableCodeLines?: boolean;
}) {
  return (
    <ReactMarkdown
      components={{
        a: ({ node, ...props }) => (
          <a className="link" target="_blank" {...props} />
        ),
        p: ({ node, ...props }) => <p className="mt-2" {...props} />,
        code: ({ node, inline, className, ...props }) => {
          if (inline) {
            return <code className={className} {...props} />;
          }

          if (useCopyableCodeLines) {
            return (
              <div className="mt-2">
                <CopyableCodeLine code={props.children as string} />
              </div>
            );
          }

          return (
            <pre className="p-2 w-full mt-2 border rounded">
              <code
                className={classNames([className, "undecorated"])}
                {...props}
              ></code>
            </pre>
          );
        },
        pre: ({ node, ...props }) => {
          if (useCopyableCodeLines) {
            return <span {...props}></span>;
          } else {
            return <pre {...props} />;
          }
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
}
