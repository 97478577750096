import { Listbox } from "@headlessui/react";
import {
  ArrowLeftIcon,
  SelectorIcon,
  ShieldCheckIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import {
  CheckCircleIcon,
  CheckIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { validateCredential } from "lib/api";
import { useCredentialInfo, useCredentials } from "lib/api/hooks";
import { PlatformCredential, Platform } from "lib/platforms";
import { BaseState } from "lib/platforms/base";
import Collapse from "components/core/Collapse";
import Steps from "components/core/Steps";
import SvgSpinner from "components/svg/Spinner";
import ResourceCredentialForm from "components/platforms/common/ResourceCredentialForm";
import {
  FieldState,
  getValidationStateIcon,
  StepProps,
  useAsyncValidation,
  useValidation,
} from "./util";
import { Button } from "lib/lucidez";
import classNames from "classnames";
import { StatusDot } from "lib/lucidez/components/StatusDot";

enum Step {
  LoadingKeys = "LoadingKeys",
  SelectKey = "SelectKey",
  CredentialForm = "CredentialForm",
}

type CredentialStepLabelBuilder = (platformDisplayName: string) => string;

type LabelsProps = {
  dropdownLabel: string;
  dropdownPlaceholder: string;
  addCredentialOptionLabel: string;
  addCredentialStepLabel: CredentialStepLabelBuilder;
};

export const CredentialStep = ({
  platform,
  collapsed,
  dropdownLabel = "Credential",
  dropdownPlaceholder = "Select a credential",
  addCredentialOptionLabel = "Add new credential",
  addCredentialStepLabel = (platformDisplayName) =>
    `Add a new ${platformDisplayName} credential`,
  onToggleCollapsed,
  onNextStep,
}: StepProps & Partial<LabelsProps>) => {
  const [step, setStep] = useState(Step.SelectKey);

  const { setValue, setError, clearErrors } = useFormContext<BaseState<any>>();
  const currentCredential = useWatch<BaseState<any>>({ name: "credential" });

  const keyValidation = useValidation(FieldState.NotValidated);

  const doKeyValidation = async () => {
    if (!currentCredential) {
      keyValidation.setFieldState(FieldState.NotValidated);
      setError("credential", {
        type: "custom",
        message: "No credential",
      });
      return;
    }

    keyValidation.setFieldState(FieldState.Validating);

    const res = await validateCredential(currentCredential.id);
    const isValid = !!(res.isOk() && res.value.secret_valid);

    keyValidation.setFieldState(
      isValid ? FieldState.Valid : FieldState.Invalid
    );

    if (isValid) {
      clearErrors("credential");
    } else {
      setError("credential", {
        type: "custom",
        message: "Invalid credential",
      });
    }
  };

  useEffect(() => {
    doKeyValidation();
  }, [currentCredential]);

  const isValid = keyValidation.fieldState === FieldState.Valid;
  const isInvalid = keyValidation.fieldState === FieldState.Invalid;
  const isValidating = keyValidation.fieldState === FieldState.Validating;

  const getStepIcon = () => {
    if (isValid) {
      return <CheckCircleIcon className="h-6 w-6 text-success" />;
    } else if (isInvalid) {
      return <XCircleIcon className="h-6 w-6 text-error" />;
    }

    return <ShieldCheckIcon className="h-6 w-6" />;
  };

  useEffect(() => {
    if (isValid) {
      onNextStep && onNextStep();
    }
  }, [isValid]);

  const [rawCredentials, mutate] = useCredentials();
  const credentials = rawCredentials?.filter((x) =>
    platform.kind === "airtable"
      ? ["airtable", "airtable_oauth"].includes(x.payload.kind) // Temp support while we deprecate older Airtable keys
      : x.payload.kind === platform.kind
  );

  const showLoadingStep = typeof rawCredentials === "undefined";

  const handleSave = async (cred: PlatformCredential) => {
    await mutate();
    setValue("credential", cred, {
      shouldDirty: true,
      shouldTouch: true,
    });
    setStep(Step.SelectKey);

    if (window && window.analytics) {
      window.analytics.track("API Key Added", {
        kind: platform.kind,
      });
    }
  };

  return (
    <Collapse
      title="Authentication"
      collapsed={!isValidating && !isInvalid && collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={getStepIcon()}
    >
      <Steps activeStep={showLoadingStep ? Step.LoadingKeys : step}>
        <Steps.Step stepKey={Step.LoadingKeys}>
          <div className="flex items-center justify-center">
            <SvgSpinner className="w-8 h-8 animate-spin" />
          </div>
        </Steps.Step>
        <Steps.Step stepKey={Step.SelectKey}>
          <SelectKey
            onAddNewKey={() => setStep(Step.CredentialForm)}
            credentials={credentials}
            platform={platform}
            validationState={keyValidation.fieldState}
            dropdownLabel={dropdownLabel}
            dropdownPlaceholder={dropdownPlaceholder}
            addCredentialOptionLabel={addCredentialOptionLabel}
            addCredentialStepLabel={addCredentialStepLabel}
          />
        </Steps.Step>
        <Steps.Step stepKey={Step.CredentialForm}>
          <div className="text-center flex flex-row items-center">
            <button
              className="btn-outlined w-8 h-8 border-transparent flex justify-center items-center"
              onClick={() => setStep(Step.SelectKey)}
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
            <h3 className="font-bold flex-1">
              {addCredentialStepLabel(platform.displayName)}
            </h3>
            <div className="w-8" />
          </div>
          <div className="mt-4">
            <ResourceCredentialForm
              kind={platform.kind}
              onBack={() => setStep(Step.SelectKey)}
              onSave={handleSave}
            />
          </div>
        </Steps.Step>
      </Steps>
    </Collapse>
  );
};

export default CredentialStep;

const SelectKey = ({
  credentials,
  platform,
  validationState,
  onAddNewKey,
  dropdownLabel,
  dropdownPlaceholder,
  addCredentialOptionLabel,
}: {
  credentials: PlatformCredential[];
  platform: Platform<any, any, any>;
  validationState: FieldState;
  onAddNewKey: () => void;
} & LabelsProps) => {
  const currentCredential = useWatch<BaseState<any>>({ name: "credential" });
  const currentCredentialExists =
    credentials &&
    currentCredential &&
    credentials.find((x) => x.id === currentCredential.id);
  const [currentCredentialInfo] = useCredentialInfo(
    currentCredentialExists && currentCredential.id
  );

  const { field } = useController<BaseState<any>>({
    name: "credential",
  });

  return (
    <>
      <Listbox
        onChange={(v) => {
          if (v === "add-new") {
            onAddNewKey();
          } else {
            field.onChange(v);
          }
        }}
        value={field.value}
      >
        <div className="flex flex-col">
          <h3 className="font-bold text-xs">{dropdownLabel}</h3>
          <Listbox.Label className="sr-only">{dropdownLabel}</Listbox.Label>
          <div className="relative w-full">
            <Listbox.Button className="mt-2 relative py-2 pl-3 pr-10 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black sm:text-xs border w-full">
              <span className="block truncate">
                {currentCredential
                  ? currentCredentialInfo && currentCredentialInfo.label
                    ? currentCredentialInfo.label
                    : platform.getLabelForCredential(currentCredential)
                  : dropdownPlaceholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <div className="w-10 h-10 flex items-center justify-center">
                  {getValidationStateIcon(validationState)}
                </div>
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute credential-list w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
              {(credentials || []).map((credential) => (
                <Listbox.Option
                  key={credential.id}
                  className={({ active }) =>
                    `${active ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                              cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={credential}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        <CredentialLabel
                          credential={credential}
                          platform={platform}
                        />
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}

              <Listbox.Option
                className={({ active }) =>
                  `${active ? "bg-gray-100" : ""}
                              cursor-pointer select-none relative py-2 pr-4 text-blue-500`
                }
                value="add-new"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <PlusIcon className="w-5 h-5" aria-hidden="true" />
                </span>
                <span className="block truncate ml-10 font-bold">
                  {addCredentialOptionLabel}
                </span>
              </Listbox.Option>
            </Listbox.Options>
          </div>
        </div>
      </Listbox>
      {currentCredentialExists &&
        currentCredentialExists.payload.kind === "airtable" &&
        currentCredentialExists.payload.secret.startsWith("key") && (
          <AirtableUpgradeKeyNotice onUpgrade={onAddNewKey} />
        )}
    </>
  );
};

const FullPageSelectKey = ({
  credentials,
  platform,
  validationState,
  onAddNewKey,
  dropdownLabel,
  dropdownPlaceholder,
  addCredentialOptionLabel,
}: {
  credentials: PlatformCredential[];
  platform: Platform<any, any, any>;
  validationState: FieldState;
  onAddNewKey: () => void;
} & LabelsProps) => {
  const currentCredential = useWatch<BaseState<any>>({ name: "credential" });
  const currentCredentialExists =
    credentials &&
    currentCredential &&
    credentials.find((cred) => cred.id === currentCredential.id);
  const [currentCredentialInfo] = useCredentialInfo(
    currentCredentialExists && currentCredential.id
  );

  const { field, formState } = useController<BaseState<any>>({
    name: "credential",
  });

  const isDirty = formState?.dirtyFields?.credential === true;

  return (
    <>
      <Listbox
        onChange={(value) => {
          if (value === "add-new") {
            onAddNewKey();
          } else {
            field.onChange(value);
          }
        }}
        value={field.value}
      >
        <div className="flex flex-col">
          <h3 className="font-bold text-xs">
            {dropdownLabel}{" "}
            {isDirty && <StatusDot variant="amber" className="ml-1" />}
          </h3>
          <Listbox.Label className="sr-only">{dropdownLabel}</Listbox.Label>
          <div className="flex flex-row w-full mt-2 ">
            <div className="relative flex-1">
              <Listbox.Button
                className={classNames(
                  "relative h-10 pl-3 pr-10 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black sm:text-xs border w-full",
                  isDirty && "border-amber-600"
                )}
              >
                <span className="block truncate">
                  {currentCredential
                    ? currentCredentialInfo?.label ??
                      platform.getLabelForCredential(currentCredential)
                    : dropdownPlaceholder}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <div className="w-10 h-10 flex items-center justify-center">
                    {getValidationStateIcon(validationState)}
                  </div>
                  <SwitchVerticalIcon
                    className="w-4 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
                {(credentials || []).map((credential) => (
                  <Listbox.Option
                    key={credential.id}
                    className={({ active }) =>
                      classNames(
                        "text-gray-900 cursor-default select-none relative py-2 pl-10 pr-4",
                        { "bg-gray-100": active }
                      )
                    }
                    value={credential}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate`}
                        >
                          <CredentialLabel
                            credential={credential}
                            platform={platform}
                          />
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
            <div className="ml-3">
              <Button
                variant="tertiary"
                size="md"
                className="text-xs"
                iconLeft={<PlusIcon className="h-4 w-4" />}
                onClick={onAddNewKey}
              >
                {addCredentialOptionLabel}
              </Button>
            </div>
          </div>
        </div>
      </Listbox>
      {currentCredentialExists &&
        currentCredentialExists.payload.kind === "airtable" &&
        currentCredentialExists.payload.secret.startsWith("key") && (
          <AirtableUpgradeKeyNotice onUpgrade={onAddNewKey} />
        )}
    </>
  );
};

const AirtableUpgradeKeyNotice = ({ onUpgrade }: { onUpgrade: () => void }) => {
  return (
    <div className="border p-3 px-4 mt-2 rounded bg-amber-50 border-amber-200">
      <h4 className="font-bold">You’ll need to update your API key soon</h4>
      <p className="mt-2 text-sm">
        Airtable is deprecating the type of API key that you are using. The key
        will stop working on February 1, 2024, which will halt your sync.
      </p>
      <p className="mt-1 text-sm">
        Reauthenticate Airtable now to avoid any disruptions in your sync.
      </p>
      <Button variant="primary" className="mt-2" onClick={onUpgrade}>
        Reauthenticate
      </Button>
    </div>
  );
};

const CredentialLabel = ({
  credential,
  platform,
}: {
  credential: PlatformCredential;
  platform: Platform<unknown, unknown, unknown>;
}) => {
  const [info] = useCredentialInfo(credential.id, {
    errorRetryCount: 3,
    revalidateOnFocus: false,
  });

  if (info && info.label) {
    return <>{info.label}</>;
  } else {
    return <>{platform.getLabelForCredential(credential)}</>;
  }
};

export const FullPageCredentialStep = ({
  platform,
  dropdownLabel = "Credential",
  dropdownPlaceholder = "Select a credential",
  addCredentialOptionLabel = "Add new credential",
  addCredentialStepLabel = (platformDisplayName) =>
    `Add a new ${platformDisplayName} credential`,
}: StepProps & Partial<LabelsProps>) => {
  const [step, setStep] = useState(Step.SelectKey);

  const { setValue, setError, clearErrors } = useFormContext<BaseState<any>>();
  const currentCredential = useWatch<BaseState<any>>({ name: "credential" });

  const keyValidation = useValidation(FieldState.NotValidated);
  const { setIsFieldValidationRunning } = useAsyncValidation();

  const doKeyValidation = async () => {
    setIsFieldValidationRunning("credential", true);

    if (!currentCredential) {
      keyValidation.setFieldState(FieldState.NotValidated);
      setError("credential", {
        type: "custom",
        message: "No credential",
      });
      setIsFieldValidationRunning("credential", false);
      return;
    }

    keyValidation.setFieldState(FieldState.Validating);

    const res = await validateCredential(currentCredential.id);
    const isValid = !!(res.isOk() && res.value.secret_valid);

    keyValidation.setFieldState(
      isValid ? FieldState.Valid : FieldState.Invalid
    );

    if (isValid) {
      clearErrors("credential");
    } else {
      setError("credential", {
        type: "custom",
        message: "Invalid credential",
      });
    }

    setIsFieldValidationRunning("credential", false);
  };

  useEffect(() => {
    doKeyValidation();
  }, [currentCredential]);

  const [rawCredentials, mutate] = useCredentials();
  const credentials = rawCredentials?.filter((x) =>
    platform.kind === "airtable"
      ? ["airtable", "airtable_oauth"].includes(x.payload.kind) // Temp support while we deprecate older Airtable keys
      : x.payload.kind === platform.kind
  );

  const showLoadingStep = typeof rawCredentials === "undefined";

  const handleSave = async (cred: Credential) => {
    await mutate();
    setValue("credential", cred, {
      shouldDirty: true,
      shouldTouch: true,
    });
    setStep(Step.SelectKey);

    if (window && window.analytics) {
      window.analytics.track("API Key Added", {
        kind: platform.kind,
      });
    }
  };

  return (
    <div>
      <Steps activeStep={showLoadingStep ? Step.LoadingKeys : step}>
        <Steps.Step stepKey={Step.LoadingKeys}>
          <div className="flex items-center justify-center">
            <SvgSpinner className="w-8 h-8 animate-spin" />
          </div>
        </Steps.Step>
        <Steps.Step stepKey={Step.SelectKey}>
          <FullPageSelectKey
            onAddNewKey={() => setStep(Step.CredentialForm)}
            credentials={credentials}
            platform={platform}
            validationState={keyValidation.fieldState}
            dropdownLabel={dropdownLabel}
            dropdownPlaceholder={dropdownPlaceholder}
            addCredentialOptionLabel={addCredentialOptionLabel}
            addCredentialStepLabel={addCredentialStepLabel}
          />
        </Steps.Step>
        <Steps.Step stepKey={Step.CredentialForm}>
          <div className="text-center flex flex-row items-center">
            <button
              className="btn-outlined w-8 h-8 border-transparent flex justify-center items-center"
              onClick={() => setStep(Step.SelectKey)}
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
            <h3 className="font-bold flex-1">
              {addCredentialStepLabel(platform.displayName)}
            </h3>
            <div className="w-8" />
          </div>
          <div className="mt-4">
            <ResourceCredentialForm
              kind={platform.kind}
              onBack={() => setStep(Step.SelectKey)}
              onSave={handleSave}
            />
          </div>
        </Steps.Step>
      </Steps>
    </div>
  );
};

interface OnboardCredentialStepProps extends StepProps {
  handleBackClick: () => void;
  onCompleteOAuth?: () => void;
  onStartOAuth?: () => void;
}

export const OnboardCredentialStep = ({
  handleBackClick,
  onCompleteOAuth,
  onStartOAuth,
  platform,
  dropdownLabel = "Credential",
  dropdownPlaceholder = "Select a credential",
  addCredentialOptionLabel = "Add new credential",
  addCredentialStepLabel = (platformDisplayName) =>
    `Add a new ${platformDisplayName} credential`,
}: OnboardCredentialStepProps & Partial<LabelsProps>) => {
  const [step, setStep] = useState(Step.SelectKey);

  const { setValue, setError, clearErrors } = useFormContext<BaseState<any>>();
  const currentCredential = useWatch<BaseState<any>>({ name: "credential" });

  const keyValidation = useValidation(FieldState.NotValidated);
  const { setIsFieldValidationRunning } = useAsyncValidation();

  const [rawCredentials, mutate] = useCredentials();
  const credentials = rawCredentials?.filter((credential) =>
    platform.kind === "airtable"
      ? ["airtable", "airtable_oauth"].includes(credential.payload.kind) // Temp support while we deprecate older Airtable keys
      : credential.payload.kind === platform.kind
  );

  const validateKey = async () => {
    setIsFieldValidationRunning("credential", true);

    if (!currentCredential) {
      keyValidation.setFieldState(FieldState.NotValidated);
      setError("credential", {
        type: "custom",
        message: "No credential",
      });
      setIsFieldValidationRunning("credential", false);
      return;
    }

    keyValidation.setFieldState(FieldState.Validating);

    const res = await validateCredential(currentCredential.id);
    const isValid = Boolean(res.isOk() && res.value.secret_valid);

    keyValidation.setFieldState(
      isValid ? FieldState.Valid : FieldState.Invalid
    );

    if (isValid) {
      clearErrors("credential");
    } else {
      setError("credential", {
        type: "custom",
        message: "Invalid credential",
      });
    }

    setIsFieldValidationRunning("credential", false);
  };

  useEffect(() => {
    if (Array.isArray(credentials) && !credentials.length) {
      setStep(Step.CredentialForm);
    }
  }, [credentials]);

  useEffect(() => {
    validateKey();
  }, [currentCredential]);

  const shouldShowLoadingStep = typeof rawCredentials === "undefined";

  const handleSave = async (credential: Credential) => {
    await mutate();
    setValue("credential", credential);
    setStep(Step.SelectKey);
    if (onCompleteOAuth) {
      onCompleteOAuth();
    }

    if (window && window.analytics) {
      window.analytics.track("API Key Added", {
        kind: platform.kind,
      });
    }
  };

  return (
    <div>
      <Steps activeStep={shouldShowLoadingStep ? Step.LoadingKeys : step}>
        <Steps.Step stepKey={Step.LoadingKeys}>
          <div className="flex items-center justify-center">
            <SvgSpinner className="w-8 h-8 animate-spin" />
          </div>
        </Steps.Step>
        <Steps.Step stepKey={Step.SelectKey}>
          <button
            className="btn-outlined w-8 h-8 border-transparent flex justify-center items-center"
            onClick={handleBackClick}
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </button>
          <OnboardSelectKey
            onAddNewKey={() => {
              setStep(Step.CredentialForm);
              if (onStartOAuth) {
                onStartOAuth();
              }
            }}
            credentials={credentials}
            platform={platform}
            validationState={keyValidation.fieldState}
            dropdownLabel={dropdownLabel}
            dropdownPlaceholder={dropdownPlaceholder}
            addCredentialOptionLabel={addCredentialOptionLabel}
            addCredentialStepLabel={addCredentialStepLabel}
          />
        </Steps.Step>
        <Steps.Step stepKey={Step.CredentialForm}>
          <div className="text-center flex flex-row items-center">
            <button
              className="btn-outlined w-8 h-8 border-transparent flex justify-center items-center"
              onClick={() => {
                // If there are no credentials, going back should go back to the select source screen
                if (credentials?.length === 0) {
                  handleBackClick();
                }
                setStep(Step.SelectKey);
                if (onCompleteOAuth) {
                  onCompleteOAuth();
                }
              }}
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
            <h3 className="font-bold flex-1">
              {addCredentialStepLabel(platform.displayName)}
            </h3>
            <div className="w-8" />
          </div>
          <div className="mt-4">
            <ResourceCredentialForm
              kind={platform.kind}
              onBack={() => setStep(Step.SelectKey)}
              onSave={handleSave}
            />
          </div>
          <p className="w-full mt-4 text-center text-xs">
            Don't have credentials?{" "}
            <a
              href="/users/invite"
              target="_blank"
              rel="noreferrer noopener"
              className="font-bold"
              onClick={() => {
                if (window && window.analytics) {
                  window.analytics.track("Onboard Invite Teammate Clicked");
                }
              }}
            >
              Invite a teammate.
            </a>
          </p>
        </Steps.Step>
      </Steps>
    </div>
  );
};

const OnboardSelectKey = ({
  credentials,
  onAddNewKey,
  platform,
  validationState,
  dropdownLabel,
  dropdownPlaceholder,
  addCredentialOptionLabel,
}: {
  credentials: PlatformCredential[];
  onAddNewKey: () => void;
  platform: Platform<any, any, any>;
  validationState: FieldState;
} & LabelsProps) => {
  const currentCredential = useWatch<BaseState<any>>({ name: "credential" });
  const currentCredentialExists =
    credentials &&
    currentCredential &&
    credentials.find((cred) => cred.id === currentCredential.id);
  const [currentCredentialInfo] = useCredentialInfo(
    currentCredentialExists && currentCredential.id
  );

  const { field } = useController<BaseState<any>>({
    name: "credential",
  });

  return (
    <>
      <Listbox
        onChange={(value) => {
          if (value === "add-new") {
            onAddNewKey();
          } else {
            field.onChange(value);
          }
        }}
        value={field.value}
      >
        <div className="flex flex-col">
          <Listbox.Label className="sr-only">{dropdownLabel}</Listbox.Label>
          <div className="flex flex-row w-full mt-2 ">
            <div className="relative flex-1">
              <Listbox.Button className="relative h-10 pl-3 pr-10 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black sm:text-xs border w-full">
                <span className="block truncate">
                  {currentCredential
                    ? currentCredentialInfo?.label ??
                      platform.getLabelForCredential(currentCredential)
                    : dropdownPlaceholder}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <div className="w-10 h-10 flex items-center justify-center">
                    {getValidationStateIcon(validationState)}
                  </div>
                  <SwitchVerticalIcon
                    className="w-4 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
                {(credentials || []).map((credential) => (
                  <Listbox.Option
                    key={credential.id}
                    className={({ active }) =>
                      classNames(
                        "text-gray-900 cursor-default select-none relative py-2 pl-10 pr-4",
                        { "bg-gray-100": active }
                      )
                    }
                    value={credential}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate`}
                        >
                          <CredentialLabel
                            credential={credential}
                            platform={platform}
                          />
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
            <div className="ml-3">
              <Button
                variant="tertiary"
                size="md"
                className="text-xs"
                iconLeft={<PlusIcon className="h-4 w-4" />}
                onClick={onAddNewKey}
              >
                {addCredentialOptionLabel}
              </Button>
            </div>
          </div>
        </div>
      </Listbox>
      {currentCredentialExists &&
        currentCredentialExists.payload.kind === "airtable" &&
        currentCredentialExists.payload.secret.startsWith("key") && (
          <AirtableUpgradeKeyNotice onUpgrade={onAddNewKey} />
        )}
    </>
  );
};
