import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 68 68" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M11.336 21.25h45.333M51 21.25v19.833a11.367 11.367 0 01-11.333 11.334H28.333A11.367 11.367 0 0117 41.083V21.25M39.67 58.083a2.833 2.833 0 01-2.834 2.834h-5.667a2.833 2.833 0 01-2.833-2.834v-5.666h11.333v5.666zM34 60.916v5.667M22.672 21.25V1.416M45.336 21.25V1.416"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const SvgConnectDatabaseOutlined = React.memo(SvgComponent);
export default SvgConnectDatabaseOutlined;
