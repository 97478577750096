import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";

interface Props {
  initialCollapsed?: boolean;
  collapsed?: boolean;
  icon?: React.ReactNode;
  collapsedDesc?: React.ReactNode;
  title: string;
  disabledTooltip?: string;
  onToggleCollapsed?: () => void;
}

export default function Collapse({
  icon,
  title,
  initialCollapsed = false,
  children,
  collapsedDesc,
  disabledTooltip,
  collapsed: collapsedProp,
  onToggleCollapsed,
}: React.PropsWithChildren<Props>) {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const disabled = !!disabledTooltip;

  const isCollapsed =
    disabled || (collapsedProp !== undefined ? collapsedProp : collapsed);

  return (
    <Tippy
      content={disabledTooltip}
      visible={disabled ? undefined : false}
      interactive
    >
      <div className="w-full rounded-lg border">
        <button
          className={`focus:outline-none focus-visible:outline-black w-full py-4 px-6 ${
            disabled ? "cursor-not-allowed" : ""
          }`}
          onClick={() => {
            setCollapsed((s) => !s);
            onToggleCollapsed && onToggleCollapsed();
          }}
          disabled={disabled}
          aria-expanded={!isCollapsed ? "true" : undefined}
        >
          <div className="flex flex-row w-full items-center">
            <span>{icon}</span>
            <div className="flex-1 text-left">
              <span className={`text-left font-bold ${icon ? "ml-2" : ""}`}>
                {title}
              </span>
              {isCollapsed && collapsedDesc && (
                <div className="mt-1 text-xs pl-8 font-medium text-gray-600 text-left">
                  {collapsedDesc}
                </div>
              )}
            </div>
            <span className="ml-1 btn-md p-0 h-6 w-6 justify-center flex flex-col rounded-full">
              {isCollapsed ? (
                <ChevronDownIcon className="h-6 w-6 text-gray-400" />
              ) : (
                <ChevronUpIcon className="h-6 w-6 text-gray-400" />
              )}
            </span>
          </div>
        </button>
        <div className={`px-6 pb-6 ${isCollapsed ? "hidden" : ""}`}>
          {children}
        </div>
      </div>
    </Tippy>
  );
}
