import { AnimatePresence, motion } from "framer-motion";
import React, { Dispatch, useEffect, useReducer } from "react";
import update from "immutability-helper";
import { useId } from "../../lib/utils/useId";

interface StepDefinition {
  stepKey: string;
}

interface State {
  steps: { id: string; step: StepDefinition }[];
  activeStep: {
    stepKey: string;
    entryDirection: "forwards" | "backwards";
  };
}

enum Actions {
  RegisterStep,
  UnregisterStep,
  UpdateStep,
  SetActiveStep,
}

type ActionObject =
  | { type: Actions.RegisterStep; id: string; step: StepDefinition }
  | { type: Actions.UnregisterStep; id: string }
  | { type: Actions.UpdateStep; id: string; step: StepDefinition }
  | {
      type: Actions.SetActiveStep;
      activeStep: { stepKey: string; entryDirection: "forwards" | "backwards" };
    };

const reducer = (state: State, action: ActionObject) => {
  switch (action.type) {
    case Actions.RegisterStep:
      return update(state, {
        steps: { $push: [{ id: action.id, step: action.step }] },
      });

    case Actions.UnregisterStep: {
      const index = state.steps.findIndex((t) => t.id === action.id);
      return update(state, { steps: { $splice: [[index, 1]] } });
    }

    case Actions.UpdateStep: {
      const index = state.steps.findIndex((t) => t.id === action.id);
      return update(state, {
        steps: { [index]: { step: { $set: action.step } } },
      });
    }

    case Actions.SetActiveStep:
      return update(state, { activeStep: { $set: action.activeStep } });
  }
};

type reducerType = [State, Dispatch<ActionObject>];

export default function Steps({
  children,
  activeStep,
  resetPosition,
}: {
  activeStep: string;
  resetPosition?: boolean;
  children: React.ReactElement<StepProps>[];
}) {
  const reducerBag = useReducer(reducer, {
    steps: [],
    activeStep: {
      stepKey: activeStep,
      entryDirection: "forwards",
    },
  });

  const [state, dispatch] = reducerBag;

  useEffect(() => {
    const previousStepIdx = state.steps.findIndex(
      (x) => x.step.stepKey === state.activeStep.stepKey
    );
    const nextStepIdx = state.steps.findIndex(
      (x) => x.step.stepKey === activeStep
    );

    dispatch({
      type: Actions.SetActiveStep,
      activeStep: {
        stepKey: activeStep,
        entryDirection:
          nextStepIdx < previousStepIdx ? "backwards" : "forwards",
      },
    });
  }, [activeStep]);

  return (
    <AnimatePresence
      exitBeforeEnter
      initial={false}
      onExitComplete={() => resetPosition && window.scrollTo(0, 0)}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { reducer: reducerBag })
      )}
    </AnimatePresence>
  );
}

const variantsForward = {
  hidden: { opacity: 0, x: 50, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: -50, y: 0 },
};

const variantsBackwards = {
  hidden: { opacity: 0, x: -50, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 50, y: 0 },
};

interface StepProps {
  stepKey: string;
  reducer?: reducerType;
  chilren?: React.ReactNode;
  className?: string;
}

const Step: React.FC<StepProps> = ({
  children,
  stepKey,
  reducer,
  className,
}) => {
  const stepId = useId("step-");
  const [state, dispatch] = reducer;

  useEffect(
    () =>
      dispatch({ type: Actions.RegisterStep, id: stepId, step: { stepKey } }),
    []
  );

  useEffect(
    () =>
      dispatch({
        type: Actions.UpdateStep,
        id: stepId,
        step: {
          stepKey,
        },
      }),
    [stepKey]
  );

  const active = state.activeStep.stepKey === stepKey;

  if (!active) {
    return null;
  }

  const { entryDirection } = state.activeStep;

  return (
    <motion.div
      key={stepId}
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={
        entryDirection === "forwards" ? variantsForward : variantsBackwards
      }
      transition={{ type: "spring", bounce: 0, duration: 0.25 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

Steps.Step = Step;
