import React from "react";
import * as z from "zod";

import StripeCredentialForm from "components/platforms/stripe/StripeCredentialForm";
import StripeForm, {
  FullPageStripeForm,
  OnboardCredentialStripeForm,
} from "components/platforms/stripe/StripeForm";
import SvgStripeFilled from "components/svg/StripeFilled";
import {
  baseCredentialSchema,
  baseResourceSchema,
  RateLimit,
  rawResourceSchema,
} from "lib/api/types";
import { Platform } from "lib/platforms";
import {
  baseInitialCreateState,
  baseInitialUpdateState,
  basePrepareFieldsForCreate,
  basePrepareFieldsForUpdate,
  BaseState,
} from "lib/platforms/base";
import {
  rateLimitableInitialCreateState,
  rateLimitableInitialUpdateState,
  rateLimitablePrepareFieldsForCreate,
  rateLimitablePrepareFieldsForUpdate,
  RateLimitableState,
} from "lib/platforms/rateLimitable";
import { denormalizeTableKeysForRHF } from "lib/utils/reactHookForm";

// Metadata definition
export const stripeDefinitionSchema = z.object({
  proxyWaitActive: z.boolean(),
});

export type StripeDefinition = z.infer<typeof stripeDefinitionSchema>;

// Credential definition
export const stripeCredentialSchema = baseCredentialSchema.extend({
  payload: z.object({
    kind: z.literal("stripe"),
    secret: z.string(),
    test: z.boolean().nullable(),
    auth_method: z.enum(["api_key", "oauth"]).nullable(),
  }),
});

export type StripeCredential = z.infer<typeof stripeCredentialSchema>;

// Integral resource definition
export const rawStripeResourceSchema = rawResourceSchema.extend({
  kind: z.literal("stripe"),
  definition: stripeDefinitionSchema,
  credential: stripeCredentialSchema,
});

export const stripeResourceSchema = baseResourceSchema.extend({
  kind: z.literal("stripe"),
  definition: stripeDefinitionSchema,
  credential: stripeCredentialSchema,
});

export type StripeResource = z.infer<typeof stripeResourceSchema>;

export interface StripeState
  extends BaseState<StripeCredential>,
    RateLimitableState {}

const DEFAULT_RATE_LIMIT: RateLimit = {
  allowedRequests: 50,
  interval: 1_000,
  maxConcurrentRequests: 3, // not in use right now
};

// Platform definition
export const STRIPE_PLATFORM: Platform<
  StripeResource,
  StripeState,
  StripeCredential
> = {
  displayName: "Stripe",
  displayIcon: ({ ref, ...props }) => {
    return <SvgStripeFilled {...props} />;
  },
  resourceAppositive: "account",
  kind: "stripe",

  supportsCollectionStatus: false,
  supportsWriteProxy: true,

  helpers: [
    {
      name: "PROXY",
      buildUrl: () => "https://proxy.sequin.io/api.stripe.com/v1/",
    },
    { name: "WAIT" },
    {
      name: "SOURCE",
      buildUrl: () => ({
        url: `https://dashboard.stripe.com`,
        displayUrl: `dashboard.stripe.com`,
      }),
    },
  ],

  buildForm: StripeForm,
  buildCredentialForm: StripeCredentialForm,
  buildOnboardCredentialForm: OnboardCredentialStripeForm,
  extraTableParams: (_state) => {
    return {};
  },

  fullPageForm: FullPageStripeForm,

  getLabelForCredential: (cred) => cred.payload.secret,

  getInitialCreateState: () => ({
    ...baseInitialCreateState("Stripe"),
    ...rateLimitableInitialCreateState(DEFAULT_RATE_LIMIT),
  }),
  getInitialUpdateState: (res) => {
    const initialState = baseInitialUpdateState(res);

    initialState.tables = denormalizeTableKeysForRHF(initialState.tables);
    return {
      ...initialState,
      ...rateLimitableInitialUpdateState(res, DEFAULT_RATE_LIMIT),
    };
  },

  prepareFieldsForCreate: (d) => ({
    ...basePrepareFieldsForCreate(d, "stripe"),
    ...rateLimitablePrepareFieldsForCreate(d),
  }),
  prepareFieldsForUpdate: (d) => ({
    ...basePrepareFieldsForUpdate(d, "stripe"),
    ...rateLimitablePrepareFieldsForUpdate(d),
  }),
};
