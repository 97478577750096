import React, { useState } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";

import { SshTunnelFormInputs } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/SshTunnelSection";
import { InputErrorWrapper } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/InputErrorWrapper";

export type HostPortExternalDbFormInputs = {
  host: {
    host: string;
    port: string;
  };
  sshTunnel: SshTunnelFormInputs;
};

export const HostSection = ({ disabled }: { disabled?: boolean }) => {
  const { register, formState, getValues } =
    useFormContext<HostPortExternalDbFormInputs>();

  const [shouldShowWarning, setShouldShowWarning] = useState<boolean>(
    hostIsLocalhost(getValues("host.host"))
  );

  const handleHostChange = (event) => {
    if (hostIsLocalhost(event.target.value)) {
      setShouldShowWarning(true);
      return;
    }
    setShouldShowWarning(false);
  };

  return (
    <section className="grid grid-cols-1 gap-x-8 gap-y-4">
      <InputErrorWrapper
        errors={formState.errors}
        name="host.host"
        label="Host"
      >
        {(hasError) => (
          <input
            className={classNames(
              "textbox-outlined textbox-md w-full mt-2 disabled-input",
              { "border-red-400": hasError }
            )}
            type="text"
            {...register("host.host", {
              required: "Host is required",
              onChange: handleHostChange,
            })}
            disabled={disabled}
          />
        )}
      </InputErrorWrapper>
      {shouldShowWarning && <LocalhostWarning />}
      <InputErrorWrapper
        errors={formState.errors}
        name="host.port"
        label="Port"
      >
        {(hasError) => (
          <input
            className={classNames(
              "textbox-outlined textbox-md w-full mt-2 disabled-input",
              { "border-red-400": hasError }
            )}
            type="text"
            {...register("host.port", {
              required: "Port is required",
              pattern: {
                value: /^\d+$/i,
                message: "Please enter a valid port number",
              },
            })}
            disabled={disabled}
          />
        )}
      </InputErrorWrapper>
    </section>
  );
};

const LocalhostWarning = () => {
  return (
    <p className="text-amber-700 text-xs mb-1 mt-[-1]">
      You'll need to setup a tunnel to use a local database.{" "}
      <a
        href="https://docs.sequin.io/self-hosted#using-localhost"
        className="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Check our docs for more info on using localhost
      </a>
    </p>
  );
};

const hostIsLocalhost = (host: string): boolean => {
  if (host.includes("localhost")) {
    return true;
  }
  if (host === "127.0.0.1") {
    return true;
  }
  return false;
};
