import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CubeIcon } from "@heroicons/react/outline";
import classNames from "classnames";

import { ResourceEnvironment } from "lib/api/types";
import { BaseState } from "lib/platforms/base";
import { PlatformCredential } from "lib/platforms";
import Collapse from "components/core/Collapse";

interface SelectEnvironmentStepProps {
  collapsed: boolean;
  onToggleCollapsed: () => void;
}

export const SelectEnvironmentStep = ({
  collapsed,
  onToggleCollapsed,
}: SelectEnvironmentStepProps) => {
  const { setValue } = useFormContext<BaseState<any>>();
  const currentEnvironment = useWatch<BaseState<any>>({
    name: "resourceEnvironment",
  });

  const credential = useWatch<BaseState<any>>({
    name: "credential",
  }) as PlatformCredential;

  const setEnvironment = (environemnt: ResourceEnvironment) => {
    setValue("resourceEnvironment", environemnt);
  };

  const getDisabledTooltip = () => {
    if (!credential) {
      return "Before continuing, please enter your credentials above";
    }

    return undefined;
  };

  return (
    <Collapse
      title="Sync environment"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={<CubeIcon className="h-6 w-6" />}
      disabledTooltip={getDisabledTooltip()}
    >
      <div className="flex flex-row gap-2">
        <SelectEnvironmentForm
          currentEnvironment={currentEnvironment}
          setEnvironment={setEnvironment}
          shouldUseLargeText={false}
        />
      </div>
    </Collapse>
  );
};

export const SelectEnvironment = () => {
  const { getValues, setValue } = useFormContext<BaseState<any>>();
  const currentEnvironment = getValues("resourceEnvironment");

  const setEnvironment = (environemnt: ResourceEnvironment) => {
    setValue("resourceEnvironment", environemnt);
  };

  return (
    <div>
      <SelectEnvironmentForm
        currentEnvironment={currentEnvironment}
        setEnvironment={setEnvironment}
        shouldUseLargeText={true}
      />
    </div>
  );
};

interface SelectEnvironmentFormProps {
  currentEnvironment: ResourceEnvironment;
  setEnvironment(environment: ResourceEnvironment): void;
  shouldUseLargeText: boolean;
}

const SelectEnvironmentForm = ({
  currentEnvironment,
  setEnvironment,
  shouldUseLargeText,
}: SelectEnvironmentFormProps) => {
  const paragraphText = shouldUseLargeText ? "text-sm" : "text-xs";
  const headingText = shouldUseLargeText ? "text-lg" : "text-md";
  return (
    <div>
      <button
        onClick={() => {
          setEnvironment("development");
        }}
        className={classNames(
          "p-6 border border-cool-gray-200 rounded-md text-left w-full mb-4",
          {
            "border-cool-gray-400 border-2":
              currentEnvironment === "development",
          }
        )}
      >
        <h2 className={classNames("mb-1 font-medium", headingText)}>
          Development
        </h2>
        <p className={paragraphText}>
          You'll probably start here. Developers use these syncs to build and
          test new features before pushing to a staging or production sync.
        </p>
      </button>
      <button
        onClick={() => {
          setEnvironment("staging");
        }}
        className={classNames(
          "p-6 border border-cool-gray-200 rounded-md text-left w-full mb-4",
          {
            "border-cool-gray-400 border-2": currentEnvironment === "staging",
          }
        )}
      >
        <h2 className={classNames("mb-1 font-medium", headingText)}>Staging</h2>
        <p className={paragraphText}>
          Staging syncs are intended for pre-production testing and QA.
        </p>
      </button>
      <button
        onClick={() => {
          setEnvironment("production");
        }}
        className={classNames(
          "p-6 border border-cool-gray-200 rounded-md text-left w-full",
          {
            "border-cool-gray-400 border-2":
              currentEnvironment === "production",
          }
        )}
      >
        <h2 className={classNames("mb-1 font-medium", headingText)}>
          Production
        </h2>
        <p className={paragraphText}>
          Built to support the scale and reliability of production applications,
          these syncs are connected to production sources and databases.
        </p>
      </button>
    </div>
  );
};
