import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import React, { useState, createContext, useContext } from "react";
import { Platform } from "../../../../lib/platforms";
import SvgSpinner from "../../../svg/Spinner";
import { FieldError } from "react-hook-form";

export enum FieldState {
  NotValidated = "NotValidated",
  Validating = "Validating",
  SilentlyValidating = "SilentlyValidating",
  Valid = "Valid",
  Invalid = "Invalid",
}

export type ValidationMachine = {
  fieldState: FieldState;
  setFieldState: (state: FieldState) => void;
};

export interface StepProps {
  isCreate?: boolean;
  collapsed?: boolean;
  onToggleCollapsed?: () => void;
  platform: Platform<unknown, unknown, unknown>;
  disabledTooltip?: string;
  onNextStep?: () => void;
}

export const getValidationStateIcon = (s: FieldState) => {
  switch (s) {
    case FieldState.SilentlyValidating:
    case FieldState.Validating: {
      return <SvgSpinner className="animate-spin h-4 w-4" />;
    }

    case FieldState.Valid: {
      return <CheckCircleIcon className="h-5 w-5 text-success" />;
    }

    case FieldState.Invalid: {
      return <XCircleIcon className="h-5 w-5 text-error" />;
    }

    default: {
      return null;
    }
  }
};

export const getValidationState = (s: {
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  error?: FieldError;
  isValidating?: boolean;
}) => {
  if (!s.isTouched && !s.isDirty) {
    return FieldState.NotValidated;
  }

  if (s.isValidating) {
    return FieldState.Validating;
  }

  if (s.error) {
    return FieldState.Invalid;
  }

  return FieldState.Valid;
};

export const obfuscateToken = (token: string) => {
  const headLength = token.length >= 8 ? 8 : 1;
  const tailLength = token.length >= 11 ? 3 : 0;
  const middleLength = token.length - headLength - tailLength;

  const head = token.slice(0, headLength);
  const middle = "*".repeat(middleLength > 0 ? middleLength : 0);
  const tail = token.slice(token.length - tailLength);

  return head + middle + tail;
};

export const useValidation = (initial: FieldState): ValidationMachine => {
  const [fieldState, setFieldState] = useState<FieldState>(initial);

  return { fieldState, setFieldState };
};

interface AsyncValidation {
  isValidationRunningFields: {
    [fieldName: string]: boolean;
  };
  setIsFieldValidationRunning: (
    fieldName: string,
    isValidating: boolean
  ) => void;
}

const AsyncValidationContext = createContext<AsyncValidation>({
  isValidationRunningFields: {},
  setIsFieldValidationRunning: () => {},
});

export const useAsyncValidation = () => {
  const context = useContext(AsyncValidationContext);
  if (context === null) {
    throw new Error(
      "useAsyncValidation needs a parent <AsyncValidationProvider/> component."
    );
  }

  const { isValidationRunningFields, setIsFieldValidationRunning } = context;

  const isAnyValidationRunning = Object.values(isValidationRunningFields).some(
    (isRunning) => isRunning
  );

  return {
    isValidationRunningFields,
    isAnyValidationRunning,
    setIsFieldValidationRunning,
  };
};

export const AsyncValidationProvider = ({
  children,
  initialValues,
}: React.PropsWithChildren<{
  initialValues?: {
    [fieldName: string]: boolean;
  };
}>) => {
  const [isValidationRunningFields, setIsValidationRunningFields] = useState(
    initialValues || {}
  );

  const setIsFieldValidationRunning = (
    fieldName: string,
    isValidationRunning: boolean
  ) => {
    setIsValidationRunningFields((fieldValidations) => ({
      ...fieldValidations,
      [fieldName]: isValidationRunning,
    }));
  };

  return (
    <AsyncValidationContext.Provider
      value={{
        isValidationRunningFields,
        setIsFieldValidationRunning,
      }}
    >
      {children}
    </AsyncValidationContext.Provider>
  );
};
