import "../public/static/fonts/dr/styles.css";
import "../public/static/fonts/plusjakarta/styles.css";

import "../styles/globals.css";
import "../styles/toasts.css";
import "tippy.js/dist/tippy.css";
import "rc-slider/assets/index.css";

// focus-visible polyfill
import "focus-visible";

import { AppProps } from "next/app";
import { useProfile } from "../lib/api/hooks";
import React, { useEffect } from "react";
import { SWRConfig } from "swr";
import swrConfig from "../lib/api/swrConfig";
import { ToastContainer } from "react-toastify";
import Head from "next/head";
import { SITE_TITLE } from "../site-constants";

import * as Sentry from "@sentry/nextjs";
import { DialogsRoot } from "../components/core/Dialog";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{SITE_TITLE}</title>
      </Head>

      <SafeHydrate>
        <SWRConfig value={swrConfig}>
          <SetLibraryContext>
            <Component {...pageProps} />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              closeButton={false}
            />
            <DialogsRoot />
          </SetLibraryContext>
        </SWRConfig>
      </SafeHydrate>
    </>
  );
}

function SetLibraryContext({ children }: React.PropsWithChildren<{}>) {
  const [user] = useProfile();

  useEffect(() => {
    if (user) {
      Sentry.setContext("user", {
        id: user.id,
        orgId: user.orgId,
      });

      if (window.Intercom) {
        window.Intercom("boot", {
          app_id: "btt358pc",
          email: user.email,
          user_id: user.id,
          created_at: user.insertedAt,
        });
      }

      if (window && window.analytics) {
        window.analytics.ready(() => {
          window.analytics.identify(user.id, {
            org_id: user.orgId,
            email: user.email,
            createdAt: user.insertedAt,
          });
          window.analytics.group(user.orgId);
        });
      }
    }
  }, [user]);

  return <>{children}</>;
}

function SafeHydrate({ children }: React.PropsWithChildren<{}>) {
  return (
    <div suppressHydrationWarning>
      {typeof window === "undefined" ? null : children}
    </div>
  );
}
