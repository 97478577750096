import { SWRConfiguration } from "swr";
import sortBy from "lodash/sortBy";

import {
  Account,
  BillingInfo,
  BillingPeriod,
  BillingPlan,
  CreateResourceStatus,
  CredentialUpstreamInfo,
  Features,
  LatestBillingPlan,
  MessageQueue,
  MessageQueues,
  Organization,
  PendingUser,
  PlatformTableSchema,
  PlatformTables,
  ProxyConnection,
  ProxyOpts,
  ProxyQueryLog,
  RefreshSchemaStatus,
  ResourceBackfill,
  ResourceCollectionStatus,
  ResourceMetadata,
  ResourceStatus,
  ResourceTableSyncing,
  ResourceUsage,
  ResourceUsageByDay,
  SchemaCreationQueries,
  SchemaDiff,
  SshTunnel,
  User,
} from "lib/api/types";
import update from "immutability-helper";
import {
  FetchError,
  ForbiddenError,
  NotAuthorizedError,
  NotFoundError,
} from "lib/api";
import { parseTimestamps } from "lib/api/utils";
import { useZodSWR } from "lib/api/utilHooks";
import {
  PlatformCredential,
  DatabaseWithResources,
  Resource,
} from "lib/platforms";
import {
  GetAirtableBases,
  GetAirtableBaseTables,
  GetUser,
  responses,
} from "lib/api/responses";
import { useRef } from "react";

interface LoadOptions {
  waitUntilLoaded?: boolean;
}

export interface AdditionalOptions {
  throwIfForbiddenError?: boolean;
}

// Please use these options in all hooks.
export type HookError =
  | NotAuthorizedError
  | ForbiddenError
  | FetchError
  | undefined;
export type HookMutation<T> = (
  data?: T,
  shouldRevalidate?: boolean
) => Promise<void>;
export type HookResponseType<TransformedData> = [
  TransformedData | undefined,
  HookMutation<TransformedData>,
  HookError
];

export const useResourceBySlug = (
  permaslug: string,
  options?: LoadOptions,
  config?: SWRConfiguration
): HookResponseType<Resource> => {
  if (!permaslug.startsWith("sync_")) {
    permaslug = `sync_${permaslug}`;
  }
  const [resources, mutateAll, error] = useResources(config);

  const mutate = async (data?: Resource, shouldRevalidate?: boolean) => {
    if (!data) {
      return await mutateAll();
    }

    if (!resources) {
      // Only will get here if mutate is called prematurely
      throw new Error(
        `Attempted to get resource but none loaded. This will happen if you attempt to mutate before data has been fully loaded.`
      );
    }

    const idx = resources.findIndex((r) => r.permaslug === permaslug);
    if (idx === -1) {
      throw new Error(
        `Could not find a resource in cache matching permaslug ${permaslug}`
      );
    }

    await mutateAll(
      update(resources, { [idx]: { $set: data as any } }),
      shouldRevalidate
    );
  };

  if (!resources) {
    return [undefined, mutate, error];
  }

  const resource: Resource | undefined = resources.find(
    (r) => r.permaslug === permaslug
  );

  if (!resource) {
    return [
      resource,
      mutate,
      new NotFoundError(`Could not find a resource for permaslug ${permaslug}`),
    ];
  }

  return [resource, mutate, error];
};

export const useResource = (
  id?: string,
  options?: LoadOptions,
  config?: SWRConfiguration
): HookResponseType<Resource> => {
  const [resources, mutateAll, error] = useResources(config);

  const mutate = async (data?: Resource, shouldRevalidate?: boolean) => {
    if (!data) {
      return await mutateAll();
    }

    if (!resources) {
      // Only will get here if mutate is called prematurely
      throw new Error(
        `Attempted to get resource but none loaded. This will happen if you attempt to mutate before data has been fully loaded.`
      );
    }

    const idx = resources.findIndex((r) => r.id === id);
    if (idx === -1) {
      throw new Error(`Could not find a resource in cache matching id ${id}`);
    }

    await mutateAll(
      update(resources, { [idx]: data as any }),
      shouldRevalidate
    );
  };

  if (!resources || !id) {
    return [undefined, mutate, error];
  }

  const resource: Resource | undefined = resources.find((r) => r.id === id);

  if (!resource && !options?.waitUntilLoaded) {
    throw new Error(`Could not find a resource for id ${id}`);
  }

  return [resource, mutate, error];
};

type Resources = Resource[];

export const useResources = (
  config?: SWRConfiguration
): HookResponseType<Resources> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resources",
    responses.listOrgResources,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: Resources) => ({ resources: data, ok: true as any })
  );

  return [
    data && sortBy(parseTimestamps(data.resources), ["insertedAt"]),
    mutate,
    error,
  ];
};

export const useResourcesWithBackfillStatus = (
  config?: SWRConfiguration
): HookResponseType<Resources> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resources_with_backfills_status",
    responses.listOrgResources,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: Resources) => ({ resources: data, ok: true as any })
  );

  return [
    data && sortBy(parseTimestamps(data.resources), ["insertedAt"]),
    mutate,
    error,
  ];
};

export const useResourceMetadatas = (
  config?: SWRConfiguration
): HookResponseType<ResourceMetadata[]> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resource_metadatas",
    responses.listOrgResourceMetadatas,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: ResourceMetadata[]) => ({ metadatas: data, ok: true as any })
  );

  return [data?.metadatas, mutate, error];
};

export const useResourceStatuses = (
  config?: SWRConfiguration
): HookResponseType<ResourceStatus[]> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resources/statuses",
    responses.listOrgResourceStatuses,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: ResourceStatus[]) => ({ statuses: data, ok: true as any })
  );

  return [data?.statuses, mutate, error];
};

export const useResourceCollectionStatuses = (
  resourceId?: string,
  config?: SWRConfiguration
): HookResponseType<ResourceCollectionStatus[]> => {
  const [data, mutate, error] = useZodSWR(
    resourceId ? `/api/resources/${resourceId}/collection_statuses` : null,
    responses.listOrgResourceCollectionStatuses,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: ResourceCollectionStatus[]) => ({
      statuses: data,
      ok: true as any,
    })
  );

  return [data?.statuses, mutate, error];
};

export const useResourceMetadata = (
  resourceId?: string,
  options?: LoadOptions,
  config?: SWRConfiguration
): HookResponseType<ResourceMetadata> => {
  const [metadatas, mutateAll, error] = useResourceMetadatas(config);

  const mutate = async (
    data?: ResourceMetadata,
    shouldRevalidate?: boolean
  ) => {
    if (!metadatas) {
      // Only will get here if mutate is called prematurely
      throw new Error(
        `Attempted to get resource but none loaded. This will happen if you attempt to mutate before data has been fully loaded.`
      );
    }

    const idx = metadatas.findIndex((r) => r.resourceId === resourceId);
    if (idx === -1) {
      throw new Error(
        `Could not find metadata in cache matching resource ${resourceId}`
      );
    }

    await mutateAll(
      update(metadatas, { [idx]: data as any }),
      shouldRevalidate
    );
  };

  if (!metadatas) {
    return [undefined, mutate, error];
  }

  const metadata: ResourceMetadata | undefined = metadatas.find(
    (r) => r.resourceId === resourceId
  );

  if (!metadata && !options?.waitUntilLoaded) {
    throw new Error(`Could not find metadata for resource ${resourceId}`);
  }

  if (metadata) {
    return [parseTimestamps(metadata), mutate, error];
  } else {
    return [undefined, mutate, error];
  }
};

export const useResourceTablesSyncing = (
  resourceId?: string,
  config?: SWRConfiguration
): HookResponseType<ResourceTableSyncing[]> => {
  const [data, mutate, error] = useZodSWR(
    resourceId ? `/api/resources/${resourceId}/tables_syncing` : null,
    responses.listResourcesTablesSyncing,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: ResourceTableSyncing[]) => ({ tables: data, ok: true as any })
  );

  return [data?.tables, mutate, error];
};

export const useResourceBackfills = (
  id?: string,
  config?: SWRConfiguration
): HookResponseType<ResourceBackfill[]> => {
  const [data, mutate, error] = useZodSWR(
    id ? `/api/resources/${id}/backfills` : null,
    responses.listResourceBackfills,
    config,
    {
      throwIfForbiddenError: true,
    },
    (backfills: ResourceBackfill[]) => ({ ok: true as any, backfills })
  );

  return [data && data.backfills, mutate, error];
};

// this is an odd one
export const useAirtableBases = (
  key?: string,
  config?: SWRConfiguration
): HookResponseType<GetAirtableBases> => {
  const [data, mutate, error] = useZodSWR(
    key ? `/api/airtable/${key}/bases` : null,
    responses.getAirtableBases,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: GetAirtableBases) => data as any
  );

  return [data, mutate, error];
};

export const useAirtableBaseTables = (
  key?: string,
  baseId?: string,
  config?: SWRConfiguration
): HookResponseType<GetAirtableBaseTables> => {
  const [data, mutate, error] = useZodSWR(
    key && baseId ? `/api/airtable/${key}/bases/${baseId}/tables` : null,
    responses.getAirtableBaseTables,
    config,
    {
      throwIfForbiddenError: true,
    },
    (bases: GetAirtableBaseTables) => bases
  );

  return [data, mutate, error];
};

export const useCredentialInfo = (
  credentialId?: string,
  config?: SWRConfiguration
): HookResponseType<CredentialUpstreamInfo> => {
  const [data, mutate, error] = useZodSWR(
    credentialId ? `/api/credentials/${credentialId}/info` : null,
    responses.getCredentialUpstreamInfo,
    config,
    {
      throwIfForbiddenError: true,
    },
    (info: CredentialUpstreamInfo) => info as any
  );

  return [data && data.info, mutate, error];
};

export const useOrg = (
  config?: SWRConfiguration
): HookResponseType<Organization> => {
  const [data, mutate, error] = useZodSWR(
    "/api/orgs/mine",
    responses.getUserOrg,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: Organization) => ({ ok: true as any, org: data })
  );

  return [data && data.org, mutate, error];
};

export const useProfile = (
  config?: SWRConfiguration
): HookResponseType<User> => {
  const [data, mutate, error] = useZodSWR(
    "/api/users/me",
    responses.getUser,
    config,
    {
      throwIfForbiddenError: true,
    },
    (user: User) => ({ ok: true as any, user })
  );

  return [data && data.user, mutate, error];
};

export const useResetPasswordProfile = (
  token?: string,
  config?: SWRConfiguration
): HookResponseType<GetUser> => {
  const [data, mutate, error] = useZodSWR(
    token ? `/password/reset/verify?token=${token}` : null,
    responses.getUser,
    config,
    {
      throwIfForbiddenError: true,
    },
    (data: GetUser) => data
  );

  return [data, mutate, error];
};

export const useUsers = (
  config?: SWRConfiguration
): HookResponseType<(User | PendingUser)[]> => {
  const [data, mutate, error] = useZodSWR(
    "/api/users",
    responses.listUsers,
    config,
    {
      throwIfForbiddenError: true,
    },
    (users: (User | PendingUser)[]) => ({ ok: true as any, users })
  );

  // Remove this any when this goes in use
  return [data && data.users, mutate, error];
};

export const useUser = (
  id: string,
  config?: SWRConfiguration
): HookResponseType<User | PendingUser> => {
  const [users, mutateAll, error] = useUsers(config);

  const mutate = async (
    data?: User | PendingUser,
    shouldRevalidate?: boolean
  ) => {
    if (!data) {
      return await mutateAll();
    }

    if (!users) {
      // Only will get here if mutate is called prematurely
      throw new Error(
        `Attempted to get user but none loaded. This will happen if you attempt to mutate before data has been fully loaded.`
      );
    }

    if (id === "new") {
      await mutateAll([...users, data], shouldRevalidate);
    } else {
      const idx = users.findIndex((u) => u.id === id);
      if (idx === -1) {
        throw new Error(`Could not find a user in cache matching id ${id}`);
      }

      await mutateAll(update(users, { [idx]: data as any }), shouldRevalidate);
    }
  };

  if (!users || id === "new") {
    return [undefined, mutate, error];
  }

  const user: User | PendingUser | undefined = users.find((u) => u.id === id);

  if (!user) {
    throw new Error(`Could not find a user for id ${id}`);
  }

  return [user, mutate, error];
};

export const useBillingInfo = (
  config?: SWRConfiguration
): HookResponseType<BillingInfo> => {
  const [data, mutate, error] = useZodSWR(
    "/api/billing",
    responses.getBillingInfo,
    config,
    {
      throwIfForbiddenError: true,
    },
    (info: BillingInfo) => ({ ok: true as any, info })
  );

  return [data && data.info, mutate, error];
};

export const useBillingPlan = (
  config?: SWRConfiguration
): HookResponseType<BillingPlan> => {
  const [data, mutate, error] = useZodSWR(
    "/api/billing/plan",
    responses.getBillingPlan,
    config,
    {
      throwIfForbiddenError: true,
    },
    (info: BillingPlan) => ({ ok: true as any, info })
  );

  return [data && data.billing_plan, mutate, error];
};

export const useLatestBillingPlan = (
  config?: SWRConfiguration
): HookResponseType<LatestBillingPlan> => {
  const [data, mutate, error] = useZodSWR(
    "/api/billing/plan/latest",
    responses.getLatestBillingPlan,
    config,
    {
      throwIfForbiddenError: true,
    },
    (info: LatestBillingPlan) => ({ ok: true as any, info })
  );

  return [data && data.plan, mutate, error];
};

export const useDatabases = (
  config?: SWRConfiguration
): HookResponseType<DatabaseWithResources[]> => {
  const [data, mutate, error] = useZodSWR(
    `/api/databases`,
    responses.getDatabasesWithResources,
    config,
    {
      throwIfForbiddenError: true,
    },
    (dbs: DatabaseWithResources[]) => ({
      ok: true as any,
      databases: dbs,
    })
  );

  return [data && data.databases, mutate, error];
};

export const useSshTunnels = (
  config?: SWRConfiguration
): HookResponseType<SshTunnel[]> => {
  const [data, mutate, error] = useZodSWR(
    `/api/databases/external/tunnels`,
    responses.listSshTunnels,
    config,
    {
      throwIfForbiddenError: true,
    },
    (tunnels: SshTunnel[]) => ({
      ok: true as any,
      tunnels: tunnels,
    })
  );

  return [data && data.tunnels, mutate, error];
};

export const useSshPublicKey = (
  config?: SWRConfiguration
): HookResponseType<string> => {
  const [data, mutate, error] = useZodSWR(
    "/api/databases/external/ssh-public-key",
    responses.getSshPublicKey,
    config,
    {
      throwIfForbiddenError: true,
    },
    (key: string) => ({ ok: true as any, key })
  );

  return [data && data.key, mutate, error];
};

export const useCredentials = (
  config?: SWRConfiguration
): HookResponseType<PlatformCredential[]> => {
  const [data, mutate, error] = useZodSWR(
    `/api/credentials`,
    responses.listOrgCredentials,
    config,
    {
      throwIfForbiddenError: true,
    },
    (creds: PlatformCredential[]) => ({
      ok: true as any,
      credentials: creds,
    })
  );

  return [data && data.credentials, mutate, error];
};

export const usePlatformTables = (
  credentialId?: string,
  params?: any,
  useSchema?: string,
  config?: SWRConfiguration
): HookResponseType<PlatformTables> => {
  const urlParams = params ? new URLSearchParams(params).toString() : "";
  const schemaDefUrlParams = useSchema ? `use_schema=${useSchema}&` : "";

  const [data, mutate, error] = useZodSWR(
    credentialId && params
      ? `/api/credentials/${credentialId}/tables?${schemaDefUrlParams}${urlParams}`
      : null,
    responses.getPlatformTables,
    config,
    {
      throwIfForbiddenError: false,
    },
    (tables: PlatformTables) => ({
      ok: true as any,
      tables,
    })
  );

  return [data && data.tables, mutate, error];
};

export const usePlatformTableSchema = (
  credentialId?: string,
  tableId?: string,
  params?: any,
  useSchema?: string,
  config?: SWRConfiguration
): HookResponseType<PlatformTableSchema> => {
  const urlParams = params ? new URLSearchParams(params).toString() : "";
  const schemaDefUrlParams = useSchema ? `use_schema=${useSchema}&` : "";

  const [data, mutate, error] = useZodSWR(
    credentialId && params && tableId
      ? `/api/credentials/${credentialId}/tables/${tableId}/schema?${schemaDefUrlParams}${urlParams}`
      : null,
    responses.getPlatformTableSchema,
    config,
    {
      throwIfForbiddenError: true,
    },
    (tables: PlatformTables) => ({
      ok: true as any,
      tables,
    })
  );

  let table = data?.schema;
  if (table) {
    table = { ...table, fields: sortBy(table.fields, ["accessor"]) };
  }

  return [table, mutate, error];
};

export const useSchemaDiff = (
  prevId?: string,
  nextId?: string,
  config?: SWRConfiguration
): HookResponseType<SchemaDiff> => {
  const now = useRef(Date.now());

  const [data, mutate, error] = useZodSWR(
    prevId && nextId
      ? `/api/resources/${prevId}/schema/diff/${nextId}?cache=${now.current}`
      : null,
    responses.getSchemaDiff,
    config,
    {
      throwIfForbiddenError: true,
    },
    (tables: PlatformTables) => ({
      ok: true as any,
      tables,
    })
  );

  return [data && data.diff, mutate as any, error];
};

export const useRefreshSchemaStatus = (
  resourceId?: string,
  config?: SWRConfiguration
): HookResponseType<RefreshSchemaStatus> => {
  const [data, mutate, error] = useZodSWR(
    resourceId ? `/api/resources/${resourceId}/refresh_schema` : null,
    responses.getRefreshSchemaStatus,
    config,
    {
      throwIfForbiddenError: true,
    },
    (status: RefreshSchemaStatus) => ({
      ok: true as any,
      status: status as any,
    })
  );

  return [data && (data.status as any), mutate, error];
};

export const useCreateResourceStatus = (
  jobId?: string,
  config?: SWRConfiguration
): HookResponseType<CreateResourceStatus> => {
  const [data, mutate, error] = useZodSWR(
    jobId ? `/api/resources/create/${jobId}/status` : null,
    responses.getCreateResourceStatus,
    config,
    {
      throwIfForbiddenError: true,
    },
    (status: CreateResourceStatus) => ({
      ok: true as any,
      status: status as any,
    })
  );

  return [data && data.status, mutate, error];
};

export const useFeature = (
  featureName,
  config?: SWRConfiguration
): HookResponseType<Features> => {
  // Add query params to the API request to allow manual feature flag overrides
  let apiEndpoint = "/api/features";
  if (typeof window !== "undefined") {
    apiEndpoint += window.location.search;
  }

  const [data, mutate, error] = useZodSWR(
    apiEndpoint,
    responses.getFeatures,
    config,
    {
      throwIfForbiddenError: true,
    },
    (features: Features) => ({ ok: true as any, features })
  );

  return [data && data.features[featureName], mutate, error];
};

export const useAccount = (
  config?: SWRConfiguration
): HookResponseType<Account> => {
  const [data, mutate, error] = useZodSWR(
    "/api/account",
    responses.getAccount,
    config,
    {
      throwIfForbiddenError: true,
    },
    (account: Account) => ({ ok: true as any, account })
  );

  return [data && data.account, mutate, error];
};

export const useNewBillingPeriod = (
  config?: SWRConfiguration
): HookResponseType<BillingPeriod> => {
  let apiEndpoint = "/api/utils/get_new_billing_period";

  const [data, mutate, error] = useZodSWR(
    apiEndpoint,
    responses.getNewBillingPeriod,
    config,
    {
      throwIfForbiddenError: true,
    },
    (period: BillingPeriod) => ({ ok: true as any, period })
  );

  return [data && data.period, mutate, error];
};

export const useCurrentBillingPeriod = (
  config?: SWRConfiguration
): HookResponseType<BillingPeriod> => {
  let apiEndpoint = "/api/orgs/mine/billing_period";

  const [data, mutate, error] = useZodSWR(
    apiEndpoint,
    responses.getCurrentBillingPeriod,
    config,
    {
      throwIfForbiddenError: true,
    },
    (period: BillingPeriod) => ({ ok: true as any, period })
  );

  return [data && data.period, mutate, error];
};

export const useResourceUsages = (
  config?: SWRConfiguration
): HookResponseType<ResourceUsage[]> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resource_usages",
    responses.listResourceUsages,
    config,
    {
      throwIfForbiddenError: true,
    },
    (resourceUsages: ResourceUsage[]) => ({ ok: true as any, resourceUsages })
  );

  return [data && data.resourceUsages, mutate, error];
};

export const useLatestResourceUsages = (
  config?: SWRConfiguration
): HookResponseType<ResourceUsage[]> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resource_usages/latest",
    responses.listResourceUsages,
    config,
    {
      throwIfForbiddenError: true,
    },
    (resourceUsages: ResourceUsage[]) => ({ ok: true as any, resourceUsages })
  );

  return [data && data.resourceUsages, mutate, error];
};

export const useResourceUsagesByDay = (
  config?: SWRConfiguration
): HookResponseType<ResourceUsageByDay> => {
  const [data, mutate, error] = useZodSWR(
    "/api/resource_usages/grouped_by_day",
    responses.listResourceUsagesByDay,
    config,
    {
      throwIfForbiddenError: true,
    },
    (resourceUsages: ResourceUsageByDay) => ({
      ok: true as any,
      resourceUsages,
    })
  );

  return [data && data.resourceUsages, mutate, error];
};

export const useSearchablePlatforms = (
  config?: SWRConfiguration
): HookResponseType<string[]> => {
  const [data, mutate, error] = useZodSWR(
    "/api/features/platforms",
    responses.listSearchablePlatforms,
    config,
    {
      throwIfForbiddenError: true,
    },
    (platforms: string[]) => ({
      ok: true as any,
      platforms,
    })
  );

  return [data && data.platforms, mutate, error];
};

export const useResourceProxyOpts = (
  resourceId?: string,
  config?: SWRConfiguration
): HookResponseType<ProxyOpts> => {
  const [data, mutate, error] = useZodSWR(
    resourceId ? `/api/resources/${resourceId}/proxy_opts` : null,
    responses.getProxyOpts,
    config,
    {
      throwIfForbiddenError: true,
    },
    (proxy: ProxyOpts) => ({ ok: true as any, proxy })
  );

  return [data && data.proxy, mutate, error];
};

export const useRecentProxyConnection = (
  databaseId?: string,
  config?: SWRConfiguration
): HookResponseType<ProxyConnection> => {
  const [data, mutate, error] = useZodSWR(
    databaseId ? `/api/databases/${databaseId}/recent_proxy_connection` : null,
    responses.getProxyConnection,
    config,
    {
      throwIfForbiddenError: true,
    },
    (connection: ProxyConnection) => ({ ok: true as any, connection })
  );

  return [data && data.connection, mutate, error];
};

export const useRecentOnboardingProxyQueryLog = (
  resourceId?: string,
  config?: SWRConfiguration
): HookResponseType<ProxyQueryLog> => {
  const [data, mutate, error] = useZodSWR(
    resourceId
      ? `/api/resources/${resourceId}/recent_onboarding_proxy_query`
      : null,
    responses.getProxyQueryLog,
    config,
    {
      throwIfForbiddenError: true,
    },
    (log: ProxyQueryLog) => ({ ok: true as any, log })
  );

  return [data && data.log, mutate, error];
};

export const useSchemaCreationQueries = (
  resourceId?: string,
  config?: SWRConfiguration
): HookResponseType<SchemaCreationQueries> => {
  const [data, mutate, error] = useZodSWR(
    resourceId ? `/api/resources/${resourceId}/schema/ddl` : null,
    responses.schemaCreationQueries,
    config,
    {
      throwIfForbiddenError: true,
    },
    (queries: SchemaCreationQueries) => ({ ok: true as any, queries })
  );

  return [data && data.queries, mutate, error];
};

export const useGetMessageQueues = (
  config?: SWRConfiguration
): HookResponseType<MessageQueues> => {
  const [data, mutate, error] = useZodSWR(
    "/api/message_queues",
    responses.getMessageQueues,
    config,
    {
      throwIfForbiddenError: true,
    },
    (messageQueues: MessageQueues) => ({ ok: true as any, messageQueues })
  );

  return [data?.messageQueues, mutate, error];
};

export const useGetMessageQueue = (
  messageQueueId: string,
  config?: SWRConfiguration
): HookResponseType<MessageQueue> => {
  const [data, mutate, error] = useZodSWR(
    messageQueueId ? `/api/message_queues/${messageQueueId}` : null,
    responses.getMessageQueue,
    config,
    {
      throwIfForbiddenError: true,
    },
    (messageQueue: MessageQueue) => ({ ok: true as any, messageQueue })
  );

  return [data?.messageQueue, mutate, error];
};
