import React, { useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Listbox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

import { PlatformTable, PlatformTables } from "lib/api/types";
import { Button } from "lib/lucidez";
import { BaseState } from "lib/platforms/base";

const forbiddenAssociationIds = ["PIPELINE", "STAGE"];

type WatchedTables = {
  [tableId: string]: { [enabled: string]: boolean | undefined };
};

type AssociationTree = Array<{
  from: PlatformTable;
  availableAssociations: (PlatformTable & { associationId: string })[];
}>;

export default function SelectAssociationsTables({
  tables,
  isCreate,
  onAddAssociation,
}: {
  tables: PlatformTables;
  isCreate: boolean;
  onAddAssociation?: () => void;
}) {
  const { watch, setValue } = useFormContext();
  const watchedTables = watch().tables ?? {};

  // Used to update the memoization used below
  const selectedTables = Object.keys(watchedTables).filter((tableId) => {
    return watchedTables[tableId]?.enabled === true;
  });

  const tablesWithAssociation = useMemo(() => {
    return tables
      .filter((table) => {
        return Boolean(table.meta?.platform_metadata?.association);
      })
      .filter((table) => {
        return (
          !forbiddenAssociationIds.includes(
            table.meta.platform_metadata.association?.from
          ) &&
          !forbiddenAssociationIds.includes(
            table.meta.platform_metadata.association?.to
          )
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [tables, selectedTables.length]);

  const associationTree = useMemo(
    () => createAssociationTree(tables, tablesWithAssociation, watchedTables),
    [tablesWithAssociation, selectedTables.length]
  );

  const handleAddAssociation = (associationId) => {
    setValue(`tables.${associationId}.enabled`, true);
    onAddAssociation?.();
  };

  if (!associationTree.length) {
    return (
      <div className="mt-6">
        <h3 className="font-bold text-sm">Add associations tables</h3>
        <p className="text-xs">
          Only synced, unlinked tables can be used in associations. Select a
          table above to add an association.
        </p>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <h3 className="font-bold text-sm">Add associations tables</h3>
      {tablesWithAssociation.length > 0 ? (
        <div className="grid grid-cols-1 mt-2">
          {tablesWithAssociation.map((table) => {
            return (
              <TableCheckbox
                table={table}
                key={table.id}
                watchedTables={watchedTables}
              />
            );
          })}
          <AddAssociationBox
            associationTree={associationTree}
            onAddAssociation={handleAddAssociation}
          />
        </div>
      ) : (
        <div className="bg-gray-100 p-2 text-xs text-center rounded mt-2">
          No associations were detected in the HubSpot account.
          {!isCreate && (
            <>
              <br />
              Try refreshing the schema to see if any associations were recently
              created.
            </>
          )}
        </div>
      )}
    </div>
  );
}

const AddAssociationBox = ({
  associationTree,
  onAddAssociation,
}: {
  associationTree: AssociationTree;
  onAddAssociation: (associationId: string) => void;
}) => {
  const associations = useMemo(
    () =>
      associationTree.flatMap((x) =>
        x.availableAssociations.flatMap((y) => [
          {
            from: x.from,
            to: y,
            associationId: y.associationId,
          },
          {
            to: x.from,
            from: y,
            associationId: y.associationId,
          },
        ])
      ),
    [associationTree]
  );

  const fromOptions = useMemo(
    () =>
      uniqBy(
        associations.map((a) => a.from),
        (a) => a.id
      ).sort((a, b) => a.name.localeCompare(b.name)),
    [associations]
  );

  const [config, setConfig] = useState<{ from?: string; to?: string }>({});

  const getToOptions = (fromId: string) => {
    const filteredOptions = associations.filter((a) => a.from.id === fromId);

    return uniqBy(filteredOptions, (a) => a.to.id);
  };

  const toOptions = useMemo(
    () =>
      getToOptions(config.from).sort((a, b) =>
        a.to.name.localeCompare(b.to.name)
      ),
    [config.from, associations]
  );

  const currentFrom = fromOptions?.find((x) => x.id === config.from);
  const currentTo = toOptions?.find((x) => x.to.id === config.to);

  return (
    <div className="flex flex-row border p-2 px-3 rounded text-xs mt-2 items-center gap-2">
      <span className="font-bold">Associations:</span>
      <Listbox onChange={(from) => setConfig({ from })} value={config.from}>
        <Listbox.Label className="sr-only">Choose a table</Listbox.Label>
        <div className="relative">
          <Listbox.Button className="flex flex-row items-center relative py-0.5 pl-2 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black text-xs border">
            <span className="block truncate">
              {currentFrom ? currentFrom.name : "Select table"}
            </span>
            <span className="ml-2 inset-y-0 right-0 flex items-center pr-1 pointer-events-none h-full">
              <SelectorIcon
                className="w-4 h-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Listbox.Options className="absolute  py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
            {fromOptions.map((from) => (
              <Listbox.Option
                key={from.id}
                className={({ active }) =>
                  `${active ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                              cursor-default select-none relative py-2 pl-10 pr-6`
                }
                value={from.id}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`${
                        selected ? "font-medium" : "font-normal"
                      } block truncate`}
                    >
                      {from.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      with
      <Listbox
        onChange={(to) => setConfig((config) => ({ ...config, to }))}
        value={config.to}
      >
        <Listbox.Label className="sr-only">Choose a table</Listbox.Label>
        <div className="relative">
          <Listbox.Button className="flex flex-row items-center relative py-0.5 pl-2 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black text-xs border">
            <span className="block truncate">
              {currentTo ? currentTo.to.name : "..."}
            </span>
            <span className="ml-2 inset-y-0 right-0 flex items-center pr-1 pointer-events-none h-full">
              <SelectorIcon
                className="w-4 h-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          {toOptions.length > 0 && (
            <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
              {toOptions.map((to) => (
                <Listbox.Option
                  key={"to-assoc-opts-" + to.to.id}
                  className={({ active }) =>
                    `${active ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                              cursor-default select-none relative py-2 pl-10 pr-6`
                  }
                  value={to.to.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {to.to.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </div>
      </Listbox>
      <div className="flex-1" />
      <Button
        variant="outlined"
        size="xs"
        disabled={!config.from || !config.to}
        onClick={() => {
          onAddAssociation(currentTo.associationId);
        }}
      >
        Add
      </Button>
    </div>
  );
};

const fromAndToAreSynced = (
  watchedTables: WatchedTables,
  table: PlatformTable
) => {
  return (
    watchedTables[table.meta.platform_metadata.association?.from]?.enabled ===
      true &&
    watchedTables[table.meta.platform_metadata.association?.to]?.enabled ===
      true
  );
};

const createAssociationTree = (
  tables: PlatformTables,
  tablesWithAssociation: PlatformTables,
  watchedTables: WatchedTables
): AssociationTree => {
  const selectedTablesWithAssociation = tablesWithAssociation.filter(
    (table) => {
      return fromAndToAreSynced(watchedTables, table);
    }
  );
  const computeTo = (fromId: string) => {
    const fromTables = selectedTablesWithAssociation
      .filter((tableWithAssociation) => {
        return (
          tableWithAssociation.meta.platform_metadata.association.from ===
          fromId
        );
      })
      .map((tableWithAssociation) => {
        return {
          to: tableWithAssociation.meta.platform_metadata.association.to,
          id: tableWithAssociation.id,
        };
      });

    return uniq(fromTables);
  };

  return uniq(
    selectedTablesWithAssociation.map((tableWithAssociation) => {
      return tableWithAssociation.meta.platform_metadata.association.from;
    })
  ).map((tableId) => {
    return {
      from: tables.find((table) => table.id === tableId),
      availableAssociations: computeTo(tableId).map((tableId) => ({
        ...tables.find((table) => table.id === tableId.to),
        associationId: tableId.id,
      })),
    };
  });
};

const TableCheckbox = ({
  table,
  watchedTables,
}: {
  table: PlatformTable;
  watchedTables: WatchedTables;
}) => {
  const { field } = useController<BaseState<any>>({
    name: `tables.${table.id}.enabled`,
  });
  const isTableSelected = field.value === true;

  if (!isTableSelected) {
    return null;
  }

  const handleCheck = ({ target: { checked } }) => {
    field.onChange(checked);
  };

  if (!fromAndToAreSynced(watchedTables, table)) {
    field.onChange(false);
  }

  return (
    <div
      className="flex flex-row items-center hover:bg-gray-100 px-2 py-1"
      key={table.id}
    >
      <input
        className="form-checkbox rounded-sm text-black ring-black cursor-pointer disabled:opacity-20"
        type="checkbox"
        id={`tablecheckbox-${table.id}`}
        name="tables"
        checked={isTableSelected}
        onChange={handleCheck}
      ></input>
      <label
        htmlFor={`tablecheckbox-${table.id}`}
        className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer truncate flex-1"
        title={table.name}
      >
        {table.name}
      </label>
    </div>
  );
};
