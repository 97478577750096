// Using this track method is preferred over calling window.analytics.directly
// This is the way forward, if you're wondering if you should use this function. Do it.
export const track = (
  event: string,
  extraProperties?: { [key: string]: string | number | boolean | object }
): void => {
  if (window.analytics) {
    if (extraProperties && Object.keys(extraProperties).length) {
      window.analytics.track(event, extraProperties);
    } else {
      window.analytics.track(event);
    }
  }
};

export const pageView = (page: string, category?: string): void => {
  if (window.analytics) {
    if (category) {
      window.analytics.page(category, page);
    } else {
      window.analytics.page(page);
    }
  }
};
