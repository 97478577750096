import { Resource } from ".";
import { RateLimit } from "../api/types";

export interface RateLimitableState {
  rateLimit: RateLimit;
}

export const rateLimitableInitialCreateState = (
  defaultRateLimit: RateLimit
): RateLimitableState => ({
  rateLimit: defaultRateLimit,
});

export const rateLimitableInitialUpdateState = (
  resource: Resource,
  defaultRateLimit: RateLimit
): RateLimitableState => {
  return {
    rateLimit: resource.rateLimit ? resource.rateLimit : defaultRateLimit,
  };
};

export const rateLimitablePrepareFieldsForCreate = (
  data: RateLimitableState,
  bindMaxConcurrentRequests?: boolean
) => {
  return {
    rate_limit: {
      allowed_requests: data.rateLimit.allowedRequests,
      interval: data.rateLimit.interval,
      max_concurrent_requests: bindMaxConcurrentRequests
        ? data.rateLimit.allowedRequests
        : data.rateLimit.maxConcurrentRequests,
    },
  };
};

export const rateLimitablePrepareFieldsForUpdate = (
  data: Partial<RateLimitableState>,
  bindMaxConcurrentRequests?: boolean
) => {
  return {
    rate_limit: {
      allowed_requests: data.rateLimit.allowedRequests,
      interval: data.rateLimit.interval,
      max_concurrent_requests: bindMaxConcurrentRequests
        ? data.rateLimit.allowedRequests
        : data.rateLimit.maxConcurrentRequests,
    },
  };
};
