import * as z from "zod";
import {
  dbWithResourcesSchema,
  resourceSchema,
  credentialSchema,
} from "lib/platforms";
import {
  accountSchema,
  airtableBaseSchema,
  airtableBaseTableSchema,
  appKeySchema,
  billingInfoSchema,
  billingPeriod,
  billingPlanSchema,
  createResourceStatusesSchema,
  credentialUpstreamInfo,
  dbSchema,
  latestBillingPlanSchema,
  messageQueueSchema,
  organizationSchema,
  pendingUserSchema,
  platformSchemaSchema,
  platformTableSchemaSchema,
  platformTables,
  proxyConnectionSchema,
  proxyOptsSchema,
  proxyQueryLogSchema,
  refreshSchemaStatusSchema,
  resourceBackfillSchema,
  resourceCollectionStatusSchema,
  resourceMetadataScheme,
  resourceStatusSchema,
  resourceTableSyncing,
  resourceUsageByDaySchema,
  resourceUsageSchema,
  schemaCreationQueriesSchema,
  schemaDiffSchema,
  sshTunnelSchema,
  upstreamInfo,
  userSchema,
} from "lib/api/types";

const listOrgResources = z.object({
  ok: z.literal(true),
  resources: z.array(resourceSchema),
});
export type ListOrgResources = z.infer<typeof listOrgResources>;

const listOrgResourceMetadatas = z.object({
  ok: z.literal(true),
  metadatas: z.array(resourceMetadataScheme),
});
export type ListOrgResourceMetadatas = z.infer<typeof listOrgResourceMetadatas>;

const listOrgResourceRowCounts = z.object({
  ok: z.literal(true),
  row_counts: z.any(),
});
export type ListOrgResourceRowCounts = z.infer<typeof listOrgResourceRowCounts>;

const getResourceMetadata = z.object({
  ok: z.literal(true),
  all_metadata: z.array(resourceMetadataScheme),
});
export type GetResourceMetadata = z.infer<typeof getResourceMetadata>;

const listOrgResourceStatuses = z.object({
  ok: z.literal(true),
  statuses: z.array(resourceStatusSchema),
});
export type GetResourceStatuses = z.infer<typeof listOrgResourceStatuses>;

const listOrgResourceCollectionStatuses = z.object({
  ok: z.literal(true),
  statuses: z.array(resourceCollectionStatusSchema),
});

export type GetResourceCollectionStatuses = z.infer<
  typeof listOrgResourceCollectionStatuses
>;

const getAirtableBases = z.object({
  ok: z.boolean(),
  bases: z.array(airtableBaseSchema).optional(),
  error: z.string().optional(),
});

export type GetAirtableBases = z.infer<typeof getAirtableBases>;

const getAirtableBaseTables = z.object({
  ok: z.boolean(),
  tables: z.array(airtableBaseTableSchema).optional(),
  error: z.string().optional(),
});

export type GetAirtableBaseTables = z.infer<typeof getAirtableBaseTables>;

const getUpstreamInfo = z.object({
  ok: z.boolean(),
  info: upstreamInfo,
});

export type GetUpstreamInfo = z.infer<typeof getUpstreamInfo>;

const getCredentialUpstreamInfo = z.object({
  ok: z.boolean(),
  info: credentialUpstreamInfo,
});

export type GetCredentialUpstreamInfo = z.infer<
  typeof getCredentialUpstreamInfo
>;

const getUserOrg = z.object({
  ok: z.literal(true),
  org: organizationSchema,
});
export type GetUserOrg = z.infer<typeof getUserOrg>;

const getUser = z.object({
  ok: z.literal(true),
  user: userSchema,
});
export type GetUser = z.infer<typeof getUser>;

const listUsers = z.object({
  ok: z.literal(true),
  users: z.array(z.union([userSchema, pendingUserSchema])),
});
export type ListUsers = z.infer<typeof listUsers>;

const getBillingInfo = z.object({
  ok: z.literal(true),
  info: billingInfoSchema,
});
export type GetBillingInfo = z.infer<typeof getBillingInfo>;

const getBillingPlan = z.object({
  ok: z.literal(true),
  billing_plan: billingPlanSchema,
});
export type GetBillingPlan = z.infer<typeof getBillingPlan>;

const getLatestBillingPlan = z.object({
  ok: z.literal(true),
  plan: latestBillingPlanSchema,
});
export type GetLatestBillingPlan = z.infer<typeof getLatestBillingPlan>;

const getNewBillingPeriod = z.object({
  ok: z.literal(true),
  period: billingPeriod,
});
export type GetNewBillingPeriod = z.infer<typeof getNewBillingPeriod>;

const getCurrentBillingPeriod = z.object({
  ok: z.literal(true),
  period: billingPeriod,
});
export type GetCurrentBillingPeriod = z.infer<typeof getCurrentBillingPeriod>;

const getAppKey = z.object({
  ok: z.literal(true),
  appKey: z.union([appKeySchema, z.null()]),
});
export type GetAppKey = z.infer<typeof getAppKey>;

const getSshPublicKey = z.object({
  ok: z.literal(true),
  key: z.string(),
});
export type GetSshPublicKey = z.infer<typeof getSshPublicKey>;

const getFeatures = z.object({
  ok: z.literal(true),
  features: z.any(),
});
export type GetFeatures = z.infer<typeof getFeatures>;

const getAccount = z.object({
  ok: z.literal(true),
  account: accountSchema,
});
export type GetAccount = z.infer<typeof getAccount>;

const fieldSchemaSchema = z.object({
  default_value: z.any(),
  is_nullable: z.boolean(),
  max_length: z.union([z.number(), z.null()]),
  name: z.string(),
  type: z.string(), // This can be limited later, but it's not relevant now
});

export type FieldSchema = z.infer<typeof fieldSchemaSchema>;

// It's one of those names - again

const tableSchemaSchema = z.object({
  fields: z.array(fieldSchemaSchema),
  table_name: z.string(),
});

export type TableSchema = z.infer<typeof tableSchemaSchema>;

const successfulQueryResultSchema = z.object({
  columns: z.array(z.string()),
  command: z.string(),
  connection_id: z.number(),
  messages: z.array(z.string()),
  num_rows: z.number(),
  rows: z.array(z.array(z.any())),
});

export type SuccessQueryResult = z.infer<typeof successfulQueryResultSchema>;

const erroredQueryResultSchema = z.object({
  connection_id: z.number(),
  message: z.any(),
  query: z.string(),
  __exception__: z.boolean(),
  postgres: z.object({
    code: z.string(),
    file: z.string(),
    line: z.string(),
    message: z.string(),
    pg_code: z.string(),
    position: z.string(),
    routine: z.string(),
    severity: z.string(),
    unknown: z.string(),
  }),
});

export type ErroredQueryResult = z.infer<typeof erroredQueryResultSchema>;

const successfulGetQueryResult = z.object({
  ok: z.literal(true),
  result: successfulQueryResultSchema,
});

const erroredGetQueryResult = z.object({
  ok: z.literal(false),
  result: erroredQueryResultSchema,
});

export type GetQueryResult =
  | z.infer<typeof successfulGetQueryResult>
  | z.infer<typeof erroredGetQueryResult>;

const listResourceBackfills = z.object({
  ok: z.literal(true),
  backfills: z.array(resourceBackfillSchema),
});
export type ListResourceBackfills = z.infer<typeof listResourceBackfills>;

const createExternalDatabaseResult = z.object({
  ok: z.literal(true),
  database: dbSchema,
});
export type CreateExternalDatabaseResult = z.infer<
  typeof createExternalDatabaseResult
>;

const updateExternalDatabaseResult = z.object({
  ok: z.literal(true),
  database: dbSchema,
});
export type UpdateExternalDatabaseResult = z.infer<
  typeof updateExternalDatabaseResult
>;

const deleteDatabaseResult = z.object({
  ok: z.literal(true),
});
export type DeleteDatabaseResult = z.infer<typeof deleteDatabaseResult>;

const deleteCredentialResult = z.object({
  ok: z.literal(true),
});
export type DeleteCredentialResult = z.infer<typeof deleteCredentialResult>;

const testAndCreateSshTunnelResult = z.object({
  ok: z.literal(true),
  tunnel: sshTunnelSchema,
});
export type TestAndCreateSshTunnelResult = z.infer<
  typeof testAndCreateSshTunnelResult
>;

const testExistingTunnelResult = z.object({
  ok: z.literal(true),
  tunnel: sshTunnelSchema,
});
export type TestExistingTunnelResult = z.infer<typeof testExistingTunnelResult>;

const listSshTunnels = z.object({
  ok: z.literal(true),
  tunnels: z.array(sshTunnelSchema),
});
export type ListSshTunnels = z.infer<typeof listSshTunnels>;

const testReachabilityResult = z.object({
  ok: z.literal(true),
});
export type TestReachabilityResult = z.infer<typeof testReachabilityResult>;

const getDatabasesWithResources = z.object({
  ok: z.literal(true),
  databases: z.array(dbWithResourcesSchema),
});
export type GetDatabasesWithResources = z.infer<
  typeof getDatabasesWithResources
>;

const listOrgCredentials = z.object({
  ok: z.literal(true),
  credentials: z.array(credentialSchema),
});
export type ListOrgCredentials = z.infer<typeof listOrgCredentials>;

const getPlatformTables = z.object({
  ok: z.literal(true),
  tables: platformTables,
});

const listResourcesTablesSyncing = z.object({
  ok: z.literal(true),
  tables: z.array(resourceTableSyncing),
});

const getPlatformTableSchema = z.object({
  ok: z.literal(true),
  schema: platformTableSchemaSchema,
});

const getPlatformSchema = z.object({
  ok: z.literal(true),
  schema: platformSchemaSchema,
});

const getSchemaDiff = z.object({
  ok: z.literal(true),
  diff: schemaDiffSchema,
});

const getRefreshSchemaStatus = z.object({
  ok: z.literal(true),
  status: refreshSchemaStatusSchema,
});

const getCreateResourceStatus = z.object({
  ok: z.literal(true),
  status: createResourceStatusesSchema,
});

const listResourceUsages = z.object({
  ok: z.literal(true),
  resourceUsages: z.array(resourceUsageSchema),
});
export type ListResourceUsages = z.infer<typeof listResourceUsages>;

const listResourceUsagesByDay = z.object({
  ok: z.literal(true),
  resourceUsages: resourceUsageByDaySchema,
});
export type ListResourceUsagesByDay = z.infer<typeof listResourceUsagesByDay>;

const listSearchablePlatforms = z.object({
  ok: z.literal(true),
  platforms: z.array(z.string()),
});
export type ListSearchablePlatforms = z.infer<typeof listSearchablePlatforms>;

const getProxyOpts = z.object({
  ok: z.literal(true),
  proxy: proxyOptsSchema,
});
export type GetProxyOpts = z.infer<typeof getProxyOpts>;

const getProxyConnection = z.object({
  ok: z.literal(true),
  connection: proxyConnectionSchema,
});
export type GetProxyConnection = z.infer<typeof getProxyConnection>;

const getProxyQueryLog = z.object({
  ok: z.literal(true),
  log: proxyQueryLogSchema,
});
export type GetProxyQueryLog = z.infer<typeof getProxyQueryLog>;

const schemaCreationQueries = z.object({
  ok: z.literal(true),
  queries: schemaCreationQueriesSchema,
});

const getMessageQueues = z.object({
  ok: z.literal(true),
  messageQueues: z.array(messageQueueSchema),
});

const getMessageQueue = z.object({
  ok: z.literal(true),
  messageQueue: messageQueueSchema,
});

export const responses = {
  createExternalDatabaseResult,
  deleteCredentialResult,
  deleteDatabaseResult,
  erroredGetQueryResult,
  getAccount,
  getAirtableBaseTables,
  getAirtableBases,
  getAppKey,
  getBillingInfo,
  getBillingPlan,
  getCreateResourceStatus,
  getCredentialUpstreamInfo,
  getCurrentBillingPeriod,
  getDatabasesWithResources,
  getFeatures,
  getLatestBillingPlan,
  getMessageQueue,
  getMessageQueues,
  getNewBillingPeriod,
  getPlatformSchema,
  getPlatformTableSchema,
  getPlatformTables,
  getProxyConnection,
  getProxyOpts,
  getProxyQueryLog,
  getRefreshSchemaStatus,
  getResourceMetadata,
  getSchemaDiff,
  getSshPublicKey,
  getUpstreamInfo,
  getUser,
  getUserOrg,
  listOrgCredentials,
  listOrgResourceCollectionStatuses,
  listOrgResourceMetadatas,
  listOrgResourceRowCounts,
  listOrgResourceStatuses,
  listOrgResources,
  listResourceBackfills,
  listResourceUsages,
  listResourceUsagesByDay,
  listResourcesTablesSyncing,
  listSearchablePlatforms,
  listSshTunnels,
  listUsers,
  schemaCreationQueries,
  successfulGetQueryResult,
  testAndCreateSshTunnelResult,
  testExistingTunnelResult,
  testReachabilityResult,
  updateExternalDatabaseResult,
};
