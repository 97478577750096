import React from "react";
import * as z from "zod";

import SalesforceCredentialForm from "components/platforms/salesforce/SalesforceCredentialForm";
import SalesforceForm, {
  FullPageSalesforceForm,
  OnboardCredentialSalesforceForm,
} from "components/platforms/salesforce/SalesforceForm";
import SvgSalesforceFilled from "components/svg/Salesforce";
import {
  baseCredentialSchema,
  baseResourceSchema,
  RateLimit,
  rawResourceSchema,
} from "lib/api/types";
import { Platform } from "lib/platforms/";
import {
  baseInitialCreateState,
  baseInitialUpdateState,
  basePrepareFieldsForCreate,
  basePrepareFieldsForUpdate,
  BaseState,
} from "lib/platforms//base";
import {
  rateLimitableInitialCreateState,
  rateLimitableInitialUpdateState,
  rateLimitablePrepareFieldsForCreate,
  rateLimitablePrepareFieldsForUpdate,
  RateLimitableState,
} from "lib/platforms//rateLimitable";

// Metadata definition
export const salesforceDefinitionSchema = z.object({
  proxyWaitActive: z.boolean(),
});

export type SalesforceDefinition = z.infer<typeof salesforceDefinitionSchema>;

// Credential definition
export const salesforceCredentialSchema = baseCredentialSchema.extend({
  payload: z.object({
    kind: z.literal("salesforce"),
    refresh_token: z.string(),
    sfid: z.string(),
  }),
});

export type SalesforceCredential = z.infer<typeof salesforceCredentialSchema>;

// Integral resource definition
export const rawSalesforceResourceSchema = rawResourceSchema.extend({
  kind: z.literal("salesforce"),
  definition: salesforceDefinitionSchema,
  credential: salesforceCredentialSchema,
});

export const salesforceResourceSchema = baseResourceSchema.extend({
  kind: z.literal("salesforce"),
  definition: salesforceDefinitionSchema,
  credential: salesforceCredentialSchema,
});

export type SalesforceResource = z.infer<typeof salesforceResourceSchema>;

export interface SalesforceState
  extends BaseState<SalesforceCredential>,
    RateLimitableState {}

const DEFAULT_RATE_LIMIT: RateLimit = {
  allowedRequests: 28_800,
  interval: 86_400_000, // One day. Not user editable for now
  maxConcurrentRequests: 3, // not in use right now
};

// Platform definition
export const SALESFORCE_PLATFORM: Platform<
  SalesforceResource,
  SalesforceState,
  SalesforceCredential
> = {
  displayName: "Salesforce",
  displayIcon: ({ ref, ...props }) => {
    return <SvgSalesforceFilled {...props} />;
  },
  resourceAppositive: "account",
  kind: "salesforce",

  supportsCollectionStatus: true,
  supportsWriteProxy: true,

  helpers: [
    {
      name: "SOURCE",
      buildUrl: (res) => ({
        url: `https://${res.credential.payload.sfid}.my.salesforce.com`,
        displayUrl: `${res.credential.payload.sfid}.my.salesforce.com`,
      }),
    },
  ],

  buildForm: SalesforceForm,
  buildCredentialForm: SalesforceCredentialForm,
  buildOnboardCredentialForm: OnboardCredentialSalesforceForm,
  extraTableParams: (_state) => {
    return {};
  },

  fullPageForm: FullPageSalesforceForm,

  getLabelForCredential: (cred) => cred.payload.sfid,

  getInitialCreateState: () => ({
    ...baseInitialCreateState("Salesforce"),
    ...rateLimitableInitialCreateState(DEFAULT_RATE_LIMIT),
  }),
  getInitialUpdateState: (res) => ({
    ...baseInitialUpdateState(res),
    ...rateLimitableInitialUpdateState(res, DEFAULT_RATE_LIMIT),
  }),

  prepareFieldsForCreate: (d) => ({
    ...basePrepareFieldsForCreate(d, "salesforce"),
    ...rateLimitablePrepareFieldsForCreate(d),
  }),
  prepareFieldsForUpdate: (d) => ({
    ...basePrepareFieldsForUpdate(d, "salesforce"),
    ...rateLimitablePrepareFieldsForUpdate(d),
  }),
};
