import { Disclosure, Switch } from "@headlessui/react";
import { QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useRef, useState } from "react";
import {
  createCredential,
  executeStripeKeyOAuth,
  validateStripeKey,
} from "../../../lib/api";
import { Button } from "../../../lib/lucidez";
import { CredentialFormProps } from "../../../lib/platforms";
import {
  StripeCredential,
  STRIPE_PLATFORM,
} from "../../../lib/platforms/stripe";
import { useDebounce, useId } from "../../../lib/utils";
import HoverPopover from "../../core/HoverPopover";
import SecretInput from "../../core/SecretInput";
import SvgSpinner from "../../svg/Spinner";
import {
  FieldState,
  getValidationStateIcon,
  useValidation,
} from "../common/forms/util";

enum OAuthState {
  Initial,
  Error,
  Connecting,
}

export default function StripeCredentialForm(
  props: CredentialFormProps<StripeCredential>
) {
  const [isFullPermissions, setIsFullPermission] = useState(true);

  return (
    <div className="flex flex-col mt-2">
      <div className="p-2 px-3 rounded border flex flex-row">
        <div className="flex-1">
          <Switch.Group>
            <div className="flex-row flex items-center">
              <Switch
                checked={isFullPermissions}
                onChange={() => setIsFullPermission((v) => !v)}
                className={`${
                  isFullPermissions ? "bg-black" : "bg-gray-300"
                } disabled:opacity-50 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-offset-2 focus:ring-offset-white dark:ring-offset-gray-700 focus:ring-2 focus:ring-black`}
              >
                <span
                  className={`${
                    isFullPermissions ? "translate-x-6" : "translate-x-1"
                  } inline-flex items-center justify-center w-4 h-4 transform bg-white rounded-full transition-transform`}
                ></span>
              </Switch>
              <Switch.Label className="text-gray-600 dark:text-white w-48 flex-1 cursor-pointer ml-2 text-xs font-bold">
                {isFullPermissions
                  ? "Stripe Connect (recommended)"
                  : "Partial permissions"}
              </Switch.Label>
            </div>
          </Switch.Group>
        </div>
        <HoverPopover
          content={
            <div className="w-64 grid grid-flow-row gap-2">
              <p>
                When using full permissions, Sequin will connect to your Stripe
                account using{" "}
                <b>
                  <a
                    href="https://stripe.com/connect"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe Connect
                  </a>
                </b>
                . <b>Connect</b> offers an easier setup and better management of
                your account keys and connected apps.
              </p>
              <p>
                Sequin will only use the resources required to sync your data.
                All keys in our database are encrypted.
              </p>
            </div>
          }
        >
          <button>
            <QuestionMarkCircleIcon className="h-5 w-5" />
          </button>
        </HoverPopover>
      </div>
      <div className="mt-4">
        {isFullPermissions ? <OAuthForm {...props} /> : <KeyForm {...props} />}
      </div>
    </div>
  );
}

const OAuthForm = ({ onSave }: CredentialFormProps<StripeCredential>) => {
  const StripeIcon = STRIPE_PLATFORM.displayIcon;

  const [oauthState, setOauthState] = useState(OAuthState.Initial);

  const testModeCheckboxId = useId("testmode-checkbox-");
  const [isTestMode, setIsTestMode] = useState(false);

  const handleConnect = async () => {
    setOauthState(OAuthState.Connecting);

    window.analytics.track("OAuth Connect Started", {
      kind: "stripe",
    });

    const res = await executeStripeKeyOAuth(isTestMode);

    if (res.isOk() && res.value) {
      setOauthState(OAuthState.Initial);

      window.analytics.track("OAuth Connect Finished", {
        kind: "stripe",
      });

      onSave(res.value.credential);
    } else {
      window.analytics.track("OAuth Connect Errored", {
        kind: "stripe",
      });

      setOauthState(OAuthState.Error);
    }
  };

  if (oauthState === OAuthState.Initial) {
    return (
      <div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <input
              className="form-checkbox rounded-sm text-black ring-black cursor-pointer"
              type="checkbox"
              id={testModeCheckboxId}
              checked={isTestMode}
              onChange={({ target: { checked } }) => setIsTestMode(checked)}
              name="testmode"
            ></input>
            <label
              htmlFor={testModeCheckboxId}
              className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer"
            >
              Use test mode
            </label>
          </div>

          <Button
            className="w-full text-center justify-center mt-2"
            variant="primary"
            size="lg"
            iconLeft={<StripeIcon className="w-5 h-5" />}
            onClick={handleConnect}
          >
            Connect to {STRIPE_PLATFORM.displayName}
          </Button>
        </div>
      </div>
    );
  } else if (oauthState === OAuthState.Connecting) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <SvgSpinner className="h-9 w-9 text-black block animate-spin" />
        <p className="mt-1">
          Waiting for connection to finish in the other window.
        </p>
      </div>
    );
  } else if (oauthState === OAuthState.Error) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <XCircleIcon className="h-9 w-9 text-error block" />
        <p className="mt-1">
          There was an error connecting to {STRIPE_PLATFORM.displayName}.
        </p>
        <Button
          className="mt-2"
          onClick={() => setOauthState(OAuthState.Initial)}
          variant="primary"
          size="md"
        >
          Try again
        </Button>
      </div>
    );
  }
};

const KeyForm = ({ onSave }: CredentialFormProps<StripeCredential>) => {
  const secretInput = useRef<HTMLInputElement>();

  const { fieldState, setFieldState } = useValidation(FieldState.NotValidated);
  const canSave = fieldState === FieldState.Valid;

  const [token, setToken] = useState("");
  const debouncedToken = useDebounce(token, 1000);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    validateKey();
  }, [debouncedToken]);

  const validateKey = async () => {
    if (!token) {
      setFieldState(FieldState.NotValidated);
      return;
    }

    setFieldState(FieldState.Validating);

    try {
      let res = await validateStripeKey(token);
      setFieldState(
        res.isOk() && res.value.secret_valid
          ? FieldState.Valid
          : FieldState.Invalid
      );
    } catch {
      setFieldState(FieldState.Invalid);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);

    const res = await createCredential({
      payload: {
        kind: "stripe",
        secret: token,
        test: token.includes("test"),
        auth_method: "api_key",
      },
    });

    if (res.isOk() && res.value) {
      onSave(res.value.credential as StripeCredential);
    }

    setIsSaving(false);
  };

  return (
    <>
      <p className="text-xs">
        Paste a key from your{" "}
        <a
          href="https://dashboard.stripe.com/apikeys"
          className="link"
          target="_blank"
          rel="noreferrer"
        >
          Stripe dashboard
        </a>{" "}
        below.
      </p>
      <div className="flex flex-row mt-2">
        <div className="flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black  flex-1">
          <SecretInput
            className="focus:outline-none rounded w-72 flex-1 px-4"
            placeholder="sk_live_SK5oshf5393y2wNJ1WTPNxiy39J3886Nm+4JTXgTiwG4J2Li5jxWGtGH0T48hytU"
            value={token}
            name="token"
            onChange={(event) =>
              setToken((event.target as HTMLInputElement).value)
            }
            ref={secretInput}
          />
          <div className="w-10 h-10 flex items-center justify-center">
            {getValidationStateIcon(fieldState)}
          </div>
        </div>
      </div>

      <div>
        <p className="text-xs mt-2">
          The key can be restricted and read-only, but{" "}
          <b>must include write access for webhooks.</b>{" "}
        </p>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-xxs font-bold my-2 link">
                {open ? "▼" : "▶"} How do I create a restricted key?
              </Disclosure.Button>
              <Disclosure.Panel className="text-xs p-4 bg-gray-100 rounded">
                <p>
                  Visit{" "}
                  <a
                    href="https://dashboard.stripe.com/apikeys"
                    target="_blank"
                    className="link"
                    rel="noreferrer"
                  >
                    your Stripe dashboard
                  </a>{" "}
                  and follow the steps below:
                </p>
                <video
                  autoPlay
                  loop
                  muted
                  controls={false}
                  src="/static/create-stripe-key.mp4"
                  className="mt-4 rounded-md"
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <Button
        className="w-full text-center justify-center mt-2"
        variant="primary"
        size="lg"
        disabled={!canSave}
        isLoading={isSaving}
        onClick={handleSave}
      >
        Save
      </Button>
    </>
  );
};
