/* The following three functions are made to work around a quirk of react-hook-form:
   It converts form fields with dots into object structures
   E.g. {billing_portal.session: {enabled: true}} becomes
   {billing_portal: {session: {enabled: true}}}
*/
export const denormalizeTableKeysForRHF = (tables) => {
  Object.keys(tables).forEach((tableKey) => {
    if (tableKey.includes(".")) {
      const newTableKey = tableKey.replace(".", "##");
      tables[newTableKey] = tables[tableKey];
      delete tables[tableKey];
    }
  });

  return tables;
};

export const normalizeTableKeysFromRHF = (tables) => {
  Object.keys(tables).forEach((tableKey) => {
    if (tableKey.includes("##")) {
      const newTableKey = tableKey.replace("##", ".");
      tables[newTableKey] = tables[tableKey];
      delete tables[tableKey];
    }
  });

  return tables;
};

export const replaceDotsWithEyesEmojiInTableIds = (tableId) => {
  return tableId.replace(".", "##");
};
