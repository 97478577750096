import * as z from "zod";

export type ResourceDestination =
  | { type: "placeholder" }
  | { type: "new" }
  | {
      type: "external";
      validSchema: boolean;
      databaseId: string;
      schema: string;
    }
  | {
      type: "existing";
      validSchema: boolean;
      databaseId: string;
      schema: string;
    };

export const DbStatus = z.enum([
  "provisioning",
  "ready",
  "error",
  "pending_removal",
]);
export type IDbStatus = z.infer<typeof DbStatus>;

export const baseResourceUsageSchema = z.object({
  resourceId: z.string(),
  deleteEventsCount: z.number(),
  upsertEventsCount: z.number(),
  totalEventsCount: z.number(),
  proxyWaitCount: z.number(),
});
export type BaseResourceUsage = z.infer<typeof baseResourceUsageSchema>;

export const resourceUsageSchema = baseResourceUsageSchema.extend({
  id: z.string(),
  insertedAt: z.any(),
  updatedAt: z.any(),
  insertedDate: z.string(),
  kind: z.string(),
  monthlyActiveRows: z.number(),
});
export type ResourceUsage = z.infer<typeof resourceUsageSchema>;

export const resourceUsageByDaySchema = z.record(z.array(resourceUsageSchema));
export type ResourceUsageByDay = z.infer<typeof resourceUsageByDaySchema>;

export const organizationSchema = z.object({
  hasCreatedResource: z.boolean(),
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  trialExpiresAt: z.any(),
  surveyCompleted: z.any().optional(),
  subscriptionStatus: z.enum([
    "active",
    "free",
    "inactive",
    "pending_inactive",
    "trial",
  ]),
  usages: z
    .object({
      deleteEventsCount: z.number(),
      upsertEventsCount: z.number(),
      totalEventsCount: z.number(),
      monthlyActiveRows: z.number(),
      proxyWaitCount: z.number(),
      orgId: z.string().optional(),
    })
    .nullable(),
  pricing: z.object({
    definition: z.any(),
    hidden: z.boolean(),
    notes: z.any(),
    id: z.string().optional(),
    insertedAt: z.any().optional(),
    updatedAt: z.any().optional(),
  }),
});
export type Organization = z.infer<typeof organizationSchema>;

const userRoleSchema = z.union([
  z.literal("user"),
  z.literal("developer"),
  z.literal("admin"),
]);
export type UserRole = z.infer<typeof userRoleSchema>;

export const userSchema = z.object({
  id: z.string(),
  name: z.union([z.string(), z.null()]),
  email: z.string(),
  orgId: z.string(),
  insertedAt: z.any(),
  status: z.literal("active"),
});
export const pendingUserSchema = z.object({
  id: z.string(),
  name: z.union([z.string(), z.null()]),
  email: z.string(),
  orgId: z.string(),
  insertedAt: z.any(),
  status: z.literal("pending"),
});

export type User = z.infer<typeof userSchema>;
export type PendingUser = z.infer<typeof pendingUserSchema>;
export type UserModifiableParams = Pick<User, "name">;
export type UserCreateParams = Pick<User, "name" | "email">;
export function isPendingUser(user: User | PendingUser) {
  return pendingUserSchema.check(user);
}
export function isActiveUser(user: unknown): boolean {
  return userSchema.check(user);
}

export const billingInfoSchema = z.object({
  enabled: z.boolean(),
});
export type BillingInfo = z.infer<typeof billingInfoSchema>;

export const billingPlanSchema = z.object({
  mar: z.number(),
  name: z.string(),
  tier: z.enum(["business", "project", "standard", "starter"]),
});

export type BillingPlan = z.infer<typeof billingPlanSchema>;

export const latestBillingPlanSchema = z.object({
  definition: z.object({
    pricing: z.array(
      z.object({
        included_mar: z.number(),
        price: z.number(),
        stripe_price_id: z.string(),
        tier: z.string(),
      })
    ),
    schema: z.string(),
  }),
  hidden: z.boolean(),
  notes: z.string(),
});

export type LatestBillingPlan = z.infer<typeof latestBillingPlanSchema>;

export const billingPeriod = z.object({
  start: z.string(),
  end: z.string(),
});
export type BillingPeriod = z.infer<typeof billingPeriod>;

export const featuresSchema = z.any();
export type Features = z.infer<typeof featuresSchema>;

export const accountSchema = z.object({
  createdAt: z.string(),
  id: z.string(),
  org: z.object({
    name: z.string(),
    slug: z.string(),
    subscriptionStatus: z.string().nullable(),
    trialExpiresAt: z.string(),
  }),
});
export type Account = z.infer<typeof accountSchema>;

export const appKeySchema = z.object({
  id: z.string(),
  key: z.string(),
  orgId: z.string(),
  insertedAt: z.any(),
  updatedAt: z.string(),
});
export type AppKey = z.infer<typeof appKeySchema>;

export const dbSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  permaslug: z.string(),

  definition: z.object({
    user: z.string().optional(),
    host: z.string(),
    dbname: z.string(),
    port: z.number(),
    type: z.enum(["dedicated_postgres", "shared_postgres"]),
    ssl: z.boolean().optional(),
  }),

  sshTunnelId: z.string().nullable(),

  status: DbStatus,

  readGroup: z.string().nullable(),

  insertedAt: z.string(),
  updatedAt: z.string(),

  schema: z.string().optional().nullable(),
  user: z
    .object({
      id: z.string().optional(),
      username: z.string(),
      password: z.string(),
    })
    .optional(),
});

export type Database = z.infer<typeof dbSchema>;

export const dbReadUserSchema = z.object({
  role: z.string(),
  has_read_access: z.boolean(),
});

export type DatabaseReadUser = z.infer<typeof dbReadUserSchema>;

export const sshTunnelSchema = z.object({
  id: z.string(),
  host: z.string(),
  user: z.string(),
  port: z.number(),
});

export type SshTunnel = z.infer<typeof sshTunnelSchema>;

export const proxyOptsSchema = z.object({
  dbname: z.string(),
  password: z.string(),
  user: z.string(),
  host: z.string(),
  port: z.number(),
});

export type ProxyOpts = z.infer<typeof proxyOptsSchema>;

export const proxyConnectionSchema = z.object({
  connected_at_date: z.string(),
  count: z.number(),
  dbuser: z.string(),
  id: z.string(),
  is_proxy: z.boolean(),
});

export type ProxyConnection = z.infer<typeof proxyConnectionSchema>;

export const proxyQueryLogSchema = z.object({
  id: z.string(),
});

export type ProxyQueryLog = z.infer<typeof proxyQueryLogSchema>;

const columnMappingSchema = z.object({
  tableId: z.string(),
  columns: z.array(
    z.object({
      columnId: z.string(),
      patch: z.object({
        dbName: z.string(),
      }),
    })
  ),
});

const selectedColumnsMappingSchema = z.object({
  tableId: z.string(),
  columns: z.array(z.string()),
});

// this name is so...
export const resourceSchemaSchema = z.object({
  tables: z.array(z.string()),
  syncAllTables: z.boolean().optional(),
  airtableUsePreferSingleRecordLinks: z.boolean().optional(),
  lazyForeignKeys: z.boolean().optional(),
  columns: z.array(columnMappingSchema),
  selectedColumns: z.array(selectedColumnsMappingSchema),
  idx: z.number(),
});

export type ResourceSchemaSchema = z.infer<typeof resourceSchemaSchema>;

export const rateLimitSchema = z.object({
  allowedRequests: z.number(),
  interval: z.number(),
  maxConcurrentRequests: z.number(),
});

export type RateLimit = z.infer<typeof rateLimitSchema>;

const resourceEnvironmentEnum = z.enum([
  "development",
  "staging",
  "production",
]);

export type ResourceEnvironment = z.infer<typeof resourceEnvironmentEnum>;

export const rawResourceSchema = z.object({
  active: z.boolean(),
  id: z.string(),
  insertedAt: z.string(),
  isBackfilling: z.boolean().optional(),
  name: z.string(),
  permaslug: z.string(),
  rateLimit: rateLimitSchema.nullable(),
  resourceEnvironment: resourceEnvironmentEnum,
  schema: resourceSchemaSchema,
  updatedAt: z.string(),
  messageQueueId: z.string().optional().nullable(),
});

export const baseResourceSchema = rawResourceSchema.extend({
  database: dbSchema,
});

export type BaseResource = z.infer<typeof baseResourceSchema>;

export const baseCredentialSchema = z.object({
  id: z.string(),
});

export type BaseCredential = z.infer<typeof baseCredentialSchema>;

export const resourceMetadataScheme = z.object({
  resourceId: z.string(),
  status: z.union([
    z.literal("healthy"),
    z.literal("degraded"),
    z.literal("error"),
    z.literal("initializing"),
    z.literal("awaiting_first_sync"),
    z.literal("inactive"),
  ]),
  rowCount: z.number(),
  cycleDurationFormatted: z.union([z.string(), z.null()]),
  lastJobSucceededAt: z.any(),
  platformStatus: z.union([
    z.object({ status: z.literal("ok") }),
    z.object({ status: z.literal("degraded"), since: z.string() }),
    z.object({ status: z.literal("severely_degraded"), since: z.string() }),
    z.object({ status: z.literal("error"), message: z.string() }),
  ]),
});

export type ResourceMetadata = z.infer<typeof resourceMetadataScheme>;

export const resourcePendingBackfillSchema = z.object({
  status: z.literal("pending"),
  tableId: z.string(),
});

export const resourceActiveBackfillSchema = z.object({
  rows: z.number(),
  status: z.literal("active"),
  startedAt: z.string().nullable().optional(),
  tableId: z.string(),
});

export type ResourceActiveBackfill = z.infer<
  typeof resourceActiveBackfillSchema
>;

export const resourceCompletedBackfillSchema = z.object({
  rows: z.number(),
  status: z.literal("completed"),
  tableId: z.string(),
  startedAt: z.string(),
  updatedAt: z.string(),
});

export type ResourceCompletedBackfill = z.infer<
  typeof resourceCompletedBackfillSchema
>;

export const resourceBackfillSchema = z.union([
  resourcePendingBackfillSchema,
  resourceActiveBackfillSchema,
  resourceCompletedBackfillSchema,
]);

export type ResourceBackfill = z.infer<typeof resourceBackfillSchema>;

export const airtableBaseSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type AirtableBase = z.infer<typeof airtableBaseSchema>;

export const airtableBaseTableSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type AirtableBaseTable = z.infer<typeof airtableBaseTableSchema>;

export const upstreamInfo = z.any();
export type UpstreamInfo = z.infer<typeof upstreamInfo>;

export const credentialUpstreamInfo = z.object({
  label: z.string().nullable(),
});
export type CredentialUpstreamInfo = z.infer<typeof credentialUpstreamInfo>;

export const platformTable = z.object({
  id: z.string(),
  name: z.string().nullable().optional(),
  // db_name is currently only returned during update
  db_name: z.string().optional(),
  meta: z.any().optional(),
});
export type PlatformTable = z.infer<typeof platformTable>;

export const resourceTableSyncing = z.object({
  id: z.string(),
  dbName: z.string(),
  providerName: z.string().nullable().optional(),
  meta: z.any().optional(),
});
export type ResourceTableSyncing = z.infer<typeof resourceTableSyncing>;

export const platformTables = z.array(platformTable);
export type PlatformTables = z.infer<typeof platformTables>;

// Lots of optionals and nullable. Schema is very inconsistent.
export const platformFieldSchema = z
  .object({
    accessor: z.string(),
    constraints: z.array(z.any()).optional().nullable(),
    db_name: z.string(),
    default: z.any().optional().nullable(),
    foreign_key: z.any().optional().nullable(),
    id: z.string(),
    index: z.any().optional().nullable(),
    meta: z.any(),
    table_id: z.string().optional().nullable(),
    type: z.string(),
  })
  .nonstrict();
export type PlatformField = z.infer<typeof platformFieldSchema>;

export const platformTableSchemaSchema = z
  .object({
    db_name: z.string(),
    fields: z.array(platformFieldSchema),
    id: z.string(),
    primary_key: z
      .object({
        ids: z.array(z.string()),
        name: z.string(),
      })
      .optional()
      .nullable(),
    provider_name: z.string().optional(),
    slug: z.string().optional().nullable(),
  })
  .nonstrict();

export type PlatformTableSchema = z.infer<typeof platformTableSchemaSchema>;

export const schemaCreationQueriesSchema = z.array(z.string());

export type SchemaCreationQueries = z.infer<typeof schemaCreationQueriesSchema>;

const messageQueueCredentialsSchema = z.object({
  id: z.string(),
  password: z.string(),
  username: z.string(),
});

const messageQueueDefinitionSchema = z.object({
  region: z.string(),
  restEndpoint: z.string(),
  tcpEndpoint: z.string(),
});

export const messageQueueSchema = z.object({
  id: z.string(),
  credentials: messageQueueCredentialsSchema,
  definition: messageQueueDefinitionSchema,
  name: z.string(),
});

export type MessageQueue = z.infer<typeof messageQueueSchema>;

export const messageQueuesSchema = z.array(messageQueueSchema);
export type MessageQueues = z.infer<typeof messageQueuesSchema>;

export const platformSchemaSchema = z
  .object({
    meta: z.any(),
    version: z.any().optional().nullable(),
    tables: z.record(platformTableSchemaSchema),
  })
  .nonstrict();
export type PlatformSchema = z.infer<typeof platformSchemaSchema>;

export const columnDiffSchema = z.object({
  db_name: z.string(),
  type: z.union([z.literal("added"), z.literal("removed")]),
});
export type ColumnDiff = z.infer<typeof columnDiffSchema>;

export const tableDiffSchema = z.object({
  provider_name: z.string(),
  type: z.union([
    z.literal("added"),
    z.literal("removed"),
    z.literal("changed"),
  ]),

  columns: z.array(columnDiffSchema).optional(),
});
export type TableDiff = z.infer<typeof tableDiffSchema>;

export const tableSelectedMutationSchema = z.object({
  kind: z.literal("table_selected"),
  table_provider_name: z.string(),
  type: z.union([z.literal("added"), z.literal("removed")]),
});

export type TableSelectedMutation = z.infer<typeof tableSelectedMutationSchema>;

export const columnSelectedMutationSchema = z.object({
  kind: z.literal("column_selected"),
  table_provider_name: z.string(),
  column_accessor: z.string(),
  type: z.union([z.literal("added"), z.literal("removed")]),
});

export type ColumnSelectedMutation = z.infer<
  typeof columnSelectedMutationSchema
>;

export const columnPatchMutationSchema = z.object({
  kind: z.literal("column_patch"),
  table_provider_name: z.string(),
  column_accessor: z.string(),
  patch: z.any(),
});

export type ColumnPatchMutation = z.infer<typeof columnPatchMutationSchema>;

export const schemaMutationSchema = z.union([
  tableSelectedMutationSchema,
  columnSelectedMutationSchema,
  columnPatchMutationSchema,
]);

export type SchemaMutation = z.infer<typeof schemaMutationSchema>;

export const transformsSchema = z
  .object({
    selected_tables: z.array(z.string()),
    selected_columns: z.record(z.array(z.string())),
    columns: z.record(z.record(z.any().nullable())),
  })
  .nonstrict();

export const schemaDiffSchema = z.object({
  mutations: z.record(z.array(schemaMutationSchema)),
  result: transformsSchema,
});

export type SchemaDiff = z.infer<typeof schemaDiffSchema>;

export const refreshSchemaStatusSchema = z.enum([
  "refreshing",
  "success",
  "failed",
  "not_started",
  "timeout",
]);
export type RefreshSchemaStatus = z.infer<typeof refreshSchemaStatusSchema>;

export const createResourceStatusesSchema = z.object({
  state: z.enum(["pending", "error", "completed"]),
  resource_id: z.string().nullable(),
});
export type CreateResourceStatus = z.infer<typeof createResourceStatusesSchema>;

// Result type and API/Validation error types
const baseServerFieldErrorSchema = z.record(z.array(z.string()));

// with this definition we can only parse errors for forms max. 2 levels deep
// not sure if we can have recursive types here
const validationFieldErrorsSchema = z.union([
  z.array(z.string()),
  baseServerFieldErrorSchema,
  z.array(baseServerFieldErrorSchema),
]);

const validationErrorsSchema = z.record(validationFieldErrorsSchema).optional();

export const apiErrorSchema = z.object({
  ok: z.literal(false),
  // this seems to be placed in the wrong place
  // so I just made another optional prop in the error object as well to avoid breaking something
  who: z.string().optional(),
  error: z.object({
    who: z.string().optional(),
    summary: z.string(),
    validation_errors: validationErrorsSchema,
    slug: z.string().optional(),
  }),
});

export const resourceStatusLevel = z.enum([
  "customer_intervention",
  "degraded",
  "healthy",
  "sequin_intervention",
]);

export type ResourceStatusLevel = z.infer<typeof resourceStatusLevel>;

export const resourceStatusSchema = z.object({
  instructions: z.string(),
  lastActivityAt: z.string().nullable(),
  level: resourceStatusLevel,
  message: z.string(),
  resourceId: z.string(),
});

export type ResourceStatus = z.infer<typeof resourceStatusSchema>;

export const resourceCollectionStatusSchema = z.object({
  failingSince: z.string().nullable(),
  jobKind: z.enum(["delta", "backfill"]),
  lastActivityAt: z.string().nullable(),
  lastCompletedAt: z.string().nullable(),
  level: resourceStatusLevel,
  reason: z.enum([
    "api_permission",
    "constraint_violation",
    "database_permission",
    "database_connectivity",
    "deadlock",
    "healthy",
    "invalid_data",
    "new_failure",
    "pending_migrations",
    "rate_limit",
    "scheduler",
    "slow",
    "timeout",
    "unknown",
    "upstream_issue",
    "view_conflict",
  ]),
  tableId: z.string(),
  instructions: z.string().nullable(),
  message: z.string().nullable(),
  averageCycleTimeMs: z.number().nullable().optional(),
});

export type ResourceCollectionStatus = z.infer<
  typeof resourceCollectionStatusSchema
>;

export type BaseServerFieldError = z.infer<typeof baseServerFieldErrorSchema>;
export type ValidationFieldErrors = z.infer<typeof validationFieldErrorsSchema>;
export type ValidationErrors = z.infer<typeof validationErrorsSchema>;
export type ApiError = z.infer<typeof apiErrorSchema>;

// normalized error types (react-hook-form)
export type FieldValidationError = {
  name: string;
  errors: string[];
};

// normalized error types (Antd)
export type AntdFieldValidationError = {
  name: string | string[];
  errors: string[];
};

export enum LoginStatus {
  Loading = "Loading",
  LoggedIn = "LoggedIn",
  LoggedOut = "LoggedOut",
}
