import { CogIcon } from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import React from "react";
import { Controller, useFormState, useWatch } from "react-hook-form";
import { AirtableState } from "lib/platforms/airtable";
import Collapse from "components/core/Collapse";
import Switch from "components/core/Switch";
import { StepProps } from "components/platforms/common/forms/util";
import { StatusDot } from "lib/lucidez/components/StatusDot";

export default function AirtableAdvancedStep({
  collapsed,
  onToggleCollapsed,
}: StepProps) {
  const useSRL = useWatch<AirtableState>({
    name: "usePreferSingleRecordLinks",
  });

  return (
    <Collapse
      title="Advanced"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      icon={<CogIcon className="h-6 w-6" />}
    >
      <div className="grid grid-flow-row gap-4">
        <Controller
          name="usePreferSingleRecordLinks"
          render={({ field }) => (
            <Switch
              checked={field.value}
              onChange={() => field.onChange(!field.value)}
            >
              <Switch.Label>
                Intelligently cast Linked Records to <code>text</code> instead
                of <code>text[]</code>
              </Switch.Label>
              <Switch.Help>
                <h4 className="font-bold">What is this?</h4>
                <p>
                  By default, Linked Record fields on Airtable can refer to
                  multiple records. However, you can turn this off on Airtable
                  by editing a Linked Record field and toggling off "Allow
                  linking to multiple records."
                  <br />
                  <br />
                  We <b>recommend</b> you <b>enable</b> this setting (default).
                  When this setting is enabled, we'll read this toggle on
                  Airtable to determine whether Linked Record fields should be{" "}
                  <code>text</code> (single records) or <code>text[]</code>{" "}
                  (multiple records). <code>text</code> columns are easier to
                  work with in Postgres than <code>text[]</code> columns.
                  <br />
                  <br />
                  When this setting is <b>disabled</b>, all Linked Record fields
                  will be cast to <code>text[]</code>, no matter the quantity of
                  records linked.
                </p>
              </Switch.Help>
            </Switch>
          )}
        />

        <Tippy
          content={
            <p>
              Foreign keys requires “Intelligently cast Linked Records” to be
              enabled.
            </p>
          }
          placement="bottom"
          hideOnClick={false}
          className={useSRL ? "hidden" : ""}
        >
          <Controller
            name="lazyForeignKeys"
            render={({ field }) => (
              <Switch
                disabled={!useSRL}
                checked={useSRL && field.value}
                onChange={() => field.onChange(!field.value)}
              >
                <Switch.Label>Foreign keys</Switch.Label>
                <Switch.Help>
                  <h4 className="font-bold">What is this?</h4>
                  <p>
                    When enabled, this setting adds <b>foreign keys</b> to your
                    database. Foreign keys will be used for all{" "}
                    <i>single record link</i> fields.
                  </p>
                  <p>
                    Foreign keys requires Intelligently cast Linked Records to
                    text to be enabled.
                  </p>
                </Switch.Help>
              </Switch>
            )}
          />
        </Tippy>
      </div>
    </Collapse>
  );
}

export const FullPageAirtableAdvancedStep = () => {
  const useSRL = useWatch<AirtableState>({
    name: "usePreferSingleRecordLinks",
  });

  const formState = useFormState<AirtableState>({
    name: ["usePreferSingleRecordLinks", "lazyForeignKeys"],
  });

  const isDirty =
    formState &&
    formState.dirtyFields &&
    (formState.dirtyFields.usePreferSingleRecordLinks ||
      formState.dirtyFields.lazyForeignKeys);

  return (
    <div className="grid grid-flow-row gap-4">
      <h3 className="text-xs font-bold mb-6">
        Advanced {isDirty && <StatusDot variant="amber" className="ml-1" />}
      </h3>
      <Controller
        name="usePreferSingleRecordLinks"
        render={({ field }) => (
          <Switch
            checked={field.value}
            onChange={() => field.onChange(!field.value)}
          >
            <Switch.Label>
              Intelligently cast Linked Records to <code>text</code> instead of{" "}
              <code>text[]</code>
            </Switch.Label>
            <Switch.Help>
              <h4 className="font-bold">What is this?</h4>
              <p>
                By default, Linked Record fields on Airtable can refer to
                multiple records. However, you can turn this off on Airtable by
                editing a Linked Record field and toggling off "Allow linking to
                multiple records."
                <br />
                <br />
                We <b>recommend</b> you <b>enable</b> this setting (default).
                When this setting is enabled, we'll read this toggle on Airtable
                to determine whether Linked Record fields should be{" "}
                <code>text</code> (single records) or <code>text[]</code>{" "}
                (multiple records). <code>text</code> columns are easier to work
                with in Postgres than <code>text[]</code> columns.
                <br />
                <br />
                When this setting is <b>disabled</b>, all Linked Record fields
                will be cast to <code>text[]</code>, no matter the quantity of
                records linked.
              </p>
            </Switch.Help>
          </Switch>
        )}
      />

      <Tippy
        content={
          <p>
            Foreign keys requires “Intelligently cast Linked Records” to be
            enabled.
          </p>
        }
        placement="bottom"
        hideOnClick={false}
        className={useSRL ? "hidden" : ""}
      >
        <Controller
          name="lazyForeignKeys"
          render={({ field }) => (
            <Switch
              disabled={!useSRL}
              checked={useSRL && field.value}
              onChange={() => field.onChange(!field.value)}
            >
              <Switch.Label>Foreign keys</Switch.Label>
              <Switch.Help>
                <h4 className="font-bold">What is this?</h4>
                <p>
                  When enabled, this setting adds <b>foreign keys</b> to your
                  database. Foreign keys will be used for all{" "}
                  <i>single record link</i> fields.
                </p>
                <p>
                  Foreign keys requires Intelligently cast Linked Records to
                  text to be enabled.
                </p>
              </Switch.Help>
            </Switch>
          )}
        />
      </Tippy>
    </div>
  );
};
