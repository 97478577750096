import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Disclosure, Listbox, Switch } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckIcon,
  ChevronRightIcon,
  DocumentDuplicateIcon,
  LinkIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  PlusIcon,
  RefreshIcon,
  SelectorIcon,
  TableIcon,
  ExclamationCircleIcon as ExclamationCircleIconOutlined,
} from "@heroicons/react/outline";
import {
  ChevronDownIcon,
  CogIcon,
  ExclamationCircleIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import _ from "lodash";
import { toast } from "react-toastify";
import { match } from "ts-pattern";

import {
  usePlatformTables,
  usePlatformTableSchema,
  useRefreshSchemaStatus,
  useResources,
  useSchemaDiff,
} from "lib/api/hooks";
import { BaseState } from "lib/platforms/base";
import Collapse from "components/core/Collapse";
import SvgSpinner from "components/svg/Spinner";
import {
  SelectColumnsForm,
  useColumnsFormReducer,
} from "components/platforms/common/forms/SelectColumnsStep";
import {
  FieldState,
  StepProps,
  useValidation,
} from "components/platforms/common/forms/util";
import {
  ColumnPatchMutation,
  ColumnSelectedMutation,
  PlatformTables,
  SchemaDiff,
  SchemaMutation,
  TableSelectedMutation,
} from "lib/api/types";
import { Platform, Resource, ResourceKind } from "lib/platforms";
import Modal from "components/core/Modal";
import { useResourceFromContext } from "components/platforms/common/ResourceForm";
import { refreshSchema } from "lib/api";
import Steps from "components/core/Steps";
import { Button } from "lib/lucidez";
import { useFilter } from "lib/utils/useFilter";
import { replaceDotsWithEyesEmojiInTableIds } from "lib/utils/reactHookForm";
import { FilterBox } from "components/core/FilterBox";

// The type of link between two tables
// if one way, means changes are not replicated
// if two way, means changes are replicated
export enum LinkWay {
  OneWay,
  TwoWay,
}

interface TableLink {
  tableId: string;
  linkedTableId: string;
  way: LinkWay;
}

type Props = {
  helperText?: string;
  switchLabel?: string;
  tableGroups?: {
    id: string;
    name: string;
    tables: string[];
  }[];
  tableLinks?: TableLink[];
};

type OnConfigureTable = (table: { id?: string; name?: string }) => void;

export default function SelectTablesStep({
  platform,
  collapsed,
  onToggleCollapsed,
  helperText = "Select the tables to sync.",
  switchLabel = "Sync all tables",
  tableGroups,
  tableLinks = [],
  children,
  isCreate,
}: StepProps &
  Props & {
    children?: (tables: PlatformTables) => React.ReactNode;
  }) {
  const watchArray =
    platform.kind === "airtable" ? ["credential", "baseId"] : ["credential"];
  const [credential, ...state] = useWatch({ name: watchArray });

  const params = platform.extraTableParams(state);
  const resource = useResourceFromContext();

  const [tables, mutate, error] = usePlatformTables(
    credential?.id,
    params,
    resource?.id,
    {
      revalidateOnFocus: false,
    }
  );
  const filteredTables = useMemo(
    () =>
      platform.kind === "hubspot" && tables
        ? tables.filter((x) => !x.meta?.platform_metadata?.association)
        : tables,
    [tables, platform.kind]
  );

  const [isRetrying, setIsRetrying] = useState(false);

  const getDisabledTooltip = () => {
    if (!credential || !params) {
      return "Before continuing, please enter your credentials above";
    }

    return undefined;
  };

  return (
    <Collapse
      title="Select tables and columns"
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      disabledTooltip={getDisabledTooltip()}
      icon={<TableIcon className="h-6 w-6" />}
      collapsedDesc={<p className="truncate"></p>}
    >
      {error && !isRetrying ? (
        <div className="w-full flex flex-col justify-center items-center p-4 text-center">
          <XCircleIcon className="h-9 w-9 text-error block" />
          <p className="mt-2">
            An unknown error happened while trying to load tables from your
            account.
          </p>
          <Button
            variant="primary"
            size="md"
            className="mt-2"
            onClick={async () => {
              setIsRetrying(true);
              await mutate();
              setIsRetrying(false);
            }}
          >
            Try again
          </Button>
        </div>
      ) : isRetrying || !filteredTables ? (
        <div className="w-full flex flex-col justify-center items-center p-4">
          <SvgSpinner className="h-8 w-8 animate-spin" />
          <p className="mt-2">This may take a minute or two.</p>
        </div>
      ) : (
        <>
          <Inner
            selectableTables={filteredTables}
            helperText={helperText}
            switchLabel={switchLabel}
            tableGroups={tableGroups}
            tableLinks={tableLinks}
            platform={platform}
            onRefresh={async () => {
              await mutate();
            }}
            isCreate={isCreate}
          />
          {children && children(tables)}
        </>
      )}
    </Collapse>
  );
}

const Inner = ({
  selectableTables,
  tableGroups,
  helperText,
  switchLabel,
  tableLinks,
  platform,
  isCreate,
  onRefresh,
}: {
  platform: Platform<any, any, any>;
  selectableTables: PlatformTables;
  isCreate: boolean;
  onRefresh: () => Promise<void>;
} & Props) => {
  const { setValue } = useFormContext<BaseState<any>>();

  const syncAll = useWatch<BaseState<any>>({ name: "syncAllTables" });
  const allowSyncAll = useAllowSyncAll();

  const getOtherKeys = () => {
    if (!tableGroups || !selectableTables) {
      return [];
    }

    const grouped = tableGroups.flatMap((x) => x.tables);
    return selectableTables.filter((x) => !grouped.includes(x.id));
  };

  const getTableById = (id: string) => {
    return selectableTables.find((x) => x.id === id);
  };

  const buildGroupTree = () => {
    if (
      !tableGroups ||
      !selectableTables ||
      selectableTables.length < tableGroups.length
    ) {
      return [];
    }

    return [
      ...tableGroups.map((tg) => ({
        ...tg,
        tables: tg.tables.map((x) => getTableById(x)),
      })),
      {
        id: "other",
        name: "Other",
        tables: getOtherKeys(),
      },
    ];
  };

  const groupTree = useMemo(
    () => buildGroupTree(),
    [tableGroups, selectableTables]
  );

  const [configuringTable, setConfiguringTable] = useState<
    string | undefined
  >();

  const handleTableToggle = (
    tables: {
      tableId: string;
      selected: boolean;
    }[]
  ) => {
    tables.forEach((table) =>
      setValue(`tables.${table.tableId}.enabled`, table.selected)
    );
  };

  return (
    <>
      <p className="text-xs mb-4">{helperText}</p>
      <SyncAllSwitch switchLabel={switchLabel} allowSyncAll={allowSyncAll} />
      {selectableTables && (
        <div className="mt-4 grid grid-flow-row gap-2">
          {tableGroups ? (
            groupTree.map((tg) => {
              if (tg.id !== "_HIDDEN") {
                return (
                  <TableGroup
                    key={tg.id}
                    group={tg}
                    onToggleTable={handleTableToggle}
                    syncAll={syncAll && allowSyncAll}
                    onConfigureTable={(t) => setConfiguringTable(t.id)}
                    tableLinks={tableLinks}
                  />
                );
              }
            })
          ) : (
            <EditTables
              onToggleTable={handleTableToggle}
              selectableTables={selectableTables}
              syncAll={syncAll && allowSyncAll}
              onConfigureTable={(t) => setConfiguringTable(t.id)}
              tableLinks={tableLinks}
              additionalHelper={
                <div className="flex flex-row gap-1 py-2 border-t border-b mb-4">
                  {!isCreate && <CopyConfigButton platform={platform} />}

                  {!isCreate && (
                    <RefreshSchemaHelper
                      onRefresh={onRefresh}
                      platform={platform}
                    />
                  )}
                </div>
              }
            />
          )}
        </div>
      )}

      {configuringTable && (
        <SelectColumnsModal
          onClose={() => setConfiguringTable(undefined)}
          platform={platform}
          editingTableId={configuringTable}
        />
      )}
    </>
  );
};

export const FullPageSelectTablesStep = ({
  platform,
  helperText = "Select the tables to sync.",
  switchLabel = "Sync all tables",
  tableGroups,
  tableLinks = [],
  children,
  isCreate,
  isOnboard = false,
}: StepProps &
  Props & {
    children?: (tables: PlatformTables) => React.ReactNode;
    isOnboard?: boolean;
  }) => {
  const watchArray =
    platform.kind === "airtable" ? ["credential", "baseId"] : ["credential"];
  const [credential, ...state] = useWatch({ name: watchArray });

  const params = platform.extraTableParams(state);
  const resource = useResourceFromContext();

  const [tables, mutate, error] = usePlatformTables(
    credential?.id,
    params,
    resource?.id,
    {
      revalidateOnFocus: false,
    }
  );
  const filteredTables = useMemo(
    () =>
      platform.kind === "hubspot" && tables
        ? tables.filter((table) => !table.meta?.platform_metadata?.association)
        : tables,
    [tables, platform.kind]
  );

  const [isRetrying, setIsRetrying] = useState(false);

  return (
    <div className="border rounded">
      {error && !isRetrying ? (
        <div className="w-full flex flex-col justify-center items-center p-4 text-center">
          <XCircleIcon className="h-9 w-9 text-error block" />
          <p className="mt-2">
            An unknown error happened while trying to load tables from your
            account.
          </p>
          <Button
            variant="primary"
            size="md"
            className="mt-2"
            onClick={async () => {
              setIsRetrying(true);
              await mutate();
              setIsRetrying(false);
            }}
          >
            Try again
          </Button>
        </div>
      ) : isRetrying || !filteredTables ? (
        <div className="w-full flex flex-col justify-center items-center p-4">
          <SvgSpinner className="h-8 w-8 animate-spin" />
          <p className="mt-2">This may take a minute or two.</p>
        </div>
      ) : (
        <>
          <FullPageInner
            selectableTables={filteredTables}
            helperText={helperText}
            switchLabel={switchLabel}
            tableGroups={tableGroups}
            tableLinks={tableLinks}
            platform={platform}
            onRefresh={async () => {
              await mutate();
            }}
            isCreate={isCreate}
            isOnboard={isOnboard}
          />
          {children && children(tables)}
        </>
      )}
    </div>
  );
};

const FullPageInner = ({
  selectableTables,
  tableGroups,
  switchLabel,
  tableLinks,
  platform,
  isCreate,
  onRefresh,
  isOnboard,
}: {
  platform: Platform<any, any, any>;
  selectableTables: PlatformTables;
  isCreate: boolean;
  onRefresh: () => Promise<void>;
} & Props & { isOnboard: boolean }) => {
  const { setValue } = useFormContext<BaseState<any>>();

  const [filter, setFilter] = useState("");

  const syncAll = useWatch<BaseState<any>>({ name: "syncAllTables" });
  const allowSyncAll = useAllowSyncAll();

  const getOtherKeys = () => {
    if (!tableGroups || !selectableTables) {
      return [];
    }

    const grouped = tableGroups.flatMap((tableGroup) => tableGroup.tables);
    return selectableTables.filter((table) => !grouped.includes(table.id));
  };

  const getTableById = (id: string) => {
    return selectableTables.find((table) => table.id === id);
  };

  const buildGroupTree = () => {
    if (
      !tableGroups ||
      !selectableTables ||
      selectableTables.length < tableGroups.length
    ) {
      return [];
    }

    return [
      ...tableGroups.map((tg) => ({
        ...tg,
        tables: tg.tables.map((table) => getTableById(table)),
      })),
      {
        id: "other",
        name: "Other",
        tables: getOtherKeys(),
      },
    ];
  };

  const groupTree = useMemo(
    () => buildGroupTree(),
    [tableGroups, selectableTables]
  );

  const [configuringTable, setConfiguringTable] = useState<
    string | undefined
  >();

  const handleTableToggle = (
    tables: {
      tableId: string;
      selected: boolean;
    }[]
  ) => {
    tables.forEach((table) =>
      setValue(`tables.${table.tableId}.enabled`, table.selected)
    );
  };

  return (
    <>
      <div className="text-xs px-6 pb-6 pt-3">
        <FilterBox
          filter={filter}
          setFilter={setFilter}
          label="Search collections"
        />
      </div>
      <SyncAllSwitch switchLabel={switchLabel} allowSyncAll={allowSyncAll} />
      {selectableTables && (
        <div className="grid grid-flow-row gap-2">
          {tableGroups ? (
            groupTree.map((tg) => {
              if (tg.id !== "_HIDDEN") {
                return (
                  <TableGroup
                    key={tg.id}
                    group={tg}
                    onToggleTable={handleTableToggle}
                    syncAll={syncAll && allowSyncAll}
                    onConfigureTable={(t) => setConfiguringTable(t.id)}
                    tableLinks={tableLinks}
                  />
                );
              }
            })
          ) : (
            <>
              {isOnboard ? (
                <OnboardEditTables
                  platform={platform}
                  onToggleTable={handleTableToggle}
                  selectableTables={selectableTables}
                  syncAll={syncAll && allowSyncAll}
                  onConfigureTable={(t) => setConfiguringTable(t.id)}
                  tableLinks={tableLinks}
                  additionalHelper={
                    <div className="flex flex-row gap-1 p-4 border-t border-b">
                      {!isCreate && <CopyConfigButton platform={platform} />}

                      {!isCreate && (
                        <RefreshSchemaHelper
                          onRefresh={onRefresh}
                          platform={platform}
                        />
                      )}
                    </div>
                  }
                  filter={filter}
                />
              ) : (
                <FullPageEditTables
                  platform={platform}
                  onToggleTable={handleTableToggle}
                  selectableTables={selectableTables}
                  syncAll={syncAll && allowSyncAll}
                  onConfigureTable={(t) => setConfiguringTable(t.id)}
                  tableLinks={tableLinks}
                  additionalHelper={
                    <div className="flex flex-row gap-1 p-4 border-t border-b">
                      {!isCreate && <CopyConfigButton platform={platform} />}

                      {!isCreate && (
                        <RefreshSchemaHelper
                          onRefresh={onRefresh}
                          platform={platform}
                        />
                      )}
                    </div>
                  }
                  filter={filter}
                />
              )}
            </>
          )}
        </div>
      )}

      {configuringTable && (
        <SelectColumnsModal
          onClose={() => setConfiguringTable(undefined)}
          platform={platform}
          editingTableId={configuringTable}
        />
      )}
    </>
  );
};

const CopyConfigButton = ({
  platform,
}: {
  platform: Platform<any, any, any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resources] = useResources();

  const { getValues, setValue, resetField } = useFormContext<BaseState<any>>();

  const currentResource = useResourceFromContext();

  // filter to show resources only of the same kind
  const filteredResources = resources
    ? resources.filter(
        (r) => r.kind === platform.kind && r.id !== currentResource?.id
      )
    : [];

  const [currentStep, setCurrentStep] = useState<"select" | "review">("select");
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(
    null
  );

  const selectedResource = useMemo(
    () =>
      filteredResources.find((r) => r.id === selectedResourceId) ||
      filteredResources[0],
    [filteredResources, selectedResourceId]
  );

  const handleCopy = (newTransforms) => {
    const nextTables = newTransforms.selected_tables.reduce((acc, tbl) => {
      return {
        ...acc,
        [tbl]: {
          enabled: true,
        },
      };
    }, {});

    // Reset to state of the resource schema
    resetField("tables");
    resetField("columns");
    resetField("selectedColumns");

    const currentTables = getValues("tables");

    setValue("tables", nextTables);

    // Disable the ones to be disable
    // that way a re-render is triggered to the
    // rhf component and updated in the UI
    const tablesKeys = Object.keys(currentTables);
    const disabledKeys = tablesKeys.filter(
      (x) => currentTables[x].enabled && !nextTables[x]?.enabled
    );

    disabledKeys.forEach((key) => {
      setValue(`tables.${key}.enabled`, false);
    });

    setValue("columns", newTransforms.columns);
    setValue("selectedColumns", newTransforms.selected_columns);

    setValue("syncAllTables", newTransforms.selected_tables.length === 0);

    window.analytics.track("Copy Config Finish Clicked");

    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="xs"
        disabled={!filteredResources.length}
        onClick={() => {
          window.analytics.track("Copy Config Clicked");

          setIsOpen(true);
          setCurrentStep("select");
        }}
        iconLeft={<DocumentDuplicateIcon className="w-4 h-4" />}
      >
        Copy config from...
      </Button>

      {isOpen && (
        <Modal
          onClose={() => setIsOpen(false)}
          containerClassName="w-full custom-container max-w-xl py-32 lg:px-16"
          cardClassName="transform bg-white rounded shadow-xl p-8"
        >
          <Steps activeStep={currentStep}>
            <Steps.Step stepKey="select">
              <button
                className="text-gray-600 hover:text-black focus:text-black"
                onClick={() => {
                  window.analytics.track("Copy Config Back Clicked");

                  setIsOpen(false);
                }}
              >
                <ArrowLeftIcon className="h-6 w-6" />
              </button>
              {resources ? (
                <SelectResourceStep
                  resources={filteredResources}
                  onSelect={(id) => {
                    setSelectedResourceId(id);
                    setCurrentStep("review");
                  }}
                />
              ) : (
                <div className="text-center py-4">
                  <SvgSpinner className="animate-spin h-6 w-6" />
                </div>
              )}
            </Steps.Step>
            <Steps.Step stepKey="review">
              <button
                className="text-gray-600 hover:text-black focus:text-black"
                onClick={() => setCurrentStep("select")}
              >
                <ArrowLeftIcon className="h-6 w-6" />
              </button>
              <ReviewStep
                selectedResource={selectedResource}
                onCopy={handleCopy}
              />
            </Steps.Step>
          </Steps>
        </Modal>
      )}
    </>
  );
};

const SelectResourceStep = ({
  resources,
  onSelect,
}: {
  resources: Resource[];
  onSelect: (id: string) => void;
}) => {
  return (
    <>
      <Modal.Title className="text-2xl font-bold mt-6 mb-8">
        Copy config from another sync
      </Modal.Title>
      <Listbox
        onChange={(v: Resource) => {
          onSelect(v.id);
        }}
        value={null}
      >
        <div className="flex flex-col">
          <Listbox.Label className="text-xs">
            Select a sync to copy the table and column config from:
          </Listbox.Label>

          <div className="relative w-full">
            <Listbox.Button className="mt-2 relative py-2 pl-3 pr-10 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black sm:text-xs border w-full">
              <span className="block truncate">Select a sync</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
              {resources.map((resource) => (
                <Listbox.Option
                  key={resource.id}
                  className={({ active }) =>
                    `${active ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                  cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={resource}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {resource.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </div>
      </Listbox>
    </>
  );
};

const ReviewStep = ({
  selectedResource,
  onCopy,
}: {
  selectedResource: Resource;
  onCopy: (newTransforms: any) => void;
}) => {
  const currentResource = useResourceFromContext();
  const [diff] = useSchemaDiff(currentResource?.id, selectedResource.id, {
    revalidateOnMount: true,
  });

  return (
    <>
      <Modal.Title className="text-2xl font-bold mt-6 mb-8">
        Review changes
      </Modal.Title>

      <div className="flex flex-col border rounded justify-between px-4 py-2">
        <span className="text-xs font-bold">Copy from</span>
        <span className="text-xs">
          {selectedResource.name}{" "}
          <span className="text-gray-400">
            (syncing to{" "}
            {selectedResource.database.name ||
              selectedResource.database.definition.dbname}
            )
          </span>
        </span>
      </div>

      {currentResource ? (
        <div className="mt-8">
          <p className="font-bold">Changes</p>
          <p className="mt-1 text-xs">
            The following changes will be applied to your sync.
          </p>
          <div className="grid grid-cols-1 gap-1 mt-4">
            {diff ? (
              Object.keys(diff.mutations).length > 0 ? (
                <DiffDisplay diff={diff} resource={currentResource} />
              ) : (
                <p className="text-xs mt-2 text-center p-2 bg-gray-50">
                  No changes in the config between the two syncs
                </p>
              )
            ) : (
              <div className="text-center">
                <SvgSpinner className="h-6 w-6 animate-spin inline-block" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <p className="font-bold">Changes</p>
          <p className="mt-1 text-xs">
            Your sync will be created with the same tables and columns as the
            sync you selected.
            <br />
            <br />
            You will be able edit those settings before creating the sync.
          </p>
        </div>
      )}

      <div className="flex flex-row justify-end mt-8">
        <Button variant="primary" size="md" onClick={() => onCopy(diff.result)}>
          Copy
        </Button>
      </div>
    </>
  );
};

const DiffDisplay = ({
  diff,
  resource,
}: {
  diff: SchemaDiff;
  resource: Resource;
}) => {
  return (
    <>
      {Object.entries(diff.mutations).map(([tableId, mutations]) => {
        return (
          <TableDiffView
            diffs={mutations}
            tableId={tableId}
            key={tableId}
            resource={resource}
          />
        );
      })}
    </>
  );
};

type ColumnMutation = ColumnSelectedMutation | ColumnPatchMutation;

const TableDiffView = ({
  diffs,
  tableId,
  resource,
}: {
  diffs: SchemaMutation[];
  tableId: string;
  resource: Resource;
}) => {
  const tableDiff = diffs.find((diff) => diff.kind === "table_selected");
  const otherDiffs = diffs.filter(
    (diff) => diff.kind !== "table_selected"
  ) as ColumnMutation[];

  const columnDiff = _.groupBy(otherDiffs, (el) => el.column_accessor);

  const diffType = tableDiff
    ? (tableDiff as TableSelectedMutation).type
    : "changed";

  const diffTextColor = match(diffType)
    .with("added", () => "text-emerald-500")
    .with("removed", () => "text-rose-600")
    .otherwise(() => "text-yellow-500");

  const diffBadgeColor = match(diffType)
    .with("added", () => "bg-emerald-500")
    .with("removed", () => "bg-rose-600")
    .otherwise(() => "bg-yellow-500");

  const diffText = match(diffType)
    .with("added", () =>
      otherDiffs.length > 0 ? "Added and modified" : "Added"
    )
    .with("removed", () =>
      otherDiffs.length > 0 ? "Modified and removed" : "Removed"
    )
    .otherwise(() => "Modified");

  return (
    <div className={`${diffTextColor} bg-white border rounded`}>
      <Disclosure
        defaultOpen={otherDiffs.length > 0 && otherDiffs.length <= 5}
        key={tableId}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center h-10  px-4">
              <MutationIcon type={diffType} />
              <span className="ml-4 font-mono overflow-hidden overflow-ellipsis">
                {tableId}
              </span>
              <span
                className={`rounded ${diffBadgeColor} text-xxs px-2 py-0.5 text-white ml-2`}
              >
                {diffText}
              </span>
              <span className="flex-1" />

              {open ? (
                <ChevronDownIcon className="h-4 flex-shrink-0" />
              ) : (
                <ChevronRightIcon className="h-4 flex-shrink-0" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className={`px-4 pb-4 flex flex-col gap-1 `}>
                {otherDiffs.length === 0 ? (
                  diffType === "removed" ? (
                    <span className="text-xs">
                      This column will be removed from the database.
                    </span>
                  ) : (
                    <span className="text-xs">Columns not changed.</span>
                  )
                ) : (
                  Object.entries(columnDiff).map(([columnId, mutations]) => {
                    return (
                      <ColumnDiffView
                        diffs={mutations}
                        columnId={columnId}
                        key={columnId}
                        resource={resource}
                      />
                    );
                  })
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

const ColumnDiffView = ({
  diffs,
  columnId,
}: {
  diffs: ColumnMutation[];
  columnId: string;
  resource: Resource;
}) => {
  const columnDiff = diffs.find(
    (diff) => diff.kind === "column_selected"
  ) as ColumnMutation;
  const patchDiff = diffs.find(
    (diff) => diff.kind === "column_patch"
  ) as ColumnPatchMutation;

  const diffType = columnDiff
    ? (columnDiff as ColumnSelectedMutation).type
    : "changed";

  const diffColor = match(diffType)
    .with("added", () => "bg-emerald-50 text-emerald-500")
    .with("removed", () => "bg-red-50 text-rose-600")
    .otherwise(() => "bg-gray-100 text-gray-800");

  const diffBadgeColor = match(diffType)
    .with("added", () => "bg-emerald-500")
    .with("removed", () => "bg-rose-600")
    .otherwise(() => "bg-yellow-500");

  const diffText = match(diffType)
    .with("added", () => "Added")
    .with("removed", () => "Removed")
    .otherwise(() => "Modified");

  return (
    <div className={`${diffColor} rounded`}>
      <div className="flex w-full items-center h-10 px-4">
        <MutationIcon type={diffType} />
        <span
          className={`ml-4 font-mono overflow-hidden overflow-ellipsis ${
            diffType === "removed" ? "line-through" : ""
          }`}
        >
          {columnId}
        </span>
        <span
          className={`rounded ${diffBadgeColor} text-xxs px-2 py-0.5 text-white ml-2`}
        >
          {diffText}
        </span>
        <span className="flex-1" />
      </div>
      {patchDiff && (
        <div className="px-4 pb-2 text-gray-800">
          <div className="bg-white rounded border mt-1 text-xs px-2 py-1">
            {patchDiff.patch && patchDiff.patch.db_name ? (
              <span>
                Rename on database to{" "}
                <span className="font-mono">{patchDiff.patch?.db_name}</span>
              </span>
            ) : (
              <span>Field unlocked</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const MutationIcon = ({ type }: { type: "added" | "removed" | "changed" }) => {
  switch (type) {
    case "added":
      return <PlusCircleIcon className="h-4 w-4 flex-shrink-0" />;

    case "removed":
      return <MinusCircleIcon className="h-4 w-4 flex-shrink-0" />;

    default:
      return (
        <ExclamationCircleIconOutlined className="h-4 w-4 flex-shrink-0" />
      );
  }
};

const RefreshSchemaHelper = ({
  onRefresh,
  platform,
}: {
  onRefresh: () => Promise<void>;
  platform: Platform<any, any, any>;
}) => {
  const res = useResourceFromContext();
  const [status, mutate] = useRefreshSchemaStatus(res.id, {
    refreshInterval: 10_000,
  });

  const [preparingRefresh, setPreparingRefresh] = useState(false);
  const [refreshedInThisSession, setRefreshedInThisSession] = useState(false);

  const parsedStatus =
    status === "refreshing"
      ? "refreshing"
      : refreshedInThisSession
      ? status
      : "not_started";
  const isRefreshing = parsedStatus === "refreshing" || preparingRefresh;

  const handleSuccess = async () => {
    await onRefresh();
    setPreparingRefresh(false);
  };

  useEffect(() => {
    if (parsedStatus === "success") {
      handleSuccess();
    }
  }, [parsedStatus]);

  const handleRefresh = async () => {
    setPreparingRefresh(true);

    toast(
      `Sequin is fetching the list of available tables and columns from ${platform.displayName}. This can take up to 2-3 minutes.`
    );

    await refreshSchema(res.id);
    await mutate("refreshing");

    setRefreshedInThisSession(true);
    setPreparingRefresh(false);
  };

  const RefreshButtonIcon = () => {
    switch (parsedStatus) {
      case "refreshing":
        return <SvgSpinner className="w-4 h-4 animate-spin" />;

      case "success":
        return <CheckIcon className="w-4 h-4 text-success" />;

      case "failed":
        return (
          <Tippy
            content={
              <span className="text-xs font-normal">Refresh failed</span>
            }
            interactive
          >
            <ExclamationCircleIcon className="w-4 h-4 text-error" />
          </Tippy>
        );

      case "timeout":
        return (
          <Tippy
            content={
              <span className="text-xs font-normal">Refresh timed out</span>
            }
            interactive
          >
            <ExclamationCircleIcon className="w-4 h-4 text-error" />
          </Tippy>
        );

      default:
        return <RefreshIcon className="w-4 h-4" />;
    }
  };

  return (
    <Button
      variant="outlined"
      size="xs"
      disabled={isRefreshing}
      iconLeft={<RefreshButtonIcon />}
      onClick={handleRefresh}
    >
      {parsedStatus === "success"
        ? "Refreshed"
        : isRefreshing
        ? "Fetching..."
        : "Refresh"}
    </Button>
  );
};

const SelectColumnsModal = ({
  onClose,
  editingTableId,
  platform,
}: {
  onClose: () => void;
  editingTableId: string;
  platform: Platform<any, any, any>;
}) => {
  const { field: columnsField } = useController<BaseState<any>>({
    name: "columns",
  });

  const { field: selectedColumnsField } = useController<BaseState<any>>({
    name: "selectedColumns",
  });

  const validation = useValidation(FieldState.NotValidated);

  const watchArray =
    platform.kind === "airtable" ? ["credential", "baseId"] : ["credential"];
  const [credential, ...state] = useWatch({ name: watchArray });

  const params = platform.extraTableParams(state);
  const resource = useResourceFromContext();

  const [isClosing, setIsClosing] = useState(false);

  const [schema] = usePlatformTableSchema(
    credential?.id,
    editingTableId,
    params,
    resource?.id,
    {
      revalidateOnFocus: false,
    }
  );

  const reducerBag = useColumnsFormReducer({
    columns: columnsField.value,
    selectedColumns: selectedColumnsField.value,
  });

  const commit = (changes) => {
    columnsField.onChange(changes.columns);
    selectedColumnsField.onChange(changes.selectedColumns);

    onClose();
  };

  return (
    <Modal
      onClose={() => setIsClosing(true)}
      cardClassName="transform bg-white rounded shadow-xl p-8"
    >
      <div className="w-full flex flex-row">
        <Modal.Title className="flex flex-row flex-1 gap-6 items-center">
          <div className="h-12 w-12 rounded-full border border-gray-200 flex flex-row items-center justify-center">
            <platform.displayIcon className="w-6 h-6 text-black" />
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-cool-gray-400 font-bold">
              Collection
            </span>
            <h3 className="text-xl text-black font-bold  mt-1.5">
              {schema
                ? schema.provider_name || schema.db_name || schema.id
                : editingTableId}
            </h3>
          </div>
        </Modal.Title>
        <button className="h-6" onClick={() => setIsClosing(true)}>
          <XCircleIcon className="text-black w-6 h-6" />
        </button>
      </div>
      <div className="mt-6 w-full">
        {schema ? (
          <SelectColumnsForm
            tableSchema={schema}
            state={reducerBag}
            validation={validation}
            editingTableId={editingTableId}
            platform={platform}
          />
        ) : (
          <div className="w-full flex flex-col justify-center items-center p-4">
            <SvgSpinner className="h-8 w-8 animate-spin" />
            <p className="mt-2">Loading table schema...</p>
          </div>
        )}
      </div>
      {isClosing && (
        <Modal
          onClose={() => setIsClosing(false)}
          cardClassName="bg-white rounded p-8 w-full max-w-md"
          containerClassName="flex flex-row items-center justify-center h-screen w-screen"
        >
          <div className="w-full flex flex-row-reverse">
            <button className="" onClick={() => setIsClosing(false)}>
              <XCircleIcon className="text-black w-6 h-6" />
            </button>
          </div>
          <h4 className="text-xl font-bold">You have unsaved changes</h4>
          <p className="mt-2">
            Do you want to keep changes made to this table?
          </p>
          <div className="flex flex-row mt-6 gap-2">
            <div className="flex-1" />
            <Button
              variant="outlined"
              size="md"
              onClick={() => onClose()}
              className="ml-2"
            >
              Discard
            </Button>
            <Button
              variant="primary"
              size="md"
              onClick={() => commit(reducerBag[0])}
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
      <div className="flex flex-row mt-4">
        <div className="flex-1" />
        <Button
          variant="primary"
          size="lg"
          className="mr-2"
          onClick={() => commit(reducerBag[0])}
          disabled={validation.fieldState === FieldState.Invalid}
        >
          Confirm changes
        </Button>
      </div>
    </Modal>
  );
};

// This context is used to enable the legacy syncAll switch for older
// syncs who previously had them enabled
const SyncAllQuirkContext = createContext({
  allowSyncAll: false,
});

export const SyncAllQuirkProvider = ({
  children,
  allowSyncAll,
}: PropsWithChildren<{ allowSyncAll: boolean }>) => {
  return (
    <SyncAllQuirkContext.Provider value={{ allowSyncAll }}>
      {children}
    </SyncAllQuirkContext.Provider>
  );
};

export const useAllowSyncAll = () => {
  const { allowSyncAll } = useContext(SyncAllQuirkContext);

  return allowSyncAll;
};

const SyncAllSwitch = ({
  switchLabel,
  allowSyncAll,
}: {
  switchLabel?: string;
  allowSyncAll: boolean;
}) => {
  const { trigger } = useFormContext<BaseState<any>>();

  const tables = useWatch<BaseState<any>>({ name: "tables" });
  const hasAnyTableEnabled = useMemo(
    () => !!_.find(tables, (v) => v.enabled === true),
    [tables]
  );

  const { field } = useController<BaseState<any>>({
    name: "syncAllTables",
    rules: {
      validate: (value) => (value && allowSyncAll) || hasAnyTableEnabled,
    },
  });

  useEffect(() => {
    if (!field.value) {
      trigger("syncAllTables");
    }
  }, [hasAnyTableEnabled]);

  if (!allowSyncAll) {
    // The switch is gone.
    return <></>;
  }

  return (
    <div className="flex-row flex">
      <div className="flex-1">
        <Switch.Group>
          <div className="flex-row flex items-center">
            <Switch
              checked={field.value}
              onChange={() => {
                field.onChange(!field.value);
              }}
              className={`${
                field.value ? "bg-black" : "bg-gray-300"
              } disabled:opacity-50 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-offset-2 focus:ring-offset-white dark:ring-offset-gray-700 focus:ring-2 focus:ring-black`}
            >
              <span
                className={`${
                  field.value ? "translate-x-6" : "translate-x-1"
                } inline-flex items-center justify-center w-4 h-4 transform bg-white rounded-full transition-transform`}
              ></span>
            </Switch>
            <Switch.Label className="text-black dark:text-white w-48 flex-1 cursor-pointer ml-2 text-xs">
              {switchLabel}
            </Switch.Label>
          </div>
        </Switch.Group>
      </div>
    </div>
  );
};

const TableGroup = ({
  onToggleTable,
  group,
  syncAll,
  onConfigureTable,
  tableLinks,
}: {
  onToggleTable: (tables: { tableId: string; selected: boolean }[]) => void;
  group: {
    tables: {
      id?: string;
      name?: string;
    }[];
    id: string;
    name: string;
  };
  syncAll: boolean;
  onConfigureTable: OnConfigureTable;
  tableLinks: TableLink[];
}) => {
  const checkboxRef = useRef<HTMLInputElement>();

  const tableIds = useMemo(() => group.tables.map((t) => t.id), [group.tables]);
  const watchingKeys = useMemo(
    () => tableIds.map((t) => `tables.${t}.enabled`),
    [tableIds]
  );
  const keys = useWatch({ name: watchingKeys });
  const enabledKeys = keys.filter((k) => k === true);

  const isChecked = enabledKeys.length === group.tables.length;
  const isIndeterminate = enabledKeys.length > 0 && !isChecked;

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate;
    }
  }, [isIndeterminate]);

  const handleSelectAll = () =>
    onToggleTable(group.tables.map((t) => ({ tableId: t.id, selected: true })));

  const handleDeselectAll = () =>
    onToggleTable(
      group.tables.map((t) => ({ tableId: t.id, selected: false }))
    );

  return (
    <div className="w-full text-left px-4 py-3 border rounded">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full text-left rounded flex flex-row items-center">
              <div className="flex-1">
                <input
                  className="form-checkbox rounded-sm text-black ring-black cursor-pointer"
                  type="checkbox"
                  id={`tablegroupcheckbox-${group.id}`}
                  name="tables"
                  ref={checkboxRef}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      handleDeselectAll();
                    } else {
                      handleSelectAll();
                    }
                  }}
                ></input>
                <label
                  htmlFor={`tablegroupcheckbox-${group.id}`}
                  className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer"
                >
                  {group.name}
                </label>
              </div>
              <ChevronDownIcon
                className={`h-6 w-6 text-gray-400 ${
                  open ? "transform rotate-180" : ""
                }`}
              />
            </Disclosure.Button>

            <Disclosure.Panel className="mt-4 border-l pl-4">
              <EditTables
                onToggleTable={onToggleTable}
                selectableTables={group.tables}
                noHelpers
                syncAll={syncAll}
                onConfigureTable={onConfigureTable}
                tableLinks={tableLinks}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

const EditTables = ({
  selectableTables,
  onToggleTable,
  noHelpers,
  syncAll,
  onConfigureTable,
  tableLinks,
  additionalHelper,
}: {
  selectableTables: {
    id?: string;
    name?: string;
  }[];
  onToggleTable: (tables: { tableId: string; selected: boolean }[]) => void;
  noHelpers?: boolean;
  syncAll: boolean;
  onConfigureTable: OnConfigureTable;
  tableLinks: TableLink[];
  additionalHelper?: React.ReactNode;
}) => {
  const sortedSelectableTables = useMemo(
    () =>
      selectableTables
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t) => {
          const links = tableLinks.filter((x) => x.tableId === t.id);

          return {
            ...t,
            links,
          };
        }),
    [selectableTables, tableLinks]
  );

  const handleSelectAll = () =>
    onToggleTable(
      selectableTables.map((t) => ({ tableId: t.id, selected: true }))
    );

  const handleDeselectAll = () =>
    onToggleTable(
      selectableTables.map((t) => ({ tableId: t.id, selected: false }))
    );

  return (
    <div>
      {noHelpers ? null : (
        <div className="flex flex-row w-full">
          <div className="py-2 border-t border-b w-full flex flex-row mb-4 flex-1">
            <Button variant="outlined" size="xs" onClick={handleSelectAll}>
              Select all
            </Button>
            <Button
              variant="outlined"
              size="xs"
              className="ml-1"
              onClick={handleDeselectAll}
            >
              De-select all
            </Button>
          </div>
          {additionalHelper || <></>}
        </div>
      )}
      <div className="grid grid-cols-1">
        {sortedSelectableTables.map((table) => {
          if (table.links && table.links.length > 0) {
            return (
              <LinkedTableCheckbox
                syncAll={syncAll}
                table={table}
                key={table.id}
                onConfigureTable={onConfigureTable}
              />
            );
          } else {
            return (
              <TableCheckbox
                syncAll={syncAll}
                table={table}
                key={table.id}
                onConfigureTable={onConfigureTable}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

const FullPageEditTables = ({
  additionalHelper,
  filter,
  noHelpers,
  onConfigureTable,
  onToggleTable,
  platform,
  selectableTables,
  syncAll,
  tableLinks,
}: {
  additionalHelper?: React.ReactNode;
  noHelpers?: boolean;
  onConfigureTable: OnConfigureTable;
  onToggleTable: (tables: { tableId: string; selected: boolean }[]) => void;
  platform: Platform<any, any, any>;
  selectableTables: {
    id?: string;
    name?: string;
    db_name?: string;
  }[];
  syncAll: boolean;
  tableLinks: TableLink[];
  filter?: string;
}) => {
  const sortedSelectableTables = useMemo(
    () =>
      selectableTables
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((table) => {
          const links = tableLinks.filter(
            (tableLink) => tableLink.tableId === table.id
          );

          return {
            ...table,
            links,
          };
        }),
    [selectableTables, tableLinks]
  );

  const filteredSelectableTables = useFilter(sortedSelectableTables, filter);

  const handleSelectAll = () =>
    onToggleTable(
      filteredSelectableTables.map((table) => ({
        tableId: table.id,
        selected: true,
      }))
    );

  const handleDeselectAll = () =>
    onToggleTable(
      filteredSelectableTables.map((table) => ({
        tableId: table.id,
        selected: false,
      }))
    );

  return (
    <div>
      {noHelpers ? null : (
        <div className="flex flex-row w-full">
          <div className="p-4 border-t border-b w-full flex flex-row flex-1">
            <Button variant="outlined" size="xs" onClick={handleSelectAll}>
              Select all
            </Button>
            <Button
              variant="outlined"
              size="xs"
              className="ml-1"
              onClick={handleDeselectAll}
            >
              De-select all
            </Button>
          </div>
          {additionalHelper || <></>}
        </div>
      )}
      <div className="grid grid-cols-1">
        {filteredSelectableTables.map((table) => {
          if (table.links && table.links.length > 0) {
            return (
              <FullPageLinkedTableCheckbox
                key={table.id}
                onConfigureTable={onConfigureTable}
                platform={platform}
                syncAll={syncAll}
                table={table}
              />
            );
          }
          return (
            <FullPageTableCheckbox
              key={table.id}
              onConfigureTable={onConfigureTable}
              platform={platform}
              syncAll={syncAll}
              table={table}
            />
          );
        })}
      </div>
    </div>
  );
};

// Due to performance penalities of extensive ReactContext usage
// we separate TableCheckbox into two components:
// LinkedTableCheckbox has access to useFormContext,
// While TableCheckbox doesnt.
const LinkedTableCheckbox = ({
  syncAll,
  table,
  onConfigureTable,
}: {
  syncAll: boolean;
  table: {
    id?: string;
    name?: string;
    links: TableLink[];
  };
  onConfigureTable: OnConfigureTable;
}) => {
  const { setValue } = useFormContext<BaseState<any>>();
  const { field } = useController<BaseState<any>>({
    name: `tables.${table.id}.enabled`,
  });
  const isTableSelected = syncAll || field.value === true;

  const selectedColumns = useWatch<BaseState<any>>({
    name: `selectedColumns.${table.id}`,
  });
  const columnsInSync = isTableSelected ? selectedColumns?.length : null;

  const linksLabel = table.links.map((x) => x.linkedTableId).join(", ");

  const handleCheck = ({ target: { checked } }) => {
    field.onChange(checked);

    // We only handle two way links for now
    table.links.forEach((link) => {
      if (link.way === LinkWay.TwoWay) {
        setValue(`tables.${link.linkedTableId}.enabled`, checked);
      } else {
        throw new Error("One way links are not handled yet.");
      }
    });
  };

  return (
    <div
      className="flex flex-row items-center hover:bg-gray-100 px-2 py-1"
      key={table.id}
    >
      <input
        className="form-checkbox rounded-sm text-black ring-black cursor-pointer disabled:opacity-20"
        type="checkbox"
        id={`tablecheckbox-${table.id}`}
        name="tables"
        disabled={syncAll}
        checked={isTableSelected}
        onChange={handleCheck}
      ></input>
      <label
        htmlFor={`tablecheckbox-${table.id}`}
        className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer truncate flex-1"
        title={table.name}
      >
        {table.name}
      </label>
      <Tippy content={`Depends on ${linksLabel}`} interactive>
        <span className="mr-2">
          <LinkIcon className="h-4 w-4" />
        </span>
      </Tippy>
      <Tippy
        content={
          columnsInSync
            ? `Select columns to sync (${columnsInSync})`
            : "Select columns to sync"
        }
        interactive
      >
        <button
          disabled={!isTableSelected}
          onClick={() => onConfigureTable(table)}
          className="relative rounded-full border border-gray-400 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 hover:shadow-md transition-all text-xxs text-black dark:text-gray-200 inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed w-6 h-6 text-center justify-center"
        >
          <CogIcon className="h-4 w-4 inline-block" />
          {columnsInSync && (
            <div
              className="h-2 w-2 bg-blue-400 rounded-full absolute top-0 right-0"
              style={{ marginRight: -2 }}
            />
          )}
        </button>
      </Tippy>
    </div>
  );
};

const TableCheckbox = ({
  syncAll,
  table,
  onConfigureTable,
}: {
  syncAll: boolean;
  table: {
    id?: string;
    name?: string;
  };
  onConfigureTable: OnConfigureTable;
}) => {
  const { field } = useController<BaseState<any>>({
    name: `tables.${table.id}.enabled`,
  });
  const isTableSelected = syncAll || field.value === true;

  const selectedColumns = useWatch<BaseState<any>>({
    name: `selectedColumns.${table.id}`,
  });
  const columnsInSync = isTableSelected ? selectedColumns?.length : null;

  const handleCheck = ({ target: { checked } }) => {
    field.onChange(checked);
  };

  return (
    <div
      className="flex flex-row items-center hover:bg-gray-100 px-2 py-1"
      key={table.id}
    >
      <input
        className="form-checkbox rounded-sm text-black ring-black cursor-pointer disabled:opacity-20"
        type="checkbox"
        id={`tablecheckbox-${table.id}`}
        name="tables"
        disabled={syncAll}
        checked={isTableSelected}
        onChange={handleCheck}
      ></input>
      <label
        htmlFor={`tablecheckbox-${table.id}`}
        className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer truncate flex-1"
        title={table.name}
      >
        {table.name}
      </label>
      <Tippy
        content={
          columnsInSync
            ? `Select columns to sync (${columnsInSync})`
            : "Select columns to sync"
        }
        interactive
      >
        <button
          disabled={!isTableSelected}
          onClick={() => onConfigureTable(table)}
          className="relative rounded-full border border-gray-400 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 hover:shadow-md transition-all text-xxs text-black dark:text-gray-200 inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed w-6 h-6 text-center justify-center"
        >
          <CogIcon className="h-4 w-4 inline-block" />
          {columnsInSync && (
            <div
              className="h-2 w-2 bg-blue-400 rounded-full absolute top-0 right-0"
              style={{ marginRight: -2 }}
            />
          )}
        </button>
      </Tippy>
    </div>
  );
};

interface FullPageTableCheckboxProps {
  onConfigureTable: OnConfigureTable;
  platform: Platform<any, any, any>;
  syncAll: boolean;
  table: {
    id?: string;
    links: TableLink[];
    name?: string;
    db_name?: string;
  };
}

// Due to performance penalities of extensive ReactContext usage
// we separate TableCheckbox into two components:
// LinkedTableCheckbox has access to useFormContext,
// While TableCheckbox doesnt.
const FullPageLinkedTableCheckbox = ({
  onConfigureTable,
  platform,
  syncAll,
  table,
}: FullPageTableCheckboxProps) => {
  const { setValue } = useFormContext<BaseState<any>>();

  // See SyncPageMapping.tsx, lib/platforms/base.tsx, lib/utils/reactHookForm.ts
  const tableId =
    platform?.kind === "stripe"
      ? replaceDotsWithEyesEmojiInTableIds(table.id)
      : table.id;
  const { field } = useController<BaseState<any>>({
    name: `tables.${tableId}.enabled`,
  });
  const isTableSelected = syncAll || field.value === true;

  const selectedColumns = useWatch<BaseState<any>>({
    name: `selectedColumns.${table.id}`,
  });
  const columnsInSync = isTableSelected ? selectedColumns?.length : null;

  const linksLabel = table.links.map((x) => x.linkedTableId).join(", ");

  const handleCheck = ({ target: { checked } }) => {
    field.onChange(checked);

    // We only handle two way links for now
    table.links.forEach((link) => {
      if (link.way === LinkWay.TwoWay) {
        setValue(`tables.${link.linkedTableId}.enabled`, checked);
      } else {
        throw new Error("One way links are not handled yet.");
      }
    });
  };

  return (
    <div className="flex flex-row items-center hover:bg-gray-100 p-4">
      <input
        className="form-checkbox rounded-sm text-black ring-black cursor-pointer disabled:opacity-20"
        type="checkbox"
        id={`tablecheckbox-${table.id}`}
        name="tables"
        disabled={syncAll}
        checked={isTableSelected}
        onChange={handleCheck}
      ></input>
      <label
        htmlFor={`tablecheckbox-${table.id}`}
        className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer truncate flex-1"
        title={table.name}
      >
        <span className="text-sm">{table.name}</span>{" "}
        {table.db_name && (
          <span className="font-mono text-xs text-purple-800">
            ({table.db_name})
          </span>
        )}
      </label>
      <Tippy content={`Depends on ${linksLabel}`} interactive>
        <span className="mr-2">
          <LinkIcon className="h-4 w-4" />
        </span>
      </Tippy>
      <Tippy
        content={
          columnsInSync
            ? `Select columns to sync (${columnsInSync})`
            : "Select columns to sync"
        }
        interactive
      >
        <button
          disabled={!isTableSelected}
          onClick={() => onConfigureTable(table)}
          className="relative rounded-full border border-gray-400 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 hover:shadow-md transition-all text-xxs text-black dark:text-gray-200 inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed w-6 h-6 text-center justify-center"
        >
          <CogIcon className="h-4 w-4 inline-block" />
          {columnsInSync && (
            <div
              className="h-2 w-2 bg-blue-400 rounded-full absolute top-0 right-0"
              style={{ marginRight: -2 }}
            />
          )}
        </button>
      </Tippy>
    </div>
  );
};

const FullPageTableCheckbox = ({
  onConfigureTable,
  platform,
  syncAll,
  table,
}: FullPageTableCheckboxProps) => {
  const { getValues } = useFormContext<BaseState<any>>();
  const tables = getValues().tables;
  const enabledAssociationTables = tables
    ? Object.keys(tables).filter((tableName) => {
        return (
          tables[tableName].enabled && tableName.startsWith("associations_")
        );
      })
    : [];

  // See SyncPageMapping.tsx, lib/platforms/base.tsx, lib/utils/reactHookForm.ts
  const tableId: string =
    platform?.kind === "stripe"
      ? replaceDotsWithEyesEmojiInTableIds(table.id)
      : table.id;
  const { field } = useController<BaseState<any>>({
    name: `tables.${tableId}.enabled`,
  });
  const isTableSelected = syncAll || field.value === true;

  const selectedColumns = useWatch<BaseState<any>>({
    name: `selectedColumns.${table.id}`,
  });
  const columnsInSync = isTableSelected ? selectedColumns?.length : null;

  const handleCheck = ({ target: { checked } }) => {
    field.onChange(checked);
  };

  const hasAssociationTable =
    enabledAssociationTables.filter((assocTableName) => {
      const tableName = tableId.toLowerCase();
      return assocTableName.includes(tableName);
    }).length > 0;

  return (
    <div className="flex flex-row items-center hover:bg-gray-100 p-4 border-b last:border-b-0">
      <input
        className="form-checkbox rounded-sm text-black ring-black cursor-pointer disabled:opacity-20"
        type="checkbox"
        id={`tablecheckbox-${table.id}`}
        name="tables"
        disabled={syncAll || hasAssociationTable}
        checked={isTableSelected}
        onChange={handleCheck}
      ></input>
      <label
        htmlFor={`tablecheckbox-${table.id}`}
        className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer truncate flex-1"
        title={table.name}
      >
        <span className="text-sm">{table.name}</span>{" "}
        {table.db_name && (
          <span className="font-mono text-xs text-purple-800">
            ({table.db_name})
          </span>
        )}
      </label>
      <Tippy
        content={
          columnsInSync
            ? `Select columns to sync (${columnsInSync})`
            : "Select columns to sync"
        }
        interactive
      >
        <button
          disabled={!isTableSelected}
          onClick={() => onConfigureTable(table)}
          className="relative rounded-full border border-gray-400 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 hover:shadow-md transition-all text-xxs text-black dark:text-gray-200 inline-flex flex-row items-center cursor-pointer font-semibold disabled:opacity-50 disabled:cursor-not-allowed w-6 h-6 text-center justify-center"
        >
          <PencilIcon className="h-4 w-4 inline-block" />
          {columnsInSync && (
            <div
              className="h-2 w-2 bg-blue-400 rounded-full absolute top-0 right-0"
              style={{ marginRight: -2 }}
            />
          )}
        </button>
      </Tippy>
    </div>
  );
};

const OnboardEditTables = ({
  additionalHelper,
  filter,
  noHelpers,
  onConfigureTable,
  onToggleTable,
  platform,
  selectableTables,
  syncAll,
  tableLinks,
}: {
  additionalHelper?: React.ReactNode;
  noHelpers?: boolean;
  onConfigureTable: OnConfigureTable;
  onToggleTable: (tables: { tableId: string; selected: boolean }[]) => void;
  platform: Platform<any, any, any>;
  selectableTables: {
    db_name?: string;
    id?: string;
    name?: string;
  }[];
  syncAll: boolean;
  tableLinks: TableLink[];
  filter?: string;
}) => {
  const tablesFromFormState = useWatch({ name: "tables" });
  const defaultTablesFilterPredicate = getDefaultTableFilterPredicate(
    platform.kind
  );
  // Only show five rows by default. Salesforce selects 4 rows by default,
  // so 5 shows there are unselected rows without showing a bazillion
  const [currentVisibleRowCount, setCurrentVisibleRowCount] = useState(5);

  const sortedSelectableTables = useMemo(
    () =>
      selectableTables
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((table) => {
          const links = tableLinks.filter(
            (tableLink) => tableLink.tableId === table.id
          );

          return {
            ...table,
            links,
          };
        }),
    [selectableTables, tableLinks]
  );

  const preSelectedTables = useMemo(() => {
    return sortedSelectableTables.filter(defaultTablesFilterPredicate);
  }, [sortedSelectableTables]);

  const orderedSelectableTables = useMemo(() => {
    return [
      ...preSelectedTables,
      ...sortedSelectableTables.filter(
        (table, i) => !defaultTablesFilterPredicate(table, i)
      ),
    ];
  }, [preSelectedTables, sortedSelectableTables]);

  const filteredSelectableTables = useFilter(orderedSelectableTables, filter);

  const handleSelectAll = () =>
    onToggleTable(
      filteredSelectableTables.map((table) => ({
        tableId: table.id,
        selected: true,
      }))
    );

  const handleDeselectAll = () =>
    onToggleTable(
      filteredSelectableTables.map((table) => ({
        tableId: table.id,
        selected: false,
      }))
    );

  useEffect(() => {
    onToggleTable(
      preSelectedTables
        .filter((table) => {
          return tablesFromFormState[table.id]?.enabled !== false;
        })
        .map((table) => {
          return {
            tableId: table.id,
            selected: true,
          };
        })
    );
  }, []);

  return (
    <div>
      {noHelpers ? null : (
        <div className="flex flex-row w-full">
          <div className="p-4 border-t border-b w-full flex flex-row flex-1">
            <Button variant="outlined" size="xs" onClick={handleSelectAll}>
              Select all
            </Button>
            <Button
              variant="outlined"
              size="xs"
              className="ml-1"
              onClick={handleDeselectAll}
            >
              De-select all
            </Button>
          </div>
          {additionalHelper || <></>}
        </div>
      )}
      <div className="grid grid-cols-1">
        {renderTableRows(
          filteredSelectableTables,
          currentVisibleRowCount,
          syncAll,
          onConfigureTable,
          platform
        )}
        {currentVisibleRowCount < filteredSelectableTables.length && (
          <button
            className="flex flex-row justify-center items-center font-medium hover:bg-gray-100 p-4 border-b last:border-b-0"
            onClick={() => {
              setCurrentVisibleRowCount(filteredSelectableTables.length);
            }}
          >
            <PlusIcon className="w-4 h-4 mr-2" /> view{" "}
            {filteredSelectableTables.length - currentVisibleRowCount} more
          </button>
        )}
      </div>
    </div>
  );
};

const renderTableRows = (
  tables: {
    db_name?: string;
    id?: string;
    links: TableLink[];
    name?: string;
  }[],
  currentVisibleRowCount: number,
  syncAll: boolean,
  onConfigureTable: OnConfigureTable,
  platform: Platform<any, any, any>
) => {
  const renderededTables = [];
  const maxRowsToShow = Math.min(currentVisibleRowCount, tables.length);

  for (let i = 0; i < maxRowsToShow; i++) {
    const table = tables[i];

    if (table.links && table.links.length > 0) {
      renderededTables.push(
        <FullPageLinkedTableCheckbox
          key={table.id}
          onConfigureTable={onConfigureTable}
          platform={platform}
          syncAll={syncAll}
          table={table}
        />
      );
    }
    renderededTables.push(
      <FullPageTableCheckbox
        key={table.id}
        onConfigureTable={onConfigureTable}
        platform={platform}
        syncAll={syncAll}
        table={table}
      />
    );
  }
  return renderededTables;
};

// TODO: if we keep the default selection of tables in onboarding around,
// make this part of the platform configuration
const getDefaultTableFilterPredicate = (platformKind: ResourceKind) => {
  if (platformKind === "salesforce") {
    return (table) => {
      return (
        table.name === "Case" ||
        table.name === "Contact" ||
        table.name === "Lead" ||
        table.name === "Opportunity"
      );
    };
  }

  if (platformKind === "hubspot") {
    return (table) => {
      return (
        table.name === "Company" ||
        table.name === "Contact" ||
        table.name === "Deal"
      );
    };
  }

  // At time of writing, this only handles Airtable, but this is general
  // so that we don't break onboarding for new platforms if we forget to modify this.
  return (table, i) => {
    if (i === 0) {
      return true;
    }
    return false;
  };
};
