import * as z from "zod";

import {
  ApiFetchError,
  FetchError,
  ForbiddenError,
  getUrl,
  NotAuthorizedError,
  WrappedZodParseError,
} from "lib/api/index";

type FetcherArgs = [string] | [string, RequestInit];

export const fetcher = async (...args: FetcherArgs) => {
  let path = args[0];
  let opts = args[1];
  let method = opts?.method || "GET";
  let defaultHeaders: { [k: string]: string } = [
    "post",
    "patch",
    "put",
  ].includes(method.toLowerCase())
    ? { "Content-Type": "application/json" }
    : { Accept: "application/json" };

  let res = await fetch(getUrl(path), {
    credentials: "include",
    method,
    ...opts,
    headers: {
      ...defaultHeaders,
      ...opts?.headers,
    },
  });
  if (!res.ok) {
    if (res.status === 401) {
      throw new NotAuthorizedError(res.statusText);
    }
    if (res.status === 403) {
      throw new ForbiddenError(res.statusText);
    }
    try {
      throw new ApiFetchError(await res.json(), res.status);
    } catch {
      throw new FetchError(res.statusText, res.status);
    }
  }
  return await res.json();
};

export function zodFetcher<T extends z.ZodObject<any, any, any>>(schema: T) {
  return async (...args: FetcherArgs) => {
    const response = await fetcher(...args);

    if (process.env.ZOD_DO_SCHEMA_VALIDATION) {
      try {
        return await schema.parseAsync(response);
      } catch (err: any) {
        throw new WrappedZodParseError(err, args as any);
      }
    }

    return response;
  };
}

let config = { fetcher, suspense: false };
export default config;
