import { err, ok, Result } from "neverthrow";
import * as Sentry from "@sentry/nextjs";
import { infer as zodInfer, ZodError, ZodObject } from "zod";
import {
  PlatformCredential,
  CredentialModifiableParams,
  ResourceModifiableParams,
} from "lib/platforms";
import { StripeCredential } from "lib/platforms/stripe";
import {
  CreateExternalDatabaseResult,
  DeleteCredentialResult,
  DeleteDatabaseResult,
  ErroredQueryResult,
  responses,
  SuccessQueryResult,
  TestAndCreateSshTunnelResult,
  TestExistingTunnelResult,
  TestReachabilityResult,
  UpdateExternalDatabaseResult,
} from "lib/api/responses";
import {
  ApiError,
  apiErrorSchema,
  MessageQueue,
  PendingUser,
  User,
  UserCreateParams,
  UserModifiableParams,
  userSchema,
} from "lib/api/types";

export enum AuthProvider {
  google = "google",
  github = "github",
  gitlab = "gitlab",
  identity = "identity",
  microsoft = "microsoft",
}

export type ValidationErrors = {
  name: string | string[];
  errors: string[];
}[];

const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
if (!API_HOST) {
  throw new Error(
    `Expected the ENV variable "NEXT_PUBLIC_API_HOST" to be set.`
  );
}

const CAPTCHA_TOKEN = process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_TOKEN;
if (!CAPTCHA_TOKEN) {
  throw new Error(
    `Expected the ENV variable "NEXT_PUBLIC_RECAPTCHA_CLIENT_TOKEN" to be set.`
  );
}

export let getUrl = (s: string) => {
  return [API_HOST, s.replace(/^\//, "")].join("/");
};

export let getReCaptchaToken = () => CAPTCHA_TOKEN;

export let getLogoutUrl = () => getUrl("/auth/logout");

export let getLoginRedirectUrl = (
  provider: AuthProvider,
  redirectPath?: string
) => {
  return getUrl(`/auth/${provider}?redirect_path=${redirectPath || ""}`);
};

type ApiActionMethod = "POST" | "PUT" | "PATCH" | "DELETE";

const apiAction = async <T, R extends ZodObject<any, any, any>>(
  endpoint: string,
  body: T,
  responseSchema: R,
  method: ApiActionMethod
): Promise<Result<zodInfer<R>, ApiError>> => {
  const response = await fetch(getUrl(endpoint), {
    method,
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  // Intercept non-apiErrorSchema compliant errors.
  switch (response.status) {
    case 401:
      // This should force a redirect to the login page.
      throw new NotAuthorizedError(response.statusText);

    case 500:
    case 502:
    case 503:
      Sentry.captureException({
        message: "Frontend received a 500 response.",
        endpoint,
        method,
      });

      return err({
        ok: false,
        who: "unknown",
        error: {
          who: "unknown",
          summary: "An internal server error occurred.",
          slug: "unknown",
        },
      });

    // We may want to handle some other 400s differently.
    default:
      break;
  }

  const responseJson = await response.json();

  if (responseJson.ok) {
    const parsedResponseJson = responseSchema.parse(responseJson);
    return ok(parsedResponseJson);
  } else {
    const error = apiErrorSchema.parse(responseJson);
    return err(error);
  }
};

export let confirmEmail = async (email: string, token: string) => {
  let response = await fetch(getUrl("/auth/confirm-email"), {
    method: "POST",
    body: JSON.stringify({ email, token }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let jsonResult = await response.json();

  if (jsonResult.ok) {
    return jsonResult;
  } else {
    let err =
      jsonResult.error?.summary ||
      "Received an error when trying to confirm email";
    throw new Error(err);
  }
};

export let resendConfirmationToken = async (token: string) => {
  let response = await fetch(getUrl("/auth/refresh-confirmation-token"), {
    method: "POST",
    body: JSON.stringify({ token }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let jsonResult = await response.json();

  if (jsonResult.ok) {
    return jsonResult;
  } else {
    throw new Error(jsonResult);
  }
};

export let resendInvite = async (userId: string) => {
  let response = await fetch(getUrl("/api/refresh-invite-token"), {
    method: "POST",
    body: JSON.stringify({ userId }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let jsonResult = await response.json();

  if (jsonResult.ok) {
    return jsonResult;
  } else {
    throw new Error(jsonResult);
  }
};

export let sendOnboardLaterEmail = async () => {
  let response = await fetch(getUrl("/api/orgs/onboard-later-email"), {
    method: "POST",
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let jsonResult = await response.json();

  if (jsonResult.ok) {
    return jsonResult;
  } else {
    throw new Error(jsonResult);
  }
};

// export let getLoginRedirectUrlOrgOauth = (
//   orgSlug: string,
//   redirectUrl?: string
// ) => {
//   if (redirectUrl) {
//     return getUrl(`/auth/org_oauth/${orgSlug}?redirect_url=${redirectUrl}`);
//   } else {
//     return getUrl(
//       `/auth/org_oauth/${orgSlug}?redirect_url=${process.env.NEXT_PUBLIC_HOST}/`
//     );
//   }
// };

export let checkIsLoggedIn = async () => {
  try {
    let res = await fetch(getUrl(`/auth/check`), { credentials: "include" });
    if (res.ok) {
      let j = await res.json();
      return { ok: true, app: j.app };
    } else {
      return { ok: false };
    }
  } catch (e) {
    return { ok: false };
  }
};

export let exchangeLoginCodeForToken = async (
  code: string,
  provider: AuthProvider
) => {
  let r = await fetch(`auth/callback/${provider}`, {
    method: "POST",
    body: JSON.stringify({ code }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return j.token;
  } else {
    let err =
      j.error?.summary ||
      `Received an error when trying to exchange code (provider: ${provider})`;
    console.error(j);
    throw new Error(err);
  }
};

export const deleteResource = async (id: string) => {
  return await fetch(getUrl(`/api/resources/${id}`), {
    method: "DELETE",
    credentials: "include",
  });
};

export const createResource = async (params: ResourceModifiableParams) => {
  let resource = params;

  let res = await fetch(getUrl("/api/resources/"), {
    method: "POST",
    body: JSON.stringify({
      resource,
    }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await res.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const updateResource = async (
  id: string,
  params: ResourceModifiableParams
) => {
  let resource = params;

  let res = await fetch(getUrl("/api/resources/" + id), {
    method: "PUT",
    body: JSON.stringify({
      resource,
    }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await res.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export type SshTunnelParams =
  | {
      type: "existing";
      id: string;
    }
  | {
      type: "new";
      attrs: {
        host: string;
        port: number;
        user: string;
      };
    };

export const createExternalDatabase = async (params: {
  definition: {
    dbname: string;
    host: string;
    port: number;
    ssl: boolean;
    user: string;
    password: string;
  };
  name?: string;
  schema: string;
  ssh_tunnel?: SshTunnelParams;
}): Promise<Result<CreateExternalDatabaseResult, ApiError>> =>
  apiAction(
    "/api/databases/external",
    params,
    responses.createExternalDatabaseResult,
    "POST"
  );

export interface UpdateExternalDatabaseParams {
  name?: string;
  ssh_tunnel_id?: string;
  definition?: {
    dbname: string;
    user?: string;
    password?: string;
    host?: string;
    port?: string;
    ssl?: boolean;
  };
}

export const updateExternalDatabase = async (
  id: string,
  params: UpdateExternalDatabaseParams
): Promise<Result<UpdateExternalDatabaseResult, ApiError>> =>
  apiAction(
    `/api/databases/external/${id}`,
    params,
    responses.updateExternalDatabaseResult,
    "PATCH"
  );

export const deleteDatabase = async (
  id: string
): Promise<Result<DeleteDatabaseResult, ApiError>> => {
  let res = await fetch(getUrl(`/api/databases/${id}`), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await res.json();

  if (j.ok) {
    let newDb = responses.deleteDatabaseResult.parse(j);
    return ok(newDb);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const testReachability = async (params: {
  host: string;
  port: number;
}): Promise<Result<TestReachabilityResult, ApiError>> =>
  apiAction(
    "/api/databases/external/tests/reachability",
    params,
    responses.testReachabilityResult,
    "POST"
  );

export const testAndCreateSshTunnel = async (params: {
  tunnel: {
    host: string;
    port: number;
    user: string;
  };
  host: string;
  port: number;
}): Promise<Result<TestAndCreateSshTunnelResult, ApiError>> =>
  apiAction(
    "/api/databases/external/test_and_create_ssh_tunnel",
    params,
    responses.testAndCreateSshTunnelResult,
    "POST"
  );

export const testExistingTunnel = async (
  tunnelId: string,
  params: {
    host: string;
    port: number;
  }
): Promise<Result<TestExistingTunnelResult, ApiError>> =>
  apiAction(
    `/api/databases/external/tunnels/${tunnelId}/test`,
    params,
    responses.testExistingTunnelResult,
    "POST"
  );

export const notifyOptimize = async () => {
  let res = await fetch(getUrl(`/api/resources/notify_optimize`), {
    method: "GET",
    credentials: "include",
  });

  let j = await res.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const notifyTunnel = async () => {
  let res = await fetch(getUrl(`/api/resources/notify_tunnel`), {
    method: "POST",
    credentials: "include",
  });

  let j = await res.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const notifyReverseTunnel = async () => {
  let res = await fetch(getUrl(`/api/resources/notify_reverse_tunnel`), {
    method: "POST",
    credentials: "include",
  });

  let j = await res.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const loginWithPassword = async (
  email: string,
  password: string,
  redirectPath?: string
) => {
  try {
    let redirect = redirectPath ? redirectPath : "";
    let res = await fetch(getUrl("/auth/identity/callback"), {
      method: "POST",
      body: JSON.stringify({ email, password, redirect_path: redirect }),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });

    const resJson = await res.json();

    if (resJson.ok) {
      const user = resJson.user;
      try {
        window.analytics.identify(user.id, {
          org_id: user.orgId,
          email: user.email,
          createdAt: user.insertedAt,
        });
        window.analytics.group(user.orgId);
      } catch {
        console.error(`Unexpected error on analytics`);
      }
      return ok(resJson);
    } else {
      return err(apiErrorSchema.parse(resJson));
    }
  } catch (e) {
    return err({
      error: {
        summary: `Unable to reach Sequin, something is wrong. Try again in a moment`,
      },
    });
  }
};

export interface RegisterUser {
  user: {
    email: string;
    password: string;
  };
}

export const registerUser = async (
  payload: RegisterUser
): Promise<Result<User, ApiError>> => {
  let response = await fetch(getUrl("/auth/signup"), {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  });

  let result = await response.json();

  if (result.ok) {
    let user = userSchema.parse(result.user);
    try {
      window.analytics.identify(user.id, {
        org_id: user.orgId,
        email: user.email,
        createdAt: user.insertedAt,
      });
      window.analytics.group(user.orgId);
    } catch {}
    return ok(user);
  } else {
    let error = apiErrorSchema.parse(result);
    return err(error);
  }
};

export interface RegisterPendingUser {
  email?: string;
  password?: string;
  token: string;
}

export const registerPendingUser = async (
  payload: RegisterPendingUser
): Promise<Result<User, ApiError>> => {
  let response = await fetch(getUrl("/auth/signup-pending"), {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ user: payload }),
    headers: {
      "content-type": "application/json",
    },
  });

  let result = await response.json();

  if (result.ok) {
    let user = userSchema.parse(result.user);
    try {
      window.analytics.identify(user.id, {
        org_id: user.orgId,
        email: user.email,
        createdAt: user.insertedAt,
      });
      window.analytics.group(user.orgId);
    } catch {}
    return ok(user);
  } else {
    let error = apiErrorSchema.parse(result);
    return err(error);
  }
};

export const createPendingUser = async (
  userParams: UserCreateParams
): Promise<Result<User, ApiError>> => {
  let r = await fetch(getUrl("/api/users/pending"), {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({
      user: userParams,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const deletePendingUser = async (
  id: string
): Promise<Result<User, ApiError>> => {
  let r = await fetch(getUrl(`/api/users/pending/${id}`), {
    credentials: "include",
    method: "DELETE",
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const deleteUser = async (
  id: string
): Promise<Result<User, ApiError>> => {
  let r = await fetch(getUrl(`/api/users/${id}`), {
    credentials: "include",
    method: "DELETE",
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const updatePendingUser = async (
  id: string,
  params: UserModifiableParams
): Promise<Result<PendingUser, ApiError>> => {
  let user = params;

  let r = await fetch(getUrl("/api/users/pending/" + id), {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify({
      user,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const updateUser = async (
  id: string,
  params: UserModifiableParams
): Promise<Result<User, ApiError>> => {
  let user = params;

  let r = await fetch(getUrl("/api/users/" + id), {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify({
      user,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export let requestPasswordReset = async (
  email: string,
  reCaptchaToken: string
): Promise<Result<{}, ApiError>> => {
  let r = await fetch(getUrl("/password/reset/request"), {
    method: "POST",
    body: JSON.stringify({ email, token: reCaptchaToken }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export let confirmPasswordReset = async (
  token: string,
  new_password: string
): Promise<Result<{}, ApiError>> => {
  let r = await fetch(getUrl("/password/reset/confirm"), {
    method: "POST",
    body: JSON.stringify({ token, new_password }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const forceRebuild = async (
  id: string
): Promise<Result<{ ok: true }, ApiError>> => {
  let r = await fetch(getUrl(`/api/resources/${id}/force_rebuild`), {
    credentials: "include",
    method: "POST",
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const refreshSchema = async (
  resourceId: string
): Promise<Result<{ ok: true }, ApiError>> => {
  let r = await fetch(getUrl(`/api/resources/${resourceId}/refresh_schema`), {
    credentials: "include",
    method: "POST",
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const validateStripeKey = async (
  secret: string
): Promise<Result<{ secret_valid: boolean }, ApiError>> => {
  let r = await fetch(getUrl("/api/credentials/stripe/validate_secret"), {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ secret }),
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const executeStripeKeyOAuth = async (
  test?: boolean
): Promise<Result<{ credential: StripeCredential }, ApiError>> =>
  new Promise<Result<{ credential: StripeCredential }, ApiError>>((resolve) => {
    const oauthUrl = getUrl(
      `/api/stripe/install?test=${test ? "true" : "false"}&source=console`
    );

    let token;

    const popup = popupWindow(oauthUrl, "Stripe OAuth", window, 650, 750);

    const cleanResolve = (
      v: Result<{ credential: StripeCredential }, ApiError>
    ) => {
      try {
        popup.close();
      } catch {}

      window.removeEventListener("message", handleMessage);
      resolve(v);
    };

    const handleMessage = (ev) => {
      if (ev.origin !== API_HOST) {
        // OAuth origin differs.
        return;
      }

      if (typeof ev.data !== "string" || token) {
        return;
      }

      const newToken = parseMessage(ev, "X-CREDENTIAL>");
      if (newToken) {
        cleanResolve(ok({ credential: JSON.parse(newToken) }));
      }

      const errorCode = parseMessage(ev, "X-AUTH-ERROR>");
      if (errorCode) {
        cleanResolve(
          err({
            ok: false,
            error: {
              slug: errorCode,
              summary: "An error occurred",
            },
          })
        );
      }
    };

    window.addEventListener("message", handleMessage);

    popup.addEventListener("close", () => {
      window.removeEventListener("message", handleMessage);

      if (!token) {
        resolve(
          err({
            ok: false,
            error: {
              summary: "Unknown error",
            },
          })
        );
      }
    });
  });

export const executeGenericAuthentication = async <K>(
  platform: string,
  query: string = ""
): Promise<Result<{ credential: K }, ApiError>> =>
  new Promise<Result<{ credential: K }, ApiError>>((resolve) => {
    const authUrl = getUrl(`api/oauth2/start/${platform}?${query}`);

    let token;

    const popup = popupWindow(authUrl, "Auth", window, 650, 750);

    const cleanResolve = (v: Result<{ credential: K }, ApiError>) => {
      try {
        popup.close();
      } catch {}

      window.removeEventListener("message", handleMessage);
      resolve(v);
    };

    const handleMessage = (ev) => {
      if (ev.origin !== API_HOST) {
        // OAuth origin differs.
        return;
      }

      if (typeof ev.data !== "string") {
        return;
      }

      const newToken = parseMessage(ev, "X-CREDENTIAL>");
      if (newToken && !token) {
        cleanResolve(ok({ credential: JSON.parse(newToken) }));
      }

      const errorSlug = parseMessage(ev, "X-AUTH-ERROR>");
      if (errorSlug) {
        cleanResolve(
          err({
            ok: false,
            error: {
              slug: errorSlug,
            },
          })
        );
      }
    };

    window.addEventListener("message", handleMessage);

    popup.addEventListener("close", () => {
      window.removeEventListener("message", handleMessage);

      if (!token) {
        resolve(
          err({
            ok: false,
            error: {
              summary: "Unknown error",
            },
          })
        );
      }
    });
  });

const parseMessage = (event, prefix) => {
  if (event.data.startsWith(prefix)) {
    return event.data.substring(prefix.length);
  } else {
    return null;
  }
};

// opens a centered popup window
// adapted from https://stackoverflow.com/a/32261263/10777177
const popupWindow = (
  url: string,
  windowName: string,
  parent: Window,
  width: number,
  height: number
) => {
  const y = parent.top.outerHeight / 2 + parent.top.screenX - height / 2;
  const x = parent.top.outerWidth / 2 + parent.top.screenX - width / 2;

  return parent.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
  );
};

export const validateCredential = async (
  credentialId: string
): Promise<Result<{ secret_valid: boolean }, ApiError>> => {
  let r = await fetch(getUrl(`/api/credentials/${credentialId}/validate`), {
    credentials: "include",
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const runQuery = async (
  resourceId: string,
  query: string
): Promise<Result<SuccessQueryResult, ErroredQueryResult>> => {
  let r = await fetch(getUrl(`/api/resource/${resourceId}/query`), {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({
      query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    const { result } = responses.successfulGetQueryResult.parse(j);
    return ok(result);
  } else {
    const { result } = responses.erroredGetQueryResult.parse(j);
    return err(result);
  }
};

export const updateSurvey = async (answers: any): Promise<Result<{}, {}>> => {
  let r = await fetch(getUrl(`/api/orgs/update_survey`), {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({
      answers,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await r.json();

  if (j.ok) {
    return ok({});
  } else {
    return err({});
  }
};

export const sendUpvote = async (
  email: string,
  platform: string
): Promise<Result<{}, {}>> => {
  let r = await fetch(`/api/sendUpvote`, {
    credentials: "omit",
    method: "POST",
    body: JSON.stringify({
      email,
      platform,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (r.ok) {
    return ok({});
  } else {
    return err({});
  }
};

export const createCredential = async (
  params: CredentialModifiableParams
): Promise<
  Result<
    {
      credential: PlatformCredential;
    },
    ApiError
  >
> => {
  let res = await fetch(getUrl("/api/credentials"), {
    method: "POST",
    body: JSON.stringify({
      credential: params,
    }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await res.json();

  if (j.ok) {
    return ok(j);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const deleteCredential = async (
  id: string
): Promise<Result<DeleteCredentialResult, ApiError>> => {
  let res = await fetch(getUrl(`/api/credentials/${id}`), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
  });

  let j = await res.json();

  if (j.ok) {
    let newDb = responses.deleteCredentialResult.parse(j);
    return ok(newDb);
  } else {
    let error = apiErrorSchema.parse(j);
    return err(error);
  }
};

export const backendEvent = async (eventName: string, eventData: {}) => {
  return await fetch(getUrl("/api/analytics/event"), {
    body: JSON.stringify({
      eventName,
      eventData,
    }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  });
};

export const createMessageQueue = async (
  region: string
): Promise<Result<MessageQueue, ApiError>> => {
  const response = await fetch(getUrl("/api/message_queues/"), {
    body: JSON.stringify({ region }),
    credentials: "include",
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  });

  const json = await response.json();

  if (json.ok) {
    return ok(json.messageQueue);
  } else {
    const error = apiErrorSchema.parse(json);
    return err(error);
  }
};

export class NotAuthorizedError extends Error {}
export class NotFoundError extends Error {}
export class ForbiddenError extends Error {}
export class FetchError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);

    this.status = status;
  }
}
export class ApiFetchError extends Error {
  public status: number;
  public apiError: ApiError;

  constructor({ error }: ApiError, status: number) {
    super(error.summary || "Unknown error");

    this.status = status;
    this.apiError = error;
  }
}

export class WrappedZodParseError extends Error {
  public error: ZodError;
  constructor(error: ZodError, endpoint: any) {
    super(
      `There was an error parsing the response:\n` +
        `Endpoint: ${endpoint}\n` +
        `${error.message}`
    );

    this.error = error;
  }
}
