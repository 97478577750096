import React from "react";
import * as z from "zod";
import { BaseCredential, Database, dbSchema } from "lib/api/types";
import {
  airtableCredentialSchema,
  airtableDefinitionSchema,
  airtableResourceSchema,
  AIRTABLE_PLATFORM,
  rawAirtableResourceSchema,
} from "lib/platforms/airtable";
import {
  GITHUB_PLATFORM,
  githubCredentialSchema,
  githubDefinitionSchema,
  githubResourceSchema,
  rawGithubResourceSchema,
} from "lib/platforms/github";
import {
  hubspotCredentialSchema,
  hubspotDefinitionSchema,
  hubspotResourceSchema,
  HUBSPOT_PLATFORM,
  rawHubspotResourceSchema,
} from "lib/platforms/hubspot";
import {
  rawSalesforceResourceSchema,
  salesforceCredentialSchema,
  salesforceDefinitionSchema,
  salesforceResourceSchema,
  SALESFORCE_PLATFORM,
} from "lib/platforms/salesforce";
import {
  rawStripeResourceSchema,
  stripeCredentialSchema,
  stripeDefinitionSchema,
  stripeResourceSchema,
  STRIPE_PLATFORM,
} from "lib/platforms/stripe";

export type ResourceKind =
  | "airtable"
  | "github"
  | "hubspot"
  | "salesforce"
  | "stripe";

export interface FormProps {
  isOnboard?: boolean;
  isCreate?: boolean;
}

export interface CredentialFormProps<
  PlatformCredential extends BaseCredential
> {
  onBack: () => void;
  onSave: (credential: PlatformCredential) => void;
}

export interface OnboardCredentialFormProps {
  handleBackClick: () => void;
}

export type ProxyHelper<T> = { name: "PROXY"; buildUrl: (res: T) => string };
export type SourceUrlHelper<T> = {
  name: "SOURCE";
  buildUrl: (res: T) => { url: string; displayUrl: string };
};

export type AvailableHelpers<T> =
  | ProxyHelper<T>
  | SourceUrlHelper<T>
  | { name: "ALL_WRITABLE" }
  | { name: "TURBO" }
  | { name: "WAIT" };

export interface Platform<T, S, C> {
  displayName: string;
  displayIcon: (
    props: React.SVGProps<SVGSVGElement> & {
      title?: string;
      titleId?: string;
    }
  ) => JSX.Element;

  /**
   * The adjective we impose to this resource in the UI: Airtable *base*, Stripe *account*
   */
  resourceAppositive: string;
  kind: ResourceKind;

  supportsCollectionStatus: boolean;
  supportsWriteProxy: boolean;

  helpers: AvailableHelpers<T>[];

  buildForm: (props: FormProps) => React.ReactElement<FormProps>;
  buildCredentialForm: (
    props: CredentialFormProps<C>
  ) => React.ReactElement<CredentialFormProps<C>>;
  buildOnboardCredentialForm: (
    props: OnboardCredentialFormProps
  ) => React.ReactElement<OnboardCredentialFormProps>;
  extraTableParams: (args: any[]) => object | null;

  fullPageForm?: (props: FormProps) => React.ReactElement<FormProps>;

  getLabelForCredential: (credential: C) => string;

  getInitialCreateState: () => S;
  getInitialUpdateState: (resource: T) => S;

  prepareFieldsForCreate: (state: S) => any;
  prepareFieldsForUpdate: (state: Partial<S>) => any;

  syncTime?: boolean;
  sourceLink?: (resource: T) => string;
}

export const REGISTERED_PLATFORMS = [
  AIRTABLE_PLATFORM,
  GITHUB_PLATFORM,
  HUBSPOT_PLATFORM,
  SALESFORCE_PLATFORM,
  STRIPE_PLATFORM,
];

export const credentialSchema = z.union([
  airtableCredentialSchema,
  githubCredentialSchema,
  hubspotCredentialSchema,
  salesforceCredentialSchema,
  stripeCredentialSchema,
]);

export type PlatformCredential = z.infer<typeof credentialSchema>;
export type CredentialModifiableParams = Partial<
  Pick<PlatformCredential, "payload">
>;

export const resourceDefinitionSchema = z.union([
  airtableDefinitionSchema,
  githubDefinitionSchema,
  hubspotDefinitionSchema,
  salesforceDefinitionSchema,
  stripeDefinitionSchema,
]);
export const resourceSchema = z.union([
  airtableResourceSchema,
  githubResourceSchema,
  hubspotResourceSchema,
  salesforceResourceSchema,
  stripeResourceSchema,
]);

export type ResourceDefinition = z.infer<typeof resourceDefinitionSchema>;
export type Resource = z.infer<typeof resourceSchema>;
export type ResourceModifiableParams = Partial<
  Pick<
    Resource,
    "definition" | "name" | "active" | "resourceEnvironment" | "messageQueueId"
  >
>;

export interface DatabaseWithSchema extends Omit<Database, "schema"> {
  schemas: {
    resource: Resource;
    schema: string;
  }[];
}

export const dbWithResourcesSchema = dbSchema.extend({
  resources: z.array(
    z.union([
      rawAirtableResourceSchema.extend({
        schema: z.string(),
      }),
      rawGithubResourceSchema.extend({
        schema: z.string(),
      }),
      rawStripeResourceSchema.extend({
        schema: z.string(),
      }),
      rawSalesforceResourceSchema.extend({
        schema: z.string(),
      }),
      rawHubspotResourceSchema.extend({
        schema: z.string(),
      }),
    ])
  ),
});

export type DatabaseWithResources = z.infer<typeof dbWithResourcesSchema>;

export const getPlatformForKind = (
  kind: ResourceKind
): Platform<any, any, any> => {
  switch (kind) {
    case "airtable":
      return AIRTABLE_PLATFORM;

    case "github":
      return GITHUB_PLATFORM;

    case "hubspot":
      return HUBSPOT_PLATFORM;

    case "salesforce":
      return SALESFORCE_PLATFORM;

    case "stripe":
      return STRIPE_PLATFORM;

    default:
      throw new Error(`Platform not handled: ${JSON.stringify(kind)}`);
  }
};
