import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

import Modal from "components/core/Modal";
import { Button } from "lib/lucidez";
import { DestinationProps } from "components/platforms/common/forms/DestinationStep";
import { MAILTO_LINK } from "site-constants";

export const FatalErrorScreen = ({
  onFinishFlow,
  resource,
  onDestinationChange,
}: DestinationProps) => {
  const handleClose = () => {
    if (resource) {
      onDestinationChange({
        type:
          resource.database.definition.type === "dedicated_postgres"
            ? "external"
            : "existing",
        validSchema: true,
        databaseId: resource.database.id,
        schema: resource.database.schema,
      });
    } else {
      onDestinationChange({
        type: "new",
      });
    }

    onFinishFlow();
    return;
  };

  return (
    <div className="flex flex-col text-center items-center justify-center h-full">
      <ExclamationCircleIcon className="h-16 w-16" />
      <Modal.Title className="text-3xl mt-4 font-bold">
        We detected an issue
      </Modal.Title>
      <div className="mt-8 flex flex-row">
        <Button variant="primary" size="md" onClick={handleClose}>
          Try again
        </Button>
        <Button
          variant="outlined"
          size="md"
          className="contact-us-trigger ml-2"
          as="a"
          href={MAILTO_LINK}
        >
          Contact us
        </Button>
      </div>
    </div>
  );
};
