import * as React from "react";
import { SVGProps, memo } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53 46"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle
      cx={19}
      cy={19}
      r={18.721}
      fill="#fff"
      stroke="#E5E7EB"
      strokeWidth={0.559}
    />
    <path
      fill="#111827"
      d="M24.7 22.34c0-6.68-8.338-4.332-8.338-7.777 0-1.41 1.03-2.192 2.574-2.192 1.595 0 2.625.887 2.625 2.427h3.062c0-3.158-2.368-5.298-5.687-5.298-3.268 0-5.61 2.036-5.61 5.063 0 6.525 8.286 3.732 8.286 7.778 0 1.487-.978 2.296-2.573 2.296-1.647 0-2.677-.887-2.677-2.427H13.3c0 3.158 2.393 5.298 5.739 5.298 3.294 0 5.661-2.088 5.661-5.167Z"
    />
    <rect width={26} height={21} x={26.5} y={24.5} fill="#F8F8F8" rx={3.5} />
    <path
      fill="#6B7280"
      d="M30.83 39v-7.45h2.44c.753 0 1.41.153 1.97.46.56.307.993.74 1.3 1.3.307.553.46 1.207.46 1.96 0 .747-.153 1.4-.46 1.96-.307.56-.74.997-1.3 1.31-.56.307-1.217.46-1.97.46h-2.44Zm.98-.9h1.47c.553 0 1.03-.117 1.43-.35.407-.233.72-.56.94-.98.22-.427.33-.927.33-1.5 0-.58-.113-1.08-.34-1.5a2.315 2.315 0 0 0-.94-.97c-.4-.233-.873-.35-1.42-.35h-1.47v5.65Zm8.802 1.02c-.52 0-.983-.123-1.39-.37a2.639 2.639 0 0 1-.96-1.01 3.01 3.01 0 0 1-.35-1.45c0-.54.113-1.02.34-1.44.233-.42.547-.75.94-.99.4-.247.847-.37 1.34-.37.4 0 .753.073 1.06.22.313.14.577.333.79.58.22.24.387.517.5.83.12.307.18.627.18.96 0 .073-.007.157-.02.25-.007.087-.017.17-.03.25h-4.42v-.8h3.88l-.44.36c.06-.347.027-.657-.1-.93a1.51 1.51 0 0 0-1.4-.89c-.313 0-.6.08-.86.24-.26.16-.463.39-.61.69-.14.293-.197.643-.17 1.05-.027.393.033.74.18 1.04.153.293.367.523.64.69.28.16.583.24.91.24.36 0 .663-.083.91-.25.247-.167.447-.38.6-.64l.78.4a1.993 1.993 0 0 1-.5.68c-.22.2-.483.36-.79.48-.3.12-.637.18-1.01.18Zm5.065-.12-2.11-5.39h1.04l1.69 4.56h-.36l1.7-4.56h1.04L46.557 39h-.88Z"
    />
    <rect width={26} height={21} x={26.5} y={24.5} stroke="#D0D0D0" rx={3.5} />
  </svg>
);

const SequinDevDbIcon = memo(SvgComponent);
export default SequinDevDbIcon;
