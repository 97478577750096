import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LockOpenIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  ExclamationIcon,
  PencilIcon,
  LockClosedIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { PlatformField, PlatformTableSchema } from "lib/api/types";

import {
  FieldState,
  ValidationMachine,
} from "components/platforms/common/forms/util";
import update from "immutability-helper";
import { Platform } from "lib/platforms";
import Tippy from "@tippyjs/react";
import Switch from "components/core/Switch";
import FocusTrap from "focus-trap-react";
import useFullObjectSearch from "lib/utils/useFullObjectSearch";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "lib/lucidez";
import { FilterBox } from "components/core/FilterBox";

interface State {
  columns: {
    [tableId: string]: {
      [columnId: string]: {
        dbName: string;
      };
    };
  };
  selectedColumns: {
    [tableId: string]: string[];
  };
}

enum StateActions {
  // selectedColumns transformations

  SelectFieldsToTable,
  UnselectFieldsToTable,

  // columns transformations
  InitiateTableFieldMapping,
  RemoveTableFieldMapping,
  ChangeTableFieldMapping,
}

export type StateActionObject =
  | {
      type: StateActions.SelectFieldsToTable;
      tableId: string;
      initialFieldIds: string[];
      fieldIds: string[];
    }
  | {
      type: StateActions.UnselectFieldsToTable;
      tableId: string;
      initialFieldIds: string[];
      fieldIds: string[];
    }
  | {
      type: StateActions.InitiateTableFieldMapping;
      tableId: string;
      fields: {
        fieldId: string;
        initialValue: string;
      }[];
    }
  | {
      type: StateActions.RemoveTableFieldMapping;
      tableId: string;
      fieldIds: string[];
    }
  | {
      type: StateActions.ChangeTableFieldMapping;
      tableId: string;
      fieldId: string;
      value: string;
    };

const reducer = (state: State, action: StateActionObject): State => {
  switch (action.type) {
    case StateActions.SelectFieldsToTable: {
      if (!state.selectedColumns[action.tableId]) {
        state = update(state, {
          selectedColumns: {
            [action.tableId]: {
              $set: action.initialFieldIds,
            },
          },
        });
      }

      // Filter out the fields that are already selected
      const newFields = action.fieldIds.filter(
        (fieldId) => !state.selectedColumns[action.tableId].includes(fieldId)
      );

      return update(state, {
        selectedColumns: {
          [action.tableId]: {
            $push: newFields,
          },
        },
      });
    }

    case StateActions.UnselectFieldsToTable: {
      if (!state.selectedColumns[action.tableId]) {
        state = update(state, {
          selectedColumns: {
            [action.tableId]: {
              $set: action.initialFieldIds,
            },
          },
        });
      }

      // Filter out the fields to unselect
      const newFields = state.selectedColumns[action.tableId].filter(
        (fieldId) => !action.fieldIds.includes(fieldId)
      );

      return update(state, {
        selectedColumns: {
          [action.tableId]: {
            $set: newFields,
          },
        },
      });
    }

    case StateActions.InitiateTableFieldMapping: {
      if (!state.columns[action.tableId]) {
        state = update(state, {
          columns: {
            [action.tableId]: { $set: {} },
          },
        });
      }

      action.fields.forEach((field) => {
        state = update(state, {
          columns: {
            [action.tableId]: {
              [field.fieldId]: {
                $set: {
                  dbName: field.initialValue,
                },
              },
            },
          },
        });
      });

      return state;
    }

    case StateActions.RemoveTableFieldMapping: {
      if (!state.columns[action.tableId]) {
        state = update(state, {
          columns: {
            [action.tableId]: { $set: {} },
          },
        });
      }

      action.fieldIds.forEach((fieldId) => {
        state = update(state, {
          columns: {
            [action.tableId]: {
              $unset: [fieldId],
            },
          },
        });
      });

      return state;
    }

    case StateActions.ChangeTableFieldMapping:
      if (!state.columns[action.tableId]) {
        state = update(state, {
          columns: {
            [action.tableId]: { $set: {} },
          },
        });
      }

      return update(state, {
        columns: {
          [action.tableId]: {
            [action.fieldId]: {
              dbName: {
                $set: action.value,
              },
            },
          },
        },
      });
  }
};

type Reducer = [State, React.Dispatch<StateActionObject>];

export const useColumnsFormReducer = (initialState: State) =>
  useReducer(reducer, initialState);

export const SelectColumnsForm = ({
  tableSchema,
  state,
  platform,
  validation,
  editingTableId,
}: {
  tableSchema: PlatformTableSchema;
  state: [State, React.Dispatch<StateActionObject>];
  platform: Platform<unknown, unknown, unknown>;
  validation: ValidationMachine;
  editingTableId: string;
}) => {
  const [invalidTables, setInvalidTables] = useState<string[]>([]);

  useEffect(() => {
    if (invalidTables.length > 0) {
      validation.setFieldState(FieldState.Invalid);
    } else {
      validation.setFieldState(FieldState.Valid);
    }
  }, [invalidTables]);

  return (
    <EditTableFieldsForm
      fields={tableSchema.fields}
      tableId={editingTableId}
      platform={platform}
      reducer={state}
      valid={invalidTables.includes(editingTableId)}
      setValid={(valid) => {
        if (valid) {
          setInvalidTables((value) => {
            const idx = value.indexOf(editingTableId);
            return update(value, { $splice: [[idx, 1]] });
          });
        } else {
          setInvalidTables((value) => {
            return update(value, { $push: [editingTableId] });
          });
        }
      }}
    />
  );
};

// We need to keep created_time because some AT queries are dependent on it.
const PERSISTENT_COLUMNS = ["id", "created_time"];

const EditTableFieldsForm = ({
  fields,
  tableId,
  platform,
  setValid,
  reducer,
}: {
  fields: PlatformField[];
  tableId: string;
  platform: Platform<unknown, unknown, unknown>;
  valid: boolean;
  setValid: (v: boolean) => void;
  reducer: Reducer;
}) => {
  const [state, dispatch] = reducer;

  const [filter, setFilter] = useState("");

  const [isBulkActions, setIsBulkActions] = useState(false);

  // The select all checkbox
  const checkboxRef = useRef<HTMLInputElement>();

  // Fields removed the persistent columns
  const unpersistentFields = fields.filter(
    (f) =>
      !PERSISTENT_COLUMNS.includes(f.db_name) && (!f.meta || !f.meta.internal)
  );

  // The initial field ids from which the transformations
  // on selected columns are applied
  const initialFieldIds = unpersistentFields.map((f) => f.id);

  // List of matches of the fitler
  const filteredResults = useFullObjectSearch(filter, unpersistentFields);

  // These are the final filtered columns
  // This is the object used to list the columns to the user
  const filteredFields = unpersistentFields.filter((f, idx) =>
    filteredResults.indexes.includes(idx)
  );
  const filteredFieldsIds = filteredFields.map((f) => f.id);

  // These are the ids of the fields with the checkbox marked
  const [selectedFieldsIds, setSelectedFieldsIds] = useState<string[]>([]);
  const selectedFields = unpersistentFields.filter((f) =>
    selectedFieldsIds.includes(f.id)
  );

  const selectedFieldsIdsIntersection = selectedFieldsIds.filter((f) =>
    filteredFieldsIds.includes(f)
  );

  // Selected fields that are not in the filtered fields
  const selectedFilteredOutFields = unpersistentFields.filter(
    (f) => selectedFieldsIds.includes(f.id) && !filteredFieldsIds.includes(f.id)
  );

  // How many fields were filtered out
  const filteredOutResults =
    selectedFieldsIds.length - selectedFieldsIdsIntersection.length;

  // Are all visible fields selected (i.e. selectedFieldsIds == filteredFields)
  const isAllVisibleFieldsSelected =
    selectedFieldsIdsIntersection.length === filteredFieldsIds.length;
  const isIndeterminate =
    selectedFieldsIdsIntersection.length > 0 && !isAllVisibleFieldsSelected;

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate;
    }
  }, [isIndeterminate]);

  // Handle the select of one checkbox, add the field id to the selected fields
  const handleSelectField = (fieldId: string, select: boolean) => {
    setSelectedFieldsIds((value) => {
      if (select) {
        return !value.includes(fieldId) ? [...value, fieldId] : value;
      } else {
        return value.filter((f) => f !== fieldId);
      }
    });
  };

  // Select all the visible fields not already selected
  const handleSelectAllVisibleFields = () => {
    setSelectedFieldsIds((value) => {
      const newSelectedFieldsIds = filteredFieldsIds.filter(
        (f) => !value.includes(f)
      );
      return [...value, ...newSelectedFieldsIds];
    });
  };

  // Deselect all the visible fields
  const handleDeselectAllVisibleFields = () => {
    setSelectedFieldsIds((value) => {
      return value.filter((f) => !filteredFieldsIds.includes(f));
    });
  };

  // Toggle the select all checkbox
  const handleToggleSelectAll = () => {
    if (isAllVisibleFieldsSelected) {
      handleDeselectAllVisibleFields();
    } else {
      handleSelectAllVisibleFields();
    }
  };

  const syncAllSelected = () => {
    dispatch({
      type: StateActions.SelectFieldsToTable,
      tableId,
      fieldIds: selectedFieldsIds,
      initialFieldIds,
    });
  };

  const unsyncAllSelected = () => {
    const fieldsToUnselect = selectedFields
      .filter(
        (x) =>
          !(
            x.meta?.required_for_write ||
            (x.meta?.required && x.meta?.creatable)
          )
      )
      .map((x) => x.id);

    dispatch({
      type: StateActions.UnselectFieldsToTable,
      tableId,
      fieldIds: fieldsToUnselect,
      initialFieldIds,
    });
  };

  // TODO: Re-implement validation around the fields names
  // not urgent, because the user can't save the field name if the
  // name is invalid
  const isTableValid = () => {
    return true;
  };

  useEffect(() => {
    setValid(isTableValid());
  }, []);

  // Handle the individual field sync switch toggle
  const handleCheckChange = (fieldId: string, value: boolean) => {
    dispatch({
      type: value
        ? StateActions.SelectFieldsToTable
        : StateActions.UnselectFieldsToTable,
      tableId,
      fieldIds: [fieldId],
      initialFieldIds,
    });
  };

  const startEditing = (fields: PlatformField[]) => {
    dispatch({
      type: StateActions.InitiateTableFieldMapping,
      tableId,
      fields: fields.map((f) => ({ fieldId: f.id, initialValue: f.db_name })),
    });
  };

  const isEditingField = (field: PlatformField) => {
    return !!(state.columns[tableId] && state.columns[tableId][field.id]);
  };

  // Fields with the name locks / renaming applied
  const transformedFields: PlatformField[] = fields.map((f) => {
    const renamed = state.columns[tableId] && state.columns[tableId][f.id];
    return {
      ...f,
      db_name: renamed ? renamed.dbName : f.db_name,
    };
  });

  // Handle batch lock
  const handleLock = () => {
    const transformedSelectedFields = selectedFields.map((f) =>
      transformedFields.find((tf) => tf.id === f.id)
    );

    startEditing(transformedSelectedFields);
  };

  const handleToggleBulkActions = () => {
    window.analytics.track("Click Column Mapping Toggle Bulk Actions");

    setIsBulkActions((b) => !b);
  };

  // Handle batch unlock
  const handleUnlock = () => {
    dispatch({
      type: StateActions.RemoveTableFieldMapping,
      tableId,
      fieldIds: selectedFieldsIds,
    });
  };

  const isAnySelected = selectedFieldsIds.length > 0;

  const forceAllWritable = !!platform.helpers.find(
    (helper) => helper.name === "ALL_WRITABLE"
  );

  return (
    <div className="border rounded">
      <div className="px-6 pt-4 pb-2">
        <div className="flex flex-row items-center">
          <AnimatePresence presenceAffectsLayout>
            <FilterBox
              label="Search columns"
              filter={filter}
              setFilter={setFilter}
            />

            <motion.button
              className={`${
                isBulkActions ? "btn-primary" : "btn-outlined"
              } h-10 flex flex-row items-center ml-3 px-3 transition-all border text-xs font-bold`}
              onClick={handleToggleBulkActions}
              key="bulkActions"
            >
              {isBulkActions ? (
                <>
                  <span>Close bulk actions</span>
                  <ChevronUpIcon className="h-4 w-4 ml-2" />
                </>
              ) : (
                <>
                  <span>Bulk actions</span>
                  <ChevronDownIcon className="h-4 w-4 ml-2" />
                </>
              )}
            </motion.button>
          </AnimatePresence>
        </div>
        <AnimatePresence presenceAffectsLayout>
          {isBulkActions && (
            <motion.div
              className="flex flex-row mt-3 bg-gray-100 rounded items-center px-1"
              initial={{
                y: -10,
              }}
              animate={{
                y: 0,
              }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              key="buttons"
            >
              <div className="w-full text-xs bg-gray-100 flex items-center justify-center text-center font-medium rounded h-10">
                {selectedFieldsIds.length} selected{" "}
                {filteredOutResults > 0 && (
                  <>({filteredOutResults} filtered out)</>
                )}
              </div>

              <Button
                variant="outlined"
                size="sm"
                onClick={syncAllSelected}
                disabled={!isAnySelected}
                className="ml-1 btn-white"
              >
                Sync selected
              </Button>

              <Button
                variant="outlined"
                size="sm"
                onClick={unsyncAllSelected}
                disabled={!isAnySelected}
                className="ml-1 btn-white"
              >
                Turn off selected
              </Button>

              <Tippy
                content={
                  <p className="text-left p-2 block">
                    If the name changes in the API,
                    <br />
                    don't change the name in the database.
                  </p>
                }
                placement="top"
                interactive
              >
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleLock}
                  disabled={!isAnySelected}
                  className="ml-2 btn-white"
                >
                  Lock selected
                </Button>
              </Tippy>
              <Tippy
                content={
                  <p className="text-left p-2 block">
                    If the name changes in the API,
                    <br />
                    change the name in the database to match.
                  </p>
                }
                placement="top"
                interactive
              >
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleUnlock}
                  disabled={!isAnySelected}
                  className="ml-1 btn-white"
                >
                  Unlock selected
                </Button>
              </Tippy>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <table className="w-full border-collapse rounded mt-3 border-cool-gray-200 text-xs">
        <thead className="text-xxs">
          <tr>
            {isBulkActions && (
              <th className="border-b border-t border-gray-200 py-2 pl-4 text-center">
                <input
                  className="form-checkbox rounded-sm text-black ring-black cursor-pointer"
                  type="checkbox"
                  ref={checkboxRef}
                  checked={isAllVisibleFieldsSelected}
                  onChange={handleToggleSelectAll}
                />
              </th>
            )}
            <th className="border-b border-t border-gray-200 py-2 px-4">
              {platform.displayName} name
            </th>
            <th className="border-b border-t border-gray-200 py-2 px-4">
              Database name
            </th>
            <th className="border-b border-t border-gray-200 py-2 px-4 text-center">
              Enabled
            </th>
            <th className="border-b border-t border-gray-200 py-2 px-4">
              Features
            </th>
            <th className="border-b border-t border-gray-200 py-2 px-4 text-center">
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredFields.map((field) => (
            <FieldRow
              key={field.id}
              field={field}
              forceAllWritable={forceAllWritable}
              isSyncing={
                !state.selectedColumns[tableId] ||
                state.selectedColumns[tableId].includes(field.id)
              }
              onIsSyncingChange={(v) => handleCheckChange(field.id, v)}
              isLocked={isEditingField(field)}
              onEdit={() => {
                startEditing([field]);
              }}
              onChange={(value) => {
                dispatch({
                  type: StateActions.ChangeTableFieldMapping,
                  tableId,
                  fieldId: field.id,
                  value,
                });
              }}
              onRemove={() => {
                dispatch({
                  type: StateActions.RemoveTableFieldMapping,
                  tableId,
                  fieldIds: [field.id],
                });
              }}
              transformedFields={transformedFields}
              isSelected={selectedFieldsIds.includes(field.id)}
              onIsSelectedToggle={(v) => handleSelectField(field.id, v)}
              isBulkActions={isBulkActions}
            />
          ))}
        </tbody>
      </table>

      {selectedFilteredOutFields.length > 0 && (
        <div className="mt-5">
          <h3 className="font-bold">Selected columns filtered out by search</h3>
          <table className="w-full border-collapse rounded mt-3">
            <tbody>
              {selectedFilteredOutFields.map((field) => (
                <FieldRow
                  key={field.id}
                  field={field}
                  forceAllWritable={forceAllWritable}
                  isSyncing={
                    !state.selectedColumns[tableId] ||
                    state.selectedColumns[tableId].includes(field.id)
                  }
                  onIsSyncingChange={(v) => handleCheckChange(field.id, v)}
                  isLocked={isEditingField(field)}
                  onEdit={() => {
                    startEditing([field]);
                  }}
                  onChange={(value) => {
                    dispatch({
                      type: StateActions.ChangeTableFieldMapping,
                      tableId,
                      fieldId: field.id,
                      value,
                    });
                  }}
                  onRemove={() => {
                    dispatch({
                      type: StateActions.RemoveTableFieldMapping,
                      tableId,
                      fieldIds: [field.id],
                    });
                  }}
                  transformedFields={transformedFields}
                  isSelected={selectedFieldsIds.includes(field.id)}
                  onIsSelectedToggle={(v) => handleSelectField(field.id, v)}
                  isBulkActions={isBulkActions}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const FieldRow = ({
  field,
  forceAllWritable,
  isSyncing,
  onIsSyncingChange,
  isLocked,
  onEdit,
  onRemove,
  onChange,
  transformedFields,
  isSelected,
  onIsSelectedToggle,
  isBulkActions,
}: {
  field: PlatformField;
  forceAllWritable?: boolean;
  isSyncing: boolean;
  onIsSyncingChange: (checked: boolean) => void;
  isSelected: boolean;
  onIsSelectedToggle: (checked: boolean) => void;
  isLocked: boolean;
  onEdit: () => void;
  onRemove: () => void;
  onChange: (v: string) => void;
  errorMessage?: string;
  transformedFields: PlatformField[];
  isBulkActions?: boolean;
}) => {
  const transformedField = transformedFields.find((f) => f.id === field.id);
  const [isEditing, setIsEditing] = useState(false);
  const [wasPreviouslyLocked, setWasPreviouslyLocked] = useState(false);

  const isRequired =
    field.meta?.required_for_write ||
    (field.meta?.required && field.meta?.creatable);

  // Force re-enable of required field
  useEffect(() => {
    if (isRequired && !isSyncing) {
      onIsSyncingChange(true);
    }
  }, [isRequired, isSyncing]);

  return (
    <tr className={isSyncing ? "bg-white" : "bg-gray-100"}>
      {isBulkActions && (
        <td
          className="border-b border-t border-gray-200 py-3 pl-4 text-center"
          width={32}
        >
          <input
            className="form-checkbox rounded-sm text-black ring-black cursor-pointer"
            type="checkbox"
            checked={isSelected}
            onChange={() => onIsSelectedToggle(!isSelected)}
          />
        </td>
      )}
      <td
        className="border-b border-t border-gray-200 text-gray-900 py-2 px-4  overflow-hidden overflow-ellipsis max-w-0"
        title={field.accessor}
      >
        {field.accessor}
        <br />
        {field.meta?.label &&
          (field.meta.label !== field.accessor || field.meta.description) && (
            <span className="text-xxs flex flex-row items-center mt-1 text-gray-600">
              {field.meta.description && (
                <Tippy content={field.meta.description} interactive>
                  <InformationCircleIcon className="h-3 w-3 inline-block mr-1" />
                </Tippy>
              )}
              {field.meta.label}
            </span>
          )}
      </td>
      <td className="border-b border-t border-gray-200 text-gray-900 overflow-hidden overflow-ellipsis max-w-0">
        {isEditing ? (
          <FieldRowEdit
            transformedFields={transformedFields}
            field={transformedField}
            initialValue={transformedField.db_name}
            onCancel={() => {
              if (!wasPreviouslyLocked) {
                onRemove();
              }

              setIsEditing(false);
            }}
            onCommit={(val) => {
              onChange(val);
              setIsEditing(false);
            }}
          />
        ) : (
          <FieldRowDisplay
            transformedField={transformedField}
            isLocked={isLocked}
            onEdit={() => {
              setWasPreviouslyLocked(isLocked);

              if (!isLocked) {
                onEdit();
              }

              setIsEditing(true);
            }}
            onLock={onEdit}
            onUnlock={onRemove}
          />
        )}
      </td>
      <td
        className="border-b border-t border-gray-200 text-gray-900 py-3 px-4 text-center"
        width={64}
      >
        <Tippy
          content="This field is required"
          interactive
          visible={!isRequired ? false : undefined}
        >
          <div className="flex flex-row justify-center">
            <Switch
              checked={isSyncing}
              onChange={() => onIsSyncingChange(!isSyncing)}
              disabled={isRequired}
            />
          </div>
        </Tippy>
      </td>
      <td
        className="border-b border-t border-gray-200 text-gray-900 px-4"
        width={224}
      >
        <div className="flex flex-row h-full gap-3">
          <div>
            <Tippy
              content={
                field.meta?.nullable === false
                  ? "Field can't be null"
                  : "Field can be null"
              }
              interactive
            >
              <span
                className={`block font-bold text-xxs ${
                  field.meta?.nullable === false ? "" : "opacity-20"
                }`}
              >
                Not null
              </span>
            </Tippy>
          </div>
          <div className="w-px h-3.5 bg-gray-200" />
          <div>
            <Tippy
              content={
                field.meta?.creatable || forceAllWritable
                  ? "Creatable using INSERT"
                  : "Not creatable using SQL"
              }
              interactive
            >
              <span
                className={`block font-bold text-xxs ${
                  !field.meta?.creatable && !forceAllWritable
                    ? "opacity-20"
                    : ""
                }`}
              >
                Creatable
              </span>
            </Tippy>
          </div>
          <div>
            <Tippy
              content={
                field.meta?.updatable || forceAllWritable
                  ? "Updatable using SQL"
                  : "Not updatable using SQL"
              }
              interactive
            >
              <span
                className={`block font-bold text-xxs ${
                  !field.meta?.updatable && !forceAllWritable
                    ? "opacity-20"
                    : ""
                }`}
              >
                Updatable
              </span>
            </Tippy>
          </div>
        </div>
      </td>
      <td
        className="border-b border-t border-gray-200 text-gray-900 px-4  font-mono text-center"
        width={96}
      >
        {field.type}
      </td>
    </tr>
  );
};

const FieldRowEdit = ({
  field,
  initialValue,
  transformedFields,
  onCancel,
  onCommit,
}: {
  field: PlatformField;
  initialValue: string;
  transformedFields: PlatformField[];
  onCancel: () => void;
  onCommit: (val: string) => void;
}) => {
  const [value, setValue] = useState(initialValue);

  const [errors, setErrors] = useState<string[]>([]);
  const errorMessage = errors[0];

  const isColumnValueDuplicate = (fieldId: string, v: string) => {
    return (
      transformedFields.findIndex((x) => x.db_name === v && x.id !== fieldId) >=
      0
    );
  };

  const doValidation = () => {
    if (typeof value === "undefined") {
      return setErrors((value) =>
        update(value, {
          $set: [],
        })
      );
    }

    if (isColumnValueDuplicate(field.id, value)) {
      return setErrors((value) =>
        update(value, {
          $set: ["Name is already in use"],
        })
      );
    }

    if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value)) {
      return setErrors((value) =>
        update(value, {
          $set: ["Name is invalid"],
        })
      );
    }

    return setErrors((value) =>
      update(value, {
        $set: [],
      })
    );
  };

  useEffect(() => {
    doValidation();
  }, [value]);

  return (
    <FocusTrap>
      <div
        className={`w-full h-full flex flex-row focus-within:ring ${
          errorMessage ? "ring-error" : "ring-black"
        } items-center z-30`}
      >
        <input
          className="w-full h-full flex-1 py-3 px-4 focus:outline-none bg-transparent"
          placeholder="Enter column name..."
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {errorMessage && (
          <Tippy content={errorMessage} placement="top" interactive>
            <button className="block h-5 w-5 mr-4">
              <ExclamationIcon className="h-5 w-5 text-red-500" />
            </button>
          </Tippy>
        )}

        <Button
          variant="outlined"
          size="xs"
          className="h-full justify-center items-center py-2"
          iconRight={<XIcon className="h-5 w-5" />}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          size="xs"
          className="h-full justify-center items-center py-2 ml-1 mr-3"
          iconRight={<CheckIcon className="h-5 w-5" />}
          disabled={!!errorMessage}
          onClick={() => onCommit(value)}
        >
          Save
        </Button>
      </div>
    </FocusTrap>
  );
};

const FieldRowDisplay = ({
  transformedField,
  isLocked,
  onEdit,
  onLock,
  onUnlock,
}: {
  transformedField: PlatformField;
  isLocked: boolean;
  onEdit: () => void;
  onLock: () => void;
  onUnlock: () => void;
}) => {
  return (
    <div className="w-full h-full flex flex-row items-center z-30">
      <span
        className="w-full h-full flex-1 py-3 px-4 focus:outline-none overflow-hidden overflow-ellipsis"
        title={transformedField.db_name}
      >
        {transformedField.db_name}
      </span>
      <Tippy content="Edit" placement="top" interactive>
        <button
          className="btn-outlined h-full flex justify-center items-center p-1"
          onClick={onEdit}
        >
          <PencilIcon className="h-5 w-5" />
        </button>
      </Tippy>
      {isLocked ? (
        <Tippy
          content={
            <div className="text-left p-2 block">
              <b>Unlock column</b>
              <br />
              <p className="mt-2">
                If the name changes in the API,
                <br />
                change the name in the database to match.
              </p>
            </div>
          }
          placement="top"
          interactive
        >
          <button
            className="btn-primary h-full flex justify-center items-center p-1 ml-1 mr-3 border border-black"
            onClick={onUnlock}
          >
            <LockClosedIcon className="h-5 w-5" />
          </button>
        </Tippy>
      ) : (
        <Tippy
          content={
            <div className="text-left p-2 block">
              <b>Lock column</b>
              <br />
              <p className="mt-2">
                If the name changes in the API,
                <br />
                don't change the name in the database.
              </p>
            </div>
          }
          placement="top"
          interactive
        >
          <button
            className="btn-outlined h-full flex justify-center items-center p-1 ml-1 mr-3"
            onClick={onLock}
          >
            <LockOpenIcon className="h-5 w-5" />
          </button>
        </Tippy>
      )}
    </div>
  );
};
