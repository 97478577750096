import {
  FieldValidationError,
  BaseServerFieldError,
  ValidationFieldErrors,
  ApiError,
} from "lib/api/types";

export const bindEnterKeyPress = (callback: () => any) => {
  return (ev) => {
    if (ev.key === "Enter") {
      callback();
    }
  };
};

// React Hook Form

/**
 * Method converts the errors returned from backend (Ecto changeset errors)
 * and perpares them to be easily consumed by the react-hook-form forms.
 */
const normalizeErrorsForForm = (apiError: ApiError): FieldValidationError[] => {
  // added to satisfy the compiler
  if (!apiError.error.validation_errors) {
    return [];
  }

  const validationErrors = apiError.error.validation_errors;
  return Object.keys(validationErrors).flatMap((fieldName) =>
    normalizeError(fieldName, validationErrors[fieldName])
  );
};

const normalizeError = (
  fieldName: string,
  fieldErrors: ValidationFieldErrors,
  parentField?: string
): FieldValidationError[] => {
  const name = normalizeFieldName(fieldName, parentField);

  // here to properly map the errors we have to distinguish between different types of fields:
  // - fields (e.g. name="name")
  // - nested field (e.g. name="connection.host")
  // - repeatable fields(e.g. name={`users.${index}.name`})

  // if it's an array of strings, it's a field
  // Note: we can do a fieldErrors.every(e => typeof e === "string") here to satisfy the compiler but that might be a bit too much
  const isField =
    Array.isArray(fieldErrors) && typeof fieldErrors[0] === "string";

  // if it's a nested object then it means it's a nested form
  const isNestedForm = typeof fieldErrors === "object";

  // if it's a repeatble field, then it's an array of objects
  const isRepeatableForm =
    Array.isArray(fieldErrors) && typeof fieldErrors[0] === "object";

  if (isField) {
    return [
      {
        name: name,
        errors: fieldErrors as string[],
      },
    ];
  } else if (isNestedForm) {
    const errors = Object.keys(fieldErrors).flatMap((fieldName) =>
      normalizeError(
        fieldName,
        (fieldErrors as BaseServerFieldError)[fieldName],
        name
      )
    );
    return errors;
  } else if (isRepeatableForm) {
    const errors = (fieldErrors as BaseServerFieldError[]).flatMap(
      (repeatableFieldErrors, index: number) =>
        normalizeError(`${index}`, repeatableFieldErrors, name)
    );
    return errors;
  } else {
    // shouldn't end up here, but let's have it just in case
    return [];
  }
};

const normalizeFieldName = (fieldName: string, parentFieldName?: string) => {
  if (parentFieldName) {
    return `${parentFieldName}.${fieldName}`;
  } else {
    return fieldName;
  }
};

export default { normalizeErrorsForForm, bindEnterKeyPress };
