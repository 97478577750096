import React, { useState } from "react";
import _ from "lodash";

interface SecretInputProps extends React.HTMLProps<HTMLInputElement> {
  forceShow?: boolean;
}

export default function SecretInput(props: SecretInputProps) {
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const hidden = !props.forceShow && !hovered && !focused;

  return (
    <input
      onFocus={(ev) => {
        setFocused(true);
        ev.target.select();
      }}
      onBlur={() => setFocused(false)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
      value={
        /* This is so browsers don't prompt to save the data inserted */
        hidden
          ? _.repeat("•", (props.value ? props.value.toString() : "").length)
          : props.value
      }
      autoComplete="off"
      type="text"
    />
  );
}
