import React from "react";
import classNames from "classnames";
import { FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";

interface InputErrorWrapperProps {
  errors: FieldErrors<any>;
  name: string;
  label: string;
  children: (hasError: boolean) => React.ReactNode;
}

export const InputErrorWrapper = ({
  children,
  errors,
  name,
  label,
}: InputErrorWrapperProps) => {
  const hasErrors = _.get(errors, name, null) !== null;

  return (
    <label
      className={classNames({
        "p-4 bg-red-50 border border-red-400 rounded-lg": hasErrors,
      })}
    >
      <span className="font-bold">{label}</span>
      {children(hasErrors)}
      <p className="text-red-700 text-xs mt-1.5">
        <ErrorMessage errors={errors} name={name} />
      </p>
    </label>
  );
};
