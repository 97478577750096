import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 66 66" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.922 40.425S46.02 10.235 47.968 8.292c2.158-2.162 9.124-3.79 12.59-4.488a1.375 1.375 0 011.619 1.62c-.699 3.464-2.327 10.427-4.488 12.589L25.602 50.097l-9.68-9.672zM19.805 36.503l9.696 9.705M40.191 35.517c.126 4.239-.2 8.48-.97 12.65a18.754 18.754 0 01-3.895 5.835l-6.921 6.91a1.375 1.375 0 01-2.34-1.132l1.344-11.484M30.47 25.793a60.113 60.113 0 00-12.72.883 18.671 18.671 0 00-5.753 3.973l-6.902 6.9a1.375 1.375 0 001.141 2.337l11.55-1.413M19.77 50.097c1.947 1.944 1.136 4.7-.97 6.809-1.972 1.969-9.447 4.215-13.272 5.266a1.375 1.375 0 01-1.68-1.672c1.04-3.836 3.264-11.333 5.225-13.307 2.137-2.134 4.865-2.915 6.809-.971"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const SvgLaunchDatabaseOutlined = React.memo(SvgComponent);
export default SvgLaunchDatabaseOutlined;
