import React, { JSXElementConstructor, ReactElement } from "react";
import Tippy from "@tippyjs/react/headless";

export default function HoverPopover({
  children,
  content,
  bodyProps,
  containerProps,
}: {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  content?: React.ReactNode;
  bodyProps?: React.ComponentProps<"div">;
  containerProps?: React.ComponentProps<typeof Tippy>;
}) {
  return (
    <Tippy
      interactive
      {...containerProps}
      render={(attrs) => (
        <div
          {...bodyProps}
          className={
            "bg-white dark:bg-gray-800 shadow-md text-xs p-3 px-3 rounded text-gray-600 dark:text-gray-400 border dark:border-0 border-gray-100" +
            ((bodyProps && bodyProps.className) || "")
          }
          tabIndex={-1}
          {...attrs}
        >
          {content}
        </div>
      )}
    >
      {children}
    </Tippy>
  );
}
