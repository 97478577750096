import merge from "lodash/merge";
import pickBy from "lodash/pickBy";

import { Resource, ResourceKind } from "lib/platforms/index";
import {
  BaseCredential,
  ResourceDestination,
  ResourceEnvironment,
} from "lib/api/types";
import { normalizeTableKeysFromRHF } from "lib/utils/reactHookForm";

export interface BaseState<C extends BaseCredential> {
  credential?: C;
  destination: ResourceDestination;
  lazyForeignKeys: boolean;
  name: string;
  resourceEnvironment: ResourceEnvironment;
  syncAllTables: boolean;

  columns: {
    [tableId: string]: {
      [columnId: string]: {
        dbName?: string;
      };
    };
  };

  selectedColumns: {
    [tableId: string]: string[];
  };

  tables: {
    [tableId: string]: {
      enabled: boolean;
    };
  };
}

export const baseInitialCreateState = (
  initialName: string
): BaseState<unknown> => ({
  columns: {},
  credential: null,
  destination: { type: "placeholder" },
  lazyForeignKeys: false,
  name: initialName,
  resourceEnvironment: null,
  selectedColumns: {},
  syncAllTables: false,
  tables: {},
});

export const baseInitialUpdateState = (
  resource: Resource
): BaseState<unknown> => {
  const columns = resource.schema.columns.reduce<{
    [tableId: string]: {
      [columnId: string]: {
        dbName?: string;
      };
    };
  }>((acc, tbl) => {
    return {
      ...acc,
      [tbl.tableId]: tbl.columns.reduce<{
        [columnId: string]: {
          dbName?: string;
        };
      }>((acc, col) => {
        return {
          ...acc,
          [col.columnId]: col.patch,
        };
      }, {}),
    };
  }, {});

  const selectedColumns = resource.schema.selectedColumns.reduce<{
    [tableId: string]: string[];
  }>((acc, tbl) => {
    return {
      ...acc,
      [tbl.tableId]: tbl.columns,
    };
  }, {});

  return {
    columns: columns,
    credential: resource.credential,
    destination: {
      type:
        resource.database.definition.type === "dedicated_postgres"
          ? "external"
          : "existing",
      validSchema: true,
      databaseId: resource.database.id,
      schema: resource.database.schema,
    },
    lazyForeignKeys: resource.schema.lazyForeignKeys,
    name: resource.name,
    resourceEnvironment: resource.resourceEnvironment,
    selectedColumns: selectedColumns,
    syncAllTables: resource.schema.syncAllTables,
    tables: resource.schema.tables.reduce((acc, tbl) => {
      return {
        ...acc,
        [tbl]: {
          enabled: true,
        },
      };
    }, {}),
  };
};

export const basePrepareFieldsForCreate = (
  data: BaseState<BaseCredential>,
  kind: ResourceKind
) => {
  if (!data.credential) {
    throw new Error("No credential informed.");
  }

  const tableIds = Object.keys(
    pickBy(data.tables, (v) => {
      return v.enabled === true;
    })
  );

  let value: any = {
    credentialId: data.credential.id,
    definition: {},
    kind,
    name: data.name,
    resourceEnvironment: data.resourceEnvironment,
    schema: {
      transforms: {
        selectedTables: data.syncAllTables ? [] : tableIds,
        lazyForeignKeys: data.lazyForeignKeys,
        // phoenix messes up this. send as raw json
        columns: data.columns
          ? JSON.stringify(data.columns).replaceAll('"dbName":', '"db_name":')
          : undefined,
        selectedColumns: data.selectedColumns
          ? JSON.stringify(data.selectedColumns)
          : undefined,
      },
    },
  };

  if (
    data.destination.type !== "new" &&
    data.destination.type !== "placeholder"
  ) {
    value = {
      ...value,
      db_namespace: data.destination.schema,
      databaseId: data.destination.databaseId,
    };
  }

  return value;
};

export const basePrepareFieldsForUpdate = (
  data: Partial<BaseState<BaseCredential>>,
  kind: ResourceKind
) => {
  let value: any = {
    name: data.name,
    credentialId: data.credential?.id,
    definition: {},
    schema: {
      transforms: {
        lazyForeignKeys: data.lazyForeignKeys,
        // phoenix messes up this. send as raw json
        columns: data.columns
          ? JSON.stringify(data.columns).replaceAll('"dbName":', '"db_name":')
          : undefined,
        selectedColumns: data.selectedColumns
          ? JSON.stringify(data.selectedColumns)
          : undefined,
      },
    },
    kind,
  };

  if (data.tables || data.syncAllTables) {
    if (kind === "stripe") {
      data.tables = normalizeTableKeysFromRHF(data.tables);
    }

    const tableIds = Object.keys(
      pickBy(data.tables, (v) => {
        return v.enabled === true;
      })
    );

    value = merge(value, {
      schema: {
        transforms: { selectedTables: data.syncAllTables ? [] : tableIds },
      },
    });
  }

  if (
    data.destination &&
    data.destination.type !== "new" &&
    data.destination.type !== "placeholder"
  ) {
    value = {
      ...value,
      db_namespace: data.destination.schema,
      databaseId: data.destination.databaseId,
    };
  }

  return value;
};
