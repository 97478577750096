import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import {
  FormProps,
  getPlatformForKind,
  ResourceKind,
  Resource,
} from "../../../lib/platforms";

interface ResourceFormProps extends FormProps {
  isFullPage?: boolean;
  kind?: ResourceKind;
}

const ResourceForm = (props: ResourceFormProps) => {
  const kind = props.kind;
  const platform = useMemo(() => getPlatformForKind(kind), [kind]);

  const PlatformForm =
    props?.isFullPage === true ? platform.fullPageForm : platform.buildForm;

  return <PlatformForm {...props} />;
};

// This context is used to enable the legacy syncAll switch for older
// syncs who previously had them enabled
const ResourceContext = createContext<{
  resource?: Resource;
}>({
  resource: null,
});

export const ResourceProvider = ({
  children,
  resource,
}: PropsWithChildren<{ resource?: Resource }>) => {
  return (
    <ResourceContext.Provider value={{ resource }}>
      {children}
    </ResourceContext.Provider>
  );
};

export const useResourceFromContext = () => {
  const ctx = useContext(ResourceContext);

  if (ctx) {
    return ctx.resource;
  }

  return null;
};

export default ResourceForm;
