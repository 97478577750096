import React, { useState } from "react";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";

import { SshTunnelParams, createExternalDatabase } from "lib/api";
import { Button } from "lib/lucidez";
import {
  hostPortExternalDbFormDefaultValues,
  credentialsExternalDbFormDefaultValues,
  ExternalDatabaseSetupProps,
  handleExternalSetupFormErrors,
  ExternalDatabaseSetupStep,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup";
import { DatabaseSetupLayout } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/DatabaseSetupLayout";
import Modal from "components/core/Modal";
import MarkdownRender from "components/core/MarkdownRender";
import { CopyableCodeLine } from "components/core/CopyableCodeLine";
import {
  HostPortExternalDbFormInputs,
  HostSection,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/HostSection";
import {
  CredentialsForm,
  CredentialsFormInputs,
  DatabaseInfoForm,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/CredentialsForm";
import Collapse from "components/core/Collapse";
import { SshTunnelSection } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/SshTunnelSection";
import { track } from "lib/utils/analytics";

type ManualDbFormInputs = HostPortExternalDbFormInputs & CredentialsFormInputs;

export function ManualFlow({
  onBack,
  resourceKind,
  setSetupStep,
  onDestinationChange,
  setProvisionDetails,
  onFinishFlow,
}: ExternalDatabaseSetupProps) {
  const [fatalError, setFatalError] = useState<null | string>(null);
  const [working, setWorking] = useState(false);

  const formMethods = useForm<ManualDbFormInputs>({
    defaultValues: {
      ...hostPortExternalDbFormDefaultValues(),
      ...credentialsExternalDbFormDefaultValues(resourceKind),
    },
  });

  const submitData = async (fields: ManualDbFormInputs) => {
    setFatalError(null);
    setWorking(true);

    const useTunnel = fields.sshTunnel.useTunnel;

    let tunnelParams: SshTunnelParams;

    if (useTunnel === "new") {
      tunnelParams = {
        type: "new",
        attrs: {
          host: fields.sshTunnel.host,
          port: Number.parseInt(fields.sshTunnel.port, 10),
          user: fields.sshTunnel.user,
        },
      };

      if (!tunnelParams.attrs.host) {
        formMethods.setError("sshTunnel.host", {
          message: "Host is required",
        });
        setWorking(false);
        return;
      }

      if (!tunnelParams.attrs.user) {
        formMethods.setError("sshTunnel.user", {
          message: "User is required",
        });
        setWorking(false);
        return;
      }
    } else if (useTunnel === "existing") {
      tunnelParams = {
        type: "existing",
        id: fields.sshTunnel.id,
      };
    }

    const params = {
      definition: {
        host: fields.host.host,
        port: Number.parseInt(fields.host.port, 10),
        dbname: fields.dbname,
        ssl: fields.ssl,
        user: fields.user,
        password: fields.password,
      },
      ssh_tunnel: tunnelParams,
      schema: fields.schema,
      name: fields.dbname,
    };

    const res = await createExternalDatabase(params);

    if (res.isErr()) {
      track("Destination Connect Failed", { error: res?.error?.error?.slug });
      const wasErrorHandled = handleExternalSetupFormErrors(
        res.error,
        formMethods.setError as any,
        setFatalError,
        fields
      );

      if (!wasErrorHandled) {
        setSetupStep(ExternalDatabaseSetupStep.FatalErrorScreen);
      }

      setWorking(false);
      return;
    }

    toast("Successfully connected to the database.");

    track("Destination Connect Succeeded", {
      databaseId: res.value.database.id,
    });

    onDestinationChange({
      type: "external",
      databaseId: res.value.database.id,
      schema: fields.schema,
      validSchema: true,
    });

    setProvisionDetails({
      user: res.value.database.definition.user,
      readGroup: res.value.database.readGroup,
      dbName: res.value.database.name,
      dbId: res.value.database.id,
    });

    await mutate("/api/databases");
    onFinishFlow();
    setWorking(false);
  };

  return (
    <FormProvider {...formMethods}>
      <DatabaseSetupLayout
        leftSideAction={
          <Button variant="outlined" size="lg" onClick={onBack}>
            Go back
          </Button>
        }
        rightSideAction={
          <Button
            variant="primary"
            size="lg"
            isLoading={working}
            onClick={() => {
              formMethods.handleSubmit(submitData)();
            }}
          >
            Continue
          </Button>
        }
      >
        <div className="w-full max-w-md mx-auto">
          <Modal.Title className="text-xl font-bold mt-8 mb-7 text-center">
            Sync to the database you host
          </Modal.Title>

          <section className="mt-4">
            <p>
              Before proceeding, please follow these steps in your target
              database:
            </p>
            <h2 className="font-bold mt-4 text-base mb-2">
              1. Create a Postgres user for Sequin
            </h2>
            <CopyableCodeLine code="create user sequin with encrypted password '▀▀▀▀▀▀';" />

            <h2 className="font-bold mt-4 text-base">2. Grant permissions</h2>

            <div className="grid gap-y-1 mt-2">
              <CopyableCodeLine code="grant connect, create on database YOUR_DBNAME to sequin;" />
            </div>

            <p className="mt-2">
              Note that Sequin will <b>only</b> be able to read and write to the
              schemas it manages.
            </p>

            <h2 className="font-bold mt-4 text-base">
              3. Create a Sequin read role
            </h2>

            <div className="grid gap-y-1 mt-2">
              <CopyableCodeLine code="create role sequin_read with admin sequin;" />
            </div>

            <p className="mt-2">
              You'll grant this{" "}
              <a
                className="link"
                href="https://docs.sequin.io/sync-process/self-hosted#the-sequin-read-role"
                target="_blank"
                rel="noopener noreferrer"
              >
                read role
              </a>{" "}
              to database users to grant access to Sequin tables.
            </p>
          </section>
          <hr className="my-6" />
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
            autoComplete="off"
            className="mt-2 flex flex-col gap-6"
          >
            {!working && fatalError && (
              <div
                ref={(element) => {
                  if (element) {
                    element.scrollIntoView();
                    element.focus();
                  }
                }}
              >
                <p
                  className="text-error rounded px-4 py-2 bg-red-100"
                  role="alert"
                >
                  <b>Error:</b> <MarkdownRender>{fatalError}</MarkdownRender>
                </p>
              </div>
            )}

            <HostSection disabled={working} />

            <Collapse
              title="SSH Tunnel (optional)"
              initialCollapsed
              collapsedDesc={
                <div className="max-w-[233px] w-full -ml-8">
                  Tunnels allow easy access to services running within a private
                  network.
                </div>
              }
            >
              <SshTunnelSection />
            </Collapse>

            <section className="p-4 rounded border bg-accent bg-opacity-5 border-accentContrast font-bold">
              If Sequin encounters issues connecting to your database, you may
              need to whitelist our IP addresses with your database provider:
              <code className="block mt-2">54.245.7.80</code>
              <code className="block mt-2">35.155.134.189</code>
            </section>

            <DatabaseInfoForm />
            <CredentialsForm />
          </form>
        </div>
      </DatabaseSetupLayout>
    </FormProvider>
  );
}
