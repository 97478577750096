export { default as FormUtils } from "./form";
export { default as ThemingUtils } from "./theming";
export { default as SentryUtils } from "./sentry";

// Misc
export { default as useFullObjectSearch } from "./useFullObjectSearch";

export { default as useDebounce } from "./useDebounce";
export { default as useWorkable } from "./useWorkable";
export { useId } from "./useId";

export const limitedFilter = <T>(
  arr: T[],
  predicate: (item: T) => boolean,
  limit: number
) => {
  const matches = [];

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];

    if (predicate(element) && matches.length < limit) {
      matches.push(element);
    }

    if (matches.length >= limit) {
      break;
    }
  }

  return matches;
};
