import React from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import HoverPopover from "./HoverPopover";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

export default function Switch({
  children,
  checked,
  onChange,
  disabled,
}: React.PropsWithChildren<{
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
}>) {
  return (
    <HeadlessSwitch.Group>
      <div className="flex-row flex items-center">
        <HeadlessSwitch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={`${
            checked ? "bg-black" : "bg-gray-300"
          } disabled:opacity-50 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-offset-2 focus:ring-offset-white dark:ring-offset-gray-700 focus:ring-2 focus:ring-black`}
        >
          <span
            className={`${
              checked ? "translate-x-6" : "translate-x-1"
            } inline-flex items-center justify-center w-4 h-4 transform bg-white rounded-full transition-transform`}
          ></span>
        </HeadlessSwitch>
        {children}
      </div>
    </HeadlessSwitch.Group>
  );
}

const SwitchLabel = ({ children }: React.PropsWithChildren<{}>) => (
  <HeadlessSwitch.Label className="text-black dark:text-white flex-1 cursor-pointer ml-2 text-xs">
    {children}
  </HeadlessSwitch.Label>
);

const SwitchHelp = ({ children }: React.PropsWithChildren<{}>) => (
  <HoverPopover
    content={<div className="w-64 grid grid-flow-row gap-2">{children}</div>}
  >
    <button>
      <QuestionMarkCircleIcon className="h-5 w-5" />
    </button>
  </HoverPopover>
);

Switch.Label = SwitchLabel;
Switch.Help = SwitchHelp;
