import React from "react";
import Dialog from "./Dialog";
import { motion } from "framer-motion";

export default function Modal({
  children,
  onClose,
  containerClassName,
  cardClassName,
  shouldBlurBackground,
}: React.PropsWithChildren<{
  onClose: () => void;
  containerClassName?: string;
  cardClassName?: string;
  shouldBlurBackground?: boolean;
}>) {
  return (
    <Dialog
      onCloseRequest={onClose}
      shouldBlurBackground={shouldBlurBackground}
    >
      <div
        className="fixed right-0 top-0 bottom-0 left-0 w-full text-left overflow-y-auto"
        onClick={onClose}
      >
        <div
          className={
            containerClassName || "w-full custom-container py-32 lg:px-16"
          }
          onClick={onClose}
        >
          <motion.div
            initial={{
              opacity: 0,
              translateY: 48,
              scale: 0.9,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.5 }}
            className={cardClassName || "transform bg-white rounded shadow-xl"}
            onClick={(e) => e.stopPropagation()}
          >
            <Dialog.Content className="w-full h-full">
              {children}
            </Dialog.Content>
          </motion.div>
        </div>
      </div>
    </Dialog>
  );
}
Modal.Title = Dialog.Title;
