import React from "react";
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
  unstable_Blocker as Blocker,
} from "react-router-dom";

import { Button } from "lib/lucidez";
import Modal from "components/core/Modal";

interface ConfirmPendingChangesProps {
  hasPendingChanges: boolean;
}

export const ConfirmPendingChanges = ({
  hasPendingChanges,
}: ConfirmPendingChangesProps) => {
  // Handles react-router-dom route changes (Link component)
  const blocker = useBlocker(hasPendingChanges);

  // Handle hard-refreshes and closing the page
  useConfirmPendingChanges(hasPendingChanges);

  if (blocker && blocker.state === "blocked") {
    return <BlockerPopup blocker={blocker} />;
  }

  return <></>;
};

const BlockerPopup = ({ blocker }: { blocker: Blocker }) => {
  return (
    <Modal
      onClose={() => {}}
      containerClassName="max-w-2xl custom-container py-32 lg:px-16"
    >
      <div className="p-8">
        <div className="flex flex-col justify-center text-center">
          <h3 className="text-xl font-bold w-full">Leave page</h3>
          <p className="mt-6">
            Are you sure that you want to leave the current page?
          </p>
          <p className="mt-2">The changes that you made won't be saved.</p>
          <div className="flex justify-center text-center mt-6">
            <Button
              id="confirm-delete-btn"
              variant="primary"
              onClick={() => blocker.proceed?.()}
              className="w-2/5 justify-center bg-gray-900"
            >
              Leave page
            </Button>
          </div>
          <a
            onClick={() => blocker.reset?.()}
            className="font-bold text-gray-500 cursor-pointer m-auto mt-4"
          >
            Cancel
          </a>
        </div>
      </div>
    </Modal>
  );
};

const useConfirmPendingChanges = (hasPendingChanges: boolean) => {
  useBeforeUnload((ev: BeforeUnloadEvent) => {
    if (hasPendingChanges) {
      ev.preventDefault();
      ev.returnValue = "";
    }
  });
};
