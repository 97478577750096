import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 17 24" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6.773 7.147c0-1.028.857-1.424 2.276-1.424 2.035 0 4.604.607 6.64 1.688V1.213C13.465.343 11.27 0 9.048 0 3.614 0 0 2.796 0 7.464c0 7.279 10.173 6.118 10.173 9.257 0 1.213-1.07 1.609-2.57 1.609-2.222 0-5.06-.897-7.309-2.11v6.277A18.799 18.799 0 007.604 24C13.171 24 17 21.284 17 16.563 16.973 8.703 6.773 10.1 6.773 7.147z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgStripeFilled = React.memo(SvgComponent);
export default SvgStripeFilled;
