import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function Displace({
  children,
  selector,
}: React.PropsWithChildren<{ selector: string }>) {
  const container = useRef<HTMLDivElement>();
  const root = useRef<HTMLDivElement>();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    root.current = document.querySelector(selector);
    container.current = document.createElement("div");

    root.current.appendChild(container.current);

    setMounted(true);

    return () => {
      root.current.removeChild(container.current);
    };
  }, []);

  return mounted ? createPortal(children, container.current) : null;
}
