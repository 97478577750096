import {
  HookError,
  HookMutation,
  AdditionalOptions,
  useResources,
} from "lib/api/hooks";
import { LoginStatus } from "lib/api/types";
import useSWR, { Key, SWRConfiguration } from "swr";
import * as z from "zod";
import { throwIfForbiddenError, throwIfZodError } from "lib/api/utils";
import { useCallback, useEffect, useState } from "react";
import { checkIsLoggedIn } from "lib/api";
import { DatabaseWithSchema } from "lib/platforms";
import { zodFetcher } from "lib/api/swrConfig";
import { useLocation, useSearchParams } from "react-router-dom";
import { normalizeRedirect } from "lib/utils/redirect";

export const useZodSWR = <
  TransformedDataType,
  T extends z.ZodObject<any, any, any>
>(
  key: Key,
  schema: T,
  config?: SWRConfiguration,
  options?: AdditionalOptions,
  mutationBuilder?: (data: TransformedDataType) => z.infer<T>
): [z.infer<T> | undefined, HookMutation<TransformedDataType>, HookError] => {
  const res = useSWR<z.infer<T>, HookError>(key, {
    ...config,
    fetcher: zodFetcher(schema),
  });

  const mutate = useCallback(
    async (data?: TransformedDataType, shouldRevalidate?: boolean) => {
      if (data && mutationBuilder) {
        await res.mutate(mutationBuilder(data), shouldRevalidate);
      } else {
        await res.mutate();
      }
    },
    [res]
  );

  if (options && options.throwIfForbiddenError) {
    throwIfForbiddenError(res.error);
  }

  throwIfZodError(res.error);

  return [res.data, mutate, res.error];
};

export const useDatabases = (config?: SWRConfiguration) => {
  const [resources] = useResources(config);
  const databases: { [id: string]: DatabaseWithSchema } = {};

  if (!resources) {
    return undefined;
  }

  resources.forEach((r) => {
    const db: DatabaseWithSchema = {
      ...r.database,
      schemas: databases[r.database.id]
        ? [
            ...databases[r.database.id].schemas,
            {
              resource: r,
              schema: r.database.schema,
            },
          ]
        : [
            {
              resource: r,
              schema: r.database.schema,
            },
          ],
    };

    databases[r.database.id] = db;
  });

  return databases;
};

export const useLoginStatus = () => {
  let location = useLocation();
  const [query] = useSearchParams();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(
    LoginStatus.Loading
  );

  useEffect(() => {
    const doEffect = async () => {
      const { ok: loggedIn, app: app } = await checkIsLoggedIn();
      const redirectPath = query.get("redirect_path");
      const normalizedRedirect = normalizeRedirect(redirectPath);
      const redirectParam =
        normalizedRedirect && normalizedRedirect !== ""
          ? `?redirect_path=${normalizedRedirect}`
          : "";

      if (loggedIn) {
        setLoginStatus(LoginStatus.LoggedIn);
      } else {
        setLoginStatus(LoginStatus.LoggedOut);
      }

      const isAuthPage = [
        "/login",
        "/logout",
        "/logout/done",
        "/confirmation",
        "/join",
        "/signup",
        "/login/confirm-reset",
        "/login/forgot-password",
      ].includes(location.pathname);

      if (loggedIn && app === "new" && !location.pathname.includes("/logout")) {
        window.location.href =
          process.env.NEXT_PUBLIC_NEW_HOST + "/login.finish" + redirectParam;
      } else if (!loggedIn && !isAuthPage) {
        window.location.href =
          process.env.NEXT_PUBLIC_HOST + "/login" + redirectParam;
      }
    };
    doEffect();
  }, []);

  return loginStatus;
};

export const useLatestResource = () => {
  const [resources] = useResources();
  const latestResource = resources && resources[resources.length - 1];

  return latestResource;
};
