import React from "react";
import { Listbox } from "@headlessui/react";
import {
  SelectorIcon,
  CheckIcon,
  PlusIcon,
  DocumentDownloadIcon,
} from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import { useFormContext, useController } from "react-hook-form";

import Steps from "components/core/Steps";
import { useSshPublicKey, useSshTunnels } from "lib/api/hooks";
import { HostPortExternalDbFormInputs } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/HostSection";
import { InputErrorWrapper } from "./InputErrorWrapper";
import classNames from "classnames";

export const defaultSshFormInputValues = {
  id: "",
  host: "",
  port: "22",
  user: "",
};

export type SshTunnelFormInputs = {
  useTunnel: "none" | "new" | "existing";
  id: string;
  host: string;
  port: string;
  user: string;
};

export const SshTunnelSection = ({ disabled }: { disabled?: boolean }) => {
  const { register, formState } =
    useFormContext<HostPortExternalDbFormInputs>();

  const [tunnels] = useSshTunnels();

  const { field: tunnelField } = useController<HostPortExternalDbFormInputs>({
    name: "sshTunnel",
  });

  const tunnelFieldValue = tunnelField.value as SshTunnelFormInputs;

  return (
    <section>
      <p className="text-xs text-gray-600 mt-2 mb-4">
        Tunnels allow easy access to services running within a private network.
      </p>
      <Steps
        activeStep={tunnelFieldValue.useTunnel === "new" ? "new" : "select"}
      >
        <Steps.Step stepKey="select">
          <Listbox
            onChange={(v) => {
              if (v === "add-new") {
                tunnelField.onChange({
                  useTunnel: "new",
                  ...defaultSshFormInputValues,
                });
              } else if (v === "no-tunnel") {
                tunnelField.onChange({
                  useTunnel: "none",
                  ...defaultSshFormInputValues,
                });
              } else {
                tunnelField.onChange(v);
              }
            }}
            value={tunnelField.value}
            disabled={disabled}
          >
            <div className="flex flex-col">
              <Listbox.Label className="text-xs">
                Choose a tunnel below or add a new one
              </Listbox.Label>
              <div className="relative w-full">
                <Listbox.Button className="mt-2 relative py-2 pl-3 pr-10 text-left rounded cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-black sm:text-xs border w-full disabled-input">
                  <span className="block truncate">
                    {tunnelFieldValue.useTunnel === "none"
                      ? "No tunnel"
                      : `${tunnelFieldValue.user}@${tunnelFieldValue.host}`}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs z-50">
                  <Listbox.Option
                    className={({ active }) =>
                      `${active ? "bg-gray-100 text-gray-900" : "text-gray-900"}
                              cursor-pointer select-none relative py-2 pl-10 pr-4`
                    }
                    value="no-tunnel"
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate`}
                        >
                          No tunnel
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>

                  {(tunnels || []).map((tunnel) => (
                    <Listbox.Option
                      key={tunnel.id}
                      className={({ active }) =>
                        `${
                          active ? "text-gray-900 bg-gray-100" : "text-gray-900"
                        }
                              cursor-default select-none relative py-2 pl-10 pr-4`
                      }
                      value={{
                        useTunnel: "existing",
                        id: tunnel.id,
                        host: tunnel.host,
                        port: tunnel.port.toString(),
                        user: tunnel.user,
                      }}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`${
                              selected ? "font-medium" : "font-normal"
                            } block truncate`}
                          >
                            {`${tunnel.user}@${tunnel.host}`}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}

                  <Listbox.Option
                    className={({ active }) =>
                      `${active ? "bg-gray-100" : ""}
                              cursor-pointer select-none relative py-2 pr-4 text-blue-500`
                    }
                    value="add-new"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <PlusIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                    <span className="block truncate ml-10 font-bold">
                      Add new tunnel
                    </span>
                  </Listbox.Option>
                </Listbox.Options>
              </div>
            </div>
          </Listbox>
        </Steps.Step>

        <Steps.Step stepKey="new">
          <section className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4">
            <InputErrorWrapper
              errors={formState.errors}
              name="sshTunnel.host"
              label="SSH host"
            >
              {(hasError) => (
                <input
                  className={classNames(
                    "textbox-outlined textbox-md w-full mt-2 disabled-input",
                    { "border-red-400": hasError }
                  )}
                  type="text"
                  {...register("sshTunnel.host")}
                  disabled={disabled}
                />
              )}
            </InputErrorWrapper>

            <InputErrorWrapper
              errors={formState.errors}
              name="sshTunnel.user"
              label="SSH user"
            >
              {(hasError) => (
                <input
                  className={classNames(
                    "textbox-outlined textbox-md w-full mt-2 disabled-input",
                    { "border-red-400": hasError }
                  )}
                  type="text"
                  {...register("sshTunnel.user")}
                  disabled={disabled}
                />
              )}
            </InputErrorWrapper>
            <DefaultPublicKeyDisplay />
          </section>
        </Steps.Step>
      </Steps>
    </section>
  );
};

const DefaultPublicKeyDisplay = () => {
  const [publicKey] = useSshPublicKey();

  const downloadKey = () => {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([atob(publicKey)], { type: "text/plain" })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", "id_rsa_sequin_tunnel.pub");

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  return (
    <section>
      <p className="font-bold">Public Key</p>
      <p className="text-xs">
        To authorize Sequin to SSH through your tunnel, allow our public key to
        connect to your instance.
        <a
          className="link"
          target="_blank"
          href="https://docs.sequin.io/sync-process/self-hosted#ssh-tunnels"
          rel="noreferrer"
        >
          Read more about SSH Tunnels.
        </a>
      </p>
      <div className="mt-2">
        <div className="flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black ">
          <input
            onFocus={(ev) => {
              ev.target.select();
            }}
            className="focus:outline-none rounded w-72 flex-1 px-4 py-2"
            value={publicKey ? atob(publicKey) : "Loading..."}
            autoComplete="off"
            type="text"
          />

          <Tippy content="Download key">
            <button
              className="h-10 w-10 border-l flex flex-row items-center justify-center text-gray-500 hover:text-gray-600"
              onClick={downloadKey}
            >
              <DocumentDownloadIcon className="h-5 w-5" />
            </button>
          </Tippy>
        </div>
      </div>
    </section>
  );
};
