import React, { useState } from "react";
import memoize from "lodash/memoize";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

import { FormProps, OnboardCredentialFormProps } from "lib/platforms";
import { SALESFORCE_PLATFORM } from "lib/platforms/salesforce";
import CredentialStep, {
  FullPageCredentialStep,
  OnboardCredentialStep,
} from "components/platforms/common/forms/CredentialStep";
import {
  DestinationStep,
  FullPageDestinationStep,
  OnboardDestinationStep,
} from "components/platforms/common/forms/DestinationStep";
import NameStep, {
  FullPageNameStep,
} from "components/platforms/common/forms/NameStep";
import SelectTablesStep from "components/platforms/common/forms/SelectTablesStep";
import {
  FullPageRateLimitStep,
  RateLimitStep,
} from "components/platforms/common/forms/RateLimitStep";
import { SelectEnvironmentStep } from "components/platforms/common/forms/SelectEnvironmentStep";
import { FullPageEventsStep } from "components/platforms/common/forms/EventsStep";

enum Step {
  AddToken = "AddToken",
  SelectEnvironment = "SelectEnvironment",
  RateLimit = "RateLimit",
  SelectTables = "SelectTables",
  Destination = "Destination",
  Advanced = "Advanced",
}

export default function SalesforceForm({ isCreate, isOnboard }: FormProps) {
  const [collapsed, setCollapsed] = useState<{ [k: string]: boolean }>({
    [Step.AddToken]: !isCreate, // Default open on creates
    [Step.SelectEnvironment]: false,
    [Step.RateLimit]: true,
    [Step.SelectTables]: false,
    [Step.Destination]: !isCreate || isOnboard, // Destination is always collapsed by default
  });

  const genericToggleCollapse = React.useMemo(
    () =>
      memoize(
        (step: Step) => () =>
          setCollapsed((collapsed) => ({
            ...collapsed,
            [step]: !collapsed[step],
          }))
      ),
    []
  );

  const stepProps = {
    isCreate,
    isOnboard,
    platform: SALESFORCE_PLATFORM,
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      {!isCreate && <NameStep />}
      <CredentialStep
        {...stepProps}
        collapsed={collapsed[Step.AddToken]}
        onToggleCollapsed={genericToggleCollapse(Step.AddToken)}
        onNextStep={genericToggleCollapse(Step.AddToken)}
      />
      <SelectEnvironmentStep
        collapsed={collapsed[Step.SelectEnvironment]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectEnvironment)}
      />
      <RateLimitStep
        {...stepProps}
        collapsed={collapsed[Step.RateLimit]}
        onToggleCollapsed={genericToggleCollapse(Step.RateLimit)}
        configurableLimit={{
          minRequestsPerInterval: 5_000,
          maxRequestsPerInterval: 100_000,
          interval: 86_400_000,
          requestSliderStep: 1_000,
        }}
        renderNotice={(maxRequests, interval, usedByOtherResources) => (
          <>
            <ExclamationCircleIcon className="h-5 w-5 inline-block text-black mr-1" />{" "}
            The rate limit for your Salesforce account depends on your
            Salesforce account type. Rate limits start at 100k requests per day.{" "}
            {usedByOtherResources != "0" && (
              <>
                Other resources using this account are using{" "}
                {usedByOtherResources} API requests per {interval}.{" "}
              </>
            )}
            See more info{" "}
            <a
              className="link"
              target="_blank"
              href="https://developer.salesforce.com/docs/atlas.en-us.salesforce_app_limits_cheatsheet.meta/salesforce_app_limits_cheatsheet/salesforce_app_limits_platform_api.htm"
              rel="noreferrer"
            >
              here
            </a>
            .
          </>
        )}
      />
      <SelectTablesStep
        {...stepProps}
        collapsed={collapsed[Step.SelectTables]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectTables)}
      />
      {isOnboard ? (
        <OnboardDestinationStep
          {...stepProps}
          collapsed={collapsed[Step.Destination]}
          onToggleCollapsed={genericToggleCollapse(Step.Destination)}
        />
      ) : (
        <DestinationStep
          {...stepProps}
          collapsed={collapsed[Step.Destination]}
          onToggleCollapsed={genericToggleCollapse(Step.Destination)}
        />
      )}
    </div>
  );
}

export const FullPageSalesforceForm = ({ isCreate }: FormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      {!isCreate && <FullPageNameStep />}
      <FullPageCredentialStep platform={SALESFORCE_PLATFORM} />
      <hr />
      <FullPageRateLimitStep
        platform={SALESFORCE_PLATFORM}
        configurableLimit={{
          title: "Read",
          minRequestsPerInterval: 5_000,
          maxRequestsPerInterval: 100_000,
          interval: 86_400_000,
          requestSliderStep: 1_000,
        }}
        renderNotice={(maxRequests, interval, usedByOtherResources) => (
          <>
            <ExclamationCircleIcon className="h-4 w-4 inline-block text-gray-500 mr-0.5" />{" "}
            The rate limit for your Salesforce account depends on your
            Salesforce account type. Rate limits start at 100k requests per day.{" "}
            {usedByOtherResources != "0" && (
              <>
                Other resources using this account are using{" "}
                {usedByOtherResources} API requests per {interval}.{" "}
              </>
            )}
            See more info{" "}
            <a
              className="link"
              target="_blank"
              href="https://developer.salesforce.com/docs/atlas.en-us.salesforce_app_limits_cheatsheet.meta/salesforce_app_limits_cheatsheet/salesforce_app_limits_platform_api.htm"
              rel="noreferrer"
            >
              here
            </a>
            .
          </>
        )}
      />
      <hr />
      <FullPageDestinationStep
        isCreate={false}
        platform={SALESFORCE_PLATFORM}
      />
      <hr />
      <FullPageEventsStep />
    </div>
  );
};

export const OnboardCredentialSalesforceForm = ({
  handleBackClick,
}: OnboardCredentialFormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      <OnboardCredentialStep
        handleBackClick={handleBackClick}
        platform={SALESFORCE_PLATFORM}
      />
    </div>
  );
};
