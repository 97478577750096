import React from "react";
import { useFormContext } from "react-hook-form";
import { InputErrorWrapper } from "./InputErrorWrapper";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";

export type CredentialsFormInputs = {
  dbname: string;
  schema: string;
  user: string;
  password: string;
  ssl: boolean;
};

export const CredentialsForm = ({
  disabled,
  isEdit = false,
}: {
  disabled?: boolean;
  isEdit?: boolean;
}) => {
  const { register, formState } = useFormContext<CredentialsFormInputs>();

  return (
    <div className="grid grid-cols-1  gap-x-8 gap-y-4">
      <InputErrorWrapper
        errors={formState.errors}
        name="user"
        label="Sequin User"
      >
        {(hasError) => (
          <input
            className={classNames(
              "textbox-outlined textbox-md w-full mt-2 disabled-input",
              { "border-red-400": hasError }
            )}
            type="text"
            autoComplete="off"
            name="user"
            {...register("user", {
              required: "User is required",
            })}
            disabled={disabled}
          />
        )}
      </InputErrorWrapper>
      <InputErrorWrapper
        errors={formState.errors}
        name="password"
        label="Password"
      >
        {(hasError) => (
          <input
            type="password"
            autoComplete="off"
            className={classNames(
              "textbox-outlined textbox-md w-full mt-2 disabled-input",
              { "border-red-400": hasError }
            )}
            name="password"
            placeholder={isEdit ? "Same password" : ""}
            {...register("password", {
              required: "Password is required",
            })}
            disabled={disabled}
          />
        )}
      </InputErrorWrapper>
      <div>
        <div className="flex flex-row items-center">
          <input
            id="destination-form--externaldbsetup-usessl"
            className="form-checkbox rounded-sm text-black ring-black cursor-pointer disabled-input"
            type="checkbox"
            name="ssl"
            {...register("ssl")}
            disabled={disabled}
          ></input>
          <label
            className="ml-3 text-xs font-semibold text-gray-600 cursor-pointer"
            htmlFor="destination-form--externaldbsetup-usessl"
          >
            Use SSL
          </label>
        </div>
        <p className="mt-1 text-error text-xs">
          <ErrorMessage errors={formState.errors} name="ssl" />
        </p>
      </div>
    </div>
  );
};

export const DatabaseInfoForm = ({
  disabled,
  hideSchema,
}: {
  disabled?: boolean;
  hideSchema?: boolean;
}) => {
  const { register, formState } = useFormContext<CredentialsFormInputs>();

  return (
    <section className="grid grid-cols-1 gap-x-8 gap-y-4">
      <InputErrorWrapper
        errors={formState.errors}
        name="dbname"
        label="Database"
      >
        {(hasError) => (
          <input
            className={classNames(
              "textbox-outlined textbox-md w-full mt-2 disabled-input",
              { "border-red-400": hasError }
            )}
            type="text"
            name="dbname"
            {...register("dbname", {
              required: "Database Name is required",
            })}
            disabled={disabled}
          />
        )}
      </InputErrorWrapper>
      {!hideSchema && (
        <InputErrorWrapper
          errors={formState.errors}
          name="schema"
          label="Schema to sync to"
        >
          {(hasError) => (
            <input
              className={classNames(
                "textbox-outlined textbox-md w-full mt-2 disabled-input",
                { "border-red-400": hasError }
              )}
              type="text"
              name="schema"
              {...register("schema", {
                required: "Schema is required",
                pattern: {
                  value: /^[a-zA-Z_]\w*$/,
                  message: "Schema contains invalid characters",
                },
              })}
              disabled={disabled}
            />
          )}
        </InputErrorWrapper>
      )}
    </section>
  );
};
