import { SearchIcon } from "@heroicons/react/outline";
import React from "react";

interface FilterBoxProps {
  filter: string;
  label: string;
  setFilter: (filter: string) => void;
}

export const FilterBox = ({ filter, label, setFilter }: FilterBoxProps) => (
  <div className="flex flex-row flex-1 border-b border-cool-gray-200">
    <div className="w-10 h-10 flex items-center justify-center">
      <SearchIcon className="h-5 text-gray-500" />
    </div>
    <input
      className="focus:outline-none rounded w-full bg-transparent"
      placeholder={label}
      value={filter}
      onChange={({ target: { value } }) => setFilter(value)}
    />
  </div>
);
