import React from "react";

import SvgSpinner from "components/svg/Spinner";
import classNames from "classnames";

interface Props {
  variant?: "normal" | "white";
}

export const PageLoadingSpinner = ({ variant = "normal" }: Props) => {
  return (
    <div className="custom-container py-12 flex flex-row justify-center items-center">
      <SvgSpinner
        className={classNames("h-8 w-8 animate-spin ", {
          "text-white": variant === "white",
        })}
      />
    </div>
  );
};
