import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Listbox, Switch } from "@headlessui/react";
import { CheckIcon, RssIcon } from "@heroicons/react/solid";

import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { createMessageQueue, updateResource } from "lib/api";
import {
  HookMutation,
  useGetMessageQueues,
  useResourceBySlug,
} from "lib/api/hooks";
import { MessageQueue, MessageQueues } from "lib/api/types";
import { Resource } from "lib/platforms";
import { Button } from "lib/lucidez/components/Button";
import ArrowsUpDown from "components/svg/ArrowsUpDown";

const VALID_REGIONS = ["us-east-1", "eu-west-1"];

export const FullPageEventsStep = () => {
  const { permaslug } = useParams();
  const [resource, mutateResource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });
  const [messageQueues, mutateMessageQueue] = useGetMessageQueues();

  if (!resource || !messageQueues) {
    return <PageLoadingSpinner />;
  }

  return (
    <div>
      <span className="flex">
        <RssIcon className="w-5 h-5 mr-6" />
        <h3 className="text-md font-bold mb-4">Event stream</h3>
      </span>
      {messageQueues.length > 0 ? (
        <>
          {messageQueues.map((messageQueue) => {
            return (
              <MessageQueueView
                messageQueue={messageQueue}
                resource={resource}
                key={messageQueue.id}
              />
            );
          })}
          <p className="mt-4">
            When enabled, changes from this sync will be sent to your org’s
            event stream.
          </p>
        </>
      ) : (
        <CreateMessageQueue
          mutateMessageQueue={mutateMessageQueue}
          mutateResource={mutateResource}
          resource={resource}
        />
      )}
    </div>
  );
};

const MessageQueueView = ({
  messageQueue,
  resource,
}: {
  messageQueue: MessageQueue;
  resource: Resource;
}) => {
  const [streamIsEnabled, setStreamIsEnabled] = useState<boolean>(
    messageQueue.id === resource.messageQueueId
  );

  useEffect(() => {
    setStreamIsEnabled(messageQueue.id === resource.messageQueueId);
  }, [messageQueue.id, resource.messageQueueId]);

  const updateMessageQueueEnabledness = async (
    messageQueue: MessageQueue,
    isEnabled: boolean
  ) => {
    try {
      setStreamIsEnabled(isEnabled);
      const messageQueueId = isEnabled ? messageQueue.id : null;
      await updateResource(resource.id, { messageQueueId });
      toast.success(
        `Event stream ${isEnabled === true ? "enabled" : "disabled"}.`,
        { toastId: "toggle-message-queue-success" }
      );
    } catch {
      setStreamIsEnabled(!isEnabled);
      toast.error(
        `There was a problem ${
          isEnabled === true ? "enabling" : "disabling"
        } the event stream. Please try again.`,
        { toastId: "toggle-message-queue-error" }
      );
    }
  };

  return (
    <div className="border border-grey-200 rounded-md bg-cool-gray-50 grid grid-cols-2 gap-y-1 p-4">
      <Switch.Group>
        <div className="flex items-center col-span-2">
          <Switch.Label className="cursor-pointer font-bold col-span-1 text-xs mr-8">
            Event stream enabled
          </Switch.Label>
          <Switch
            checked={streamIsEnabled}
            onChange={() => {
              updateMessageQueueEnabledness(messageQueue, !streamIsEnabled);
            }}
            className={classNames(
              "disabled:opacity-50 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-offset-2 focus:ring-offset-white dark:ring-offset-gray-700 focus:ring-2 focus:ring-black",
              {
                "bg-black": streamIsEnabled,
                "bg-gray-300": !streamIsEnabled,
              }
            )}
          >
            <span
              className={classNames(
                "inline-flex items-center justify-center w-4 h-4 transform bg-white rounded-full transition-transform",
                {
                  "translate-x-6": streamIsEnabled,
                  "translate-x-1": !streamIsEnabled,
                }
              )}
            />
          </Switch>
        </div>
      </Switch.Group>
      <span className="col-span-2 text-xs uppercase text-cool-gray-500 mt-2">
        Region
      </span>
      <span className="col-span-2 text-xs text-cool-gray-900 mb-2">
        {messageQueue.definition.region}
      </span>
      <span className="col-span-2 text-xs uppercase text-cool-gray-500">
        Host
      </span>
      <span className="col-span-2 text-xs text-cool-gray-900">
        {messageQueue.definition.tcpEndpoint}
      </span>
    </div>
  );
};

const CreateMessageQueue = ({
  mutateMessageQueue,
  mutateResource,
  resource,
}: {
  mutateMessageQueue: HookMutation<MessageQueues>;
  mutateResource: HookMutation<Resource>;
  resource: Resource;
}) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string>(
    VALID_REGIONS[0]
  );
  const [shouldShowCreateState, setShouldShowCreateState] =
    useState<boolean>(false);

  const showCreateState = () => {
    setShouldShowCreateState(true);
  };

  const createStream = async () => {
    setIsCreating(true);

    const result = await createMessageQueue(selectedRegion);

    if (result.isOk() && result.value) {
      await updateResource(resource.id, { messageQueueId: result.value.id });
      toast.success("The event stream was created successfully.", {
        toastId: "create-event-stream-success",
      });
    } else {
      toast.error(
        "There was a problem creating your event stream. Please try again or contact support@sequin.io.",
        { toastId: "create-event-stream-error" }
      );
    }

    mutateResource();
    mutateMessageQueue();
  };

  return (
    <div>
      {shouldShowCreateState ? (
        <div className="mt-4">
          <Listbox value={selectedRegion} onChange={setSelectedRegion}>
            <Listbox.Label className="font-bold text-md">
              Select region
            </Listbox.Label>
            <Listbox.Button className="flex items-center justify-between mt-2 py-2 px-4 w-3/5 border border-grey-200 rounded-md text-cool-gray-600">
              {selectedRegion} <ArrowsUpDown className="h-5" />
            </Listbox.Button>
            <Listbox.Options className="w-3/5 border border-t-0 border-grey-200 rounded-md">
              {VALID_REGIONS.map((region) => {
                return (
                  <Listbox.Option key={region} value={region}>
                    {({ active, selected }) => {
                      return (
                        <span
                          className={classNames(
                            "p-4 flex items-center cursor-pointer rounded-md",
                            { "bg-cool-gray-50": active }
                          )}
                        >
                          {region}
                          {selected && <CheckIcon className="ml-2 h-5 w-5" />}
                        </span>
                      );
                    }}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Listbox>
          <Button
            className="mt-4 w-3/5 justify-center"
            disabled={selectedRegion === null}
            isLoading={isCreating}
            onClick={createStream}
            size="lg"
            variant="primary"
          >
            Create
          </Button>
        </div>
      ) : (
        <div className="mt-4">
          <p className="w-3/5 mb-2 text-center">
            Your organization doesn't currently have any event streams.
          </p>
          <p className="w-3/5 mb-4 text-center">
            <a
              href="https://docs.sequin.io/events"
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about events.
            </a>
          </p>
          <Button
            className="w-3/5 justify-center"
            onClick={showCreateState}
            size="lg"
            variant="primary"
          >
            Create new event stream
          </Button>
        </div>
      )}
    </div>
  );
};
