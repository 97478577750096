import React, { useState } from "react";
import { UseFormSetError } from "react-hook-form";
import { ApiError } from "lib/api/types";
import { ResourceKind } from "lib/platforms";
import { FormUtils } from "lib/utils";
import Modal from "components/core/Modal";
import Steps from "components/core/Steps";
import { HostPortExternalDbFormInputs } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/HostSection";
import { FatalErrorScreen } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/FatalErrorScreen";
import {
  defaultSshFormInputValues,
  SshTunnelFormInputs,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/SshTunnelSection";
import { CredentialsFormInputs } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/CredentialsForm";
import { DatabaseNameFormInputs } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/DatabaseNameForm";
import { ConfirmPendingChanges } from "lib/utils/ConfirmPendingChanges";
import { DestinationProps } from "components/platforms/common/forms/DestinationStep";
import { ManualFlow } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/ManualFlow";

const { normalizeErrorsForForm } = FormUtils;

export enum ExternalDatabaseSetupStep {
  FatalErrorScreen = "fatal",
  Combined = "combined",
}

export type ProvisionDetails = {
  user: string;
  readGroup: string;
  dbName: string;
  dbId: string;
};

export type HostDetails = {
  host: string;
  port: number;
};

export type SshTunnelDetails = {
  useTunnel: "none" | "new" | "existing";
  id: string;
  host: string;
  port: number;
  user: string;
};

export type ExternalDatabaseSetupProps = DestinationProps & {
  setSetupStep: (step: ExternalDatabaseSetupStep) => void;

  setProvisionDetails: (provisionDetails?: ProvisionDetails) => void;
  provisionDetails?: ProvisionDetails;

  setHostDetails: (hostDetails?: HostDetails) => void;
  hostDetails?: HostDetails;

  setSshTunnelDetails: (hostDetails?: SshTunnelDetails) => void;
  sshTunnelDetails?: SshTunnelDetails;

  onBack: () => void;
};

export const ExternalDatabaseSetup = (
  props: DestinationProps & { onClose: () => void }
) => {
  const [setupStep, setSetupStep] = useState(
    ExternalDatabaseSetupStep.Combined
  );
  const [provisionDetails, setProvisionDetails] = useState<
    ProvisionDetails | undefined
  >();
  const [hostDetails, setHostDetails] = useState<HostDetails | undefined>();
  const [sshTunnelDetails, setSshTunnelDetails] = useState<
    SshTunnelDetails | undefined
  >();

  const stepProps = {
    ...props,
    setSetupStep,
    provisionDetails,
    setProvisionDetails,
    hostDetails,
    setHostDetails,
    sshTunnelDetails,
    setSshTunnelDetails,
  };

  return (
    <Modal
      onClose={props.onClose}
      containerClassName="w-full custom-container max-w-5xl py-8 h-screen flex flex-col justify-center"
      cardClassName="transform bg-white rounded-lg shadow-xl  h-full max-h-[772px] overflow-auto db_setup_flow_container"
      key="externalDatabaseSetup"
      shouldBlurBackground
    >
      <Steps activeStep={setupStep} resetPosition>
        <Steps.Step
          stepKey={ExternalDatabaseSetupStep.Combined}
          className="h-full"
        >
          <ManualFlow {...stepProps} onBack={props.onClose} />
        </Steps.Step>

        <Steps.Step stepKey={ExternalDatabaseSetupStep.FatalErrorScreen}>
          <FatalErrorScreen {...stepProps} />
        </Steps.Step>
      </Steps>
      <ConfirmPendingChanges hasPendingChanges={true} />
    </Modal>
  );
};

export const hostPortExternalDbFormDefaultValues = (
  hostDetails?: HostDetails,
  sshTunnelDetails?: SshTunnelDetails
): HostPortExternalDbFormInputs => ({
  host: {
    host: hostDetails ? hostDetails.host : "",
    port: hostDetails ? hostDetails.port.toString() : "5432",
  },
  sshTunnel: sshTunnelDetails
    ? {
        useTunnel: sshTunnelDetails.useTunnel,
        id: sshTunnelDetails.id,
        host: sshTunnelDetails.host,
        port: sshTunnelDetails.port.toString(),
        user: sshTunnelDetails.user,
      }
    : {
        useTunnel: "none",
        ...defaultSshFormInputValues,
      },
});

export const credentialsExternalDbFormDefaultValues = (
  kind: ResourceKind
): CredentialsFormInputs => ({
  dbname: "",
  schema: kind,
  user: "",
  password: "",
  ssl: true,
});

export const databaseNameFormDefaultValues = (
  currentName: string
): DatabaseNameFormInputs => ({
  name: currentName,
});

type FormInputsIntersection =
  | CredentialsFormInputs
  | HostPortExternalDbFormInputs
  | SshTunnelFormInputs;

export const handleExternalSetupFormErrors = (
  errors: ApiError,
  setError: UseFormSetError<FormInputsIntersection>,
  setFatalError: React.Dispatch<React.SetStateAction<string>>,
  data: FormInputsIntersection
) => {
  const credentialFormData = data as CredentialsFormInputs;

  switch (errors.error.slug) {
    case "validation": {
      const normalizedErrors = normalizeErrorsForForm(errors);
      normalizedErrors.forEach(({ name, errors }) => {
        // Strip out "definition." -- required for compatibility with the
        // database update flow
        // TODO: Refactor db updates and remove auto and manual flows
        if (name.startsWith("definition.")) {
          name = name.substring("definition.".length);
        }

        setError(
          name as keyof (
            | CredentialsFormInputs
            | HostPortExternalDbFormInputs
            | SshTunnelFormInputs
          ),
          {
            message: errors.join(", "),
          }
        );
      });
      return true;
    }

    case "conn_error":
      setFatalError(
        "Your database was not reachable. This usually means the provided hostname and port are wrong or the database is not publicly accessible. Our IP addresses to whitelist are below."
      );
      return true;

    case "timeout":
      setFatalError(
        "Could not connect to the database. This usually means the hostname or port provided are incorrect."
      );
      return true;

    case "jump_host_not_reachable":
      setFatalError("Could not open the SSH tunnel. Jump host not reachable.");
      return true;

    case "jump_host_connect_error":
      setFatalError(
        "Could not open the SSH tunnel. Jump host didn't accepted the connection. Verify if Sequin's public key is properly authorized."
      );
      return true;

    case "invalid_catalog_name":
      setError("dbname", {
        message:
          "This database doesn't exist in the current Postgres instance.",
      });
      return true;

    case "database_name_conflict":
      setError("dbname", { message: "Database already setup" });
      return true;

    case "invalid_password":
      setError("password", {
        message: `Invalid password for user '${
          (data as CredentialsFormInputs).user
        }'.`,
      });
      return true;

    case "insufficient_privilege": {
      setFatalError(
        "The provided user doesn't have the necessary admin permissions. [Check our docs for how to fix this](https://docs.sequin.io/sync-process/self-hosted#the-sequin-read-role)"
      );
      setError("user", {
        message:
          "The provided user doesn't have the necessary admin permissions.",
      });
      return true;
    }

    case "invalid_authorization_specification": {
      setFatalError(
        "We had a problem authenticating with your database. This usually occurs when there is a mismatch between the authentication method specified by the database and how we're trying to connect. Ensure that Use SSL is checked and [see our docs for more info](https://docs.sequin.io/self-hosted#invalid-authorization-specification)"
      );

      return true;
    }

    case "ssl_not_available":
      setFatalError(
        "It looks like your database doesn't support SSL. [Check our docs for more info on SSL](https://docs.sequin.io/self-hosted#ssl-modes)"
      );
      setError("ssl", { message: "SSL is not supported." });
      return true;

    case "db_connection":
      setFatalError("Database was not reachable.");
      return true;

    case "host_not_reachable":
      setFatalError("Could not open the SSH tunnel. Host not reachable.");
      return true;

    case "namespace_usage_forbidden":
      setFatalError(`
The user ${credentialFormData.user} doesn't have USAGE privileges in the \`${credentialFormData.schema}\` schema.

Run the query below to grant the required permission:

\`\`\`
grant usage, create on schema ${credentialFormData.schema} to ${credentialFormData.user};
\`\`\`

[Check our docs for more info](https://docs.sequin.io/self-hosted#common-setup-issues)`);
      return true;

    case "namespace_create_forbidden":
      setFatalError(`
The user ${credentialFormData.user} doesn't have CREATE privileges in the \`${credentialFormData.schema}\` schema.

Run the query below to grant the required permission:

\`\`\`
grant usage, create on schema ${credentialFormData.schema} to ${credentialFormData.user};
\`\`\`

[Check our docs for more info](https://docs.sequin.io/self-hosted#common-setup-issues)`);
      return true;

    case "database_create_forbidden":
      setFatalError(
        "The provided user doesn't have CREATE privileges on the database. [Check our docs for more info](https://docs.sequin.io/self-hosted#common-setup-issues)"
      );
      return true;

    case "database_connect_forbidden":
      setFatalError(
        "The provided user doesn't have CONNECT privileges on the database. [Check our docs for more info](https://docs.sequin.io/self-hosted#common-setup-issues)"
      );
      return true;

    case "transaction_read_only":
      setFatalError(
        "The provided user can only run read only transactions on the database. Ensure the database instance is writable and default_transaction_read_only is off."
      );
      return true;

    case "feature_not_supported":
      setFatalError(
        "Your Postgres version is not supported by Sequin. Please use Sequin with a Postgres database running version 10 or above."
      );
      return true;

    case "ssl_required":
      setFatalError(
        "SSL is required by the database. Please go back through the configuration in this setup process and ensure that SSL is checked."
      );
      return true;

    case "invalid_hostname":
      setFatalError(
        "Invalid hostname. Please enter a valid hostname for your database, like: my-db.us-west-2.rds.amazonaws.com."
      );
      return true;

    case "invalid_port":
      setFatalError(
        "Invalid port. A valid port for your database will be between 1 and 65535."
      );
      return true;

    case "unsupported_version":
      setFatalError(
        "This version of Postgres is not supported by Sequin. Sequin supports Postgres version 10 and above."
      );
      return true;

    case "tcp_error":
      setFatalError(`
We failed to establish a connection to your database.

${errors.error.summary}

[Check our docs for help on debugging connectivity issues](https://docs.sequin.io/sync-process/self-hosted#common-networking-issues)`);
      return true;

    case "invalid_ssl_config":
      setFatalError(`
We couldn't establish an SSL connection with your database. This usually indicates that SSL is misconfigured in your database.

[Check our docs for help on debugging SSL issues](https://docs.sequin.io/sync-process/self-hosted#ssltls-failures)`);
      return true;

    default:
      setFatalError("An unknown error happened.");

      if (window.analytics) {
        window.analytics.track("Destination Connect Unhandled Error");
      }
      return false;
  }
};
