import React, { useEffect, useMemo } from "react";
import {
  CredentialFormProps,
  getPlatformForKind,
  ResourceKind,
} from "../../../lib/platforms";
import { FieldState, useValidation } from "./forms/util";

export const useCredentialMachine = (
  collapseTrigger: (val: boolean) => void
) => {
  const credentialValidation = useValidation(FieldState.NotValidated);
  const credentialState = credentialValidation.fieldState;

  useEffect(() => {
    // If the token is SilentlyValidating (because it is a update, not a create)
    // we just collapse this. When the validation completes, we handle the result
    // appropriately, by uncolapsing this thing if need
    if (
      credentialState === FieldState.Valid ||
      credentialState === FieldState.SilentlyValidating
    ) {
      collapseTrigger(true);
    } else {
      collapseTrigger(false);
    }
  }, [credentialState]);

  return { credentialValidation, credentialState };
};
interface ResourceCredentialFormProps extends CredentialFormProps<any> {
  kind: ResourceKind;
}

export default function ResourceCredentialForm(
  props: ResourceCredentialFormProps
) {
  const kind = props.kind;
  const platform = useMemo(() => getPlatformForKind(kind), [kind]);

  const PlatformCredentialForm = platform.buildCredentialForm;

  return <PlatformCredentialForm {...props} />;
}
