import { useState } from "react";

export default function useWorkable(): [
  boolean,
  <R>(func: () => Promise<R>) => Promise<R>
] {
  const [isWorking, setIsWorking] = useState(false);

  const call = async <R>(func: () => Promise<R>) => {
    setIsWorking(true);
    const response = await func();
    setIsWorking(false);

    return response;
  };

  return [isWorking, call];
}
