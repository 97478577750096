import React from "react";
import classNames from "classnames";

import { CopyTextToClipboardButton } from "components/core/CopyTextToClipboardButton";

export const CopyableCodeLine = ({
  code,
  discrete,
  pre,
}: {
  code: string;
  discrete?: boolean;
  pre?: boolean;
}) => {
  return (
    <div
      className={classNames("flex flex-row rounded ph-no-capture ", {
        border: !discrete,
        "bg-white": !discrete,
      })}
    >
      <code
        className={classNames({
          "break-all": discrete,
          "code-block p-2 flex-1 text-xs break-all": !discrete,
        })}
      >
        {pre ? <pre>{code}</pre> : <>{code}</>}
      </code>
      <div
        className={classNames({
          "border-l flex items-center justify-center": !discrete,
        })}
      >
        <CopyTextToClipboardButton
          textToCopy={code}
          className={classNames({ "p-2": !discrete })}
        />
      </div>
    </div>
  );
};
