import { XCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

import { executeGenericAuthentication } from "lib/api";
import { Button } from "lib/lucidez";
import { CredentialFormProps } from "lib/platforms";
import {
  SalesforceCredential,
  SALESFORCE_PLATFORM,
} from "lib/platforms/salesforce";
import SvgSpinner from "components/svg/Spinner";

enum OAuthState {
  Initial,
  Error,
  Connecting,
}

export default function SalesforceCredentialForm(
  props: CredentialFormProps<SalesforceCredential>
) {
  return (
    <div className="flex flex-col mt-2">
      <OAuthForm {...props} />
    </div>
  );
}

const OAuthForm = ({ onSave }: CredentialFormProps<SalesforceCredential>) => {
  const StripeIcon = SALESFORCE_PLATFORM.displayIcon;

  const [oauthState, setOauthState] = useState(OAuthState.Initial);

  const [sfdcDomain, setSfdcDomain] = useState("");

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleConnect = async () => {
    setOauthState(OAuthState.Connecting);

    window.analytics.track("OAuth Connect Started", {
      kind: "salesforce",
    });

    const res = await executeGenericAuthentication(
      "salesforce",
      "sfid=" + sfdcDomain
    );

    if (res.isOk() && res.value) {
      setOauthState(OAuthState.Initial);

      window.analytics.track("OAuth Connect Finished", {
        kind: "salesforce",
      });

      onSave(res.value.credential);
    } else {
      window.analytics.track("OAuth Connect Errored", {
        kind: "salesforce",
      });

      if (
        res.isErr() &&
        res.error?.error?.slug === "missing_apex_permissions"
      ) {
        setErrorMessage("Missing permissions to create Apex objects");
      }

      setOauthState(OAuthState.Error);
    }
  };

  if (oauthState === OAuthState.Initial) {
    return (
      <div>
        <p className="text-xs">
          You can find your Salesforce subdomain in your browser's address bar
          after{" "}
          <a
            href="https://login.salesforce.com"
            rel="noopener noreferrer"
            className="link"
            target="_blank"
          >
            signing in to your Salesforce account
          </a>
          .
        </p>
        <div className="flex flex-col mt-2">
          <div className="flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black">
            <div className="font-medium rounded-l px-4 h-10 flex items-center justify-center bg-gray-100 border-r border-gray-200">
              https://
            </div>
            <input
              className="focus:outline-none rounded flex-1 pl-2"
              placeholder="sequin"
              value={sfdcDomain}
              onChange={(ev) => setSfdcDomain(ev.target.value)}
            />
            <div className="font-medium rounded-r px-4 h-10 flex items-center justify-center bg-gray-100 border-l border-gray-200">
              .my.salesforce.com
            </div>
          </div>
          <Button
            className="w-full text-center justify-center mt-2"
            variant="primary"
            size="lg"
            iconLeft={<StripeIcon className="w-5 h-5" />}
            onClick={handleConnect}
            disabled={!sfdcDomain}
          >
            Connect to {SALESFORCE_PLATFORM.displayName}
          </Button>
        </div>
      </div>
    );
  } else if (oauthState === OAuthState.Connecting) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <SvgSpinner className="h-9 w-9 text-black block animate-spin" />
        <p className="mt-1">
          Waiting for connection to finish in the other window.
        </p>
      </div>
    );
  } else if (oauthState === OAuthState.Error) {
    return (
      <div className="flex text-center flex-col items-center justify-center w-full">
        <XCircleIcon className="h-9 w-9 text-error block" />
        <p className="mt-1">
          There was an error connecting to {SALESFORCE_PLATFORM.displayName}.
        </p>
        {errorMessage && <p className="mt-1">{errorMessage}</p>}
        <Button
          className="mt-2"
          onClick={() => setOauthState(OAuthState.Initial)}
          variant="primary"
          size="md"
        >
          Try again
        </Button>
      </div>
    );
  }
};
