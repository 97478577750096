import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import memoize from "lodash/memoize";

import { FormProps, OnboardCredentialFormProps } from "lib/platforms";
import { BaseState } from "lib/platforms/base";
import { StripeCredential, STRIPE_PLATFORM } from "lib/platforms/stripe";
import CredentialStep, {
  FullPageCredentialStep,
  OnboardCredentialStep,
} from "components/platforms/common/forms/CredentialStep";
import DestinationStep, {
  FullPageDestinationStep,
} from "components/platforms/common/forms/DestinationStep";
import NameStep, {
  FullPageNameStep,
} from "components/platforms/common/forms/NameStep";
import {
  FullPageRateLimitStep,
  RateLimitStep,
} from "components/platforms/common/forms/RateLimitStep";
import SelectTablesStep from "components/platforms/common/forms/SelectTablesStep";
import { StepProps } from "components/platforms/common/forms/util";
import { SelectEnvironmentStep } from "components/platforms/common/forms/SelectEnvironmentStep";
import { FullPageEventsStep } from "components/platforms/common/forms/EventsStep";

enum Step {
  AddToken = "AddToken",
  SelectEnvironment = "SelectEnvironment",
  RateLimit = "RateLimit",
  SelectTables = "SelectTables",
  Destination = "Destination",
  Advanced = "Advanced",
}

export default function StripeForm({ isCreate }: FormProps) {
  const [collapsed, setCollapsed] = useState<{ [k: string]: boolean }>({
    [Step.AddToken]: !isCreate, // Default open on creates
    [Step.SelectEnvironment]: false,
    [Step.RateLimit]: true,
    [Step.SelectTables]: false,
    [Step.Destination]: !isCreate, // Destination is always collapsed by default
  });

  const genericToggleCollapse = React.useMemo(
    () =>
      memoize(
        (step: Step) => () =>
          setCollapsed((collapsed) => ({
            ...collapsed,
            [step]: !collapsed[step],
          }))
      ),
    []
  );
  const stepProps = {
    isCreate,
    platform: STRIPE_PLATFORM,
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      {!isCreate && <NameStep />}
      <CredentialStep
        {...stepProps}
        collapsed={collapsed[Step.AddToken]}
        onToggleCollapsed={genericToggleCollapse(Step.AddToken)}
        onNextStep={genericToggleCollapse(Step.AddToken)}
      />
      <SelectEnvironmentStep
        collapsed={collapsed[Step.SelectEnvironment]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectEnvironment)}
      />
      <CustomRateLimitStep
        {...stepProps}
        collapsed={collapsed[Step.RateLimit]}
        onToggleCollapsed={genericToggleCollapse(Step.RateLimit)}
      />
      <SelectTablesStep
        {...stepProps}
        collapsed={collapsed[Step.SelectTables]}
        onToggleCollapsed={genericToggleCollapse(Step.SelectTables)}
      />
      <DestinationStep
        {...stepProps}
        collapsed={collapsed[Step.Destination]}
        onToggleCollapsed={genericToggleCollapse(Step.Destination)}
      />
    </div>
  );
}

const CustomRateLimitStep = (stepProps: StepProps) => {
  const { setValue } = useFormContext();

  const credential = useWatch<BaseState<StripeCredential>>({
    name: "credential",
  }) as StripeCredential;

  const isTestCredential = credential && credential.payload.test;
  const credMaxReq = isTestCredential ? 25 : 100;

  useEffect(() => {
    // Set rate limit to 15 req/s on test accounts
    if (isTestCredential && stepProps.isCreate) {
      setValue("rateLimit.allowedRequests", 15);
    }
  });

  return (
    <RateLimitStep
      {...stepProps}
      configurableLimit={{
        minRequestsPerInterval: 2,
        maxRequestsPerInterval: credMaxReq,
        interval: 1_000,
        requestSliderStep: 1,
        notice:
          "This is the maximum amount of quota that Sequin will use. We'll use this quota during your initial backfill and anytime you make schema changes to one of the tables you're syncing.",
        title: "Backfill rate limit",
      }}
      operatingLimit={{
        requestsPerInterval: 2,
        interval: 1_000,
        notice: "During normal syncing, we'll use 2 requests per second.",
        title: "Syncing rate limit",
      }}
      renderNotice={(maxRequests, interval, usedByOtherResources) => (
        <>
          <ExclamationCircleIcon className="h-5 w-5 inline-block text-black mr-1" />{" "}
          {isTestCredential ? "Stripe test" : "Stripe"} accounts are limited to{" "}
          {credMaxReq} read requests and {credMaxReq} write requests per second.{" "}
          {usedByOtherResources != "0" && (
            <>
              Other resources using this account are using{" "}
              {usedByOtherResources} API requests per {interval}.{" "}
            </>
          )}
          <a
            className="link"
            target="_blank"
            href="https://docs.sequin.io/rate-limits"
            rel="noreferrer"
          >
            Read more about rate limits
          </a>
          .
        </>
      )}
    />
  );
};

export const FullPageStripeForm = ({ isCreate }: FormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      {!isCreate && <FullPageNameStep />}
      <FullPageCredentialStep platform={STRIPE_PLATFORM} />
      <hr />
      <FullPageCustomRateLimitStep platform={STRIPE_PLATFORM} />
      <hr />
      <FullPageDestinationStep isCreate={false} platform={STRIPE_PLATFORM} />
      <hr />
      <FullPageEventsStep />
    </div>
  );
};

const FullPageCustomRateLimitStep = (stepProps: StepProps) => {
  const { setValue } = useFormContext();

  const credential = useWatch<BaseState<StripeCredential>>({
    name: "credential",
  }) as StripeCredential;

  const isTestCredential = credential && credential.payload.test;
  const credMaxReq = isTestCredential ? 25 : 100;

  useEffect(() => {
    // Set rate limit to 15 req/s on test accounts
    if (isTestCredential && stepProps.isCreate) {
      setValue("rateLimit.allowedRequests", 15);
    }
  });

  return (
    <FullPageRateLimitStep
      {...stepProps}
      configurableLimit={{
        minRequestsPerInterval: 2,
        maxRequestsPerInterval: credMaxReq,
        interval: 1_000,
        requestSliderStep: 1,
        notice:
          "This is the maximum amount of quota that Sequin will use. We'll use this quota during your initial backfill and anytime you make schema changes to one of the tables you're syncing.",
        title: "Backfill rate limit",
      }}
      operatingLimit={{
        requestsPerInterval: 2,
        interval: 1_000,
        notice: "During normal syncing, we'll use 2 requests per second.",
        title: "Syncing rate limit",
      }}
      renderNotice={(maxRequests, interval, usedByOtherResources) => (
        <>
          <ExclamationCircleIcon className="h-4 w-4 inline-block text-gray-500 mr-0.5" />{" "}
          {isTestCredential ? "Stripe test" : "Stripe"} accounts are limited to{" "}
          {credMaxReq} read requests and {credMaxReq} write requests per second.{" "}
          {usedByOtherResources != "0" && (
            <>
              Other resources using this account are using{" "}
              {usedByOtherResources} API requests per {interval}.{" "}
            </>
          )}
          <a
            className="link"
            target="_blank"
            href="https://docs.sequin.io/rate-limits"
            rel="noreferrer"
          >
            Read more about rate limits
          </a>
          .
        </>
      )}
    />
  );
};

export const OnboardCredentialStripeForm = ({
  handleBackClick,
}: OnboardCredentialFormProps) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      <OnboardCredentialStep
        handleBackClick={handleBackClick}
        platform={STRIPE_PLATFORM}
        dropdownLabel="Stripe Account"
        dropdownPlaceholder="Select an account"
        addCredentialOptionLabel="Install in another account"
        addCredentialStepLabel={() => "Install in another Stripe account"}
      />
    </div>
  );
};
